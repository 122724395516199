import { get } from "../api";
import { Account } from "@/@types/app/Account";
import { IndicatorsData } from "../esus/indicatorService";

type DashboardFederalUnitParams = {
	uf: string;
	quarter: string;
};

export type CityResumeData = {
	city: Account;
	indicators: IndicatorsData;
};

export async function fetchDashboardCities({ uf, ...params }: DashboardFederalUnitParams) {
	const response = await get<CityResumeData[]>(`/uf/${uf}/Indicator/Dashboard`, params);
	return response.data || [];
}
