import { Paginated } from "@/@types/generics/paginated";
import { Problem } from "@/@types/esus/Problem";
import { get } from "../api";

type ProblemParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	cids: string[];
	ciaps: string[];
	youngerAge: number;
	olderAge: number;
	startDate: string;
	endDate: string;
	cns: string;
	gender: string;
	pageNumber: number;
	pageSize?: number;
};
export async function getProblemsPaginated({ uf, ibgeCode, ...problemParams }: ProblemParams) {
	const response = await get<Paginated<Problem>>(
		`/uf/${uf}/city/${ibgeCode}/Problem/Get`,
		problemParams
	);
	return response.data || ({} as Paginated<Problem>);
}
