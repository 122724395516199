import dayjs from "dayjs";

import Select from "react-select";
import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import { Option } from "@/@types/generics/Option";

type Props = {
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	setEpidemicType: (epidemicType: string) => void;
	handleFilter: () => void;
};

const epidemicOptions: Option<string>[] = [
	{ label: "Dengue (Ciap A77)", value: "1" },
	{ label: "Sifílis feminina (Ciap x70)", value: "2" },
	{ label: "Hanseniase (Ciap A78)", value: "3" },
	{ label: "Tuberculose (Ciap A70)", value: "4" },
];

export function EpidemicDashboardFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	setEpidemicType,
	handleFilter,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter();
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
					<Form.Label className="align-items-center fw-bolder">Período</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							type="date"
							placeholder="Data inicial"
							min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
							max={dayjs().format("YYYY/MM/DD")}
							defaultValue={dayjs().subtract(4, "M").format("YYYY-MM-DD")}
							onChange={(event) => setStartDate(event.target.value)}
						/>
					</InputGroup>
					<InputGroup className="mb-3">
						<Form.Control
							type="date"
							placeholder="data final"
							defaultValue={dayjs().format("YYYY-MM-DD")}
							min={startDate}
							max={dayjs().format("YYYY-MM-DD")}
							value={endDate}
							onChange={(event) => setEndDate(event.target.value)}
						/>
					</InputGroup>
				</Col>
				<Col sm={12} lg={4} className="d-flex align-items-center gap-3">
					<Form.Label>Epidemia</Form.Label>
					<InputGroup className="mb-3">
						<Select
							className="w-100"
							name="epidemic"
							options={epidemicOptions}
							onChange={(event) => setEpidemicType((event as Option<string>).value)}
							defaultValue={{ value: "", label: "Escolha uma opção" }}
							noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
						/>
					</InputGroup>
				</Col>
				<Col sm={12} lg={2}>
					<Button type="submit" className="w-100">
						<BsSearch className="me-2" />
						Consultar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
