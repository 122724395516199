import axios from "axios";

import { LOGIN_URL, logout, refreshToken } from "@/services/auth/accountService";

type Token = {
	accessToken?: string;
	refreshToken?: string;
};

export function getApiInstance() {
	const api = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
	});
	const token: Token = JSON.parse(localStorage.getItem("tokens") || "{}");
	const userAccessToken = (token.accessToken || "").replaceAll('"', "");
	const userRefreshToken = (token.refreshToken || "").replaceAll('"', "");

	if (userAccessToken) {
		api.defaults.headers["Authorization"] = `Bearer ${userAccessToken}`;
	}

	api.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalConfig = error.config;

			if (
				originalConfig?.url !== LOGIN_URL &&
				error.response &&
				error.response.status === 401
			) {
				if(!originalConfig._retry) {
					originalConfig._retry = true;
					if (userRefreshToken && userAccessToken) {
						try {
							const tokens = await refreshToken(userAccessToken, userRefreshToken);
							localStorage.setItem(
								"tokens",
								JSON.stringify({
									accessToken: tokens?.token || "",
									refreshToken: tokens?.refreshToken || "",
								})
							);
							originalConfig.headers["Authorization"] = `Bearer ${tokens?.token}`;

							return api(originalConfig);
						} catch (err) {}
					}
				}
				logout();
			}
			return Promise.reject(error);
		}
	);

	return api;
}
