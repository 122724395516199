import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";

import { fetchIndicators, IndicatorsData } from "@/services/esus/indicatorService";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useUnits } from "@/hooks/useUnits";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useLayout } from "@/../_metronic/layout/core";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { SelectOptionsProps } from "@/@types/generics/genericals";

import { defaultUnit } from "@/components/UnitsSelect";
import { defaultHealthTeam } from "@/components/HealthTeamsSelect";
import { DashboardFilters } from "./DashboardFilters";
import { PrevineBrasilComponent } from "./PrevineBrasilComponent";
import { LoadingScreen } from "@/components/LoadingScreen";

import "./styles.css";

export type ISFHealthTeam = {
	healthTeam: HealthTeam | null;
	totalValid: number;
	totalIdentified: number;
	isf: number;
};

export function PrevineBrasilDashboard() {
	const { units } = useUnits();
	const { setTitle } = useLayout();
	const { healthTeams } = useHealthTeams();
	const { uf, ibgeCode, cnes, currentAccount } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [indicatorsData, setIndicatorsData] = useState<IndicatorsData>({} as IndicatorsData);

	const [selectedUnit, setSelectedUnit] = useState<SelectOptionsProps>(defaultUnit);

	const [selectedHealthTeam, setSelectedHealthTeam] =
		useState<SelectOptionsProps>(defaultHealthTeam);
	const quarter = `${Math.ceil((dayjs().month() + 1) / 4)}`;
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<SelectOptionsProps>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});
	const dashboardDataRef = useRef<HTMLTableElement | null>(null);
	const handlePrint = useReactToPrint({
		content: () => dashboardDataRef.current,
	});

	async function handleIndicators() {
		if (!ibgeCode) return;
		setIsLoading(true);

		setIndicatorsData(
			await fetchIndicators(
				uf,
				ibgeCode,
				selectedQuarter.value,
				selectedUnit.value || cnes || "",
				selectedHealthTeam.value
			)
		);
		setIsLoading(false);
	}

	useEffect(() => {
		setTitle("Dashboard ESF");
		handleIndicators();

		return () => {
			setIndicatorsData({} as IndicatorsData);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main className="app-container container-fluid p-2">
			<LoadingScreen loading={isLoading} />
			<section className="bg-white p-2 mb-3 no-print">
				<DashboardFilters
					selectedQuarter={selectedQuarter}
					selectedUnit={selectedUnit}
					selectedHealthTeam={selectedHealthTeam}
					units={units}
					healthTeams={healthTeams}
					handleChangeQuarter={(newValue) =>
						setSelectedQuarter(newValue as SelectOptionsProps)
					}
					handleChangeUnit={(newValue) => setSelectedUnit(newValue as SelectOptionsProps)}
					handleChangeHealthTeams={(newValue) =>
						setSelectedHealthTeam(newValue as SelectOptionsProps)
					}
					handleFilter={handleIndicators}
					handlePrint={handlePrint}
					isLoading={isLoading}
				/>
			</section>
			<section ref={dashboardDataRef}>
				<PrevineBrasilComponent
					city={currentAccount.locale}
					quarter={selectedQuarter.value}
					indicators={indicatorsData}
					selectedUnit={selectedUnit}
					selectedHealthTeam={selectedHealthTeam}
				/>
			</section>
		</main>
	);
}
