import { useEffect, useState } from "react";

import { useFormik } from "formik";
import Yup from "@/utils/mixins/yup";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { MdMode } from "react-icons/md";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { federalUnits } from "@/utils/federalUnits";
import { phoneReplace } from "@/utils/formatPhone";
import statesAndCities from "@/utils/static/states-and-cities.json";
import { StatesAndCities } from "@/@types/statesAndCities";
import { User } from "@/@types/app/user";
import { Account } from "@/@types/app/Account";
import { updateUserAuth } from "@/services/auth/accountService";
import { updateUser } from "@/services/app/userService";

type Props = ModalProps & {
	selectedUser: User;
	titleModal?: string;
};

export function ModalProfessionalEdit({ selectedUser, titleModal, ...rest }: Props) {
	const { uf, ibgeCode, cnes, getCurrentAccount } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [otherOccupation, setOtherOccupation] = useState("");
	const { states, cities } = statesAndCities as StatesAndCities;
	const [selectedState, setSelectedState] = useState("");
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const initialValues = {} as User;

	const validationUserSchema = Yup.object().shape({
		fullName: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 50 caracteres!")
			.required("Nome é Obrigatório!"),
		occupation: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 100 caracteres!")
			.required("Ocupação é Obrigatório!"),
		email: Yup.string()
			.max(100)
			.email("Deve ser um email válido!")
			.required("Email é Obrigatório!"),
		phone: Yup.string().required("Telefone Obrigatório!"),
		gender: Yup.string(),
		street: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 100 caracteres!"),
		neighborhood: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 100 caracteres!"),
		houseNumber: Yup.string().max(10, "Máximo de 10 caracteres!"),
		birthdate: Yup.string().max(10, "Máximo de 10 caracteres!"),
		password: Yup.string().min(6),
	});

	const formik = useFormik<User>({
		initialValues,
		validationSchema: validationUserSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	const handleSubmit = async (user: User) => {
		const selectedCity = cities.find((city) => String(city.id) === user.city?.ibgeCode);
		const occupation =
			formik.values.occupation !== "Outro" ? formik.values.occupation : otherOccupation;
		user.id = selectedUser.id;
		user.phone = phoneReplace(user.phone);
		if (selectedCity) {
			user.city = {
				ibgeCode: String(selectedCity.id),
				locale: selectedCity.nome,
				federalUnitId: federalUnits.find(
					(federalUnit) =>
						federalUnit.abbreviation === selectedCity.microrregiao.mesorregiao.UF.sigla
				)?.id,
			} as Account;
		}
		const convertDate = user.birthdate?.split("/").reverse().join("-");
		handleUpdateUser({ ...user, birthdate: convertDate, occupation: occupation });
	};

	async function handleUpdateUser(user: User) {
		setIsLoading(true);
		const responseAuth = await updateUserAuth(user, password);
		const isUpdated = await updateUser(user);
		if (responseAuth && isUpdated) {
			toast.success("Alterado com sucesso!!");
			handleClose();
			await getCurrentAccount(uf, ibgeCode, cnes);
		} else {
			toast.error("Não foi possível realizar a alteração, tente novamente mais tarde.");
		}
		setIsLoading(false);
	}

	useEffect(() => {
		if (selectedUser) {
			if (selectedUser.city) {
				setSelectedState(selectedUser.city?.federalUnit?.abbreviation || "");
			}
			formik.setValues({
				...selectedUser,
				birthdate:
					selectedUser.birthdate && dayjs(selectedUser.birthdate).format("DD/MM/YYYY"),
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	return (
		<>
			<Modal {...rest} size="xl">
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Confirme sua senha</Modal.Title>
					</Modal.Header>
					<Form onSubmit={formik.handleSubmit}>
						<Modal.Body>
							<h1 className="fs-6"> Digite senha Atual </h1>
							<input
								type="password"
								className="form-control form-control-lg px-5 fs-3"
								onChange={(e) => setPassword(e.target.value)}
								autoComplete="off"
							/>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={handleClose}>
								Fechar
							</Button>
							<Button
								variant="success"
								type="submit"
								disabled={password.length < 6 && isLoading}
							>
								Confimar
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
				<Modal.Header closeButton>
					<Modal.Title>
						{titleModal ? titleModal : "Informações do Profissional"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xl={8} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Nome Completo</Form.Label>
								<Form.Control
									name="fullName"
									value={formik.values.fullName}
									onChange={formik.handleChange}
									className="fs-7 px-3 text-dark"
								/>
							</Form.Group>

							{formik.errors.fullName && (
								<span className="text-danger">{formik.errors.fullName}</span>
							)}
						</Col>

						<Col xl={2} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Telefone</Form.Label>
								<div>
									<InputMask
										className="form-control fs-7 text-dark"
										name="phone"
										id="input-phone"
										type="phone"
										mask="+99(99)99999-9999"
										value={formik.values.phone}
										onChange={formik.handleChange}
									/>
								</div>
							</Form.Group>
							{formik.errors.phone && (
								<span className="text-danger">{formik.errors.phone}</span>
							)}
						</Col>
						<Col xl={2} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Dt. Nascimento</Form.Label>
								<div>
									<InputMask
										className="form-control fs-7 text-dark"
										name="birthdate"
										id="input-phone"
										type="phone"
										mask="99/99/9999"
										value={formik.values.birthdate}
										onChange={formik.handleChange}
									/>
								</div>
							</Form.Group>
							{formik.errors.birthdate && (
								<span className="text-danger">{formik.errors.birthdate}</span>
							)}
						</Col>
					</Row>
					<Row>
						<Col xl={9}>
							<Form.Group>
								<Form.Label className="fs-7">Cargo</Form.Label>
								<select
									value={formik.values.occupation}
									onChange={formik.handleChange}
									name="occupation"
									id="occupation"
									className="form-select form-select-lg fs-7 p-2 text-muted fw-normal"
									onFocus={formik.handleChange}
								>
									<option value="" disabled>
										Qual seu cargo
									</option>
									<option value="Secretário de Saúde">
										Secretário de Saúde{" "}
									</option>
									<option value="Secretário Adjunto">
										Secretário Adjunto (a)
									</option>
									<option value="Coordenador de Secretaria">
										Coordenador de Secretaria
									</option>
									<option value="Coordenador Externo">Coordenador Externo</option>
									<option value="Enfermeiro(a)">Enfermeiro(a)</option>
									<option value="Técnico em Enfermagem">
										Técnico em Enfermagem
									</option>
									<option value="Médico (a)">Médico (a)</option>
									<option value="Cirurgião Dentista ">Cirurgião Dentista</option>
									<option value="Auxiliar/Técnico de Saúde Bucal">
										Auxiliar/Técnico de Saúde Bucal
									</option>
									<option value="Agente Comunitário de Saúde">
										Agente Comunitário de Saúde
									</option>
									<option value="Outro">Outro</option>
								</select>
								{formik.values.occupation === "Outro" && (
									<div className="mt-3">
										<label htmlFor="othet" className="sr-only ">
											Outro Cargo:
										</label>
										<input
											className="form-control form-control-lg fs-7 fw-normal"
											type="text"
											placeholder="Digite seu cargo"
											value={otherOccupation}
											onChange={(e) => {
												setOtherOccupation(e.target.value);
											}}
										/>
									</div>
								)}
							</Form.Group>
						</Col>
						<Col xl={3}>
							<Form.Group>
								<Form.Label className="fs-7">Gênero</Form.Label>

								<select
									{...formik.getFieldProps("gender")}
									id="gender"
									className="form-select form-select-sm fs-7 p-2 text-dark fw-normal"
									defaultValue={formik.values.gender}
								>
									<option value="" selected disabled>
										Selecione
									</option>
									<option value="m">Masculino</option>
									<option value="f">Feminino</option>
									<option value="">Outro </option>
								</select>
							</Form.Group>
							{formik.errors.gender && (
								<span className="text-danger">{formik.errors.gender}</span>
							)}
						</Col>
					</Row>

					<Row>
						<Col md={6} className="mb-4 mt-2">
							<Form.Group>
								<Form.Label className="fs-7">Email</Form.Label>
								<Form.Control
									name="email"
									value={formik.values.email}
									onChange={formik.handleChange}
									className="fs-7 px-x text-dark"
								/>
							</Form.Group>

							{formik.errors.email && (
								<span className="text-danger">{formik.errors.email}</span>
							)}
						</Col>
					</Row>
					<Row>
						<Col md={3} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Estado</Form.Label>
								<select
									name="state"
									id="state"
									value={selectedState}
									onChange={(event) => setSelectedState(event.target.value)}
									className="form-select form-select-lg fs-6 p-2 text-muted fw-normal mb-2"
								>
									<option value="" disabled>
										Selecione o estado
									</option>
									{states.map((state) => (
										<option
											key={state.abbreviation}
											value={state.abbreviation}
											className="fw-normal text-dark"
										>
											{state.abbreviation}
										</option>
									))}
								</select>
							</Form.Group>
						</Col>
						<Col md={6} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Cidade</Form.Label>
								<select
									{...formik.getFieldProps("city")}
									name="city"
									id="city"
									className="form-select form-select-lg fs-6 p-2 text-dark fw-normal"
									value={formik.values.city?.ibgeCode}
									onChange={(event) =>
										formik.setFieldValue("city.ibgeCode", event.target.value)
									}
								>
									<option value="" selected disabled>
										selecione a cidade
									</option>
									{selectedState &&
										cities
											.filter(
												(city) =>
													city.microrregiao.mesorregiao.UF.sigla ===
													selectedState
											)
											.map((city) => (
												<option
													key={city.id}
													value={city.id}
													className="text-dark"
												>
													{city.nome}
												</option>
											))}
								</select>
							</Form.Group>
						</Col>

						<Col md={3} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Bairro</Form.Label>
								<Form.Control
									name="neighborhood"
									value={formik.values.neighborhood}
									onChange={formik.handleChange}
									className="fs-7 px-x text-dark"
								/>
							</Form.Group>

							{formik.errors.neighborhood && (
								<span className="text-danger">{formik.errors.neighborhood}</span>
							)}
						</Col>
					</Row>
					<Row>
						<Col md={10} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Logradouro</Form.Label>
								<Form.Control
									name="street"
									value={formik.values.street}
									onChange={formik.handleChange}
									className="fs-7 px-x text-dark"
								/>
							</Form.Group>

							{formik.errors.street && (
								<span className="text-danger">{formik.errors.street}</span>
							)}
						</Col>
						<Col md={2} className="mb-4">
							<Form.Group>
								<Form.Label className="fs-7">Numero</Form.Label>
								<Form.Control
									name="houseNumber"
									value={formik.values.houseNumber}
									onChange={formik.handleChange}
									className="fs-7 px-x text-dark"
								/>
							</Form.Group>

							{formik.errors.houseNumber && (
								<span className="text-danger">{formik.errors.houseNumber}</span>
							)}
						</Col>
					</Row>
					<div className="d-flex my-2 justify-content-center">
						<Button variant="success" disabled={!formik.isValid} onClick={handleShow}>
							<MdMode className="me-2" />
							Salvar Alterações
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
