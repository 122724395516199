import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { getBanners } from "@/services/app/storageService";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { checkWithInThePeriod } from "@/utils/checkWithInThePeriod";
import { Banner } from "@/@types/app/Banner";

import { CarouselImageProps } from "@/pages/app/InstitutionalPlatform/CarouselImages";
import { CarouselBanner } from "../Banner";

type Props = {
	bannerTypeEnum: BannerTypeEnum;
	style?: React.CSSProperties;
	className?: string;
};

export function BannerDisplay({ bannerTypeEnum, className, style }: Props) {
	const [banners, setBanners] = useState<Banner[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const expirateDate = dayjs().add(10, "minute").format();
	async function handleBanners() {
		setIsLoading(true);
		const cacheKey = `[banners]`;
		setBanners(await fetchWithCache(cacheKey, expirateDate, getBanners));
		setIsLoading(false);
	}

	const carouselImagens = banners
		?.filter(
			(banner) =>
				banner.bannerTypeId === bannerTypeEnum &&
				checkWithInThePeriod(banner.startDate, banner.endDate)
		)
		?.map<CarouselImageProps>((banner) => ({
			title: banner.storageFile?.description,
			url: banner.storageFile?.urlFile,
		}));

	useEffect(() => {
		handleBanners();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerTypeEnum]);

	return !isLoading ? (
		carouselImagens?.length > 1 ? (
			<CarouselBanner carouselImages={carouselImagens} className={className} style={style} />
		) : (
			<img
				src={carouselImagens?.at(0)?.url}
				className={className || "w-100"}
				alt="Banner"
				style={style}
			/>
		)
	) : null;
}
