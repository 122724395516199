import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { DiseasesEnum } from "@/utils/enums/DiseasesEnum";
import { Option } from "@/@types/generics/Option";

import { SelectComponent } from "@/components/SelectComponent";

type Props = {
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	setCommunicableDiseaseType: (communicableDiseaseType: string) => void;
	handleFilter: () => void;
};

const chronicCommunicableDiseasesOptions: Option<string>[] = [
	{ label: "Hanseniase", value: String(DiseasesEnum.HANSEN) },
	{ label: "Tuberculose", value: String(DiseasesEnum.TUBERCULOSIS) },
];
const defaultCommunicableDiseasesOption = chronicCommunicableDiseasesOptions.at(0) || {};

export function ChronicCommunicableDiseasesDashboardFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	setCommunicableDiseaseType,
	handleFilter,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter();
			}}
		>
			<Row>
				<Col sm={12} lg={4} className="mb-3">
					<Form.Group className="w-100 h-100">
						<Form.Label className="fw-bolder">Período</Form.Label>
						<InputGroup className="gap-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								value={startDate}
								onChange={(event) => setStartDate(event.target.value)}
							/>
							<Form.Control
								type="date"
								placeholder="data final"
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								value={endDate}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</InputGroup>
					</Form.Group>
				</Col>
				<Col sm={12} lg={4} className="mb-3">
					<Form.Group className="w-100 h-100">
						<Form.Label>Epidemia</Form.Label>
						<SelectComponent
							name="epidemic"
							options={chronicCommunicableDiseasesOptions}
							onChange={(event) =>
								setCommunicableDiseaseType((event as Option<string>).value)
							}
							defaultValue={defaultCommunicableDiseasesOption}
							noOptionsMessage={() => "nenhum resultado encontrado na pesquisa"}
						/>
					</Form.Group>
				</Col>
				<Col sm={12} lg={4} className="mb-3">
					<div className="d-flex align-items-end justify-content-center justify-content-sm-center justify-content-md-end h-100">
						<Button
							type="submit"
							className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-1 flex-lg-grow-0"
						>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}
