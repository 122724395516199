import { Props as SelectProps } from "react-select";

import { Professional } from "@/@types/esus/Professional";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	professionals: Professional[];
};

export const defaultProfessional = { value: "", label: "Todos os profissionais" };

export function ProfessionalsSelect({ professionals, ...rest }: Props) {
	const options = [
		defaultProfessional,
		...professionals?.map((professional) => ({
			value: professional.cns,
			label: `${professional.name}-${professional.cns}`,
		})),
	];

	return (
		<div className="d-flex gap-2 flex-column">
			<label htmlFor={rest.id}>Profissionais:</label>
			<SelectComponent
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum profissional encontrado"}
				{...rest}
			/>
		</div>
	);
}
