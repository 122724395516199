import { HealthTeam } from "@/@types/esus/HealthTeam";
import { get } from "../api";

type GetHealthTeamsParams = {
	uf: string;
	ibgeCode: string;
	cnes?: string;
};

export async function getHealthTeams({ uf, ibgeCode, ...params }: GetHealthTeamsParams) {
	const { data } = await get<HealthTeam[]>(
		`/uf/${uf}/city/${ibgeCode}/healthteam/gethealthteams`,
		params
	);
	return data || [];
}
