import { CityLicense } from "@/@types/app/CityLicense";
import { get, post } from "../api";
import { LicenseEnum } from "@/utils/enums/LicenseEnum";

type RequestCityLicenseParams = {
	uf: string;
	ibgeCode: string;
	locale: string;
	identifier: string;
	licenseId?: number;
};

export async function getCityLicensesByCity(ibgeCode: string) {
	const { data } = await get<CityLicense[]>("/CityLicense/GetByCity", { ibgeCode });
	return data || [];
}

export async function invalidateCityLicense(ibgeCode: string) {
	const response = await post("/CityLicense/Invalidate", { ibgeCode });
	return response.status === 200;
}

export async function acceptCityLicense(ibgeCode: string) {
	const response = await post("/CityLicense/Activate", { ibgeCode });
	return response.status === 200;
}

export async function rejectCityLicense(ibgeCode: string) {
	const response = await post("/CityLicense/Reject", { ibgeCode });
	return response.status === 200;
}

export async function requestCityLicense({ licenseId, ...params }: RequestCityLicenseParams) {
	const response = await post("/cityLicense/register", {
		licenseId: licenseId || LicenseEnum.TEMPORARY,
		...params,
	});
	return response.status === 200;
}
