import { Paginated } from "@/@types/generics/paginated";
import { get } from "../api";

export type ManagedCitizen = {
	area: string;
	birthDate: string;
	city: string;
	cns: string;
	cpf: string;
	familyGuardianName: string;
	fatherName: string;
	gender: number;
	healthTeam: string;
	healthUnit: {
		cnes: string;
		name: string;
	};
	motherName: string;
	name: string;
	nis: string | null;
	professional: string;
	professionalCns: string;
	registerDate: string;
	registerType: string;
};

type CitizenRegistrationsParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	pageSize: number;
	searchFullName?: string;
	queryFilter: string;
	cnes?: string;
	ine?: string;
};

type CitizensDuplicatedParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	pageSize: number;
	fullName?: string;
	cnes?: string;
	ine?: string;
	motherName?: string;
	birthdate?: string;
	gender?: number;
};

export async function getCitizenRegistrations({
	uf,
	ibgeCode,
	...params
}: CitizenRegistrationsParams) {
	const { data } = await get<Paginated<ManagedCitizen>>(
		`/uf/${uf}/city/${ibgeCode}/registrationmanagement/getcitizensbyfilter`,
		params
	);
	return data || ({} as Paginated<ManagedCitizen>);
}

export async function getCitizensDuplicated({ uf, ibgeCode, ...params }: CitizensDuplicatedParams) {
	const response = await get<Paginated<ManagedCitizen>>(
		`/uf/${uf}/city/${ibgeCode}/registrationmanagement/GetCitizensRegistrationDuplicatesByFilter`,
		params
	);
	return response.data || ({} as Paginated<ManagedCitizen>);
}
