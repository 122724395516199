import { FormEvent } from "react";
import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { BsSearch } from "react-icons/bs";

import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { UnitsSelect } from "@/components/UnitsSelect";

import { HealthTeam } from "@/@types/esus/HealthTeam";
import { Option } from "@/@types/generics/Option";
import { HealthUnit } from "@/@types/esus/HealthUnit";

type Props = {
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	healthTeams: HealthTeam[];
	setSelectedIne: (ine: string) => void;
	healthUnits: HealthUnit[];
	setSelectedCnes: (cnes: string) => void;
	handleFilter: (event: FormEvent) => void;
};

export function TripleViralCitizensResumeFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	healthTeams,
	setSelectedIne,
	healthUnits,
	setSelectedCnes,
	handleFilter,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={12} md={12} lg={6} xl={6} className="justify-content-start mb-2">
					<Form.Label>Período</Form.Label>
					<div className="d-flex gap-3">
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY-MM-DD")}
								value={startDate}
								onChange={(event) => setStartDate(event.target.value)}
							/>
						</InputGroup>
						<InputGroup className="mb-3">
							<Form.Control
								type="date"
								placeholder="data final"
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								value={endDate}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</InputGroup>
					</div>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6}>
					<Row>
						<Col sm={12} md={12} lg={4} xl={4} className="mb-4">
							<HealthTeamsSelect
								healthTeams={healthTeams}
								onChange={(event) =>
									setSelectedIne((event as Option<string>).value)
								}
								defaultValue={{ value: "", label: "selecione..." }}
								noOptionsMessage={() => "Nenhuma equipe encontrada"}
							/>
						</Col>
						<Col sm={12} md={12} lg={4} xl={4} className="mb-4">
							<UnitsSelect
								units={healthUnits}
								onChange={(event) =>
									setSelectedCnes((event as Option<string>).value)
								}
								defaultValue={{ value: "", label: "selecione..." }}
								noOptionsMessage={() => "Nenhuma unidade encontrada"}
							/>
						</Col>
						<Col sm={12} md={12} lg={4} xl={4} className="d-flex align-items-end mb-4">
							<div>
								<Button type="submit">
									<BsSearch className="me-2" />
									Consultar
								</Button>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
