/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { toAbsoluteUrl } from "@/../_metronic/helpers";

import { FaKey, FaRandom, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import { RiAdminFill, RiRadioButtonLine } from "react-icons/ri";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";

import { ModalUnitSelection } from "@/pages/app/UserAccessSelection/ModalUnitSelection";
import { Restrict } from "@/components/Restrict";
import { ModalNewCityLicense } from "@/components/ModalNewCityLicense";

const HeaderUserMenu: FC = () => {
	const { currentAccount, currentFederalUnit, user, currentHealthUnit, uf } = useCurrentAccount();
	const navigate = useNavigate();
	const name = user.fullName;
	const logout = () => {
		navigate("/logout");
	};

	const [showModalUnitSelection, setShowModalUnitSelection] = useState(
		Boolean(!uf) && !user.isAdmin
	);
	const handleOpenModalUnitSelection = () => {
		setShowModalUnitSelection(true);
	};
	const handleCloseModalUnitSelection = () => setShowModalUnitSelection(false);

	const [showModalCityLicense, setShowModalCityLicense] = useState(false);
	const handleOpenModalCityLicense = () => {
		setShowModalCityLicense(true);
	};
	const handleCloseModalCityLicense = () => setShowModalCityLicense(false);

	return (
		<div
			className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
			data-kt-menu="true"
		>
			<ModalUnitSelection
				show={showModalUnitSelection}
				onHide={handleCloseModalUnitSelection}
			/>
			<ModalNewCityLicense show={showModalCityLicense} onHide={handleCloseModalCityLicense} />

			<div className="menu-item px-3">
				<div className="menu-content px-3">
					<div className="d-flex">
						<div className="symbol symbol-50px me-5">
							<img alt="Logo" src={toAbsoluteUrl("/media/avatars/blank.png")} />
						</div>

						<div className="d-flex flex-column">
							<h6 className="fw-bolder fs-5 text-uppercase m-0">{name}</h6>
							<span className="text-muted fs-7">{user?.occupation}</span>
							<span className="text-primary fs-7 mb-2">
								{!user.isAdmin ? (
									<span>
										<RiRadioButtonLine />
										{currentFederalUnit?.abbreviation ? (
											<label>
												{` ${[
													currentAccount?.locale,
													currentFederalUnit?.abbreviation,
												]
													.filter(
														(value) => value && value.trim().length > 0
													)
													.join(" - ")}`}
											</label>
										) : (
											<label></label>
										)}
									</span>
								) : (
									<span className="d-flex text-primary">
										<RiAdminFill className="text-primary fs-3" />
										{currentFederalUnit?.abbreviation ? (
											<label>
												{` ${[
													currentAccount?.locale,
													currentFederalUnit?.abbreviation,
												]
													.filter(
														(value) => value && value.trim().length > 0
													)
													.join(" - ")}`}
											</label>
										) : (
											<label>Acesso Administrador</label>
										)}
									</span>
								)}
							</span>
						</div>
					</div>
					<Restrict permissions={[PermissionEnum.VIEW_INDICATORS]}>
						<div className="form-control fs-8 pt-1" style={{ lineHeight: 1.3 }}>
							{currentHealthUnit?.name ? (
								currentHealthUnit?.name
							) : (
								<label className="d-flex align-items-center">
									Todas as Unidades
								</label>
							)}
						</div>
					</Restrict>
				</div>
			</div>
			<div className="separator my-2"></div>
			<div className="menu-item px-5">
				<a onClick={() => navigate("/perfil")} className="menu-link px-5 fs-7">
					<FaUserCircle className="fs-8 me-3" /> Meu Perfil
				</a>
			</div>
			<div className="separator my-2"></div>
			<div className="menu-item px-5">
				<a onClick={handleOpenModalUnitSelection} className="menu-link px-5 fs-7">
					<FaRandom className="fs-8 me-3" /> Trocar Unidade/Cidade
				</a>
			</div>
			<div className="separator my-2"></div>
			<div className="menu-item px-5">
				<a onClick={handleOpenModalCityLicense} className="menu-link px-5 fs-7">
					<FaKey className="fs-8 me-3" /> Solicitar Acesso
				</a>
			</div>
			<div className="separator my-2"></div>

			<div className="menu-item px-5">
				<a onClick={logout} className="menu-link px-5 fs-7">
					<FaSignOutAlt className="fs-8 me-3" /> Sair
				</a>
			</div>
		</div>
	);
};

export { HeaderUserMenu };
