import { useState } from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";

import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import { identifierReplaceAll } from "@/utils/formatIdentifier";
import { User } from "@/@types/app/user";
import { recoveryInformation } from "@/services/auth/accountService";

import { ChooseConfirmationModal } from "./ChooseConfirmationModal";

const initialValues = {
	identifier: "",
};

const forgotPasswordSchema = Yup.object().shape({
	identifier: Yup.string()
		.min(14, "CPF inválido")
		.max(14, "CPF inválido")
		.required("CPF é obrigatório"),
});

export function ForgotPassword() {
	const [infoMask, setInfoMask] = useState<User>();
	const [isLoading, setIsLoading] = useState(false);
	const [showChooseConfirmation, setShowChooseConfirmation] = useState(false);

	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: handleSubmit,
	});

	async function handleSubmit() {
		setIsLoading(true);
		const userIdentifier = identifierReplaceAll(formik.values.identifier);
		const userInfo = await recoveryInformation(userIdentifier);
		if (userInfo) {
			setInfoMask({ email: userInfo.email, phone: userInfo.phone } as User);
			setShowChooseConfirmation(true);
		} else {
			toast.error("Ocorreu um erro ao buscar as informações do usuário.");
		}
		setIsLoading(false);
	}

	return (
		<div>
			<ChooseConfirmationModal
				show={showChooseConfirmation}
				onHide={() => setShowChooseConfirmation(false)}
				infoMask={infoMask}
				identifier={formik.values.identifier}
			/>
			<Row className="mb-7 text-center">
				<Col>
					<h3>Recuperação de Conta</h3>
				</Col>
			</Row>

			<form noValidate id="forgot-pass-word-form" onSubmit={formik.handleSubmit}>
				<Row className="mb-4">
					<Col>
						<h5>
							Informe seu CPF que enviaremos um código para definir uma nova senha
						</h5>
					</Col>
				</Row>
				<div className="d-flex justify-content-center mb-10">
					<div className="d-flex flex-column">
						<ReactInputMask
							placeholder="CPF"
							{...formik.getFieldProps("identifier")}
							className={clsx(
								"form-control form-control-lg",
								{
									"is-invalid":
										formik.touched.identifier && formik.errors.identifier,
								},
								{
									"is-valid":
										formik.touched.identifier && !formik.errors.identifier,
								}
							)}
							maskChar={null}
							name="identifier"
							id="input-identifier"
							type="text"
							mask="999.999.999-99"
							autoComplete="off"
							value={formik.values.identifier}
							onChange={formik.handleChange}
							style={{ width: "min-content" }}
							autoFocus={true}
						/>
						{formik.touched.identifier && formik.errors.identifier && (
							<div className="fv-plugins-message-container text-danger">
								<span role="alert">{formik.errors.identifier}</span>
							</div>
						)}
					</div>
				</div>

				<div className="d-flex flex-wrap justify-content-center gap-3">
					<Link to="/auth/login">
						<button
							type="button"
							id="kt_login_password_reset_form_cancel_button"
							className="btn btn-lg btn-light-primary fw-bolder"
						>
							Cancelar
						</button>
					</Link>

					<Button
						type="submit"
						id="bt-choose-confirmation-submit"
						className="btn btn-lg btn-primary fw-bolder"
						disabled={!formik.isValid && !infoMask}
					>
						{isLoading ? (
							<span>
								Por favor, aguarde...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						) : (
							<span className="indicator-label text-white">Enviar</span>
						)}
					</Button>
				</div>
			</form>
		</div>
	);
}
