import { useEffect, useState } from "react";

import { toast } from "react-toastify";

import Select from "react-select";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import statesAndCities from "@/utils/static/states-and-cities.json";
import { LicenseEnum } from "@/utils/enums/LicenseEnum";
import { SelectOptionsProps } from "@/@types/generics/genericals";
import { StatesAndCities } from "@/@types/statesAndCities";
import { Account } from "@/@types/app/Account";
import { Role } from "@/@types/app/Role";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { RoleEnum, StateRoleEnum } from "@/utils/enums/RoleEnum";
import { getPermittedFederalUnits } from "@/services/app/userService";
import { getActivatedCities, getNotLinkedCities } from "@/services/app/cityService";
import { getAllProfiles } from "@/services/app/profileService";
import { requestAccessToCity } from "@/services/app/userService";
import { requestCityLicense } from "@/services/app/cityLicenseService";

import { ProfileSelect } from "@/components/ProfileSelect";

type Props = ModalProps & {
	handleClose?: () => void;
};

export function ModalNewCityLicense({ handleClose, ...rest }: Props) {
	const { user, uf, ibgeCode, cnes, getCurrentAccount } = useCurrentAccount();
	const { states, cities } = statesAndCities as StatesAndCities;
	const [confirmTerms, setConfirmTerms] = useState(false);
	const [activatedCities, setActivatedCities] = useState<Account[]>([]);
	const [selectedOptionProfile, setSelectedOptionProfile] = useState<SelectOptionsProps>();
	const [selectedOptionState, setSelectedOptionState] = useState<SelectOptionsProps>();
	const [selectedOptionCity, setSelectedOptionCity] = useState<SelectOptionsProps>();
	const [selectedOptionAccessType, setSelectedOptionAccessType] = useState("city");
	const [isLoading, setIsLoading] = useState(false);
	const [permittedFederalUnits, setPermittedFederalUnits] = useState<FederalUnit[]>([]);

	const [notLinkedCities, setNotLinkedCities] = useState<Account[]>([]);
	const [roles, setRoles] = useState<Role[]>([]);

	const filteredRoles = roles.filter((role) =>
		selectedOptionAccessType === "uf"
			? Object.values(StateRoleEnum).find((stateRole) => String(role.id) === stateRole)
			: Object.values(RoleEnum).find((roleObject) => String(role.id) === roleObject)
	);

	async function handleFetchNotLinkedCities() {
		setNotLinkedCities(await getNotLinkedCities());
	}

	async function handleFetchProfiles() {
		setRoles(await getAllProfiles());
	}

	async function handleRegister() {
		setIsLoading(true);
		if (
			activatedCities.find(
				(cityActivated) => String(cityActivated.ibgeCode) === selectedOptionCity?.value
			) ||
			(selectedOptionState?.value && !selectedOptionCity?.value)
		) {
			await handleRequestAccessToCity(
				selectedOptionState?.value || "",
				selectedOptionCity?.value,
				selectedOptionProfile?.value
			);
		} else {
			await handleRequestCityLicense();
		}
		setIsLoading(false);
	}

	async function handleRequestAccessToCity(uf: string, ibgeCode?: string, roleId?: string) {
		const isRequested = await requestAccessToCity(uf, ibgeCode, roleId);
		if (isRequested) {
			toast.info(
				`Solicitação registrada. Entre em contato com o gestor do municipio para efetuar a ativação.`,
				{ bodyClassName: "fs-5" }
			);
			setTimeout(() => {
				handleClose && handleClose();
			}, 3000);
		} else {
			toast.error(`Erro ao solicitar acesso.`, { bodyClassName: "fs-5" });
		}
	}

	const selectedCityIsActive =
		activatedCities.filter(
			(cityActivated) => cityActivated.ibgeCode === selectedOptionCity?.value
		).length > 0;

	const selectStates: SelectOptionsProps[] = states
		.filter(
			(state) =>
				selectedOptionAccessType === "city" ||
				(selectedOptionAccessType === "uf" &&
					permittedFederalUnits.filter(
						(federalUnit) => federalUnit?.abbreviation !== state.abbreviation
					))
		)
		.map((state) => ({
			label: state.abbreviation,
			value: String(state.abbreviation),
		}));

	const isDisabledRegisterButton =
		isLoading ||
		!Boolean(
			selectedOptionCity &&
				selectedOptionCity.value &&
				selectedOptionProfile &&
				selectedOptionProfile.value &&
				((confirmTerms && !selectedCityIsActive) || selectedCityIsActive)
		);

	const selectCities = cities
		.filter(
			(city) =>
				city.microrregiao.mesorregiao.UF.sigla === selectedOptionState?.value &&
				(!activatedCities.find(
					(cityActivated) => cityActivated.ibgeCode === String(city.id)
				) ||
					notLinkedCities.find(
						(notLinkedAccount) => notLinkedAccount.ibgeCode === String(city.id)
					))
		)
		.map(
			(city) =>
				({
					label: city.nome,
					value: String(city.id),
				} as SelectOptionsProps)
		);

	async function handleFetchCitiesActivated() {
		setActivatedCities(await getActivatedCities());
	}

	async function handleFetchPermittedFederalUnits() {
		setPermittedFederalUnits(await getPermittedFederalUnits());
	}

	async function handleRequestCityLicense() {
		if (!selectedOptionState?.value || !selectedOptionCity?.value || !selectedOptionCity?.label)
			return;
		const isRequested = await requestCityLicense({
			uf: selectedOptionState?.value,
			ibgeCode: selectedOptionCity?.value,
			locale: selectedOptionCity?.label,
			identifier: user.identifier,
			licenseId: LicenseEnum.TEMPORARY,
		});
		if (isRequested) {
			await getCurrentAccount(uf, ibgeCode, cnes);
			toast.success("A confirmação da liberação da licença, chegará no seu whatssap.", {
				bodyClassName: "fs-5",
			});
			await handleFetchCitiesActivated();
			setSelectedOptionCity({ value: "", label: "Selecione uma cidade" });
			setTimeout(() => {
				handleClose && handleClose();
			}, 3000);
		} else {
			toast.error("Falha na solicitação.");
		}
	}

	useEffect(() => {
		if (rest.show) {
			handleFetchPermittedFederalUnits();
			handleFetchCitiesActivated();
			handleFetchNotLinkedCities();
			handleFetchProfiles();
		}
		return () => {
			setSelectedOptionCity({} as SelectOptionsProps);
			setSelectedOptionState({} as SelectOptionsProps);
			setSelectedOptionProfile({} as SelectOptionsProps);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rest.show]);

	return (
		<Modal size="lg" {...rest}>
			<Modal.Header closeVariant="white" closeButton style={{ backgroundColor: "#0c6fd1" }}>
				<Modal.Title className="text-white">Solicitação de Acesso</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="mb-5 ms-3 me-3 pe-10 ps-10">
					<Row className="mb-4">
						<Col>
							<label htmlFor="option" className="mb-4">
								Formato de acesso
							</label>
							<div>
								<Form.Check
									inline
									label="Municipal"
									className="text-nowrap"
									name="option"
									type="radio"
									id="city-radio"
									onChange={() => {
										setSelectedOptionAccessType("city");
										setSelectedOptionProfile({
											value: "2",
											label: "Administrador",
										} as SelectOptionsProps);
									}}
									checked={selectedOptionAccessType === "city"}
								/>
								<Form.Check
									inline
									label="Estadual"
									className="text-nowrap"
									name="option"
									type="radio"
									id="uf-radio"
									onChange={() => {
										setSelectedOptionAccessType("uf");
										setSelectedOptionCity({
											value: "",
											label: "Selecione uma cidade",
										});
										setSelectedOptionProfile({
											value: "5",
											label: "Administrador estadual",
										} as SelectOptionsProps);
									}}
									checked={selectedOptionAccessType === "uf"}
								/>
							</div>
						</Col>
					</Row>
					<Row>
						<Col lg={2} md={2}>
							<label htmlFor="state">Estado</label>
							<Select
								onChange={(newValue) =>
									setSelectedOptionState(newValue as SelectOptionsProps)
								}
								defaultValue={{ value: "", label: "UF" }}
								noOptionsMessage={() => "Nenhum estado encontrado"}
								options={selectStates}
								value={selectedOptionState}
								name="state"
								id="state"
								className="fs-3 text-muted fw-normal mb-2"
							/>
						</Col>
						<Col lg={10} md={10}>
							<label htmlFor="city">Cidade de vinculo profissional</label>
							<Select
								onChange={(newValue) =>
									setSelectedOptionCity(newValue as SelectOptionsProps)
								}
								defaultValue={{ value: "", label: "Selecione uma cidade" }}
								noOptionsMessage={() => "Nenhuma cidade encontrada"}
								options={selectCities}
								value={selectedOptionCity}
								name="city"
								id="city"
								className="fs-3 text-muted fw-normal mb-2"
								isDisabled={selectedOptionAccessType === "uf"}
							/>
						</Col>
					</Row>
					<Row>
						<ProfileSelect
							id="Profile-select"
							className="fs-3"
							noDefaultOption={true}
							roles={filteredRoles}
							value={selectedOptionProfile}
							onChange={(newValue) => {
								setSelectedOptionProfile(newValue as SelectOptionsProps);
							}}
							isDisabled={selectedOptionAccessType === "uf"}
						/>
					</Row>
					{!(selectedCityIsActive || selectedOptionAccessType === "uf") && (
						<div className="mt-3">
							<input
								className="form-check-input fs-9"
								type="checkbox"
								name="confirm-terms"
								checked={confirmTerms}
								onChange={() => setConfirmTerms(!confirmTerms)}
								disabled={selectedCityIsActive || selectedOptionAccessType === "uf"}
							/>
							<label className="mx-2">
								Confirmo que estou solicitando uma licença temporária.
							</label>
						</div>
					)}
				</section>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={rest.onHide}>
					Fechar
				</Button>
				<Button
					disabled={
						selectedOptionState?.value && selectedOptionAccessType === "uf"
							? false
							: isDisabledRegisterButton
					}
					variant="success"
					onClick={handleRegister}
				>
					Confimar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
