import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { phoneFormat } from "@/utils/formatPhone";
import { User } from "@/@types/app/user";
import {
	getAdminSettingsByIdentifier,
	updateAdminSettings,
} from "@/services/app/adminSettingsService";

import { Restrict } from "@/components/Restrict";

type Props = ModalProps & {
	selectedUser: User;
};

type AdminSettings = {
	enableNewCityNotification: boolean;
	userId: number;
};

export function ModalProfessionalInformation({ selectedUser, ...rest }: Props) {
	const { user } = useCurrentAccount();
	const [adminSettings, setAdminSettings] = useState<AdminSettings>({} as AdminSettings);

	async function handleUpdateAdminSettings() {
		const isUpdated = await updateAdminSettings(
			selectedUser.identifier,
			!adminSettings?.enableNewCityNotification
		);
		if (isUpdated) {
			toast.success("Permissão Atualizada!!", { autoClose: 1000 });
			await getAdminSettings();
		} else {
			toast.error("Não foi possível Alterar a Permissão de Acesso", { autoClose: 1500 });
		}
	}

	async function getAdminSettings() {
		if (!user.isAdmin) return;
		setAdminSettings(await getAdminSettingsByIdentifier(selectedUser.identifier));
	}

	useEffect(() => {
		getAdminSettings();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser]);

	return (
		<Modal {...rest} size="xl">
			<Modal.Header closeButton>
				<Modal.Title className="text-primary">Informações do profissional</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="rounded bg-white">
					<Row className="w-100 px-3 py-3">
						<Col lg={6} className="d-flex align-items-center justify-content-start">
							<h3 className="text-primary ms-3">Dados Pessoais</h3>
						</Col>
					</Row>
					<Row className="d-flex justify-content-between w-100 px-5 py-3">
						<Col lg={6}>
							<Row className="py-2">
								<label className="fs-7 text-break">
									<strong>Nome completo: </strong>
									{selectedUser.fullName}
								</label>
							</Row>
							<Row className="py-2">
								<label className="fs-7 text-break">
									<strong>Email: </strong>
									{selectedUser.email}
								</label>
							</Row>
							<Row className="py-2">
								<label className="fs-7">
									<strong>Cargo: </strong>
									{selectedUser.occupation}
								</label>
							</Row>
						</Col>

						<Col lg={2}>
							<Row className="py-2">
								<label className="fs-7">
									<strong>Sexo: </strong>
									{selectedUser.gender}
								</label>
							</Row>
							<Row className="py-2">
								<label className="fs-7">
									<strong>CPF: </strong>
									{formatIdentifier(selectedUser.identifier)}
								</label>
							</Row>
						</Col>

						<Col lg={4}>
							<Row className="py-2">
								<label className="fs-7">
									<strong>Dt. Nascimento: </strong>
									{selectedUser.birthdate &&
										dayjs(selectedUser.birthdate).format("DD/MM/YYYY")}
								</label>
							</Row>
							<Row className="py-2">
								<label className="fs-7">
									<strong>WhatsApp: </strong>
									{phoneFormat(selectedUser.phone)}
								</label>
							</Row>
						</Col>
					</Row>
					<hr
						className="dashed-line bg-white mx-4"
						style={{ border: "1px dashed #000" }}
					/>
					<Row className="rounded px-5 py-5">
						<Row>
							<h3 className="text-primary">Endereço: </h3>
						</Row>
						<Row>
							<Col lg={1} className="fs-7 py-2">
								<strong className="me-1">Estado:</strong>
								{selectedUser.city?.federalUnit?.abbreviation}
							</Col>
							<Col lg={3} className="fs-7 py-2">
								<strong className="me-1">Cidade:</strong>
								{selectedUser.city?.locale}
							</Col>
							<Col lg={4} className="fs-7 py-2 text-break">
								<strong className="me-1">Logradouro:</strong>
								{selectedUser.street}
							</Col>
							<Col lg={2} className="fs-7 py-2">
								<strong className="me-1">N°:</strong>
								{selectedUser.houseNumber}
							</Col>
							<Col lg={2} className="fs-7 py-2">
								<strong className="me-1">Bairro:</strong>
								{selectedUser.neighborhood}
							</Col>
						</Row>
					</Row>
					<Row className="p-4">
						{selectedUser.isAdmin && (
							<Restrict>
								<div className="form-check ms-5">
									<input
										className="form-check-input"
										type="checkbox"
										checked={adminSettings?.enableNewCityNotification}
										id="flexCheckDefault"
										onChange={handleUpdateAdminSettings}
									/>
									<label className="form-check-label">
										<strong>
											Receber notificações de novas cidades cadastradas por
											whatsapp
										</strong>
									</label>
								</div>
							</Restrict>
						)}
					</Row>
				</section>
			</Modal.Body>
		</Modal>
	);
}
