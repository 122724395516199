import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { TrackOrder } from "@/@types/app/TrackOrder";
import { TrackFile } from "@/@types/app/TrackFile";
import { TrackList } from "@/@types/app/TrackList";
import { LogUserViewTrack } from "@/@types/app/LogUserViewTrack";
import { getTrackOrdersByTrackListId } from "@/services/app/trackOrderService";
import {
	getCurrentLogUserViewTrack,
	getVideoUrl,
	updateTrackProgress,
} from "@/services/app/trackFileService";

import { Video } from "@/components/Video";
import { Skeleton } from "@/components/Skeleton";
import { Playlist } from "../PlayList";

export function Player() {
	const navigate = useNavigate();
	const { trackListId, sequence } = useParams();
	const { setTitle } = useLayout();
	const [latestLogTrack, setLatestLogTrack] = useState<LogUserViewTrack>({} as LogUserViewTrack);
	const [playedSeconds, setPlayedSeconds] = useState(0);
	const [isContinueWatching, setIsContinueWatching] = useState(true);

	const [urlVideo, setUrlVideo] = useState("");
	const [isLoadingTracksOrders, setIsLoadingTrackOrders] = useState(false);
	const [trackOrders, setTrackOrders] = useState<TrackOrder[]>([]);
	const currentTrackList =
		trackOrders.find((trackOrder) => String(trackOrder.trackListId) === trackListId)
			?.trackList || ({} as TrackList);
	const currentTrackFile =
		trackOrders.find((trackOrder) => String(trackOrder.sequenceOrder) === sequence)
			?.trackFile || ({} as TrackFile);

	async function fetchVideoUrl() {
		const trackFileId = currentTrackFile?.id;
		if (!trackFileId) return;
		setUrlVideo(await getVideoUrl(trackFileId));
	}

	async function fetchTrackOrders() {
		if (!trackListId) return;
		setIsLoadingTrackOrders(true);
		setTrackOrders(await getTrackOrdersByTrackListId(Number(trackListId)));
		setIsLoadingTrackOrders(false);
	}

	async function fetchCurrentLog() {
		const trackOrderId = trackOrders.find(
			(trackOrder) => String(trackOrder.trackListId) === trackListId
		)?.id;
		if (!trackOrderId) return;
		setLatestLogTrack(await getCurrentLogUserViewTrack(trackOrderId));
	}

	async function handleUpdateTrackProgress(playedSeconds: number) {
		const trackOrderId = trackOrders.find(
			(trackOrder) => String(trackOrder.sequenceOrder) === sequence
		)?.id;
		if (!trackOrderId) return;
		await updateTrackProgress(trackOrderId, playedSeconds);
	}

	async function handleEndProgress() {
		await handleUpdateTrackProgress(currentTrackFile.duration);
		const nextTrack = trackOrders
			.sort((currentTrack, nextTrack) => currentTrack.sequenceOrder - nextTrack.sequenceOrder)
			.find((trackOrder) => trackOrder.sequenceOrder > (Number(sequence) || 0));
		if (nextTrack) {
			navigate(
				`/ambiente-de-apoio/acesso-a-plataforma/tracks/${nextTrack.trackListId}/video/${nextTrack.sequenceOrder}`
			);
		}
	}

	useEffect(() => {
		if (currentTrackList.id && currentTrackFile.id) {
			fetchCurrentLog();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [trackOrders]);

	useEffect(() => {
		if (currentTrackFile.id) {
			fetchVideoUrl();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentTrackFile]);

	useEffect(() => {
		if (Math.floor(playedSeconds % 10) === 0 && trackOrders && trackOrders.length > 0) {
			handleUpdateTrackProgress(playedSeconds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [playedSeconds]);

	useEffect(() => {
		setTitle("AMBIENTE DE APOIO");
		fetchTrackOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			{!isLoadingTracksOrders && currentTrackList.id ? (
				<Card>
					<Card.Header>
						<Card.Title className="font-roboto m-2">
							{currentTrackList?.title}
						</Card.Title>
					</Card.Header>
					<Row className="mt-2 h-100">
						<Col sm={12} md={12} lg={9} xxl={8} className="h-100">
							<Video
								progressInterval={1000}
								onEnded={() => handleEndProgress()}
								onProgress={(state) => setPlayedSeconds(state.playedSeconds)}
								onPause={() => handleUpdateTrackProgress(playedSeconds)}
								url={urlVideo || ""}
								onReady={(player) => {
									if (isContinueWatching && latestLogTrack.currentTimeTrack) {
										player.seekTo(latestLogTrack.currentTimeTrack, "seconds");
										setIsContinueWatching(false);
									}
								}}
							/>
							<section className="m-5">
								<h2 className="fw-bolder font-roboto">{currentTrackFile.title}</h2>
								<p className="font-roboto">{currentTrackFile.description}</p>
							</section>
						</Col>
						<Col sm={12} md={12} lg={3} xxl={4} className="p-0 h-100">
							<Playlist
								isLoading={isLoadingTracksOrders}
								videos={trackOrders
									.sort(
										(trackOrderA, trackOrderB) =>
											trackOrderA.sequenceOrder - trackOrderB.sequenceOrder
									)
									.map((trackOrder) => trackOrder.trackFile!)}
							/>
						</Col>
					</Row>
				</Card>
			) : (
				<Row>
					<Col sm={12} md={12} lg={9} xxl={9}>
						<Skeleton className="m-2" height={20} width={"50%"} />
						<section className="m-5">
							<Skeleton className="m-2" height="30rem" width={"100%"} />
							<Skeleton height={15} width={"100%"} />
							<Skeleton height={15} width={"25%"} />
						</section>
					</Col>
					<Col sm={12} md={12} lg={3} xxl={3}>
						{Array(4)
							.fill(null)
							.map((_, index) => (
								<Row key={`skeleton-TrackList-item-carousel-${index}`}>
									<Skeleton
										className="mt-2 rounded ps-1"
										width="100%"
										height={120}
									/>
								</Row>
							))}
					</Col>
				</Row>
			)}
		</div>
	);
}
