import { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaEye, FaInfoCircle } from "react-icons/fa";

import { Shift } from "@/@types/esus/Shift";
import { Ciap } from "@/@types/esus/Ciap";
import { Cid } from "@/@types/esus/Cid";
import { ResumeGrouped } from "@/services/esus/attendanceService";

import {
	PopulationByGenderAndAge,
	PopulationByGenderAndAgeGroupBarChart,
} from "../PopulationByGenderBarChart";
import { DonutChart } from "@/components/Charts/DonutChart";
import { BarChart } from "@/components/Charts/BarChart";
import { AttendanceRateByCiapModal } from "./AttendanceRateByCiapModal";
import { AttendanceRateByCidModal } from "./AttendanceRateByCidModal";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

type Props = {
	attendancesAggregatedByAgeAndGender: ResumeGrouped<string, ResumeGrouped<string, object>>[];
	attendancesAggregatedByShift: ResumeGrouped<Shift, object>[];
	problemsAggregatedByCiapAndProfessionalCategory: ResumeGrouped<
		Ciap,
		ResumeGrouped<string, object>
	>[];
	problemsAggregatedByCidAndProfessionalCategory: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
};

export function CitizenProfileAttendanceDetails({
	attendancesAggregatedByAgeAndGender,
	attendancesAggregatedByShift,
	problemsAggregatedByCiapAndProfessionalCategory,
	problemsAggregatedByCidAndProfessionalCategory,
}: Props) {
	const [showAttendanceRateByCiapModal, setShowAttendanceRateByCiapModal] = useState(false);
	const [showAttendanceRateByCidModal, setShowAttendanceRateByCidModal] = useState(false);

	const ageGroupCategories = attendancesAggregatedByAgeAndGender
		.map<PopulationByGenderAndAge>((dashboard) => {
			return {
				ageGroup: dashboard.key || "",
				men: dashboard.values?.find((ageGroup) => ageGroup.key === "Masculino")?.total || 0,
				women:
					dashboard.values?.find((ageGroup) => ageGroup.key === "Feminino")?.total || 0,
			};
		})
		.sort((a, b) => b.ageGroup.localeCompare(a.ageGroup));

	const shiftAttendanceSeries = attendancesAggregatedByShift.map((shift) => shift.total);
	const shiftAttendanceCategories = attendancesAggregatedByShift.map(
		(shift) => shift.key.description
	);

	const ciapsSeries = problemsAggregatedByCiapAndProfessionalCategory.map(
		(aggregatedAttendances) => aggregatedAttendances.total
	);
	const ciapsCategories = problemsAggregatedByCiapAndProfessionalCategory
		.map((aggregatedAttendances) => aggregatedAttendances.key)
		.map((ciap) => (ciap.ciapCode ? `${ciap.ciapCode} - ${ciap.description}` : ""));

	const cidsSeries = problemsAggregatedByCidAndProfessionalCategory.map(
		(aggregatedProblems) => aggregatedProblems.total
	);
	const cidsCategories = problemsAggregatedByCidAndProfessionalCategory
		.map((aggregatedProblems) => aggregatedProblems.key)
		.map((cid) => (cid.cidCode ? `${cid.cidCode} - ${cid.description}` : ""));

	return (
		<section>
			<Row className="p-2">
				<Col sm={12} md={12} lg={12} xl={6} xxl={6}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex gap-2">
								Atendimentos por faixa etária
								<CardOverlayTrigger
									header={"Atendimentos por faixa etária"}
									message={
										"Total de atendimentos realizados por faixa etária e gênero"
									}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="justify-content-center" style={{ height: 400 }}>
							<PopulationByGenderAndAgeGroupBarChart
								populationByGenderAndAges={ageGroupCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={12} xl={6} xxl={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fw-bolder justify-content-between align-items-center">
							<Card.Title className="d-flex gap-2">
								Atendimentos por turno
								<CardOverlayTrigger
									header={"Atendimentos por turno"}
									message={"Total de atendimentos realizados por turno"}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="justify-content-center" style={{ height: 400 }}>
							<DonutChart
								series={shiftAttendanceSeries}
								labels={shiftAttendanceCategories}
								height={"100%"}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								Cids mais utilizados
								<Button
									className="no-print"
									variant="secondary"
									onClick={() => setShowAttendanceRateByCidModal(true)}
								>
									<FaEye className="me-2" />
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body className="ps-0">
							<BarChart
								series={[{ data: cidsSeries.slice(0, 10), name: "Total" }]}
								categories={cidsCategories.slice(0, 10)}
								categoryTextWidth={250}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								Ciaps mais utilizados
								<Button
									className="no-print"
									variant="secondary"
									onClick={() => setShowAttendanceRateByCiapModal(true)}
								>
									<FaEye className="me-2" />
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body className="ps-0">
							<BarChart
								series={[{ data: ciapsSeries.slice(0, 10), name: "Total" }]}
								categories={ciapsCategories.slice(0, 10)}
								categoryTextWidth={250}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AttendanceRateByCiapModal
				aggregatedAttendanceCiaps={problemsAggregatedByCiapAndProfessionalCategory}
				show={showAttendanceRateByCiapModal}
				onHide={() => setShowAttendanceRateByCiapModal(false)}
			/>
			<AttendanceRateByCidModal
				aggregatedAttendanceCids={problemsAggregatedByCidAndProfessionalCategory}
				show={showAttendanceRateByCidModal}
				onHide={() => setShowAttendanceRateByCidModal(false)}
			/>
		</section>
	);
}
