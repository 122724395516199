import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { FaUser, FaWhatsapp } from "react-icons/fa";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { getDailyScheduleDetails, TScheduleDetails } from "@/services/esus/TScheduleService";
import { TScheduleStatusEnum } from "@/utils/enums/TScheduleStatusEnum";
import { phoneValidation } from "@/utils/phoneValidation";
import { phoneFormat } from "@/utils/formatPhone";
import { getProfessionalCategory } from "@/utils/getProfessionalCategory";
import { Option } from "@/@types/generics/Option";
import { Paginated } from "@/@types/generics/paginated";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";

import { ProfessionalCategorySelect } from "@/components/ProfessionalCategorySelect";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { PaginationLinks } from "@/components/PaginationLinks";
import { SearchInput } from "@/components/SearchInput";
import { TableSkeletons } from "@/components/TableSkeletons";
import { ScheduleStatusSelect } from "@/components/ScheduleStatusSelect";
import { LegendData } from "@/components/LegendData";

export type SchedulingDetailsProps = {
	startDate: string;
	endDate: string;
	healthUnit?: Option<string>;
	healthTeam?: Option<string>;
	professional?: Option<string>;
	category?: Option<string>;
};

export function SchedulingDetails({
	startDate,
	endDate,
	healthUnit,
	healthTeam,
	professional,
	category,
}: SchedulingDetailsProps) {
	const { ibgeCode, uf, cnes } = useCurrentAccount();
	const [schedulesDetails, setSchedulesDetails] = useState<Paginated<TScheduleDetails>>();
	const [searchCitizen, setSearchCitizen] = useState("");
	const debouncedSearchCitizen = useDebounce(searchCitizen, 1000);
	const [selectedProfessionalCategoryModal, setSelectedProfessionalCategoryModal] = useState<
		Option<string>
	>(category || ({ value: "", label: "Selecione..." } as Option<string>));
	const [selectedScheduleStatusModal, setSelectedScheduleStatusModal] =
		useState<Option<TScheduleStatus>>();
	const [pageNumber, setPageNumber] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	async function handleSchedulesDetails(pageNumber?: number) {
		return await getDailyScheduleDetails({
			ibgeCode: ibgeCode,
			uf: uf,
			startDate: startDate,
			endDate: endDate,
			fullName: searchCitizen,
			cnes: healthUnit?.value || cnes,
			cns: professional?.value,
			ine: healthTeam?.value,
			professionalCategory: selectedProfessionalCategoryModal?.value || category?.value,
			scheduleStatusId: selectedScheduleStatusModal?.value?.id,
			pageNumber: pageNumber || 1,
			pageSize: 10,
		});
	}

	async function fetch(pageNumber?: number) {
		setIsLoading(true);
		setSchedulesDetails(await handleSchedulesDetails(pageNumber));
		setIsLoading(false);
	}

	useEffect(() => {
		if (pageNumber > 1) {
			setPageNumber(1);
		}
		fetch();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedProfessionalCategoryModal,
		selectedScheduleStatusModal,
		debouncedSearchCitizen,
		startDate,
		endDate,
	]);

	return (
		<div>
			<Row>
				<Col>
					<label className="mb-2">Pesquisar Cidadão:</label>
					<SearchInput
						placeholder="Pesquisar"
						value={searchCitizen}
						setValue={(value) => setSearchCitizen(value)}
					/>
				</Col>
				<Col>
					<ProfessionalCategorySelect
						onChange={(event) => {
							setSelectedProfessionalCategoryModal(event as Option<string>);
						}}
						defaultValue={
							category?.label === "Outros" || category?.value === null
								? { value: "", label: "Selecione..." }
								: { value: category?.value, label: category?.label }
						}
						noOptionsMessage={() => "Nenhum categoria profissional encontrada"}
					/>
				</Col>
				<Col>
					<ScheduleStatusSelect
						onChange={(event) => {
							setSelectedScheduleStatusModal(event as Option<TScheduleStatus>);
						}}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhum Status encontrado"}
					/>
				</Col>
			</Row>
			<Row className="mt-3 overflow-auto">
				<LegendData
					titles={[
						"Agendado",
						"Cancelado",
						"Presentes",
						"Não compareceram",
						"Não aguardaram",
						"Excluidos",
						"Atendidos",
					]}
					colors={[
						"#b4b5c2",
						"#f1416c",
						"#04E69A",
						"#E6E6E6",
						"#ffd52f",
						"#7239ea",
						"#008FFB",
					]}
				/>
			</Row>
			<Table className="bg-white mt-3" striped bordered hover responsive>
				<thead className="text-center fs-7">
					<tr className="fw-bolder">
						<th>Data</th>
						<th>Horário</th>
						<th className="px-2">Nome</th>
						<th>Telefone</th>
						<th>Profissional</th>
						<th>Categoria</th>
						<th>CBO</th>
						<th>Observação</th>
					</tr>
				</thead>
				<tbody className="fs-8">
					{!isLoading && schedulesDetails?.data ? (
						schedulesDetails?.data?.map((item, index) => (
							<tr
								key={`item-${index}`}
								className={`text-center ${
									item.status?.identifier === TScheduleStatusEnum.ATTENDED
										? "text-primary"
										: item.status?.identifier === TScheduleStatusEnum.CANCELED
										? "text-danger"
										: item.status?.identifier ===
										  TScheduleStatusEnum.CITIZEN_PRESENT
										? "text-success"
										: item.status?.identifier ===
										  TScheduleStatusEnum.DID_NOT_SHOW_UP
										? "text-black"
										: item.status?.identifier ===
										  TScheduleStatusEnum.DID_NOT_WAIT
										? "text-warning"
										: item.status?.identifier === TScheduleStatusEnum.EXCLUDED
										? "text-info"
										: item.status?.identifier === TScheduleStatusEnum.SCHEDULED
										? "text-muted"
										: ""
								}`}
							>
								<td>{dayjs(item.scheduleDate).format("DD/MM/YYYY")}</td>
								<td>{dayjs(item.scheduleDate).format("HH:mm:ss")}</td>
								<td className="text-start">{item.citizenExtra?.fullName}</td>
								<td>
									{item.citizenExtra?.phone &&
										phoneValidation(item.citizenExtra.phone) && (
											<label className="d-flex justify-content-between">
												<label className="text-nowrap me-2">
													{phoneFormat("55" + item.citizenExtra.phone)}
												</label>

												<a
													rel="noreferrer"
													href={`https://wa.me/55${item.citizenExtra.phone}`}
													target="_blank"
												>
													<div title="Contato">
														<FaWhatsapp className="text-success" />
													</div>
												</a>
											</label>
										)}
								</td>
								<td>{item.professional?.name}</td>
								<td>{getProfessionalCategory(item.category || "") || ""}</td>
								<td>{item.cbo?.description}</td>
								<th className="text-lowercase">{item.annotation}</th>
							</tr>
						))
					) : (
						<TableSkeletons numberOfCells={8} />
					)}
				</tbody>
			</Table>
			<section className="d-flex mt-3 justify-content-center align-items-center">
				{schedulesDetails?.totalRecords ? (
					<PaginationLinks
						itemsPerPage={schedulesDetails?.pageSize}
						totalPages={schedulesDetails?.totalRecords}
						changeSelectedPage={(pageNumber) => {
							setPageNumber(pageNumber);
							fetch(pageNumber);
						}}
						pageNumber={pageNumber}
					/>
				) : (
					schedulesDetails?.totalRecords === 0 &&
					!isLoading && (
						<div className="w-100 justify-content-center">
							<NoRecordsFeedback
								message={"Nenhum agendamento encontrado."}
								icon={<FaUser />}
							/>
						</div>
					)
				)}
			</section>
		</div>
	);
}
