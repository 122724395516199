type Props = {
	title?: string;
	zipCode?: string;
	city?: {
		id?: number;
		name?: string;
		state?: {
			id?: number;
			name?: string;
		};
	};
	state?: string;
	neighborhood?: string;
	street?: string;
	houseNumber?: string;
};

export function Map({ houseNumber, street, city }: Props) {
	const stateReplaced = city?.state?.name
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");

	const cityReplaced = city?.name
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");
	const streetReplaced = street
		?.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/ /g, "+");

	const url = `${houseNumber || ""}+${streetReplaced || ""}-${cityReplaced}+${stateReplaced}`;

	return (
		<iframe
			title="maps"
			width="100%"
			height="400rem"
			id="gmap_canvas"
			src={`https://maps.google.com/maps?q=${url},&t=&z=13&ie=UTF8&iwloc=&output=embed`}
		/>
	);
}
