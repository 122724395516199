import { get, post } from "../api";
import { TrackList } from "@/@types/app/TrackList";

export type TrackListFormData = {
	title: string;
	description: string;
	thumbnailFile?: File;
};

export async function registerTrackList(trackListFormData: TrackListFormData) {
	if (!trackListFormData.thumbnailFile) return false;
	const formData = new FormData();
	formData.append("thumbnailFile", trackListFormData.thumbnailFile);
	formData.append("title", trackListFormData.title);
	formData.append("description", trackListFormData.description);
	const response = await post("/tracklist/register", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.status === 200;
}

export async function updateTrackList(trackList: TrackList) {
	const response = await post("/TrackList/Update", { id: trackList.id, ...trackList });
	return response.status === 200;
}

export async function getTrackListById(trackListId: number) {
	const { data } = await get<TrackList>("/tracklist/GetById", { trackListId });
	return data || ({} as TrackList);
}

export async function getAllTrackLists() {
	const { data } = await get<TrackList[]>("/tracklist/all");
	return data || [];
}
