import { Paginated } from "@/@types/generics/paginated";
import { get, post } from "../api";
import { Role } from "@/@types/app/Role";
import { User } from "@/@types/app/user";
import { PaginationFilter } from "@/@types/generics/genericals";
import { UserAccount } from "@/@types/app/UserAccount";
import { CityLicense } from "@/@types/app/CityLicense";

export type UserAccountsUnitsAccess = {
	userIdentifier: string;
	userName: string;
	ibgeCode: string;
	healthUnitName: string;
	isActive: boolean;
	cnes: string;
	role: Role;
};

export type UpdateAccessUserAccountParams = {
	userId: number;
	uf: string;
	ibgeCode: string;
	cnesList: string[];
	roleId?: string;
};

type CurrentAccount = {
	userAccount?: UserAccount;
	permissions?: string[];
	currentCityLicense?: CityLicense;
	isAuthenticated?: boolean;
};

type GetUsersAccountParams = PaginationFilter & {
	uf?: string;
	ibgeCode?: string;
	cnes?: string;
	fullName?: string;
	isLocked: boolean | null;
	hasProfileActive: boolean | null;
};

type RevokeAccessUserAccountParams = {
	uf?: string;
	ibgeCode?: string;
	userId: number;
};

export async function getUserAccounts(
	uf?: string,
	ibgeCode?: string,
	identifier?: string,
	cnes?: string
) {
	const { data } = await get<UserAccountsUnitsAccess[]>(
		`${uf ? `/uf/${uf}` : ""}${
			uf && ibgeCode ? `/city/${ibgeCode}` : ""
		}/UserAccount/GetUserAccountsUnitsAccess/`,
		{
			identifier,
			cnes,
		}
	);
	return data || [];
}

export async function getCurrentUserAccount(uf?: string, ibgeCode?: string, cnes?: string) {
	const response = await get<CurrentAccount>(
		`${uf ? `/uf/${uf}` : ""}${
			uf && ibgeCode ? `/city/${ibgeCode}` : ""
		}/UserAccount/GetCurrent`,
		{
			cnes,
		}
	);
	return { ...response.data, isAuthenticated: response.status === 200 };
}

export async function updateAccessUserAccount({
	uf,
	ibgeCode,
	...params
}: UpdateAccessUserAccountParams) {
	const response = await post(
		`${uf ? `/uf/${uf}` : ""}${
			uf && ibgeCode ? `/city/${ibgeCode}` : ""
		}/UserAccount/UpdateAccess`,
		params
	);
	return response.status === 200;
}

export async function getUsersAccount({ uf, ibgeCode, ...params }: GetUsersAccountParams) {
	const { data } = await get<Paginated<User>>(
		`${uf ? `/uf/${uf}` : ""}${
			uf && ibgeCode ? `/city/${ibgeCode}` : ""
		}/UserAccount/UsersAccount`,
		params
	);
	return data || ({} as Paginated<User>);
}

export async function revokeAccessUserAccount({
	uf,
	ibgeCode,
	...params
}: RevokeAccessUserAccountParams) {
	const response = await post(
		`${uf ? `/uf/${uf}` : ""}${
			uf && ibgeCode ? `/city/${ibgeCode}` : ""
		}/UserAccount/RevokeAccess`,
		params
	);
	return response.status === 200;
}
