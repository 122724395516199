import { Props as SelectProps } from "react-select";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { Option } from "@/@types/generics/Option";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	units: HealthUnit[];
	noDefaultOption?: boolean;
};

export const defaultUnit = { value: "", label: "Todas as unidades" };

export function UnitsSelect({ units, noDefaultOption, ...rest }: Props) {
	let options: Option<string>[] = [
		...units?.map((unit) => ({ value: unit.cnes, label: unit.name && unit.cnes ? `${unit.name}-${unit.cnes}`: "Todas as unidades" })),
	];
	if (!noDefaultOption) {
		options = [defaultUnit, ...options];
	}
	return (
		<div className="d-flex flex-column gap-2">
			<label title="Lista de Unidades Disponíveis" htmlFor={rest.id}>
				Unidades:
			</label>
			<SelectComponent
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma unidade encontrada"}
				{...rest}
			/>
		</div>
	);
}
