import { useState } from "react";

import { BsArrowBarUp, BsFolderCheck, BsFolderX, BsFileEarmarkCheck } from "react-icons/bs";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import { uploadHealthInformationSystemForPrimaryCare } from "@/services/esus/sisabPreliminaryReportService";

import { quarters } from "@/utils/quarters";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";

type Props = ModalProps & {
	handleGetReports: () => void;
};

type UploadProps = {
	quarter: string;
	fileType: string;
};

export function ModalUploadHealthInformationSystemForPrimaryCare({
	handleGetReports,
	...rest
}: Props) {
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [importModalStatus, setImportModalStatus] = useState<"form" | "success" | "error">(
		"form"
	);

	const quartersArray = quarters().map((quarter) => {
		return {
			name: quarter.split("/").join(" - "),
			value: quarter,
		};
	});

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		multiple: false,
		accept: {
			"text/csv": [".csv"],
		},
	});

	const [file] = acceptedFiles;
	const borderDropzoneBorderSlug = file ? "success" : "primary";

	const initialValues = {
		quarter: "",
		fileType: "",
	};

	const uploadSchema = Yup.object().shape({
		quarter: Yup.string().required("O quadrimestre é obrigatório!"),
		fileType: Yup.string().required("O tipo de relatório é obrigatório!"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: uploadSchema,
		onSubmit: (values) => {
			const data = {
				quarter: values.quarter,
				fileType: values.fileType,
			};

			handleUploadHealthInformationSystemForPrimaryCare(data);
		},
	});

	async function handleUploadHealthInformationSystemForPrimaryCare({
		quarter,
		fileType,
	}: UploadProps) {
		const isUploaded = await uploadHealthInformationSystemForPrimaryCare({
			quarter,
			fileType,
			uf,
			ibgeCode,
			cnes,
			file,
		});
		if (isUploaded) {
			setImportModalStatus("success");

			setTimeout(() => {
				onHideModal();
			}, 2500);

			handleGetReports();
		} else {
			setImportModalStatus("error");
		}
	}

	function onHideModal() {
		rest.onHide?.();
		setTimeout(() => {
			setImportModalStatus("form");

			formik.resetForm({
				values: {
					quarter: "",
					fileType: "",
				},
			});

			acceptedFiles.pop();
		}, 500);
	}

	return (
		<Modal {...rest} size="lg" onHide={onHideModal}>
			<Modal.Header className="d-flex flex-column">
				<button
					id="btn-close-modal-upload"
					className="btn-close"
					onClick={onHideModal}
				></button>
				<div className="col-xl-8 d-flex flex-column mx-auto text-center">
					<Modal.Title>Adicione um Relatório</Modal.Title>
					<p>
						Ao adicionar um novo arquivo em um quadrimestre que já possui um relatório
						enviado, ele será substituido pelo novo.
					</p>
				</div>
			</Modal.Header>
			<Modal.Body>
				{importModalStatus === "form" ? (
					<Form onSubmit={formik.handleSubmit}>
						<div className="mb-4">
							<h5>Selecione um Quadrimestre</h5>
							<div className="d-flex gap-4">
								<ButtonGroup className="mb-2">
									{quartersArray.map((quarter, index) => (
										<ToggleButton
											id="togleButton"
											key={index}
											name="quarter"
											type="radio"
											variant="secondary"
											className="me-4 rounded-2"
											value={quarter.value}
											checked={formik.values.quarter === quarter.value}
											onClick={() => {
												formik.setFieldValue("quarter", quarter.value);
											}}
										>
											{quarter.name}
										</ToggleButton>
									))}
								</ButtonGroup>
							</div>
							{formik.errors.quarter && (
								<span className="text-danger fs-6">{formik.errors.quarter}</span>
							)}
						</div>
						<div className="mb-4">
							<h5 className="mb-4">Selecione o Tipo de Relatório</h5>
							<div className="border-bottom mb-4">
								<Form.Check
									type="radio"
									name="fileType"
									value="0"
									checked={formik.values.fileType === "0"}
									onChange={formik.handleChange}
									label={
										<>
											<h6>Gestantes</h6>
											<p>
												Relatório de apoio ao cuidado contendo relação
												prévia de gestantes a serem analisadas no
												quadrimestre indicado acima.
											</p>
										</>
									}
								/>
							</div>
							<div className="border-bottom mb-4">
								<Form.Check
									type="radio"
									name="fileType"
									value="1"
									checked={formik.values.fileType === "1"}
									onChange={formik.handleChange}
									label={
										<>
											<h6>Crianças</h6>
											<p>
												Relatório de apoio ao cuidado contendo relação
												prévia de crianças a serem analisadas no
												quadrimestre indicado acima.
											</p>
										</>
									}
								/>
							</div>
							{formik.errors.fileType && (
								<span className="text-danger fs-6">{formik.errors.fileType}</span>
							)}
						</div>
						<Card>
							<Card.Body className="mb-4">
								<div className="card-inputs">
									<div className="fv-row">
										<div
											{...getRootProps({
												className: `dropzone border-${borderDropzoneBorderSlug}`,
											})}
										>
											<input
												name="file"
												{...getInputProps({ className: "d-none" })}
											/>
											<div className="dz-message needsclick d-flex align-items-center justify-content-center">
												{!file ? (
													<BsArrowBarUp className="text-primary fs-3x" />
												) : (
													<BsFileEarmarkCheck className="text-success fs-3x" />
												)}
												<div className="ms-4">
													{!file ? (
														<>
															<h3 className="fs-5 fw-bolder text-gray-900 mb-1">
																Solte os arquivos aqui ou clique
																para fazer o upload.
															</h3>
															<span className="fs-7 fw-bold text-gray-400">
																Upload somente arquivo .csv
															</span>
														</>
													) : (
														<h3>{file?.name}</h3>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
						<div className="d-flex justify-content-center gap-2">
							<Button variant="secondary" onClick={onHideModal}>
								Fechar
							</Button>
							<Button type="submit" variant="primary">
								Enviar
							</Button>
						</div>
					</Form>
				) : importModalStatus === "success" ? (
					<>
						<Card>
							<Card.Body className="mb-4">
								<div className="card-inputs">
									<div className="fv-row">
										<div className="dropzone border-success">
											<div className="dz-message needsclick  d-flex align-items-center justify-content-center">
												<BsFolderCheck className="text-success fs-3x" />
												<div className="ms-4">
													<h3 className="fs-5 fw-bolder text-gray-900 mb-1">
														UPLOAD CONCLÚIDO COM SUCESSO
													</h3>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
						<div className="d-flex justify-content-center">
							<Button variant="primary" onClick={onHideModal}>
								Concluido
							</Button>
						</div>
					</>
				) : (
					<>
						<Card>
							<Card.Body className="mb-4">
								<div className="card-inputs">
									<div className="fv-row">
										<div className="dropzone border-danger">
											<div className="dz-message needsclick d-flex justify-content-center">
												<BsFolderX className="text-danger fs-3x" />
												<div className="ms-4">
													<h3 className="fs-5 fw-bolder text-gray-900 mb-1">
														ERRO AO FAZER UPLOAD
													</h3>
													<span className="fs-7 fw-bold text-gray-400">
														Adicione um arquivo no formato .csv
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Card.Body>
						</Card>
						<div className="d-flex gap-2 justify-content-center">
							<Button variant="secondary" onClick={onHideModal}>
								Fechar
							</Button>
							<Button variant="primary" onClick={() => setImportModalStatus("form")}>
								Tentar novamente
							</Button>
						</div>
					</>
				)}
			</Modal.Body>
		</Modal>
	);
}
