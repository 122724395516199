import { useEffect, useState } from "react";
import dayjs from "dayjs";

import { RiFileExcel2Fill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useDebounce } from "@/hooks/useDebounce";
import { Paginated } from "@/@types/generics/paginated";
import { ActiveOlderVaccination } from "@/@types/esus/ActiveOlderVaccination";
import { Option } from "@/@types/generics/Option";
import {
	ActiveVaccinationResume,
	handleActiveOlderVaccinationResume,
	handleActiveOlderVaccinationSearch,
} from "@/services/esus/activeVaccinationService";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { exportExcel } from "@/utils/exports";
import { GenericObject } from "@/@types/generics/genericals";

import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { SearchInput } from "@/components/SearchInput";
import { GlobalInformativeActiveVaccination } from "../GlobalInformativeActiveVaccination";
import { Skeleton } from "@/components/Skeleton";
import { TableActiveOlderVaccinationSearch } from "./TableActiveOlderVaccinationSearch";
import { BarChart } from "@/components/Charts/BarChart";
import { ActiveVaccinationFilters } from "../ActiveVaccinationFilters";
import { LoadingScreen } from "@/components/LoadingScreen";
import { TImmunobiological } from "@/@types/esus/TImmunobiological";

type Props = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export function ActiveVaccinationOlder({ uf, ibgeCode, cnes }: Props) {
	const { setTitle } = useLayout();
	const [searchFullName, setSearchFullName] = useState<string>("");
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const debouncedFullName = useDebounce(searchFullName, 2000);
	const [selectedImmunobiological, setSelectedImmunobiological] = useState<
		Option<TImmunobiological>
	>({} as Option<TImmunobiological>);
	const [selectedHealthUnit, setSelectedHealthUnit] = useState<Option<string>>(
		{} as Option<string>
	);
	const [selectedHealthTeam, setSelectedhealthTeam] = useState<Option<string>>(
		{} as Option<string>
	);
	const [activeOlderVaccinationData, setActiveOlderVaccinationData] = useState<
		Paginated<ActiveOlderVaccination>
	>({} as Paginated<ActiveOlderVaccination>);
	const [activeOlderVaccinationResume, setActiveOlderVaccinationResume] = useState<
		ActiveVaccinationResume[]
	>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingResume, setIsLoadingResume] = useState(false);
	const cacheExpirationDate = getExpirationDateMidnight();

	const totalImmunobiologicalPreview = activeOlderVaccinationResume
		.map((item) => item.immunobiologicalPreview)
		.reduce((acc, curr) => acc + curr, 0);

	const totalImmunobiologicalDelayed = activeOlderVaccinationResume
		.map((item) => item.immunobiologicalDelayed)
		.reduce((acc, curr) => acc + curr, 0);

	const imunobiologicalCategories = activeOlderVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalName);

	const vaccinesDelayedSeries = activeOlderVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalDelayed);

	const vaccinesPendingSeries = activeOlderVaccinationResume
		?.sort((a, b) => b.immunobiologicalPreview - a.immunobiologicalPreview)
		?.map((vaccine) => vaccine.immunobiologicalPreview - vaccine.immunobiologicalDelayed);

	async function handleActiveVaccinationResume() {
		return handleActiveOlderVaccinationResume({
			uf: uf,
			ibgeCode: ibgeCode,
			cnes: selectedHealthUnit.value || cnes,
			ine: selectedHealthTeam.value,
			immunobiologicalCod: selectedImmunobiological.value?.id,
		});
	}
	async function handleActiveVaccination(pageSize?: number) {
		return handleActiveOlderVaccinationSearch({
			uf: uf,
			ibgeCode: ibgeCode,
			pageNumber: pageNumber,
			fullName: searchFullName,
			cnes: selectedHealthUnit.value || cnes,
			ine: selectedHealthTeam.value,
			pageSize: pageSize,
			immunobiologicalCod: selectedImmunobiological.value?.id,
		});
	}

	async function fetchResume() {
		const cacheKey = `[searchActiveOlderVaccinationResume][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}][${selectedHealthTeam.value}][${selectedImmunobiological.value?.id}]`;
		setIsLoadingResume(true);
		setActiveOlderVaccinationResume(
			await fetchWithCache(cacheKey, cacheExpirationDate, handleActiveVaccinationResume)
		);
		setIsLoadingResume(false);
	}

	async function fetchData() {
		const cacheKey = `[searchActiveOlderVaccination][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}][${selectedHealthTeam.value}][${
			selectedImmunobiological.value?.id
		}][${searchFullName}][${pageNumber}]`;
		setIsLoading(true);
		setActiveOlderVaccinationData(
			await fetchWithCache(cacheKey, cacheExpirationDate, handleActiveVaccination)
		);
		setIsLoading(false);
	}

	async function handleExportTable() {
		setShowLoadingScreen(true);
		const response = await handleActiveVaccination(activeOlderVaccinationData.totalRecords);
		setShowLoadingScreen(false);

		if (Object.keys(response?.data || []).length) {
			const excelData: GenericObject[] = transformToExportExcel(response?.data);
			exportExcel(excelData, `Tabela`);
		}
	}

	function transformToExportExcel(oldersVaccination: ActiveOlderVaccination[]) {
		return oldersVaccination.map(
			(older) =>
				({
					Nome: older.fullName,
					Cpf: older.identifier,
					Cns: older.cns,
					"Data de Nascimento": dayjs(older.birthdate).format("DD/MM/YYYY"),
					"Nº de pendências": older.registers.length ?? "-",
				} as GenericObject)
		);
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		setTitle("BUSCA ATIVA VACINAL");
		fetchResume();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);
			return;
		}
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedFullName]);

	useEffect(() => {
		if (pageNumber !== 0) {
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageNumber]);

	return (
		<div>
			<LoadingScreen loading={showLoadingScreen} />
			<ActiveVaccinationFilters
				handleSearch={() => {
					fetchResume();
					fetchData();
				}}
				setSelectedImmunobiological={setSelectedImmunobiological}
				setSelectedHealthUnit={setSelectedHealthUnit}
				setSelectedHealthTeam={setSelectedhealthTeam}
			/>
			<Row>
				<GlobalInformativeActiveVaccination
					total={activeOlderVaccinationResume.at(0)?.totalCitizens || 0}
					totalPreview={totalImmunobiologicalPreview}
					totalPending={totalImmunobiologicalPreview - totalImmunobiologicalDelayed}
					totalDelayed={totalImmunobiologicalDelayed}
				/>
			</Row>
			<Row className="d-flex justify-content-stretch my-2">
				<Col
					md={6}
					lg={6}
					xl={6}
					className="d-flex align-items-center justify-content-center bg-white"
				>
					<Card className="w-100 h-100">
						<Card.Header>
							<Card.Title>Quantidade de vacinas previstas</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex justify-content-center align-items-center p-2 w-100 h-100">
							{!isLoadingResume ? (
								<BarChart
									categories={imunobiologicalCategories}
									series={[
										{
											name: "Pendentes",
											data: vaccinesPendingSeries,
										},
										{
											name: "Atrasadas",
											data: vaccinesDelayedSeries,
										},
									]}
									paddingRight={40}
									categoryTextWidth={100}
									colors={["#ffc700", "#f1416c"]}
									isStacked
								/>
							) : (
								<div className="d-flex flex-column w-100 text-center  ">
									<Skeleton height={250} width="95%" />
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>

				<Col md={6} lg={6} xl={6}>
					<Card className="w-100 h-100">
						<Card.Header className="text-center">
							<Card.Title>Detalhamento por imunobiológico</Card.Title>
						</Card.Header>
						<Card.Body className="d-flex p-2 w-100 h-100">
							{!isLoadingResume ? (
								<Table>
									<thead>
										<tr>
											<th className="fw-bolder text-center text-muted">
												Imunobiológico
											</th>
											<th className="fw-bolder text-center bg-info opacity-75 text-white">
												Previstas
											</th>
											<th className="fw-bolder text-center bg-warning pe-2 opacity-75 text-white">
												Pendentes
											</th>
											<th className="fw-bolder text-center bg-danger opacity-75 text-white">
												Atrasadas
											</th>
										</tr>
									</thead>
									<tbody>
										{activeOlderVaccinationResume.map((item, index) => (
											<tr key={index}>
												<td className="ps-2 text-muted fs-8">
													{item.immunobiologicalName}
												</td>
												<td className="text-center text-info">
													{item.immunobiologicalPreview?.toLocaleString()}
												</td>
												<td className="text-center text-warning">
													{(
														Number(item.immunobiologicalPreview || 0) -
														Number(item.immunobiologicalDelayed || 0)
													)?.toLocaleString()}
												</td>
												<td className="text-center text-danger">
													{item.immunobiologicalDelayed?.toLocaleString()}
												</td>
											</tr>
										))}
									</tbody>
								</Table>
							) : (
								<div className="d-flex w-100 text-start flex-column ps-4">
									<Skeleton height={250} width="90%" />
								</div>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Card>
				<Card.Body>
					<Row className="d-flex justify-content-between align-items-end my-3">
						<Col sm={12} md={5} lg={5} xl={5}>
							<label className="mb-2">
								<strong>Pesquisar Cidadão:</strong>
							</label>
							<SearchInput
								placeholder="Pesquisar"
								value={searchFullName}
								setValue={setSearchFullName}
							/>
						</Col>
						<Col
							xs={12}
							sm={6}
							md={2}
							lg={2}
							xl={2}
							className="d-flex flex-column my-2"
						>
							<Button
								variant="secondary"
								className="px-2"
								disabled={isLoading}
								onClick={handleExportTable}
							>
								<RiFileExcel2Fill />
								Exportar
							</Button>
						</Col>
					</Row>
					<TableActiveOlderVaccinationSearch
						activeOlderVaccinationData={activeOlderVaccinationData}
						isLoading={isLoading}
					/>
				</Card.Body>
				<Card.Footer className="no-print">
					{activeOlderVaccinationData.totalRecords ? (
						<PaginationLinks
							itemsPerPage={activeOlderVaccinationData.pageSize}
							totalPages={activeOlderVaccinationData.totalRecords}
							changeSelectedPage={handleChangePageNumber}
							pageNumber={pageNumber || 1}
						/>
					) : (
						activeOlderVaccinationData.totalRecords === 0 &&
						!isLoading && (
							<NoRecordsFeedback
								message={"Nenhum resultado encontrado."}
								icon={<FaUser />}
							/>
						)
					)}
				</Card.Footer>
			</Card>
		</div>
	);
}
