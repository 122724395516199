import { ModalProps } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { phoneFormat } from "@/utils/formatPhone";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { User } from "@/@types/app/user";

type Props = ModalProps & {
	userSelectedLicense?: User;
};

export default function UserInformationModal({ userSelectedLicense, ...rest }: Props) {
	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>
					Informações do Solicitante
					<label className="d-flex text-primary fs-6 ">
						{userSelectedLicense?.fullName}
					</label>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col lg={6}>
						<label className="fs-5 text-break">
							<strong>CPF: </strong>
							{formatIdentifier(userSelectedLicense?.identifier || "")}
						</label>
					</Col>
					<Col lg={6}>
						<label className="fs-5 text-break">
							<strong>Telefone: </strong>
							{phoneFormat(userSelectedLicense?.phone || "")}
						</label>
					</Col>
				</Row>
				<Row className="my-2">
					<Col lg={12}>
						<label className="fs-5 text-break">
							<strong>Email: </strong>
							{userSelectedLicense?.email}
						</label>
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={rest.onHide}>
					Fechar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
