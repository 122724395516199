import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useFormik } from "formik";
import Yup from "@/utils/mixins/yup";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { ReleaseNote } from "@/@types/app/ReleaseNote";
import { registerReleaseNote, updateReleaseNote } from "@/services/app/releaseNoteService";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type Props = {
	releaseNote?: ReleaseNote;
	onSuccess?: () => void;
};

export function FormReleaseNote({ releaseNote, onSuccess }: Props) {
	const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(true);

	const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

	const initialValues = {
		description: "",
		title: "",
		version: "",
	} as ReleaseNote;

	const validationReleaseNoteSchema = Yup.object().shape({
		id: Yup.number(),
		description: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(1500, "Máximo de 1500 caracteres!")
			.required("Descrição é Obrigatório!"),
		title: Yup.string()
			.min(2, "Mínimo de 2 caracteres!")
			.max(150, "Máximo de 150 caracteres!")
			.required("Título é Obrigatório!"),
		version: Yup.string()
			.min(2, "Mínimo de 2 caracteres!")
			.max(20, "Máximo de 20 caracteres!")
			.required("Versão é Obrigatório!"),
		createdAt: Yup.string(),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationReleaseNoteSchema,
		onSubmit: (values) => {
			const data = {
				id: values.id,
				description: values.description,
				title: values.title,
				version: values.version,
				createdAt: values.createdAt,
			} as ReleaseNote;
			handleRegisterOrUpdateReleaseNote(data);
		},
	});

	async function handleRegisterReleaseNote(releaseNote: ReleaseNote) {
		const isRegistered = await registerReleaseNote(releaseNote);
		if (isRegistered) {
			toast.success("Cadastrado com sucesso!!");
			onSuccess && onSuccess();
		} else {
			toast.error("Não foi possível realizar o cadastro, algo está errado.");
		}
	}

	async function handleUpdateReleaseNote(releaseNote: ReleaseNote) {
		const isUpdated = await updateReleaseNote(releaseNote);
		if (isUpdated) {
			toast.success("Atualizado com sucesso!!");
			onSuccess && onSuccess();
		} else {
			toast.error("Não foi possível realizar a atualização, algo está errado.");
		}
	}

	async function handleRegisterOrUpdateReleaseNote(releaseNoteEdit: ReleaseNote) {
		if (releaseNote) {
			await handleUpdateReleaseNote(releaseNoteEdit);
		} else {
			await handleRegisterReleaseNote(releaseNoteEdit);
		}
	}

	function handleFocusInput() {
		setIsUpdateButtonDisabled(false);
	}

	useEffect(() => {
		if (!isUpdateButtonDisabled) {
			setIsUpdateButtonDisabled(true);
		}
		if (releaseNote) {
			formik.setValues(releaseNote);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseNote]);

	return (
		<div>
			<Form onSubmit={formik.handleSubmit} onFocus={handleFocusInput}>
				<input type="hidden" name="id" value={formik.values.id} />
				<Form.Group className="mb-4">
					<Form.Label>Nº da versão</Form.Label>
					<Form.Control
						autoFocus
						type="text"
						name="version"
						placeholder="Digite o número da versão"
						value={formik.values.version}
						onChange={formik.handleChange}
					/>
					{formik.errors.version && (
						<span className="text-danger">{formik.errors.version}</span>
					)}
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label>Título</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formik.values.title}
						onChange={formik.handleChange}
						placeholder="Digite um título"
						autoFocus
					/>
					{formik.errors.title && (
						<span className="text-danger">{formik.errors.title}</span>
					)}
				</Form.Group>
				<Form.Group className="mb-4">
					<Form.Label>Descrição</Form.Label>
					<Editor
						editorState={editorState}
						toolbarClassName="toolbarClassName"
						wrapperClassName="wrapperClassName"
						editorClassName="m-0 p-2 border border-2"
						placeholder="Digite uma descrição"
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"fontFamily",
								"list",
								"textAlign",
								"colorPicker",
								"link",
								"embedded",
								"emoji",
								"remove",
								"history",
							],
						}}
						onEditorStateChange={(editorState) => {
							setEditorState(editorState);
							formik.setFieldValue(
								"description",
								draftToHtml(
									convertToRaw(editorState.getCurrentContent())
								).trim() !== "<p></p>"
									? draftToHtml(convertToRaw(editorState.getCurrentContent()))
									: ""
							);
						}}
					/>
					{formik.errors.description && (
						<span className="text-danger">{formik.errors.description}</span>
					)}
				</Form.Group>

				<div className="d-flex justify-content-center">
					<Button
						title="Salvar Alterações"
						variant="success"
						type="submit"
						disabled={isUpdateButtonDisabled}
						className="fs-7"
					>
						Salvar Alterações
					</Button>
				</div>
			</Form>
		</div>
	);
}
