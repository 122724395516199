import Table from "react-bootstrap/Table";

import { Account } from "@/@types/app/Account";
import { ResumeGrouped } from "@/services/esus/attendanceService";

export type PregnancyByCityAndHighRiskResume = {
	resumes: ResumeGrouped<number | null, object>[];
	city: Account;
};

type Props = {
	pregnancyByCityAndHighRiskResumes: PregnancyByCityAndHighRiskResume[];
};

export function PregnancyByCityAndHighRiskTable({ pregnancyByCityAndHighRiskResumes }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			id="table-pregnancy-city-highrisk"
			className="table border border-1 align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Município</th>
					<th>Baixo risco</th>
					<th>Alto risco</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{pregnancyByCityAndHighRiskResumes
					.sort((a, b) => a.city.locale.localeCompare(b.city.locale))
					.map((cityResume, index) => (
						<tr key={`table-pregnancy-city-highrisk-${index}`}>
							<td>{cityResume.city.locale}</td>
							<td>
								{cityResume.resumes.find(
									(resume) => resume.key === 0 || resume.key === null
								)?.total || 0}
							</td>
							<td>
								{cityResume.resumes.find((resume) => resume.key === 1)?.total || 0}
							</td>
							<td>
								{cityResume.resumes
									.map((resume) => resume?.total || 0)
									.reduce((acc, curr) => acc + curr, 0)}
							</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
