import { Props as SelectProps } from "react-select";

import { Option } from "@/@types/generics/Option";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps;

export function IndicatorSelect({ ...rest }: Props) {
	const options: Option<number>[] = [
		{ value: 1, label: "Indicador 1" },
		{ value: 2, label: "Indicador 2" },
		{ value: 3, label: "Indicador 3" },
		{ value: 4, label: "Indicador 4" },
		{ value: 5, label: "Indicador 5" },
		{ value: 6, label: "Indicador 6" },
		{ value: 7, label: "Indicador 7" },
	];

	return (
		<div className="d-flex flex-column">
			<label htmlFor={rest.id} className="form-label">
				Indicadores:
			</label>
			<SelectComponent
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum nenhum indicador encontrado"}
				options={options}
				{...rest}
			/>
		</div>
	);
}
