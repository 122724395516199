import { useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FaEye, FaInfoCircle } from "react-icons/fa";

import { Period } from "@/@types/generics/Period";
import { Cid } from "@/@types/esus/Cid";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { ResumeGrouped } from "@/services/esus/attendanceService";
import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { AttendanceReferralHistoryChart } from "./AttendanceReferralHistoryChart";
import { BarChart } from "@/components/Charts/BarChart";
import { Specialty } from "@/@types/esus/Specialty";
import { AttendanceReferralRateBySpecialtyModal } from "./AttendanceReferralRateBySpecialtyModal";

type Props = {
	attendanceReferralsAggregatedByPeriodAndProfessionalCategory: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedByCidAndProfessionalCategory: ResumeGrouped<
		Cid,
		ResumeGrouped<string, object>
	>[];
	attendanceReferralsAggregatedBySpecialty: ResumeGrouped<Specialty, object>[];
};

export function AttendanceReferralDetails({
	attendanceReferralsAggregatedByPeriodAndProfessionalCategory,
	attendanceReferralsAggregatedByCidAndProfessionalCategory,
	attendanceReferralsAggregatedBySpecialty,
}: Props) {
	const [
		showAttendanceReferralRateBySpecialtyModal,
		setShowAttendanceReferralRateBySpecialtyModal,
	] = useState(false);
	const attendanceReferralsAggregatedByPeriodAndProfessionalCategorySorted =
		attendanceReferralsAggregatedByPeriodAndProfessionalCategory.sort(
			(aggregatedA, aggregatedB) =>
				dayjs(new Date(aggregatedB.key.year, aggregatedB.key.month, 1)).isAfter(
					dayjs(new Date(aggregatedA.key.year, aggregatedA.key.month, 1))
				)
					? -1
					: 0
		);
	const periodReferralsSeries =
		attendanceReferralsAggregatedByPeriodAndProfessionalCategorySorted.map(
			(aggregatedAttendances) => aggregatedAttendances.total
		);
	const periodReferralsCategories =
		attendanceReferralsAggregatedByPeriodAndProfessionalCategorySorted?.map((period) =>
			period.key.year && period.key.month
				? `${period.key.year}/${SHORT_MONTHS[period.key.month - 1]}`
				: ""
		);

	const attendanceReferralsAggregatedByCidAndProfessionalCategorySorted =
		attendanceReferralsAggregatedByCidAndProfessionalCategory
			.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
			.slice(0, 10);
	const attendanceReferralsAggregatedByCidAndProfessionalCategoryCategories =
		attendanceReferralsAggregatedByCidAndProfessionalCategorySorted
			.map((aggregatedAttendances) => aggregatedAttendances.key)
			.map((dashboard) =>
				dashboard.cidCode ? `${dashboard.cidCode} - ${dashboard.description}` : ""
			);
	const attendanceReferralsAggregatedByCidAndProfessionalCategorySeries = [
		{
			data: attendanceReferralsAggregatedByCidAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.MEDIC.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Médico",
		},
		{
			data: attendanceReferralsAggregatedByCidAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.NURSE.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Enfermeiro",
		},
		{
			data: attendanceReferralsAggregatedByCidAndProfessionalCategorySorted.map(
				(professionalCategories) =>
					(professionalCategories.values || [])
						.filter(
							(professionalCategory) =>
								![
									ProfessionalCategoryEnum.MEDIC.valueOf(),
									ProfessionalCategoryEnum.NURSE.valueOf(),
								].includes(professionalCategory.key)
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Outros",
		},
	];

	const specialtyAttendanceReferralsSeries = [
		{
			data: attendanceReferralsAggregatedBySpecialty
				.slice(0, 10)
				.map((aggregatedAttendances) => aggregatedAttendances.total),
			name: "Total",
		},
	];
	const specialtyAttendanceReferralsCategories = attendanceReferralsAggregatedBySpecialty
		.slice(0, 10)
		.map((period) => period.key.description || "");

	return (
		<section>
			<Row className="p-2">
				<Col>
					<Card>
						<Card.Header>
							<Card.Title className="d-flex gap-2">
								Evolução histórica de encaminhamentos
								<CardOverlayTrigger
									header={"Encaminhamentos"}
									message={"Total de encaminhamentos registrados mensalmente"}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body style={{ height: 500 }}>
							<AttendanceReferralHistoryChart
								series={[
									{
										data: periodReferralsSeries,
										name: "Encaminhamentos realizados",
									},
								]}
								categories={periodReferralsCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col>
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex gap-2">
								Cids mais utilizados
								<CardOverlayTrigger
									header={"Cids mais utilizados"}
									message={
										"Total de cids informados nos encaminhamentos do período por categoria profissional"
									}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="ps-0">
							<div>
								<BarChart
									series={
										attendanceReferralsAggregatedByCidAndProfessionalCategorySeries
									}
									categories={
										attendanceReferralsAggregatedByCidAndProfessionalCategoryCategories
									}
									categoryTextWidth={250}
									isStacked
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
				<Col>
					<Card className="h-100">
						<Card.Header className="d-flex justify-content-between align-items-center">
							<Card.Title className="d-flex gap-2">
							Especialidades mais utilizadas
							<CardOverlayTrigger
									header={"Especialidades mais utilizadas"}
									message={
										"Total de tipos de especilidades informadas nos encaminhamentos"
									}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
							<Button
								className="no-print"
								variant="secondary"
								onClick={() => setShowAttendanceReferralRateBySpecialtyModal(true)}
							>
								<FaEye className="me-2" />
								Detalhes
							</Button>
						</Card.Header>
						<Card.Body className="ps-0">
							<div>
								<BarChart
									series={specialtyAttendanceReferralsSeries}
									categories={specialtyAttendanceReferralsCategories}
									categoryTextWidth={380}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<AttendanceReferralRateBySpecialtyModal
				aggregatedAttendanceReferrals={attendanceReferralsAggregatedBySpecialty}
				show={showAttendanceReferralRateBySpecialtyModal}
				onHide={() => setShowAttendanceReferralRateBySpecialtyModal(false)}
			/>
		</section>
	);
}
