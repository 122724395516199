import { ReleaseNote } from "@/@types/app/ReleaseNote";
import { get, post } from "../api";

export async function getAllReleaseNotes() {
	const { data } = await get<ReleaseNote[]>("/releasenote/all");
	return data || [];
}

export async function getPendingReleaseNote() {
	const { data } = await get<ReleaseNote>("/releasenote/GetPendingReleaseNote");
	return data || ({} as ReleaseNote);
}

export async function registerReleaseNote(releaseNote: ReleaseNote) {
	const response = await post(`releasenote/register`, releaseNote);
	return response.status === 200;
}

export async function updateReleaseNote(releaseNote: ReleaseNote) {
	const response = await post(`releasenote/update`, releaseNote);
	return response.status === 200;
}
