import dayjs from "dayjs";

import { decrypt, encrypt } from "./genericals";

type CachedData<T> = {
	expirationDate: string;
	data: T;
};

export async function fetchWithCache<T>(
	cacheKey: string,
	expirationDate: string,
	fetch: () => Promise<T>
) {
	try {
		const decrypted = sessionStorage.getItem(cacheKey)
			? JSON.parse(decrypt(JSON.parse(sessionStorage.getItem(cacheKey) || "{}")?.value || ""))
			: ({} as CachedData<T>);
		const cachedData: CachedData<T> = decrypted;
		if (
			cachedData.data &&
			Object.keys(cachedData.data).length !== 0 &&
			cachedData.expirationDate &&
			dayjs(cachedData.expirationDate).isAfter(dayjs().format())
		) {
			return cachedData.data;
		}
	} catch (error) {}
	const newCachedData: CachedData<T> = {
		data: await fetch(),
		expirationDate: expirationDate,
	};
	try {
		sessionStorage.setItem(
			cacheKey,
			JSON.stringify({ value: encrypt(JSON.stringify(newCachedData)) })
		);
	}
	catch {
		sessionStorage.clear();
	}
	return newCachedData.data;
}
