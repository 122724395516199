import { get, post } from "../api";
import { AdminSettings } from "@/@types/app/AdminSettings";

export async function getAdminSettingsByIdentifier(identifier: string) {
	const { data } = await get<AdminSettings>("/AdminSettings/FindByUserIdentifier", {
		identifier,
	});
	return data || ({} as AdminSettings);
}

export async function updateAdminSettings(identifier: string, enableNewCityNotification: boolean) {
	const response = await post("/AdminSettings/Update", {
		identifier,
		enableNewCityNotification,
	});
	return response.status === 200;
}
