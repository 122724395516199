import { Props as SelectProps } from "react-select";

import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";
import { Option } from "@/@types/generics/Option";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	noDefaultOption?: boolean;
};

export function ProfessionalCategorySelect({ noDefaultOption, ...rest }: Props) {
	const options: Option<
		(typeof ProfessionalCategoryEnum)[keyof typeof ProfessionalCategoryEnum] | null
	>[] = [
		{ label: "Todas", value: null },
		{ label: "Médico", value: ProfessionalCategoryEnum.MEDIC },
		{ label: "Enfermeiro", value: ProfessionalCategoryEnum.NURSE },
		{ label: "Dentista", value: ProfessionalCategoryEnum.DENTIST },
		{ label: "Técnico de enfermagem", value: ProfessionalCategoryEnum.NURSING_TECHNICIAN },
	];
	return (
		<div className="d-flex flex-column">
			<label title="Categorias profissional" htmlFor={rest.id} className="mb-2">
				Categorias:
			</label>
			<SelectComponent
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum perfil encontrado"}
				{...rest}
			/>
		</div>
	);
}
