import { HealthTeam } from "@/@types/esus/HealthTeam";
import { HealthUnit } from "@/@types/esus/HealthUnit";
import { Professional } from "@/@types/esus/Professional";
import { get } from "../api";

export type TerritorialCitizenResume = {
	totalCitizens: number;
	totalMC: number;
	totalFA: number;
	totalFamilies: number;
	totalResidences: number;
	totalOtherBuildings: number;
	totalAcs: number;
};

export type ResumeHealthUnit = {
	healthUnit: HealthUnit;
	total: number;
};

export type ResumeHealthTeam = {
	healthTeam: HealthTeam;
	total: number;
};

export type ResumeByProfessional = {
	professional: Professional;
	total: number;
};

export type ResumeByPeriod = {
	period: {
		year: number;
		month: number;
	};
	total: number;
};

type TerritorialCitizenResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

type TerritorialCitizenResumeByPeriodParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	startDate: string;
	endDate: string;
};

export async function getTerritorialCitizenResume({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<TerritorialCitizenResume>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/getResume`,
		params
	);
	return response.data || ({} as TerritorialCitizenResume);
}

export async function getResumeByHealthUnits({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthUnit[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByHealthUnit`,
		params
	);
	return response.data || [];
}

export async function getResumeByHealthTeams({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeHealthTeam[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByHealthTeam`,
		params
	);
	return response.data || [];
}

export async function getResumeByProfessionals({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams) {
	const response = await get<ResumeByProfessional[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeByProfessional`,
		params
	);
	return response.data || [];
}

export async function getResumeCitizensUpdatedByPeriod({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeByPeriodParams) {
	const response = await get<ResumeByPeriod[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeCitizensUpdatedByPeriod`,
		params
	);
	return response.data || [];
}

export async function getResumeNewCitizensByPeriod({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeByPeriodParams) {
	const response = await get<ResumeByPeriod[]>(
		`/uf/${uf}/city/${ibgeCode}/TerritorialCitizen/GetResumeNewCitizensByPeriod`,
		params
	);
	return response.data || [];
}

export async function getTotalCitizens({
	uf,
	ibgeCode,
	...params
}: TerritorialCitizenResumeParams & { ine: string }) {
	const response = await get<number>(
		`/uf/${uf}/city/${ibgeCode}/territorialCitizen/getTotalCitizens`,
		params
	);
	return response.data || 0;
}
