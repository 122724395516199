import { createContext, ReactNode, useState, useEffect } from "react";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { getHealthTeams } from "@/services/esus/healthTeamService";

type HealthTeamContextType = {
	healthTeams: HealthTeam[];
};

export const HealthTeamContext = createContext({} as HealthTeamContextType);

type UnitsContextProviderProps = {
	children: ReactNode;
};

export function HealthTeamContextProvider({ children }: UnitsContextProviderProps) {
	const [healthTeams, setHealthTeams] = useState<HealthTeam[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function handleGetHealthTeams() {
		if (!ibgeCode) return;
		setHealthTeams(await getHealthTeams({ uf, ibgeCode, cnes }));
	}

	useEffect(() => {
		handleGetHealthTeams();
		return () => setHealthTeams([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<HealthTeamContext.Provider value={{ healthTeams }}>{children}</HealthTeamContext.Provider>
	);
}
