import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import "dayjs/locale/pt-br";

export function generateCalendar(date: dayjs.Dayjs) {
	dayjs.extend(weekday);
	dayjs.locale("pt-br");

	const startOfMonth = date.startOf("month");
	const endOfMonth = date.endOf("month");

	const startDay = startOfMonth.day(0);
	const endDay = endOfMonth.day(6);

	const days = [];
	let currentDay = startDay;

	while (currentDay.isBefore(endDay.add(1, "day"), "day")) {
		days.push(currentDay);
		currentDay = currentDay.add(1, "day");
	}

	return days;
}
