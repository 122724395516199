import { LogUserViewTrack } from "@/@types/app/LogUserViewTrack";
import { get, post } from "../api";
import { TrackFile } from "@/@types/app/TrackFile";

export type TrackFileFormData = {
	title: string;
	description: string;
	videoFile: File;
	thumbnailFile: File;
	imageFile?: File;
	duration: number;
};

export async function registerTrackFile(trackFileFormData: TrackFileFormData) {
	if (!trackFileFormData.videoFile || !trackFileFormData.thumbnailFile) return {} as TrackFile;
	const formData = new FormData();
	formData.append("videoFile", trackFileFormData.videoFile);
	formData.append("thumbnailFile", trackFileFormData.thumbnailFile);
	if (trackFileFormData.imageFile) {
		formData.append("imageFile", trackFileFormData.imageFile);
	}
	formData.append("title", trackFileFormData.title);
	formData.append("description", trackFileFormData.description);
	formData.append("duration", String(Math.floor(trackFileFormData.duration)));
	const { data } = await post<TrackFile>("/trackfile/register", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return data || ({} as TrackFile);
}

export async function updateTrackFileImage(
	trackFileId: number,
	trackFileFormData: TrackFileFormData
) {
	if (!trackFileFormData.thumbnailFile && !trackFileFormData.imageFile) return;
	const formData = new FormData();
	if (trackFileFormData.thumbnailFile) {
		formData.append("thumbnailFile", trackFileFormData.thumbnailFile);
	}
	if (trackFileFormData.imageFile) {
		formData.append("imageFile", trackFileFormData.imageFile);
	}
	formData.append("id", String(trackFileId));
	const response = await post("/trackfile/updateimages", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.status === 200;
}

export async function updateTrackFile(trackFile: TrackFile) {
	const response = await post("/TrackFile/Update", trackFile);
	return response.status === 200;
}

export async function getCurrentLogUserViewTrack(trackOrderId: number) {
	const { data } = await get<LogUserViewTrack>(
		`/LogUserViewTrack/GetCurrentLogByTrackOrderId`,
		{ trackOrderId }
	);
	return data || ({} as LogUserViewTrack);
}

export async function updateTrackProgress(trackOrderId: number, playedSeconds: number) {
	const response = await post("/LogUserViewTrack/register", {
		trackOrderId,
		currentTimeTrack: Math.floor(playedSeconds),
	});
	return response.status === 200;
}

export async function getVideoUrl(trackFileId: number) {
	const { data } = await get<string>("/trackfile/GetUrlVideo", { trackFileId });
	return data || "";
}
