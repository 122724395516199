import { Account } from "@/@types/app/Account";
import { get } from "../api";

export type ResumeCitieEpidemic = {
	city: Account;
	total: number;
};

export type ResumeDiseasesChronicNotTransmissibleOfCities = {
	city: Account;
	totalDiabetics: number;
	totalAttendancesCitizensWithDiabetics: number;
	totalHypertension: number;
	totalAttendancesCitizensWithHypertension: number;
};

export type ResumeDiseasesChronicTransmissibleOfCities = {
	city: Account;
	totalHansen: number;
	totalAttendancesCitizensWithHansen: number;
	totalTuberculosis: number;
	totalAttendancesCitizensWithTuberculosis: number;
};

type DiseasesChronicParams = {
	uf: string;
	startDate: string;
	endDate: string;
};

export async function getResumeDiseasesChronicTransmissibleOfCities({
	uf,
	...params
}: DiseasesChronicParams) {
	const response = await get<ResumeDiseasesChronicTransmissibleOfCities[]>(
		`/uf/${uf}/Epidemic/GetResumeDiseasesChronicTransmissibleOfCities`,
		params
	);
	return response.data || [];
}

export async function getResumeDiseasesChronicNotTransmissibleOfCities({
	uf,
	...params
}: DiseasesChronicParams) {
	const response = await get<ResumeDiseasesChronicNotTransmissibleOfCities[]>(
		`/uf/${uf}/Epidemic/GetResumeDiseasesChronicNotTransmissibleOfCities`,
		params
	);
	return response.data || [];
}
