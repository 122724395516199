import { useState, useEffect, createContext, ReactNode } from "react";

import { LayoutSplashScreen } from "@/../_metronic/layout/core";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { User } from "@/@types/app/user";
import { Account } from "@/@types/app/Account";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { getCurrentUserAccount } from "@/services/app/userAccountService";
import { login as userLogin, logout as userLogout } from "@/services/auth/accountService";

type CurrentAccountContextType = {
	currentHealthUnit: HealthUnit;
	currentAccount: Account;
	currentFederalUnit: FederalUnit;
	isLoading: Boolean;
	user: User;
	getCurrentAccount: (uf?: string, ibgeCode?: string, cnes?: string) => Promise<void>;
	uf: string;
	ibgeCode: string;
	cnes: string;
	permissions: string[];
	login: (identifier: string, password: string) => Promise<boolean>;
	logout: () => Promise<void>;
};

export const CurrentAccountContext = createContext({} as CurrentAccountContextType);

type CurrentAccountContextProviderProps = {
	children: ReactNode;
};

export function CurrentAccountContextProvider({ children }: CurrentAccountContextProviderProps) {
	const [currentAccount, setCurrentAccount] = useState<Account>({} as Account);
	const [currentFederalUnit, setCurrentFederalUnit] = useState<FederalUnit>({} as FederalUnit);
	const [currentHealthUnit, setCurrentHealthUnit] = useState<HealthUnit>({} as HealthUnit);
	const [permissions, setPermissions] = useState<string[]>([]);
	const [user, setUser] = useState<User>({} as User);
	const [isLoading, setIsLoading] = useState(true);
	const [uf, setUf] = useState(localStorage.getItem("uf") || "");
	const [ibgeCode, setIbgeCode] = useState(localStorage.getItem("ibgeCode") || "");
	const [cnes, setCnes] = useState(localStorage.getItem("cnes") || "");

	async function getCurrentAccount(
		currentUf?: string,
		currentIbgeCode?: string,
		currentCnes?: string
	) {
		setUf(currentUf || "");
		setIbgeCode(currentIbgeCode || "");
		setCnes(currentCnes || "");
		localStorage.setItem("uf", currentUf || "");
		localStorage.setItem("ibgeCode", currentIbgeCode || "");
		localStorage.setItem("cnes", currentCnes || "");
		const data = await getCurrentUserAccount(currentUf, currentIbgeCode, currentCnes);
		if (data.isAuthenticated) {
			const { userAccount, permissions: userPermissions, currentCityLicense } = data;
			setCurrentFederalUnit(userAccount?.federalUnit || ({} as FederalUnit));
			setUser(userAccount?.user || ({} as User));
			setCurrentHealthUnit(userAccount?.healthUnit || ({} as HealthUnit));
			if (userAccount?.account && currentCityLicense) {
				userAccount.account.currentCityLicense = currentCityLicense;
				setCurrentAccount(userAccount.account);
			}
			setPermissions(userPermissions || []);
		} else {
			logout();
		}
	}

	async function login(identifier: string, password: string) {
		const tokens = await userLogin(identifier, password);
		if (tokens) {
			localStorage.removeItem("uf");
			localStorage.removeItem("ibgeCode");
			localStorage.removeItem("cnes");
			localStorage.setItem(
				"tokens",
				JSON.stringify({ accessToken: tokens.token, refreshToken: tokens.refreshToken })
			);
			await getCurrentAccount();
		}
		return Boolean(tokens);
	}

	async function logout() {
		userLogout();
		setUf("");
		setIbgeCode("");
		setCnes("");
		setUser({} as User);
		setCurrentFederalUnit({} as FederalUnit);
		setCurrentAccount({} as Account);
		setCurrentHealthUnit({} as HealthUnit);
	}

	useEffect(() => {
		async function fetchData() {
			await getCurrentAccount(uf, ibgeCode, cnes);
			setIsLoading(false);
		}

		fetchData();
		return () => setCurrentAccount({} as Account);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<CurrentAccountContext.Provider
			value={{
				currentAccount,
				currentFederalUnit,
				currentHealthUnit,
				isLoading,
				user,
				getCurrentAccount,
				uf,
				ibgeCode,
				cnes,
				permissions,
				login,
				logout,
			}}
		>
			{isLoading ? <LayoutSplashScreen /> : <>{children}</>}
		</CurrentAccountContext.Provider>
	);
}
