import CloseButton from "react-bootstrap/CloseButton";
import Modal from "react-bootstrap/Modal";
import { ModalProps } from "react-bootstrap";

import { ReleaseNote } from "@/@types/app/ReleaseNote";
import { ReleaseNoteCard } from "../ReleaseNoteCard";

type Props = ModalProps & {
	releaseNote?: ReleaseNote;
};

export function ModalReleaseNoteView({ releaseNote, ...rest }: Props) {
	if (!releaseNote) return <></>;

	return (
		<Modal scrollable {...rest} size="lg">
			<Modal.Header className="d-block pb-3">
				<div className="col-xl-12 d-flex">
					<CloseButton onClick={rest.onHide} />
				</div>
				<div className="col-xl-12">
					<Modal.Title className="text-center">Dados da nova atualização</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<ReleaseNoteCard releaseNote={releaseNote} />
			</Modal.Body>
		</Modal>
	);
}
