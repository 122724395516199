import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import { FaSyringe } from "react-icons/fa";

import { useLayout } from "@/../_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import {
	ChildAbandonmentTripleViralByCityAndPeriod,
	handleChildAbandonmentTripleViralByPeriod,
	handleChildTripleViralVaccinationsByCityAndPeriodResume,
	TripleViralVaccinationsByCityAndPeriod,
} from "@/services/federalUnit/vaccinationService";

import { TripleViralReportFilters } from "@/pages/federalunitesus/TripleViralReport/TripleViralReportFilters";
import { TripleViralDosagesAppliedChart } from "@/pages/federalunitesus/TripleViralReport/TripleViralDosagesAppliedChart";
import { SemiDonut } from "@/components/Charts/SemiDonut";
import { TripleViralVaccinationsRateChart } from "./TripleViralVaccinationsRateChart";
import { LoadingScreen } from "@/components/LoadingScreen";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { TripleViralDosagesAppliedTable } from "./TripleViralDosagesAppliedTable";

export function TripleViralReport() {
	const { setTitle } = useLayout();
	const { uf } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [startDate, setStartDate] = useState(dayjs().add(-12, "month").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
	const [
		childAbandonmentTripleViralByCitiesAndPeriods,
		setChildAbandonmentTripleViralByCitiesAndPeriods,
	] = useState<ChildAbandonmentTripleViralByCityAndPeriod[]>();
	const [
		childTripleViralVaccinationsByCityAndPeriodResume,
		setChildTripleViralVaccinationsByCityAndPeriodResume,
	] = useState<TripleViralVaccinationsByCityAndPeriod[]>([]);
	const [tripleViralOption, setTripleViralOption] = useState(1);

	async function fetchChildAbandonmentTripleViralByCitiesAndPeriods() {
		setChildAbandonmentTripleViralByCitiesAndPeriods(
			await handleChildAbandonmentTripleViralByPeriod({ startDate, endDate, uf })
		);
	}

	async function fetchChildTripleViralVaccinationsByCityAndPeriodResume() {
		setChildTripleViralVaccinationsByCityAndPeriodResume(
			await handleChildTripleViralVaccinationsByCityAndPeriodResume({
				startDate,
				endDate,
				uf,
			})
		);
	}

	const tripleViralPeriods =
		childAbandonmentTripleViralByCitiesAndPeriods
			?.at(0)
			?.resumesByPeriod.map(
				(resumeByPeriod) =>
					`${resumeByPeriod.period.year}/${SHORT_MONTHS[resumeByPeriod.period.month - 1]}`
			) || [];

	const childAbandonmentPeriodSeries: ApexAxisChartSeries = [
		{
			name: "Identificados",
			color: "#a1a5b7",
			data: tripleViralPeriods.map(
				(period) =>
					childAbandonmentTripleViralByCitiesAndPeriods
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.resumesByPeriod.find(
									(resumeByPeriod) =>
										`${resumeByPeriod.period.year}/${
											SHORT_MONTHS[resumeByPeriod.period.month - 1]
										}` === period
								)?.totalChildren
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
		{
			name: "Vacinados",
			color: "#008ffb",
			data: tripleViralPeriods.map(
				(period) =>
					childAbandonmentTripleViralByCitiesAndPeriods
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.resumesByPeriod.find(
									(resumeByPeriod) =>
										`${resumeByPeriod.period.year}/${
											SHORT_MONTHS[resumeByPeriod.period.month - 1]
										}` === period
								)?.totalWithVaccinationFinished
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
		{
			name: "Abandonos",
			color: "#775dd0",
			data: tripleViralPeriods.map(
				(period) =>
					childAbandonmentTripleViralByCitiesAndPeriods
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.resumesByPeriod.find(
									(resumeByPeriod) =>
										`${resumeByPeriod.period.year}/${
											SHORT_MONTHS[resumeByPeriod.period.month - 1]
										}` === period
								)?.totalWithVaccinationPending
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
		{
			name: "Não Vacinados",
			color: "#ff4560",
			data: tripleViralPeriods.map(
				(period) =>
					childAbandonmentTripleViralByCitiesAndPeriods
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.resumesByPeriod.find(
									(resumeByPeriod) =>
										`${resumeByPeriod.period.year}/${
											SHORT_MONTHS[resumeByPeriod.period.month - 1]
										}` === period
								)?.totalWithoutVaccination
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
	];

	const childTripleViralVaccinationPeriods =
		childTripleViralVaccinationsByCityAndPeriodResume
			?.at(0)
			?.vaccinationsResumes.map(
				(vaccinationResume) =>
					`${vaccinationResume.period.year}/${
						SHORT_MONTHS[vaccinationResume.period.month - 1]
					}`
			) || [];

	const totalChildVaccinations = [
		tripleViralPeriods
			.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"D1"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			)
			.reduce((acc, curr) => acc + curr, 0),
		tripleViralPeriods
			.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"D2"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			)
			.reduce((acc, curr) => acc + curr, 0),
		tripleViralPeriods
			.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"DU"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			)
			.reduce((acc, curr) => acc + curr, 0),
	];

	const childVaccinationsByPeriodSeries: ApexAxisChartSeries = [
		{
			name: "D1",
			data: tripleViralPeriods.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"D1"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
		{
			name: "D2",
			data: tripleViralPeriods.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"D2"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
		{
			name: "DU",
			data: tripleViralPeriods.map(
				(period) =>
					childTripleViralVaccinationsByCityAndPeriodResume
						?.map(
							(resumeByCityAndPeriod) =>
								resumeByCityAndPeriod.vaccinationsResumes
									.find(
										(resumeByPeriod) =>
											`${resumeByPeriod.period.year}/${
												SHORT_MONTHS[resumeByPeriod.period.month - 1]
											}` === period
									)
									?.vaccinationResumes.find(
										(vaccinationResume) =>
											vaccinationResume.immunobiologicalDosage.acronym ===
											"DU"
									)?.total
						)
						.reduce((acc, curr) => (acc || 0) + (curr || 0), 0) || 0,
				0
			),
		},
	];

	async function fetchChildResumes() {
		setIsLoading(true);
		await fetchChildAbandonmentTripleViralByCitiesAndPeriods();
		await fetchChildTripleViralVaccinationsByCityAndPeriodResume();
		setIsLoading(false);
	}

	useEffect(() => {
		setTitle("VACINAÇÃO TRÍPLICE VIRAL EM CRIANÇAS ENTRE 1 E 2 ANOS");
		fetchChildResumes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="mx-4">
			<LoadingScreen loading={isLoading} />
			<Row className="my-4">
				<Col>
					<Card className="h-100">
						<Card.Body>
							<TripleViralReportFilters
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								handleFilter={fetchChildResumes}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="min-h-100 mb-2">
				<Col sm={12} md={12} lg={12} xl={9} className="min-h-100 mb-4">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="w-100">
								<Row className="w-100">
									<Col
										sm={12}
										md={12}
										lg={12}
										xl={6}
										className="mb-4 d-flex flex-row align-items-center"
									>
										<div className="me-2">PERCENTUAL MENSAL DE VACINAÇÃO</div>
										<CardOverlayTrigger>
											<section>
												<label>
													Consideram-se todas as crianças que completaram
													2 anos no período informado.
												</label>
											</section>
										</CardOverlayTrigger>
									</Col>
									<Col sm={12} md={12} lg={12} xl={6} className="mb-4">
										<Nav fill variant="tabs" defaultActiveKey="vacinados">
											<Nav.Item
												onClick={() => {
													setTripleViralOption(1);
												}}
											>
												<Nav.Link
													eventKey="vacinados"
													className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
												>
													Vacinados
												</Nav.Link>
											</Nav.Item>
											<Nav.Item
												onClick={() => {
													setTripleViralOption(2);
												}}
											>
												<Nav.Link
													eventKey="abandonos"
													className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
												>
													Abandonos
												</Nav.Link>
											</Nav.Item>
											<Nav.Item
												onClick={() => {
													setTripleViralOption(3);
												}}
											>
												<Nav.Link
													eventKey="nao-vacinados"
													className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
												>
													Não vacinados
												</Nav.Link>
											</Nav.Item>
										</Nav>
									</Col>
								</Row>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<TripleViralVaccinationsRateChart
								categories={tripleViralPeriods}
								series={[
									childAbandonmentPeriodSeries[0],
									childAbandonmentPeriodSeries[tripleViralOption],
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col
					sm={12}
					md={12}
					lg={12}
					xl={3}
					className="mb-4 min-h-100 d-flex flex-column gap-4"
				>
					<Card className="h-50">
						<Card.Header>
							<Card.Title>PERCENTUAL VACINADOS X NÃO VACINADOS</Card.Title>
						</Card.Header>
						<Card.Body className="p-4">
							<SemiDonut
								descriptionsLabels={["Vacinados", "Não Vacinados"]}
								series={[
									childAbandonmentTripleViralByCitiesAndPeriods
										?.map(
											(resumeByCityAndPeriods) =>
												resumeByCityAndPeriods.totalWithVaccinationFinished
										)
										.reduce((acc, curr) => acc + curr, 0) || 0,
									childAbandonmentTripleViralByCitiesAndPeriods
										?.map(
											(resumeByCityAndPeriods) =>
												resumeByCityAndPeriods.totalWithoutVaccination
										)
										.reduce((acc, curr) => acc + curr, 0) || 0,
								]}
							/>
						</Card.Body>
					</Card>
					<Card className="h-50">
						<Card.Header>
							<Card.Title>PERCENTUAL DE ABANDONO</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<SemiDonut
								descriptionsLabels={["Vacinados", "Abandonos"]}
								series={[
									childAbandonmentTripleViralByCitiesAndPeriods
										?.map(
											(resumeByCityAndPeriods) =>
												resumeByCityAndPeriods.totalWithVaccinationFinished
										)
										.reduce((acc, curr) => acc + curr, 0) || 0,
									childAbandonmentTripleViralByCitiesAndPeriods
										?.map(
											(resumeByCityAndPeriods) =>
												resumeByCityAndPeriods.totalWithVaccinationPending
										)
										.reduce((acc, curr) => acc + curr, 0) || 0,
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12} lg={12} xl={9} className="mb-4">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<div className="me-2">DOSES APLICADAS NO PERÍODO</div>
								<CardOverlayTrigger>
									<section>
										<label>
											Consideram-se todas as vacinações de triplice viral
											realizadas em crianças entre 1 e 2 anos de idade.
										</label>
									</section>
								</CardOverlayTrigger>
							</Card.Title>
						</Card.Header>
						<Card.Body className="p-4">
							<TripleViralDosagesAppliedChart
								categories={childTripleViralVaccinationPeriods}
								series={childVaccinationsByPeriodSeries}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={12} xl={3} className="d-flex flex-column">
					<Card className="mb-4 flex-grow-1">
						<Card.Body className="p-0">
							<div className="d-flex flex-column justify-content-between w-100 p-2">
								<div className="d-flex justify-content-between">
									<div className="justify-content-start">
										<FaSyringe />
									</div>
									<CardOverlayTrigger>
										Total de vacinas D1, D2 e DU aplicadas no período em
										crianças entre 1 e 2 anos
									</CardOverlayTrigger>
								</div>
								<strong className="d-flex justify-content-center align-items-center">
									<label className={`fs-1`}>
										{totalChildVaccinations.reduce(
											(acc, curr) => acc + curr,
											0
										)}
									</label>
								</strong>
								<div className="d-flex justify-content-center align-items-end">
									<label className={`fw-bolder mt-3`}>TOTAL DE VACINAS</label>
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="my-4 flex-grow-1">
						<Card.Body className="p-0">
							<div className="d-flex flex-column justify-content-between w-100 p-2">
								<div className="d-flex justify-content-between">
									<div className="justify-content-start">
										<FaSyringe />
									</div>
									<CardOverlayTrigger>
										Total de vacinas D1 aplicadas no período em crianças entre 1
										e 2 anos
									</CardOverlayTrigger>
								</div>
								<strong className="d-flex justify-content-center align-items-center">
									<label className={`fs-1`}>
										{totalChildVaccinations[0] || 0}
									</label>
								</strong>
								<div className="d-flex justify-content-center align-items-end">
									<label className={`fw-bolder mt-3`}>D1</label>
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="my-4 flex-grow-1">
						<Card.Body className="p-0">
							<div className="d-flex flex-column justify-content-between w-100 p-2">
								<div className="d-flex justify-content-between">
									<div className="justify-content-start">
										<FaSyringe />
									</div>
									<CardOverlayTrigger>
										Total de vacinas D2 aplicadas no período em crianças entre 1
										e 2 anos
									</CardOverlayTrigger>
								</div>
								<strong className="d-flex justify-content-center align-items-center">
									<label className={`fs-1`}>
										{totalChildVaccinations[1] || 0}
									</label>
								</strong>
								<div className="d-flex justify-content-center align-items-end">
									<label className={`fw-bolder mt-3`}>D2</label>
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="my-4 flex-grow-1">
						<Card.Body className="p-0">
							<div className="d-flex flex-column justify-content-between w-100 p-2">
								<div className="d-flex justify-content-between">
									<div className="justify-content-start">
										<FaSyringe />
									</div>
									<CardOverlayTrigger>
										Total de vacinas DU aplicadas no período em crianças entre 1
										e 2 anos
									</CardOverlayTrigger>
								</div>
								<strong className="d-flex justify-content-center align-items-center">
									<label className={`fs-1`}>
										{totalChildVaccinations[2] || 0}
									</label>
								</strong>
								<div className="d-flex justify-content-center align-items-end">
									<label className={`fw-bolder mt-3`}>DU</label>
								</div>
							</div>
						</Card.Body>
					</Card>
					<Card className="my-4 flex-grow-1">
						<Card.Body className="p-0">
							<div className="d-flex flex-column justify-content-between w-100 p-2">
								<div className="d-flex justify-content-between">
									<div className="justify-content-start">
										<FaSyringe />
									</div>
									<CardOverlayTrigger>
										Média de vacinas D1, D2 e DU aplicadas mensalmente em
										crianças entre 1 e 2 anos
									</CardOverlayTrigger>
								</div>
								<strong className="d-flex justify-content-center align-items-center">
									<label className={`fs-1`}>
										{childTripleViralVaccinationPeriods.length > 0
											? Math.round(
													totalChildVaccinations.reduce(
														(acc, curr) => acc + curr,
														0
													) / childTripleViralVaccinationPeriods.length
											  )
											: 0}
									</label>
								</strong>
								<div className="d-flex justify-content-center align-items-end">
									<label className={`fw-bolder mt-3`}>
										MÉDIA DE VACINAS / MÊS
									</label>
								</div>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={12} lg={12} xl={12} className="mb-4">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<div className="me-2">DOSES APLICADAS POR CIDADE</div>
								<CardOverlayTrigger>
									<section>
										<label>
											Consideram-se todas as vacinações de triplice viral
											realizadas em crianças entre 1 e 2 anos de idade.
										</label>
									</section>
								</CardOverlayTrigger>
							</Card.Title>
						</Card.Header>
						<Card.Body className="p-8">
							<TripleViralDosagesAppliedTable
								tripleViralVaccinationsByCityAndPeriods={
									childTripleViralVaccinationsByCityAndPeriodResume
								}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
