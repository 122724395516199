import { TImmunobiological } from "@/@types/esus/TImmunobiological";
import { get } from "../api";

type Params = {
	uf: string;
	ibgeCode: string;
};
export async function getTImmunobiologicals({ uf, ibgeCode }: Params) {
	const { data } = await get<TImmunobiological[]>(
		`/uf/${uf}/city/${ibgeCode}/TImmunobiological/GetImmunobiologicals`
	);
	return data || [];
}
