import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Modal from "react-bootstrap/Modal";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useDebounce } from "@/hooks/useDebounce";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Column, handleColumnToggle } from "@/utils/handleColumnTogle";
import { getDailyScheduleDashboard, TScheduleDashboard } from "@/services/esus/TScheduleService";
import { ResumeGrouped } from "@/services/esus/attendanceService";
import { calculatePercentage } from "@/utils/calculatePercentage";
import { TScheduleStatusEnum } from "@/utils/enums/TScheduleStatusEnum";
import { getProfessionalCategory } from "@/utils/getProfessionalCategory";
import { Option } from "@/@types/generics/Option";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";
import { Period } from "@/@types/generics/Period";
import { THealthUnit } from "@/@types/esus/THealthUnit";
import { THealthTeam } from "@/@types/esus/THealthTeam";
import { TProfessional } from "@/@types/esus/TProfessional";

import { Calendar, CalendarContent } from "@/components/Calendar";
import { RadialChart } from "@/components/Charts/RadialChart";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { SchedulingFilters } from "./SchedulingFilters";
import { SchedulingByProgressBarStatusComponent } from "./SchedulingByProgressBarStatusComponent";
import { SchedulingByHealthUnit } from "./SchedulingByHealthUnit";
import { SchedulingByHealthTeam } from "./SchedulingByHealthTeam";
import { SchedulingByProfessional } from "./SchedulingByProfessional";
import { SchedulingDetails, SchedulingDetailsProps } from "./SchedulingDetails";
import { LoadingScreen } from "@/components/LoadingScreen";
import { SchedulingByProfessionalCategory } from "./SchedulingByProfessionalCategory";

type SchedulingStatus = {
	[K in keyof typeof TScheduleStatusEnum]: Column;
};

export function totalFilteredByStatus(
	schedulingValues: ResumeGrouped<TScheduleStatus, object>[],
	status: keyof typeof TScheduleStatusEnum
) {
	const result = schedulingValues
		?.filter((item) => item.key.identifier === TScheduleStatusEnum[status])
		?.map((item) => item.total)
		?.reduce<number>((acc, current) => acc + current, 0);
	return result;
}

export function Scheduler() {
	const { setTitle } = useLayout();
	const { uf, ibgeCode, cnes } = useCurrentAccount();
	const [currentMonth, setCurrentMonth] = useState(dayjs());
	const debouncedCurrentMonth = useDebounce(currentMonth, 1500);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedhealthUnit, setSelectedHealthUnit] = useState<Option<string>>();
	const [selectedHealthTeam, setSelectedHealthTeam] = useState<Option<string>>();
	const [selectedProfessional, setSelectedProfessional] = useState<Option<string>>();
	const [selectedProfessionalCategory, setSelectedProfessionalCategory] =
		useState<Option<string>>();
	const [schedulesDashboard, setSchedulesDashboard] = useState<TScheduleDashboard>();
	const [selectedDateInCalendar, setSelectedDateInCalendar] = useState<dayjs.Dayjs>();
	const [startDate, setStartDate] = useState<dayjs.Dayjs>(currentMonth.startOf("month"));
	const [endDate, setEndDate] = useState<dayjs.Dayjs>(currentMonth.endOf("month"));
	const [showSchedulingDailyDetailsModal, setShowSchedulingDailyDetailsModal] = useState(false);
	const [showSchedulingMonthDetailsModal, setShowSchedulingMonthDetailsModal] = useState(false);

	const [columns, setColumns] = useState<SchedulingStatus>({
		ATTENDED: { title: "Atendido", visible: true },
		CANCELED: { title: "Cancelado", visible: false },
		CITIZEN_PRESENT: { title: "Cidadão presente", visible: false },
		DID_NOT_SHOW_UP: { title: "Cidadão não compareceu", visible: false },
		DID_NOT_WAIT: { title: "Cidadão não aguardou", visible: false },
		EXCLUDED: { title: "Excluido", visible: false },
		SCHEDULED: { title: "Agendado", visible: true },
	});

	const schedulingValues =
		schedulesDashboard?.schedulesAggregatedByPeriodAndScheduleStatus?.flatMap(
			(item) => item.values || []
		) || [];

	const scheduleExpired = totalFilteredByStatus(
		schedulesDashboard?.schedulesAggregatedByPeriodAndScheduleStatus
			?.filter((dashboard) =>
				dayjs(`${dashboard.key.year}-${dashboard.key.month}-${dashboard.key.day}`).isBefore(
					dayjs()
				)
			)
			?.flatMap((dashboard) => dashboard.values || [])
			.filter((dashboard) => dashboard?.key.identifier === TScheduleStatusEnum.SCHEDULED) ||
			[],
		"SCHEDULED"
	);

	const scheduleCanceledTotal = totalFilteredByStatus(schedulingValues, "CANCELED");
	const scheduledTotal = totalFilteredByStatus(schedulingValues, "SCHEDULED");
	const scheduleCitizenPresentTotal = totalFilteredByStatus(schedulingValues, "CITIZEN_PRESENT");
	const scheduleDidNotShowUpTotal = totalFilteredByStatus(schedulingValues, "DID_NOT_SHOW_UP");
	const scheduleDidNoWaitTotal = totalFilteredByStatus(schedulingValues, "DID_NOT_WAIT");
	const scheduleAttendedTotal = totalFilteredByStatus(schedulingValues, "ATTENDED");
	const scheduleExcludedTotal = totalFilteredByStatus(schedulingValues, "EXCLUDED");

	const schedulesInMonthTotal =
		scheduleCanceledTotal +
		scheduledTotal +
		scheduleCitizenPresentTotal +
		scheduleDidNotShowUpTotal +
		scheduleDidNoWaitTotal +
		scheduleAttendedTotal +
		scheduleExcludedTotal;

	const percentageCanceled = calculatePercentage(scheduleCanceledTotal, schedulesInMonthTotal);
	const percentageAttended = calculatePercentage(scheduleAttendedTotal, schedulesInMonthTotal);
	const percentageScheduled = calculatePercentage(scheduledTotal, schedulesInMonthTotal);
	const percentageCitizenPresent = calculatePercentage(
		scheduleCitizenPresentTotal,
		schedulesInMonthTotal
	);
	const percentageDidNotShowUpTotal = calculatePercentage(
		scheduleDidNotShowUpTotal,
		schedulesInMonthTotal
	);
	const percentageDidNoWait = calculatePercentage(scheduleDidNoWaitTotal, schedulesInMonthTotal);
	const percentageExcluded = calculatePercentage(scheduleExcludedTotal, schedulesInMonthTotal);

	const [selectedDailyFilters, setSelectedDailyFilters] =
		useState<SchedulingDetailsProps>({} as SchedulingDetailsProps);
	const [selectedMonthlyFilters, setSelectedMonthlyFilters] =
		useState<SchedulingDetailsProps>({} as SchedulingDetailsProps);

	const calendarContents =
		schedulesDashboard?.schedulesAggregatedByPeriodAndScheduleStatus?.map<CalendarContent>(
			(item: ResumeGrouped<Period, ResumeGrouped<TScheduleStatus, object>>) => {
				return {
					content: item.values ? (
						<span className={`d-flex w-100 flex-column gap-1 ${isLoading && "d-none"}`}>
							{item.values.map((status, index) => {
								const visibilityAndColorByStatus =
									TScheduleStatusEnum.SCHEDULED === status.key.identifier
										? `muted ${!columns.SCHEDULED.visible && "d-none"}`
										: TScheduleStatusEnum.ATTENDED === status.key.identifier
										? `primary ${!columns.ATTENDED.visible && "d-none"}`
										: TScheduleStatusEnum.CANCELED === status.key.identifier
										? `danger ${!columns.CANCELED.visible && "d-none"}`
										: TScheduleStatusEnum.CITIZEN_PRESENT ===
										  status.key.identifier
										? `success ${!columns.CITIZEN_PRESENT.visible && "d-none"}`
										: TScheduleStatusEnum.DID_NOT_SHOW_UP ===
										  status.key.identifier
										? `black ${!columns.DID_NOT_SHOW_UP.visible && "d-none"}`
										: TScheduleStatusEnum.DID_NOT_WAIT
										? `warning ${!columns.DID_NOT_WAIT.visible && "d-none"}`
										: TScheduleStatusEnum.EXCLUDED
										? `info ${!columns.EXCLUDED.visible && "d-none"}`
										: "";
								return (
									<span
										key={index}
										className={`d-flex justify-content-end w-100 fw-bolder p-1 rounded text-${visibilityAndColorByStatus}`}
									>
										{status.total.toLocaleString()}
									</span>
								);
							})}
						</span>
					) : undefined,
					date: dayjs(`${item.key.year}-${item.key.month}-${item.key.day}`).format(
						"YYYY-MM-DD"
					),
				};
			}
		) || [];

	async function handleDailyScheduleDashboard() {
		return await getDailyScheduleDashboard({
			startDate: currentMonth.startOf("month").format("YYYY-MM-DD"),
			endDate: currentMonth.endOf("month").format("YYYY-MM-DD"),
			uf: uf,
			ibgeCode: ibgeCode,
			cnes: selectedhealthUnit?.value || cnes,
			ine: selectedHealthTeam?.value,
			cns: selectedProfessional?.value,
			professionalCategory: selectedProfessionalCategory?.value,
		});
	}

	async function fetch() {
		setIsLoading(true);
		setSchedulesDashboard(await handleDailyScheduleDashboard());
		setIsLoading(false);
	}

	function calendarDayClickEvent(date: dayjs.Dayjs) {
		setSelectedDateInCalendar(date);
		setSelectedDailyFilters({
			startDate: dayjs(date).format(),
			endDate: dayjs(date).add(1, "day").format(),
			professional: selectedProfessional,
			category: selectedProfessionalCategory,
			healthTeam: selectedHealthTeam,
			healthUnit: selectedhealthUnit,
		});
		setShowSchedulingDailyDetailsModal(true);
	}

	useEffect(() => {
		fetch();
		setTitle("Agendamentos");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedCurrentMonth]);

	return (
		<section>
			<LoadingScreen loading={isLoading} />
			<Modal
				size="xl"
				scrollable
				show={showSchedulingDailyDetailsModal}
				onHide={() => setShowSchedulingDailyDetailsModal(false)}
			>
				<Modal.Header className="w-100" closeButton>
					<div className="d-flex justify-content-between w-100">
						<div className="d-flex flex-column fw-bolder gap-1 fs-7">
							<label>Agendamentos Diário</label>
							<label className="fw-light">
								{selectedDailyFilters?.healthUnit?.label}
							</label>
							<label className="fw-light">
								{selectedDailyFilters?.healthTeam?.label}
							</label>
							<label className="fw-light">
								{selectedDailyFilters?.professional?.label}
							</label>
						</div>
						<label>{dayjs(selectedDateInCalendar).format("D MMMM, YYYY")}</label>
					</div>
				</Modal.Header>
				<Modal.Body>
					<SchedulingDetails {...selectedDailyFilters} />
				</Modal.Body>
			</Modal>
			<Modal
				size="xl"
				scrollable
				show={showSchedulingMonthDetailsModal}
				onHide={() => {
					setShowSchedulingMonthDetailsModal(false);
				}}
			>
				<Modal.Header className="w-100" closeButton>
					<div className="d-flex justify-content-between w-100">
						<div className="d-flex flex-column fw-bolder gap-1 fs-7">
							<label>Agendamentos Mensal</label>
							<label className="fw-light">{selectedMonthlyFilters.healthUnit?.label}</label>
							<label className="fw-light">{selectedMonthlyFilters.healthTeam?.label}</label>
							<label className="fw-light">{selectedMonthlyFilters.professional?.label}</label>
						</div>
						<label className="me-3">{`${dayjs(startDate).format(
							"D MMMM, YYYY"
						)} á ${dayjs(endDate).format("D MMMM, YYYY")}`}</label>
					</div>
				</Modal.Header>
				<Modal.Body>
					<SchedulingDetails
						{...selectedMonthlyFilters}
					/>
				</Modal.Body>
			</Modal>
			<SchedulingFilters
				setSelectedOptionProfessional={setSelectedProfessional}
				setSelectedOptionUnit={setSelectedHealthUnit}
				setSelectedOptionhealtTeam={setSelectedHealthTeam}
				setSelectedOptionProfessionalCategory={setSelectedProfessionalCategory}
				fetch={fetch}
			/>

			<Row className="mx-2">
				<Col sm={12} lg={8} xl={8} className="ps-0 mt-2">
					<Calendar
						title="Calendário"
						calendarContents={calendarContents}
						currentMonth={currentMonth}
						setCurrentMonth={(month) => {
							setCurrentMonth(month);
							setStartDate(month.startOf("month"));
							setEndDate(month.endOf("month"));
						}}
						calendarDayClickEvent={calendarDayClickEvent}
						overlayTriggerChildren={
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[
																key as keyof typeof TScheduleStatusEnum
															].title
														}
														checked={
															columns[
																key as keyof typeof TScheduleStatusEnum
															].visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof typeof TScheduleStatusEnum,
																setColumns
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						}
					/>
				</Col>
				<Col sm={12} lg={4} xl={4} className="h-100 p-0 mt-2">
					<Col sm={12} lg={12} xl={12}>
						<SchedulingByProgressBarStatusComponent
							percentageAttended={!isLoading ? percentageAttended : 0}
							percentageCanceled={!isLoading ? percentageCanceled : 0}
							percentageCitizenPresent={!isLoading ? percentageCitizenPresent : 0}
							percentageDidNoWait={!isLoading ? percentageDidNoWait : 0}
							percentageDidNotShowUpTotal={
								!isLoading ? percentageDidNotShowUpTotal : 0
							}
							percentageExcluded={!isLoading ? percentageExcluded : 0}
							percentageScheduled={!isLoading ? percentageScheduled : 0}
							scheduleAttendedTotal={!isLoading ? scheduleAttendedTotal : 0}
							scheduleCanceledTotal={!isLoading ? scheduleCanceledTotal : 0}
							scheduleCitizenPresentTotal={
								!isLoading ? scheduleCitizenPresentTotal : 0
							}
							scheduleDidNoWaitTotal={!isLoading ? scheduleDidNoWaitTotal : 0}
							scheduleDidNotShowUpTotal={!isLoading ? scheduleDidNotShowUpTotal : 0}
							scheduleExcludedTotal={!isLoading ? scheduleExcludedTotal : 0}
							scheduledTotal={!isLoading ? scheduledTotal : 0}
							schedulesInMonthTotal={!isLoading ? schedulesInMonthTotal : 0}
						/>
					</Col>
					<Col sm={12} lg={12} xl={12} className="d-flex gap-1 mt-2 w-100">
						<Col sm={12} lg={12} xl={6} className="h-100 m-0">
							<Card className="h-100">
								<Card.Header
									className="d-flex fw-bolder m-0 p-2 justify-content-between align-items-center cursor-help"
									title="Absenteismo = ( não aguardaram + não compareceram )/ total agendamentos"
								>
									<Card.Title>Absenteísmo</Card.Title>
								</Card.Header>
								<Card.Body className="d-flex flex-column justify-content-center align-items-center px-0 h-100">
									<RadialChart
										colors={["dark"]}
										series={
											!isLoading
												? [
														calculatePercentage(
															scheduleDidNoWaitTotal +
																scheduleDidNotShowUpTotal,
															scheduledTotal
														),
												  ]
												: []
										}
										width={200}
										categories={["Absenteísmo"]}
									/>
									<Card.Text>
										{!isLoading &&
											(
												scheduleDidNoWaitTotal + scheduleDidNotShowUpTotal
											).toLocaleString()}
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
						<Col sm={12} lg={12} xl={6} className="h-100 m-0">
							<Card className="h-100">
								<Card.Header
									className="d-flex m-0 p-2 justify-content-between align-items-center"
									title={"Agendados sem finalização até a data atual."}
								>
									<Card.Title className="d-flex gap-2">
										Não finalizados
										<CardOverlayTrigger
											message={`São considerados não finalizados os agendamentos com status de agendado até a data atual.`}
										/>
									</Card.Title>
								</Card.Header>
								<Card.Body className="d-flex flex-column justify-content-center align-items-center px-0 h-100">
									<RadialChart
										colors={["muted"]}
										series={
											!isLoading
												? [
														calculatePercentage(
															scheduleExpired,
															scheduledTotal
														),
												  ]
												: []
										}
										width={200}
										categories={["Ausência"]}
									/>
									<Card.Text>
										{!isLoading && scheduleExpired.toLocaleString()}
									</Card.Text>
								</Card.Body>
							</Card>
						</Col>
					</Col>
				</Col>
			</Row>

			<Row className="overflow-auto mx-2 mt-3">
				<SchedulingByHealthUnit
					schedulesAggregatedByHealtUnitAndStatus={
						schedulesDashboard?.schedulesAggregatedByHealthUnitAndStatus
					}
					setSelectedHealthUnit={(healthUnit: THealthUnit) => {
						setSelectedMonthlyFilters({
							startDate: startDate.format("YYYY-MM-DD"),
							endDate: endDate.format("YYYY-MM-DD"),
							healthTeam: selectedHealthTeam,
							healthUnit: {
								value: healthUnit?.cnes,
								label: healthUnit?.name,
							} as Option<string>,
							category: selectedProfessionalCategory,
							professional: selectedProfessional,
						});
						setShowSchedulingMonthDetailsModal(true);
					}}
				/>
			</Row>
			<Row className="mx-2 mt-3">
				<SchedulingByHealthTeam
					schedulesAggregatedByHealthTeamAndStatus={
						schedulesDashboard?.schedulesAggregatedByHealthTeamAndStatus
					}
					setSelectedHealthTeam={(healthTeam: THealthTeam) => {
						setSelectedMonthlyFilters({
							startDate: startDate.format("YYYY-MM-DD"),
							endDate: endDate.format("YYYY-MM-DD"),
							healthTeam: {
								value: healthTeam?.ine,
								label: healthTeam?.name,
							} as Option<string>,
							healthUnit: selectedhealthUnit,
							category: selectedProfessionalCategory,
							professional: selectedProfessional,
						});
						setShowSchedulingMonthDetailsModal(true);
					}}
				/>
			</Row>
			<Row className="mx-2 mt-3">
				<SchedulingByProfessional
					schedulesAggregatedByProfessionalAndStatus={
						schedulesDashboard?.schedulesAggregatedByProfessionalAndStatus
					}
					setSelectedProfessional={(professional: TProfessional) => {
						setSelectedMonthlyFilters({
							startDate: startDate.format("YYYY-MM-DD"),
							endDate: endDate.format("YYYY-MM-DD"),
							healthTeam: selectedHealthTeam,
							healthUnit: selectedhealthUnit,
							category: selectedProfessionalCategory,
							professional: {
								value: professional?.cns,
								label: professional?.name,
							} as Option<string>,
						});
						setShowSchedulingMonthDetailsModal(true);
					}}
				/>
			</Row>
			<Row className="mx-2 mt-3">
				<SchedulingByProfessionalCategory
					schedulesAggregatedByCategoryAndStatus={
						schedulesDashboard?.schedulesAggregatedByCategories || []
					}
					setSelectedCategory={(category: string) => {
						if (category === "") return;
						setSelectedMonthlyFilters({
							startDate: startDate.format("YYYY-MM-DD"),
							endDate: endDate.format("YYYY-MM-DD"),
							healthTeam: selectedHealthTeam,
							healthUnit: selectedhealthUnit,
							category: {
								value: category,
								label: getProfessionalCategory(category) || "Outros",
							} as Option<string>,
							professional: selectedProfessional,
						});
						setShowSchedulingMonthDetailsModal(true);
					}}
				/>
			</Row>
		</section>
	);
}
