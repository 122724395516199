import { ProfessionalCategoryEnum } from "./enums/ProfessionalCategoryEnum";

export function getProfessionalCategory(category: ProfessionalCategoryEnum | string) {
	switch (category) {
		case ProfessionalCategoryEnum.DENTIST.valueOf():
			return "Dentista";
		case ProfessionalCategoryEnum.NURSE.valueOf():
			return "Enfermeiro";
		case ProfessionalCategoryEnum.MEDIC.valueOf():
			return "Médico";
		case ProfessionalCategoryEnum.NURSING_TECHNICIAN.valueOf():
			return "Técnico de enfermagem";
		default:
			break;
	}
}
