import { SisabPreliminaryReport } from "@/@types/app/sisabPreliminaryReport";
import { get, post } from "../api";

type GetUploadedReportsParams = {
	uf: string;
	ibgeCode: string;
	cnes?: string;
};

type UploadHealthInformationSystemForPrimaryCareProps = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	quarter: string;
	fileType: string;
	file: File;
};

export async function getUploadedReports({ uf, ibgeCode, cnes }: GetUploadedReportsParams) {
	const { data } = await get<SisabPreliminaryReport[]>(
		`/uf/${uf}/city/${ibgeCode}/sisabpreliminaryreport/getuploadedreports`,
		{
			cnes,
		}
	);
	return data || [];
}

export async function uploadHealthInformationSystemForPrimaryCare({
	uf,
	ibgeCode,
	file,
	...params
}: UploadHealthInformationSystemForPrimaryCareProps) {
	const formData = new FormData();
	formData.append("file", file);
	const response = await post(
		`/uf/${uf}/city/${ibgeCode}/SisabPreliminaryReport/UploadReport`,
		formData,
		{
			params,
		}
	);

	return Boolean(response.data);
}
