import { Account } from "@/@types/app/Account";
import { get } from "../api";
import { Period } from "@/@types/generics/Period";
import { AttendanceDashboard } from "../esus/attendanceService";
import { PregnancyIndicatorsResume } from "@/pages/federalunitesus/PregnancyDashboard/PregnancyDashboardIndicators";
import { PregnancyResume } from "@/pages/federalunitesus/PregnancyDashboard/PregnancyDashboardPrenatal";
import { ChildBirthResume } from "@/pages/federalunitesus/PregnancyDashboard/PregnancyDashboardChildBirth";

export type CityResumeChildBirth = {
	city: Account;
	resumeChildBirth: ResumeChildBirth[];
};

export type ResumeChildBirth = {
	totalChildBirthNormal?: number;
	totalChildBirthCesarean?: number;
	totalStillBirthNormal?: number;
	totalStillBirthCesarean?: number;
	totalAbortion?: number;
	totalUnspecified?: number;
	period: Period;
};

export type CityChronicCommunicableDiseaseDashboard = {
	city: Account;
	resume: AttendanceDashboard;
};

type PregnancyWithChildBirthResume = PregnancyIndicatorsResume & PregnancyResume & ChildBirthResume;

export type PregnancyWithChildBirthResumeCity = {
	pregnancyResume?: PregnancyWithChildBirthResume;
	city: Account;
};

type ChronicCommunicableDiseaseDashboardParams = {
	uf: string;
	startDate: string;
	endDate: string;
	epidemicType: string;
};

type PregnancyWithChildBirthResumeCityParams = {
	uf: string;
	startDate: string;
	endDate: string;
};

type ResumeChildBirthParams = {
	uf: string;
	startDate: string;
	endDate: string;
};

export async function getResumeChildBirth({ uf, ...params }: ResumeChildBirthParams) {
	const response = await get<CityResumeChildBirth[]>(
		`/uf/${uf}/Attendance/ResumeChildBirth`,
		params
	);
	return response.data || [];
}

export async function getChronicCommunicableDiseaseDashboard({
	uf,
	...params
}: ChronicCommunicableDiseaseDashboardParams) {
	const response = await get<CityChronicCommunicableDiseaseDashboard[]>(
		`/uf/${uf}/Attendance/ChronicCommunicableDiseaseDashboard`,
		params
	);
	return response.data || [];
}

export async function getPregnancyWithChildBirthResumeCity({
	uf,
	...params
}: PregnancyWithChildBirthResumeCityParams) {
	const response = await get<PregnancyWithChildBirthResumeCity[]>(
		`/uf/${uf}/Attendance/pregnancyResume`,
		params
	);
	return response.data || [];
}
