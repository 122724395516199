import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

import { useFormik } from "formik";

import { toast } from "react-toastify";
import { BsUpload } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../_metronic/layout/core";

import { CardDropzone } from "@/components/CardDropzone";
import { LoadingScreen } from "@/components/LoadingScreen";
import { registerTrackOrder } from "@/services/app/trackOrderService";
import { registerTrackFile, TrackFileFormData } from "@/services/app/trackFileService";

export function FormTrackFile() {
	const { setTitle } = useLayout();
	const { trackListId } = useParams();
	const navigate = useNavigate();
	const [showLoadingScreen, setShowLoadingScreen] = useState(false);
	const [videoDuration, setVideoDuration] = useState<number>(0);
	const initialValues = {} as TrackFileFormData;

	const formik = useFormik<TrackFileFormData>({
		initialValues,
		onSubmit: handleRegisterTrackFile,
	});

	const video = document.createElement("video");
	video.ondurationchange = function () {
		setVideoDuration(video.duration);
	};

	if (formik.values.videoFile) {
		const fileURL = URL.createObjectURL(formik.values.videoFile || "");
		video.src = fileURL;
	}

	async function handleRegisterTrackFile(trackFileFormData: TrackFileFormData) {
		const registeredTrackFile = await registerTrackFile({
			...trackFileFormData,
			duration: videoDuration,
		});
		if (!trackFileFormData.videoFile || !trackFileFormData.thumbnailFile) return;
		setShowLoadingScreen(true);
		if (!registeredTrackFile?.id) {
			toast.error("Erro ao registrar arquivo");
		}
		const isRegisteredTrackOrder = await registerTrackOrder(
			Number(trackListId),
			registeredTrackFile.id || 0
		);
		if (isRegisteredTrackOrder) {
			toast.success("Arquivos registrados com sucesso");
			navigate(-1);
		} else {
			toast.error("Erro ao registrar arquivo");
		}
		setShowLoadingScreen(false);
	}

	useEffect(() => {
		setTitle("GERENCIAMENTO DE PLAYLIST");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="bg-white p-4">
			<LoadingScreen
				loading={showLoadingScreen}
				messages={[
					"Já estamos processando o upload do video.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
				<div className="d-flex my-3 justify-content-between w-100">
					<h1 className="text-primary fs-5">Upload de video para playlist</h1>
					<Button className="ml-auto" type="submit">
						<BsUpload className="me-2" /> Upload
					</Button>
				</div>
				<Row className="my-3">
					<Form.Group>
						<Form.Label className="fs-6">Título:</Form.Label>
						<Form.Control
							type="text"
							name="title"
							value={formik.values.title}
							onChange={formik.handleChange}
							required
						/>
					</Form.Group>
					<Form.Group className="mt-2">
						<Form.Label className="fs-6">Descrição:</Form.Label>
						<Form.Control
							type="text"
							name="description"
							value={formik.values.description}
							onChange={formik.handleChange}
							as="textarea"
							rows={3}
							required
						/>
					</Form.Group>
				</Row>
				<Row>
					<Col lg={4}>
						<Form.Group className="text-center bg-white">
							<Form.Label className="text-warning fs-5">
								<strong>Arquivo de Vídeo</strong>
							</Form.Label>
							<CardDropzone
								inputName="videoFile"
								description="Solte o arquivo de video aqui, para fazer o upload."
								acceptedFileType={{ "video/*": [".mp4", ".svi", ".mkv"] }}
								descriptionAcceptedFilesExtension="Upload somente de arquivo .mp4/.avi (tamanho máximo: 500MB)"
								handleChange={(file: File) =>
									formik.setFieldValue("videoFile", file)
								}
							/>
						</Form.Group>
					</Col>
					<Col lg={4}>
						<Form.Group className="text-center bg-white">
							<Form.Label className="text-warning fs-5">
								<strong>Arquivo da thumbnail</strong>
							</Form.Label>
							<CardDropzone
								inputName="thumbnailFile"
								description="Solte o arquivo de thumbnail aqui, para fazer o upload."
								acceptedFileType={{ "image/*": [".jpeg", ".png"] }}
								descriptionAcceptedFilesExtension="Upload somente de arquivo .jpg/.png (tamanho máximo: 10MB)"
								handleChange={(file: File) =>
									formik.setFieldValue("thumbnailFile", file)
								}
							/>
						</Form.Group>
					</Col>
					<Col lg={4}>
						<Form.Group className="text-center bg-white">
							<Form.Label className="text-warning fs-5">
								<strong>Arquivo de imagem de capa alterativa</strong>
							</Form.Label>
							<CardDropzone
								inputName="imageFile"
								description="Solte o arquivo alternativo aqui, para fazer o upload."
								acceptedFileType={{ "image/*": [".jpeg", ".png"] }}
								descriptionAcceptedFilesExtension="Upload somente de arquivo .jpg/.png (tamanho máximo: 10MB)"
								handleChange={(file: File) =>
									formik.setFieldValue("imageFile", file)
								}
							/>
						</Form.Group>
					</Col>
				</Row>
			</Form>
		</section>
	);
}
