import dayjs from "dayjs";

import { CityLicense } from "@/@types/app/CityLicense";

export function getCityLicenseStatus(cityLicense: CityLicense) {
	if (
		cityLicense.startDate &&
		(!cityLicense.endDate ||
			(cityLicense.endDate && dayjs(cityLicense.endDate).isAfter(dayjs())))
	) {
		return "ativo";
	} else if (!cityLicense.startDate && cityLicense.id) {
		return "pendente";
	} else {
		return "inativo";
	}
}
