import { Props as SelectProps } from "react-select";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	noDefaultOption?: boolean;
};


export const defaultGender = { value: "", label: "Todos" };

export function GenderSelect({ noDefaultOption, ...rest }: Props) {
	const options = [
		{ value: "", label: "Todos" },
		{ value: "m", label: "Masculino" },
		{ value: "f", label: "Feminino" },
		{ value: "i", label: "Ignorado" },
	];
	return (
		<div className="d-flex flex-column gap-2">
			<label title="Lista de Unidades Disponíveis" htmlFor={rest.id}>
				Gênero:
			</label>
			<SelectComponent
				className="fs-8 p-0"
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma opção encontrada"}
				{...rest}
			/>
		</div>
	);
}
