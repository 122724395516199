import { FormEvent, useEffect, useState } from "react";

import { BsSearch } from "react-icons/bs";
import { FaPrint } from "react-icons/fa";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import { Option } from "@/@types/generics/Option";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { Account } from "@/@types/app/Account";
import { getPermittedFederalUnits, getPermittedCities } from "@/services/app/userService";

import { SelectComponent } from "@/components/SelectComponent";

type Props = {
	selectedFederalUnit: FederalUnit;
	selectedCity: Account;
	setSelectedFederalUnit: (federalUnit: FederalUnit) => void;
	setSelectedCity: (city: Account) => void;
	handleFilter: (event: FormEvent) => void;
	printPage: () => void;
};

export function CityAdminReportFilters({
	selectedCity,
	selectedFederalUnit,
	setSelectedFederalUnit,
	setSelectedCity,
	handleFilter,
	printPage,
}: Props) {
	const [federalUnits, setFederalUnits] = useState<FederalUnit[]>([]);
	const [cities, setCities] = useState<Account[]>([]);
	const [isLoadingCities, setIsloadingCities] = useState(false);

	const federalUnitOptions: Option<FederalUnit>[] = federalUnits.map((federalUnit) => {
		return {
			label: federalUnit.name || "Selecione...",
			value: federalUnit,
		} as Option<FederalUnit>;
	});

	const cityOptions: Option<Account>[] = cities.map((city) => {
		return { label: city.locale || "Selecione...", value: city } as Option<Account>;
	});

	async function handlePermittedFederalUnits() {
		setFederalUnits(await getPermittedFederalUnits());
	}

	async function handlePermittedCities() {
		setIsloadingCities(true);
		if (selectedFederalUnit) {
			setCities(await getPermittedCities(selectedFederalUnit.abbreviation));
		} else {
			setCities([]);
		}
		setIsloadingCities(false);
	}

	useEffect(() => {
		handlePermittedFederalUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		handlePermittedCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFederalUnit]);

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter(event);
			}}
		>
			<Row className="d-flex justify-content-between">
				<Col sm={3} lg={3} xl={3} className="justify-content-start mt-1">
					<div className="d-flex flex-column">
						<label htmlFor={"federal-unit-admin-report-select"} className="form-label">
							UF:
						</label>
						<SelectComponent
							id="federal-unit-admin-report-select"
							options={federalUnitOptions}
							placeholder="Selecione..."
							onChange={(event) => {
								setSelectedFederalUnit((event as Option<FederalUnit>).value);
							}}
						/>
					</div>
				</Col>
				<Col sm={6} lg={6} xl={6} className="justify-content-start mt-1">
					<div className="d-flex flex-column">
						<label htmlFor={"city-admin-report-select"} className="form-label">
							Cidade:
						</label>
						<SelectComponent
							id="city-admin-report-select"
							options={cityOptions}
							value={{
								value: selectedCity.ibgeCode,
								label: selectedCity.locale || "Selecione...",
							}}
							isLoading={isLoadingCities}
							placeholder="Selecione..."
							defaultValue={{ value: {} as Account, label: "Selecione...." }}
							onChange={(event) => setSelectedCity((event as Option<Account>).value)}
						/>
					</div>
				</Col>
				<Col
					sm={3}
					lg={3}
					xl={3}
					className="d-flex justify-content-end align-items-end mt-1 gap-1"
				>
					<Button
						className="d-flex align-items-center justify-content-center w-100"
						type="submit"
						disabled={!selectedCity.locale && !isLoadingCities}
					>
						<BsSearch className="me-2" />
						Consultar
					</Button>
					<Button
						variant="secondary"
						className="d-flex align-items-center justify-content-center w-100"
						title="Imprimir"
						disabled={!selectedCity.locale && !isLoadingCities}
						onClick={printPage}
					>
						<FaPrint /> Exportar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
