import { Professional } from "@/@types/esus/Professional";
import { get } from "../api";

type GetProfessionalsParams = {
	uf: string;
	ibgeCode: string;
	cnes?: string;
};

export async function getProfessionals({ uf, ibgeCode, ...params }: GetProfessionalsParams) {
	const response = await get<Professional[]>(
		`/uf/${uf}/city/${ibgeCode}/professional/getprofessionals`,
		params
	);
	return response.data || [];
}
