import { Props as SelectProps } from "react-select";

import { Role } from "@/@types/app/Role";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	roles: Role[];
	noDefaultOption?: boolean;
};

export function ProfileSelect({ roles, noDefaultOption, ...rest }: Props) {
	const options = [...roles?.map((role) => ({ value: role.id, label: role.description }))];
	return (
		<div className="d-flex flex-column">
			<label title="Lista de Perfis Disponíveis" htmlFor={rest.id} className="form-label">
				Perfil:
			</label>
			<SelectComponent
				options={options}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhum perfil encontrado"}
				{...rest}
			/>
		</div>
	);
}
