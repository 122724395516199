import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getApiInstance } from "@/utils/getApiInstance";

const currentApiVersion = 2.4;

export const api = axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const authApi = axios.create({
	baseURL: process.env.REACT_APP_AUTH_BASE_URL,
});

export async function get<T>(url: string, params?: any): Promise<AxiosResponse<T | undefined>> {
	const api = getApiInstance();
	const apiVersion = localStorage.getItem("apiVersion") || currentApiVersion;

	try {
		const response = await api.get<T>(`/api/v${apiVersion}${url}`, {
			params: params,
		});
		return response;
	} catch (error) {
		console.log(error);
		return { status: 500 } as AxiosResponse<T | undefined>;
	}
}

export async function post<T>(
	url: string,
	data?: any,
	config?: AxiosRequestConfig<any>
): Promise<AxiosResponse<T | undefined>> {
	const api = getApiInstance();
	const apiVersion = localStorage.getItem("apiVersion") || currentApiVersion;
	
	try {
		const response = await api.post<T>(`/api/v${apiVersion}${url}`, data, config);
		return response;
	} catch (error) {
		console.log(error);
		return { status: 500 } as AxiosResponse<T | undefined>;
	}
}
