import { BsSearch } from "react-icons/bs";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Select from "react-select";

import { useProfessionals } from "@/hooks/useProfessionals";
import { useUnits } from "@/hooks/useUnits";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Option } from "@/@types/generics/Option";

import { UnitsSelect } from "@/components/UnitsSelect";
import { ProfessionalCategorySelect } from "@/components/ProfessionalCategorySelect";
import { ProfessionalsSelect } from "@/components/ProfessionalsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";

type Props = {
	setSelectedOptionUnit: (unit: Option<string>) => void;
	setSelectedOptionhealtTeam: (healthTeam: Option<string>) => void;
	setSelectedOptionProfessional: (professional: Option<string>) => void;
	setSelectedOptionProfessionalCategory: (professionalCaregory: Option<string>) => void;
	fetch: () => void;
};

export function SchedulingFilters({
	setSelectedOptionUnit,
	setSelectedOptionhealtTeam,
	setSelectedOptionProfessional,
	setSelectedOptionProfessionalCategory,
	fetch,
}: Props) {
	const { cnes } = useCurrentAccount();
	const { professionals } = useProfessionals();
	const { units } = useUnits();
	const { healthTeams } = useHealthTeams();
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				fetch();
			}}
		>
			<Row className="d-flex align-items-end justify-content-between bg-white mx-3 mb-3 p-3">
				<Col sm={3} md={4} lg={4} xl={5} className="p-0 mt-2">
					{!cnes ? (
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedOptionUnit(event as Option<string>)}
							defaultValue={{ value: "", label: "selecione..." }}
							noOptionsMessage={() => "Nenhuma unidade encontrada"}
						/>
					) : (
						<div className="d-flex flex-column">
							<label>Unidades:</label>
							<Select
								value={{
									label: units.find((healthUnit) => healthUnit.cnes === cnes)
										?.name,
									value: cnes,
								}}
								isDisabled={!!cnes}
							/>
						</div>
					)}
				</Col>
				<Col sm={3} md={4} lg={4} xl={6} className="p-0 mt-2">
					<HealthTeamsSelect
						healthTeams={healthTeams}
						onChange={(event) => setSelectedOptionhealtTeam(event as Option<string>)}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhuma equipe encontrada"}
					/>
				</Col>
				<Col sm={12} md={6} lg={5} xl={5} className="p-0 mt-2">
					<ProfessionalsSelect
						professionals={professionals}
						onChange={(event) => setSelectedOptionProfessional(event as Option<string>)}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhum profissional encontrado"}
					/>
				</Col>
				<Col sm={12} md={3} lg={3} xl={3} className="p-0 mt-2">
					<ProfessionalCategorySelect
						onChange={(event) =>
							setSelectedOptionProfessionalCategory(event as Option<string>)
						}
						defaultValue={{ value: "", label: "selecione..." }}
						noOptionsMessage={() => "Nenhum categoria profissional encontrada"}
					/>
				</Col>
				<Col sm={3} md={3} lg={3} xl={2} className="p-0 mt-2">
					<Button type="submit" className="fs-8 p-3 text-nowrap w-100">
						<BsSearch className="me-2" />
						Consultar
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
