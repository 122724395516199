import { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import Yup from "@/utils/mixins/yup";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";

import { Account } from "@/@types/app/Account";
import { updateCity } from "@/services/app/cityService";

type Props = ModalProps & {
	city?: Account;
};

export function ModalCitiesEdit({ city, ...rest }: Props) {
	const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState<boolean>(true);

	const initialValues: Account = {
		id: 0,
		ibgeCode: "",
		databaseConnectionString: "",
		locale: "",
	};

	const validationCitySchema = Yup.object().shape({
		id: Yup.number(),
		locale: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(50, "Máximo de 50 caracteres!")
			.required("Nome é Obrigatório!"),
		federalUnitId: Yup.number().required("Estado é Obrigatório!"),
		ibgeCode: Yup.string()
			.min(7, "Minimo de 7 caracteres")
			.max(7, "Maximo de 7 caracteres")
			.required("IBGE Code é Obrigatório!"),
		databaseConnectionString: Yup.string().required("Connection String Obrigatória"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: validationCitySchema,
		onSubmit: (values) => {
			const data = {
				id: values.id,
				locale: values.locale,
				federalUnitId: values.federalUnitId,
				ibgeCode: values.ibgeCode,
				databaseConnectionString: values.databaseConnectionString,
			} as Account;
			handleUpdateCity(data);
		},
	});

	async function handleUpdateCity(city: Account) {
		const isUpdated = await updateCity(city);
		if (isUpdated) {
			toast.success("Alterado com sucesso!!");
		} else {
			toast.error("Não foi possível realizar a alteração, algo está errado.");
		}
	}

	function handleFocusInput() {
		setIsUpdateButtonDisabled(false);
	}

	useEffect(() => {
		if (!isUpdateButtonDisabled) {
			setIsUpdateButtonDisabled(true);
		}
		if (city) {
			formik.setValues(city);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [city]);

	return (
		<Modal scrollable {...rest}>
			<Modal.Header className="d-block pb-3">
				<div className="col-xl-12 d-flex">
					<CloseButton onClick={rest.onHide} />
				</div>
				<div className="col-xl-12">
					<Modal.Title className="text-center">
						Configuração Conexão Banco de Dados
					</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={formik.handleSubmit} onFocus={handleFocusInput}>
					<input type="hidden" name="id" value={formik.values.id} />
					<Form.Group className="mb-4">
						<Form.Label>IBGE Code</Form.Label>
						<Form.Control
							disabled={true}
							type="text"
							name="ibgeCode"
							value={formik.values.ibgeCode}
							onChange={formik.handleChange}
						/>
						{formik.errors.ibgeCode && (
							<span className="text-danger">{formik.errors.ibgeCode}</span>
						)}
					</Form.Group>
					<div className="col-xl-12 d-flex justify-content-between mb-4 container-cpf-busy">
						<Form.Group className="col-xl-6">
							<Form.Label>Cidade</Form.Label>
							<Form.Control
								disabled={true}
								type="text"
								name="locale"
								value={formik.values.locale}
								onChange={formik.handleChange}
								placeholder="Digite nome da cidade..."
								autoFocus
							/>
							{formik.errors.locale && (
								<span className="text-danger">{formik.errors.locale}</span>
							)}
						</Form.Group>
						<Form.Group className="col-xl-6">
							<Form.Label>Estado</Form.Label>
							<Form.Control
								disabled={true}
								type="text"
								name="federalUnitId"
								value={formik.values.federalUnit?.abbreviation}
								onChange={formik.handleChange}
							/>
							{formik.errors.federalUnitId && (
								<span className="text-danger">{formik.errors.federalUnitId}</span>
							)}
						</Form.Group>
					</div>
					<div>
						<Form.Group className="col-xl-12">
							<Form.Label>Conection String</Form.Label>
							<Form.Control
								type="text"
								name="databaseConnectionString"
								value={formik.values.databaseConnectionString}
								onChange={formik.handleChange}
							/>
							{formik.errors.databaseConnectionString && (
								<span className="text-danger">
									{formik.errors.databaseConnectionString}
								</span>
							)}
						</Form.Group>
					</div>
					<div className="d-flex justify-content-center mt-3">
						<Button
							title="Salvar Alterações"
							variant="success"
							type="submit"
							disabled={isUpdateButtonDisabled}
							className="fs-7"
						>
							Salvar Alterações
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
