import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Card from "react-bootstrap/Card";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { TrackOrder } from "@/@types/app/TrackOrder";
import { TrackList } from "@/@types/app/TrackList";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { getAllTrackOrders } from "@/services/app/trackOrderService";

import { Skeleton } from "@/components/Skeleton";
import { CarouselImageProps, CarouselImages } from "./CarouselImages";
import { ContinueWatching } from "./ContinueWatching";
import { BannerDisplay } from "@/components/BannerDisplay";

import "./styles.scss";

export function InstitutionalPlataform() {
	const navigate = useNavigate();
	const { setTitle } = useLayout();
	const [isLoadingTrackOrders, setIsLoadingTrackOrders] = useState(false);

	const [trackOrders, setTrackOrders] = useState<TrackOrder[]>([]);
	const trackLists = trackOrders
		.map((trackOrder) => trackOrder.trackList!)
		.reduce((accumulator: TrackList[], current: TrackList) => {
			return accumulator.find((item) => item.id === current.id)
				? accumulator
				: [...accumulator, current];
		}, []);

	async function handleTrackOrders() {
		setIsLoadingTrackOrders(true);
		setTrackOrders(await getAllTrackOrders());
		setIsLoadingTrackOrders(false);
	}

	const trackListImages: CarouselImageProps[] = trackLists.map<CarouselImageProps>(
		(trackList) => {
			return {
				url: trackList?.urlThumbnail,
				title: trackList?.title,
				description: trackList?.description,
				handleClick: () =>
					navigate(
						`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackList?.id}/video/1`
					),
			} as CarouselImageProps;
		}
	);

	function FilterTrackOrdersByTrackListId(trackOrders: TrackOrder[], trackListId?: number) {
		const trackOrdersFiltered: TrackOrder[] = trackOrders
			.filter((trackOrder) => trackOrder.trackListId === trackListId)
			.sort(
				(trackOrderA, trackOrderB) => trackOrderA.sequenceOrder - trackOrderB.sequenceOrder
			);
		return trackOrdersFiltered;
	}

	useEffect(() => {
		setTitle("AMBIENTE DE APOIO");
		handleTrackOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div>
			<section className="p-0">
				<BannerDisplay bannerTypeEnum={BannerTypeEnum.SUPPORTIVE_ENVIRONMENT} />
			</section>
			<Card>
				<ContinueWatching />
			</Card>
			<Card className="mt-2 rounded ">
				<Card.Header className="p-3">
					<Card.Title className="fw-bolder font-roboto">
						Principais conteúdos recomendados
					</Card.Title>
				</Card.Header>
				<Card.Body className="p-3">
					<p className="px-5 fw-bold font-roboto">
						Apresentamos a seguir todas as trilhas nas quais disponibilizamos
						compilações de vídeos relacionados à temática. Não perca a oportunidade de
						expandir seus conhecimentos e aprimorar a qualidade dos seus atendimentos na
						atenção primária à saúde. Clique para assistir e explore o rico conteúdo que
						preparamos para você.
					</p>

					<CarouselImages
						isLoading={isLoadingTrackOrders}
						carouselImagesProps={trackListImages}
					/>
				</Card.Body>
			</Card>
			{!isLoadingTrackOrders ? (
				trackLists.map((trackList, index) => (
					<Card key={index} className="mt-2 rounded">
						<Card.Header className="p-3">
							<Card.Title className="fw-bolder font-roboto">
								{trackList?.title}
							</Card.Title>
						</Card.Header>
						<Card.Body className="p-2">
							<p className="px-5 font-roboto">{trackList?.description}</p>
							<CarouselImages
								isLoading={isLoadingTrackOrders}
								carouselImagesProps={FilterTrackOrdersByTrackListId(
									trackOrders,
									trackList?.id
								)
									.filter((trackOrder) => trackOrder.trackFile?.urlThumbnail)
									.map<CarouselImageProps>((trackOrder) => {
										return {
											url: trackOrder.trackFile?.urlThumbnail || "",
											title: trackOrder.trackFile?.title,
											description: trackOrder.trackFile?.description,
											handleClick: () => {
												navigate(
													`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackOrder?.trackListId}/video/${trackOrder.sequenceOrder}`
												);
											},
										};
									})}
							/>
						</Card.Body>
					</Card>
				))
			) : (
				<Card className="mt-2 rounded">
					<Card.Header>
						<Card.Title className="p-3">
							<Skeleton height={20} width="50%" />
							<Skeleton height={15} width="20%" />
						</Card.Title>
					</Card.Header>
					<Card.Body className="p-0">
						<CarouselImages
							isLoading={isLoadingTrackOrders}
							carouselImagesProps={Array(6)}
						/>
					</Card.Body>
				</Card>
			)}
		</div>
	);
}
