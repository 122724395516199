import { FaDotCircle } from "react-icons/fa";

type Props = {
	titles: string[];
	ClassNamesColors?: string[];
	colors?: string[];
};

export function LegendData({ titles, ClassNamesColors, colors }: Props) {
	return (
		<section>
			<label className="fw-bolder fs-8">Legenda:</label>
			<div className="d-flex gap-3 m-2">
				{titles?.map((value, index) => (
					<div key={index} className="d-flex align-items-center">
						{
							<FaDotCircle
								className={`me-2 ${ClassNamesColors && ClassNamesColors[index]}`}
								style={{ color: colors && colors[index] }}
							/>
						}
						<label className="text-nowrap fs-8">{titles[index]}</label>
					</div>
				))}
			</div>
		</section>
	);
}
