import { get } from "../api";
import { ActiveChildVaccination } from "@/@types/esus/ActiveChildVaccination";
import { ActivePregnantVaccination } from "@/@types/esus/ActivePregnantVaccination";
import { ActiveTeenVaccination } from "@/@types/esus/ActiveTeenVaccination";
import { Paginated } from "@/@types/generics/paginated";
import { ActiveOlderVaccination } from "@/@types/esus/ActiveOlderVaccination";

export type ActiveVaccinationResume = {
	totalCitizens: number;
	immunobiologicalName: string;
	immunobiologicalPreview: number;
	immunobiologicalDelayed: number;
};

type ActiveVaccinationParams = {
	uf: string;
	ibgeCode: string;
	pageNumber: number;
	fullName: string;
	cnes: string;
	ine: string;
	pageSize?: number;
	immunobiologicalCod: number;
};

type ActiveVaccinationResumeParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	ine: string;
	immunobiologicalCod: number;
};

export async function handleActiveChildVaccinationResume({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetResumeActiveVaccinationChild`,
		params
	);
	return response.data || [];
}

export async function handleActiveTeenVaccinationResume({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetResumeActiveVaccinationTeen`,
		params
	);
	return response.data || [];
}

export async function handleActiveOlderVaccinationResume({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetResumeActiveVaccinationOlder`,
		params
	);
	return response.data || [];
}

export async function handleActivePregnantVaccinationResume({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationResumeParams) {
	const response = await get<ActiveVaccinationResume[]>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetResumeActiveVaccinationPregnant`,
		params
	);
	return response.data || [];
}

export async function handleActiveChildVaccinationSearch({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveChildVaccination>>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetChilds`,
		params
	);
	return response.data || ({} as Paginated<ActiveChildVaccination>);
}

export async function handleActiveTeenVaccinationSearch({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveTeenVaccination>>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetTeens`,
		params
	);
	return response.data || ({} as Paginated<ActiveTeenVaccination>);
}

export async function handleActiveOlderVaccinationSearch({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationParams) {
	const response = await get<Paginated<ActiveOlderVaccination>>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetOlders`,
		params
	);
	return response.data || ({} as Paginated<ActiveOlderVaccination>);
}

export async function handleActivePregnantVaccinationSearch({
	uf,
	ibgeCode,
	...params
}: ActiveVaccinationParams) {
	const response = await get<Paginated<ActivePregnantVaccination>>(
		`/uf/${uf}/city/${ibgeCode}/ActiveVaccination/GetPregnants`,
		params
	);
	return response.data || ({} as Paginated<ActivePregnantVaccination>);
}
