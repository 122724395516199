import { useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { removeDuplicates } from "@/utils/genericals";
import { Period } from "@/@types/generics/Period";

import { PregnantDashboardChildBirthLineChart } from "../PregnantDashboardChildBirthLineChart";
import { PregnantDashboardChildBirthAreaChart } from "../PregnantDashboardChildBirthAreaChart";
import { DonutChart } from "@/components/Charts/DonutChart";
import { ChildBirthResumeCity } from "..";
import { ChildBirthTypeResume } from "../PregnancyDashboardChildBirthResume";
import { ChildBirthByCityAndTypeModal } from "./ChildBirthByCityAndTypeModal";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";

type Props = {
	childBirthResumeCities: ChildBirthResumeCity[];
};

export function PregnancyDashboardChildBirthDetails({ childBirthResumeCities }: Props) {
	const [showChildBirthByCityAndTypeModal, setShowChildBirthByCityAndTypeModal] = useState(false);

	const childBirthTypeResume: ChildBirthTypeResume = {
		totalNormal: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "normal"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalCesarean: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "cesarean"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalNormalStillBirth: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "normalstillbirth"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalCesareanStillBirth: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "cesareanstillbirth"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalAbortion: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "abortion"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalUnspecified: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "unspecified"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
		totalUnregistered: childBirthResumeCities
			.map(
				(resume) =>
					resume.childBirthTypeResumes?.find(
						(childBirthType) => childBirthType.key === "unregistered"
					)?.total || 0
			)
			.reduce((acc, curr) => acc + curr, 0),
	};

	const childBirthTypeResumeChart = [
		{
			category: "Nativivo",
			color: "#0f9beb",
			value: childBirthTypeResume.totalNormal + childBirthTypeResume.totalCesarean,
		},
		{
			category: "Natimorto",
			color: "#f1416c",
			value:
				childBirthTypeResume.totalNormalStillBirth +
				childBirthTypeResume.totalCesareanStillBirth,
		},
		{
			category: "Aborto",
			color: "#ffc700",
			value: childBirthTypeResume.totalAbortion,
		},
	];

	const periods = removeDuplicates<Period[]>(
		childBirthResumeCities
			.map((childBirthResumeCity) =>
				childBirthResumeCity.childBirthByPeriodAndTypeResumes.map(
					(periodResume) => periodResume.key
				)
			)
			.reduce((acc, curr) => acc.concat(curr), [])
	);
	const periodAndTypeResume = periods
		.map((period) => {
			const childBirthTypes = childBirthResumeCities
				.map((childBirthResumeCity) => {
					return (
						childBirthResumeCity.childBirthByPeriodAndTypeResumes.find(
							(childBirthByPeriodResume) =>
								childBirthByPeriodResume.key?.year === period.year &&
								childBirthByPeriodResume.key.month === period.month
						)?.values || []
					);
				})
				.reduce((acc, curr) => {
					return acc.concat(curr);
				}, [])
				.reduce((acc, curr) => {
					return acc.concat(curr);
				}, []);
			const periodResume = {
				normal:
					childBirthTypes
						.filter((a) => a.key === "normal")
						.map((a) => a?.total)
						.reduce((acc, curr) => acc + curr, 0) || 0,
				cesarean:
					childBirthTypes
						.filter((a) => a.key === "cesarean")
						.map((a) => a?.total)
						.reduce((acc, curr) => acc + curr, 0) || 0,
			};
			return {
				period: period,
				normal: periodResume.normal,
				cesarean: periodResume.cesarean,
			};
		})
		.sort((a, b) =>
			dayjs(new Date(a.period.year, a.period.month - 1, 1)).diff(
				dayjs(new Date(b.period.year, b.period.month - 1, 1), "months")
			)
		);

	const childBirthByPeriodAndAverageGestationalAge = periods
		.map((period) => {
			const averageGestationalAgeResume = childBirthResumeCities
				.map((childBirthResumeCity) => {
					return {
						averageGestationalAge:
							childBirthResumeCity.childBirthByPeriodAndAverageGestationalAgeResumes.find(
								(childBirthByPeriodResume) =>
									childBirthByPeriodResume.key?.year === period.year &&
									childBirthByPeriodResume.key.month === period.month
							)?.total || 37,
					};
				})
				.filter((age) => Boolean(age.averageGestationalAge))
				.map((age) => age.averageGestationalAge);
			return {
				period: period,
				averageGestationalAge: Math.round(
					averageGestationalAgeResume.reduce((acc, curr) => acc + curr, 0) /
						(averageGestationalAgeResume.length || 1)
				),
			};
		})
		.sort((a, b) =>
			dayjs(new Date(a.period.year, a.period.month - 1, 1)).diff(
				dayjs(new Date(b.period.year, b.period.month - 1, 1), "months")
			)
		);

	return (
		<section>
			<Row className="px-2 pb-2">
				<Col sm={12} md={12} lg={6} xl={6} className="py-2 px-3">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Nascidos vivos por tipo de parto: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() => setShowChildBirthByCityAndTypeModal(true)}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<PregnantDashboardChildBirthLineChart
								categories={periodAndTypeResume.map(
									(resume) =>
										`${SHORT_MONTHS[resume.period.month - 1]}/${
											resume.period.year
										}`
								)}
								series={[
									{
										data: periodAndTypeResume.map((resume) => resume.normal),
										name: "Normal",
									},
									{
										data: periodAndTypeResume.map((resume) => resume.cesarean),
										name: "Cesariano",
									},
								]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6} className="py-2 px-3">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex justify-content-between w-100">
								<h3>Encerramento de gestação: </h3>
								<Button
									className="no-print"
									variant="primary"
									onClick={() => setShowChildBirthByCityAndTypeModal(true)}
								>
									Detalhes
								</Button>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<DonutChart
								labels={childBirthTypeResumeChart.map((resume) => resume.category)}
								series={childBirthTypeResumeChart.map((resume) => resume.value)}
								height={"100%"}
								colors={childBirthTypeResumeChart.map((resume) => resume.color)}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="px-2 pb-2">
				<Col>
					<Card>
						<Card.Header>
							<Card.Title>
								<h3>Média de idade gestacional por período: </h3>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<PregnantDashboardChildBirthAreaChart
								categories={childBirthByPeriodAndAverageGestationalAge.map(
									(age) =>
										`${SHORT_MONTHS[age.period.month - 1]}/${age.period.year}`
								)}
								series={[
									{
										name: "IG média",
										data: childBirthByPeriodAndAverageGestationalAge.map(
											(age) => age.averageGestationalAge
										),
									},
								]}
								annotations={{
									yaxis: [
										{
											y: 37,
											borderColor: "#000000",
										},
									],
								}}
								height="500px"
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<ChildBirthByCityAndTypeModal
				childBirthByCityAndTypeResumes={childBirthResumeCities.map((resume) => {
					return { city: resume.city, resumes: resume.childBirthTypeResumes };
				})}
				show={showChildBirthByCityAndTypeModal}
				onHide={() => setShowChildBirthByCityAndTypeModal(false)}
			/>
		</section>
	);
}
