import { useEffect, useState } from "react";

import dayjs from "dayjs";
import Swal from "sweetalert2";

import { toast } from "react-toastify";
import { MdAddPhotoAlternate } from "react-icons/md";
import { FaClock, FaEllipsisH, FaImage, FaTrash } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";

import { deleteBanner, getBannersByType } from "@/services/app/storageService";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { checkWithInThePeriod } from "@/utils/checkWithInThePeriod";
import { Banner } from "@/@types/app/Banner";

import { BaseModalProps } from "@/components/BaseModal";
import { BannerEditModal } from "./BannerEditModal";
import { BannerRegisterModal } from "./BannerRegisterModal";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { TimeLine, TimeLineContent } from "@/components/TimeLine";

type Props = BaseModalProps & {
	bannerType: number;
};

export function BannersModal({ bannerType, ...rest }: Props) {
	const [banners, setBanners] = useState<Banner[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedBanner, setSelectedBanner] = useState<Banner>({} as Banner);
	const [showModalEdit, setShowModalEdit] = useState(false);
	const [showModalRegister, setShowModalRegister] = useState(false);

	const bannerSorted = banners?.sort((a, b) => {
		if (dayjs(a.startDate).isAfter(b.startDate)) return 1;
		if (dayjs(a.startDate).isBefore(b.startDate)) return -1;
		return 0;
	});

	async function handleBannerByType() {
		setIsLoading(true);
		setBanners(await getBannersByType(bannerType));
		setIsLoading(false);
	}

	async function handleBannerDelete(banner: Banner) {
		const { isConfirmed } = await Swal.fire({
			title: "Deseja apagar o banner?",
			text: "Não poderá ser desfeito!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#009ef7",
			cancelButtonColor: "#F1416C",
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
		});
		if (isConfirmed) {
			setIsLoading(true);
			const response = await deleteBanner(banner);
			setIsLoading(false);
			if (response.status !== 200) {
				toast.error("Erro ao excluir banner");
			} else {
				toast.success("Banner excluido com sucesso.");
				handleBannerByType();
			}
		}
	}

	useEffect(() => {
		if (rest.show) {
			handleBannerByType();
		}
		return () => {
			setBanners([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerType, rest.show]);

	return (
		<Modal size="xl" scrollable {...rest}>
			<BannerEditModal
				show={showModalEdit}
				onHide={() => setShowModalEdit(false)}
				bannerType={bannerType}
				selectedBanner={selectedBanner}
				fetchBanners={handleBannerByType}
			/>
			<BannerRegisterModal
				bannerType={bannerType}
				show={showModalRegister}
				fetchBanners={handleBannerByType}
				onHide={() => setShowModalRegister(false)}
			/>
			<Modal.Header closeButton>
				<Modal.Title className="d-flex w-100 align-items-center justify-content-between">
					<label>{BannerTypeEnum[bannerType as number]}</label>{" "}
					<MdAddPhotoAlternate
						size={25}
						className="cursor-pointer"
						onClick={() => setShowModalRegister(true)}
					/>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row className="ps-20">
					{!isLoading ? (
						bannerSorted.length > 0 ? (
							<TimeLine
								timeLineContents={bannerSorted.map<TimeLineContent>(
									(banner, index) => {
										return {
											content: (
												<Col
													key={index}
													sm={12}
													md={12}
													lg={12}
													xl={12}
													className="d-flex flex-column justify-content-center align-items-center ms-2 mt-2 cursor-pointer"
												>
													<div style={{ position: "relative" }}>
														<img
															src={banner.storageFile?.urlFile}
															alt={banner.storageFile?.description}
															style={{
																width: "100%",
																opacity: !checkWithInThePeriod(
																	banner.startDate,
																	banner.endDate
																)
																	? "0.2"
																	: "",
															}}
														/>
														<div
															className="d-flex gap-2"
															style={{
																position: "absolute",
																top: "2px",
																right: "10px",
																zIndex: 1,
															}}
														>
															<OverlayTrigger
																trigger="click"
																placement="bottom"
																overlay={
																	<Popover id="options-popover">
																		<Popover.Body className="px-2">
																			<div className="d-flex flex-column gap-2">
																				<button
																					className={`d-flex align-items-center border border-none bg-white border-bottom pb-2 text-hover-primary gap-2 text-muted`}
																					disabled={
																						isLoading
																					}
																					onClick={() => {
																						setSelectedBanner(
																							banner
																						);
																						setShowModalEdit(
																							true
																						);
																					}}
																				>
																					<FaClock />
																					<span>
																						Editar
																					</span>
																				</button>
																				<button
																					className={`d-flex align-items-center border border-none bg-white text-hover-primary gap-2 text-muted`}
																					disabled={
																						isLoading
																					}
																					onClick={() =>
																						handleBannerDelete(
																							banner
																						)
																					}
																				>
																					<FaTrash />
																					<span>
																						Excluir
																					</span>
																				</button>
																			</div>
																		</Popover.Body>
																	</Popover>
																}
																rootClose
															>
																<Button
																	variant="secondary"
																	className="px-3 py-2"
																>
																	<FaEllipsisH />
																</Button>
															</OverlayTrigger>
														</div>
													</div>
												</Col>
											),
											eventDate: `${dayjs(banner.startDate).format(
												"DD/MM/YYYY"
											)} - ${
												banner.endDate
													? dayjs(banner.endDate).format("DD/MM/YYYY")
													: `atemporal`
											}`,
										};
									}
								)}
								timeLineTitle=""
								style={{ backgroundColor: "#ffffff" }}
							/>
						) : (
							<NoRecordsFeedback icon={<FaImage />} message="" />
						)
					) : (
						"carregando..."
					)}
				</Row>
			</Modal.Body>
		</Modal>
	);
}
