import { FaPrint } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { calculateIndicatorPercentage } from "@/utils/calculatePercentage";
import { IndicatorType } from "@/utils/enums/indicatorType";
import { IndicatorDetails } from "@/utils/indicators";
import { Option } from "@/@types/generics/Option";

import { IndicatorChart } from "../IndicatorChart";
import { IndicatorQuantityStatistic } from "../IndicatorsPanel/IndicatorQuantityStatistic";
import { Skeleton } from "../Skeleton";

type Props = {
	setSelectedIndicatorTableType: React.Dispatch<
		React.SetStateAction<Option<IndicatorType | null>>
	>;
	handlePrint?: () => void;
	indicatorDetails: IndicatorDetails;
	isLoading: boolean;
	totalValids: number;
	totalPendingEligible: number;
	totalPendingIneligible: number;
	totalSeries: number;
};

export function InformationIndicatorCard({
	indicatorDetails,
	isLoading,
	totalPendingEligible,
	totalPendingIneligible,
	totalSeries,
	totalValids,
	setSelectedIndicatorTableType,
	handlePrint,
}: Props) {
	return (
		<Row className="p-0 my-5 d-flex justify-content-stretch">
			<Col md={6} lg={6} xl={6} className="my-5">
				<IndicatorChart
					isLoading={isLoading}
					indicatorDetails={indicatorDetails}
					showLink={false}
					className="indicator-details-body-item"
					percentage={calculateIndicatorPercentage(totalValids, totalSeries)}
					chartOptions={{
						chartSeries: [calculateIndicatorPercentage(totalValids, totalSeries)],
						chartLabels: ["TOTAL"],
						chartColors: [indicatorDetails?.getChartColor(totalSeries)],
					}}
				/>
			</Col>

			<Col md={6} lg={6} xl={6} className="my-5">
				<Card className="indicator-details-body-item h-100">
					<Card.Header className="border-0">
						<Card.Title className="d-flex justify-content-between w-100">
							<label className="fw-bolder fs-7 mb-1">QUANTIDADES IDENTIFICADAS</label>
							<span className="mx-3 py-1 fs-3 fw-bold rounded">
								{!isLoading ? (
									<span className="bg-secondary px-5">
										{totalSeries.toLocaleString()}{" "}
									</span>
								) : (
									<Skeleton />
								)}
							</span>
							<Button
								variant="dark"
								className="fs-4 no-print"
								onClick={handlePrint}
								disabled={isLoading}
							>
								<FaPrint />
							</Button>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<div className="d-flex flex-column gap-3">
							<IndicatorQuantityStatistic
								isLoading={isLoading}
								onPressIcon={() => {
									setSelectedIndicatorTableType({
										label: "Validos",
										value: IndicatorType.Validated,
									} as Option<IndicatorType>);
								}}
								theme="primary"
								value={totalValids}
								valueDescription={indicatorDetails.indicatorTypeDescriptions.valid}
							/>
							<IndicatorQuantityStatistic
								isLoading={isLoading}
								onPressIcon={() => {
									setSelectedIndicatorTableType({
										label: "Elegíveis",
										value: IndicatorType.PendingEligible,
									} as Option<IndicatorType>);
								}}
								theme="warning"
								value={totalPendingEligible}
								valueDescription={
									indicatorDetails.indicatorTypeDescriptions.pendingEligible
								}
							/>
							<IndicatorQuantityStatistic
								isLoading={isLoading}
								onPressIcon={() =>
									setSelectedIndicatorTableType({
										label: "Perdidos",
										value: IndicatorType.PendingIneligible,
									} as Option<IndicatorType>)
								}
								theme="danger"
								value={totalPendingIneligible}
								valueDescription={
									indicatorDetails.indicatorTypeDescriptions.pendingIneligible
								}
							/>
						</div>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
}
