import { useState } from "react";

import { useFormik } from "formik";
import Yup from "@/utils/mixins/yup";
import classNamesGroups from "clsx";

import { ModalProps } from "react-bootstrap";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { updatePasswordUserAuth } from "@/services/auth/accountService";

type Props = ModalProps;

export function UpdatePassword({ ...rest }: Props) {
	const [oldPassword, setOldPassword] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const initialValues = {
		codeConfirmation: "",
		password: "",
		confirmPassword: "",
	};

	const codeVerificationSchema = Yup.object().shape({
		password: Yup.string()
			.min(6, "precisa ter mais que 6 caracteres")
			.max(30, "tamanho máximo excedido")
			.required("Senha Obrigatória"),
		confirmPassword: Yup.string()
			.min(6, "Mínimo de 6 caracteres")
			.max(20, "Máximo de 20 caracteres")
			.required("Confirmação de senha é obrigatória")
			.oneOf([Yup.ref("password"), null], "Senhas não conferem"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema: codeVerificationSchema,
		onSubmit: handleSubmit,
	});

	async function handleSubmit() {
		setIsLoading(true);
		const isUpdated = await updatePasswordUserAuth({
			password: oldPassword,
			newPassword: formik.values.password,
			confirmPassword: formik.values.confirmPassword,
		});
		if (isUpdated) {
			toast.success("Senha alterada com sucesso.");
		} else {
			toast.error("Falha ao tentar atualizar a senha, tente novamente mais tarde.");
		}
		setIsLoading(false);
	}

	return (
		<Modal {...rest} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Atualização de senha</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form noValidate id="kt-Code-Verification" onSubmit={formik.handleSubmit}>
					<div className="d-flex flex-column justify-content-center align-items-center">
						<div className="d-flex flex-column gap-2">
							<h1 className="fs-6"> Digite senha Atual </h1>
							<input
								type="password"
								className="form-control form-control-lg px-5 fs-3"
								onChange={(e) => setOldPassword(e.target.value)}
							/>
						</div>
						<div className="d-flex flex-column gap-2 my-3">
							<h1 className="fs-6">Nova Senha:</h1>

							<input
								{...formik.getFieldProps("password")}
								className={classNamesGroups(
									"form-control form-control-lg px-5 fs-3",
									{
										"is-invalid":
											formik.touched.password && formik.errors.password,
									},
									{
										"is-valid":
											formik.touched.password && !formik.errors.password,
									}
								)}
								type="password"
								name="password"
								disabled={formik.errors.codeConfirmation ? true : false}
							/>

							{formik.touched.password && formik.errors.confirmPassword && (
								<div className="fv-plugins-message-container text-danger m-0">
									<span role="alert">{formik.errors.password}</span>
								</div>
							)}
						</div>
						<div className="d-flex flex-column gap-2 my-3">
							<h1 className="fs-6">Confirme a Senha</h1>

							<input
								{...formik.getFieldProps("confirmPassword")}
								className={classNamesGroups(
									"form-control form-control-lg px-5 fs-3",
									{
										"is-invalid":
											formik.touched.confirmPassword &&
											formik.errors.confirmPassword,
									},
									{
										"is-valid":
											formik.touched.confirmPassword &&
											!formik.errors.confirmPassword,
									}
								)}
								name="confirmPassword"
								type="password"
								autoComplete="off"
								disabled={formik.errors.codeConfirmation ? true : false}
							/>
							{formik.touched.confirmPassword && formik.errors.confirmPassword && (
								<p className="text-danger">senhas devem ser iguais!!</p>
							)}
						</div>
					</div>

					<div className="d-flex justify-content-center gap-2 mt-5">
						<Button
							type="submit"
							id="kt_password_reset_submit"
							className="btn btn-lg btn-primary fw-bolder me-4"
							disabled={!formik.isValid || isLoading}
						>
							<span className="indicator-label text-white">Confirmar</span>
							{isLoading && (
								<span className="indicator-progress">
									Por favor, aguarde...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
								</span>
							)}
						</Button>
					</div>
				</form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={rest.onHide}>
					Fechar
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
