import dayjs from "dayjs";

import Table, { TableProps } from "react-bootstrap/Table";

import { SisabPreliminaryReport } from "@/@types/app/sisabPreliminaryReport";

import { ViewCitizenButton } from "@/components/ViewCitizenButton";

type Props = TableProps & {
	reports: SisabPreliminaryReport[];
};

export function UploadedReportsTable({ reports, ...rest }: Props) {
	return (
		<Table
			id="table-imports"
			className="table align-middle table-row-dashed fs-6 dataTable no-footer text-center"
			{...rest}
		>
			<thead>
				<tr className="text-center text-muted fs-7 fw-800">
					<th className="min-w-125px sorting update">ÚLTIMA ATUALIZAÇÃO</th>
					<th className="min-w-125px sorting update">QUADRIMESTRE</th>
					<th className="min-w-125px sorting">TIPO</th>
					<th className="min-w-125px sorting">AÇÕES</th>
				</tr>
			</thead>
			<tbody>
				{reports.map((report, index) => (
					<tr key={index} className="fs-7">
						<td>{dayjs(report.updatedAt).format("DD/MM/YYYY - HH:mm")}</td>
						<th>{report.quarter.split("/").join(" - ")}</th>
						<td>{report.reportType === "Pregnant" ? "Gestantes" : "Crianças"}</td>
						<td>
							<ViewCitizenButton />
						</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}
