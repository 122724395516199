import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaSync } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { getHealthUnits, updateCityHealthUnits } from "@/services/app/healthUnitService";

import { BaseModalProps } from "@/components/BaseModal";
import { LoadingScreen } from "../LoadingScreen";

type Props = BaseModalProps & {
	uf?: string;
	ibgeCode?: string;
};

export function ModalUpdateUnits({ uf, ibgeCode, ...rest }: Props) {
	const [units, setUnits] = useState<HealthUnit[]>([]);
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

	async function handleUpdateCityHealthUnits() {
		if (!uf || !ibgeCode) return;
		setIsLoadingUpdate(true);
		const numberOfHealthUnitsAdded = await updateCityHealthUnits(uf, ibgeCode);
		if (numberOfHealthUnitsAdded && numberOfHealthUnitsAdded > 0) {
			toast.success(`${numberOfHealthUnitsAdded} Unidades adicionadas`, { autoClose: 1500 });
		} else if (numberOfHealthUnitsAdded === 0) {
			toast.info("Unidades estão atualizadas", { autoClose: 1500 });
		} else {
			toast.error("não foi possivel atualizar no momento.");
		}
		await handleGetHealthUnits();
		setIsLoadingUpdate(false);
	}

	async function handleGetHealthUnits() {
		if (!uf || !ibgeCode) return;
		setUnits(await getHealthUnits({ uf, ibgeCode }));
	}

	useEffect(() => {
		handleGetHealthUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ibgeCode]);

	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Atualização de Unidades </Modal.Title>
			</Modal.Header>
			<div className="text-center">
				<Button className="btn-sm" onClick={handleUpdateCityHealthUnits}>
					<FaSync /> Buscar Atualização
				</Button>
			</div>
			<Modal.Body>
				<LoadingScreen loading={isLoadingUpdate} />
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>CNES</th>
							<th>NOME</th>
							<th>BAIRRO</th>
						</tr>
					</thead>
					<tbody>
						{units.map((unit: HealthUnit) => (
							<tr key={unit.cnes}>
								<td>{unit.cnes}</td>
								<td>{unit.name}</td>
								<td>{unit.neighborhood}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Modal.Body>
		</Modal>
	);
}
