import { User } from "@/@types/app/user";
import { get, post } from "../api";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { Account } from "@/@types/app/Account";
import { HealthUnit } from "@/@types/esus/HealthUnit";

type RegisterUserForm = {
	fullName: string;
	email: string;
	identifier: string;
	phone: string;
	occupation: string;
	ibgeCode: string;
	locale: string;
	federalUnitId?: number;
};

export async function checkUserExists(identifier: string) {
	const response = await get("/User/CheckUserExists", { identifier: identifier });
	if (response.status !== undefined) {
		return response.status === 200;
	}
}

export async function registerUser(params: RegisterUserForm) {
	const response = await post("/user/registeruser", params);
	return response.status !== undefined;
}

export async function getUserInfoMasked(identifier: string) {
	const response = await get<User>("/User/GetUserInforMask", { identifier: identifier });
	return response.data || ({} as User);
}

export async function requestAccessToCity(uf: string, ibgeCode?: string, roleId?: string) {
	const response = await post("/User/RequestAccessToCity", {
		uf,
		ibgeCode,
		roleId,
	});
	return response.status === 200;
}

export async function getPermittedFederalUnits() {
	const { data } = await get<FederalUnit[]>(`/User/GetPermittedFederalUnits`);
	return data || [];
}

export async function getPermittedCities(uf: string) {
	const { data } = await get<Account[]>(`/User/GetPermittedCities`, { uf });
	return data || [];
}

export async function getPermittedHealthUnits(uf: string, ibgeCode: string) {
	const { data } = await get<HealthUnit[]>("/User/GetPermittedHealthUnits", { uf, ibgeCode });
	return data || [];
}

export async function updateUser(user: User) {
	const response = await post("/User/Update", user);
	return response.status === 200;
}

export async function changeIsLockedUser(user: User) {
	const response = await post(
		"/User/UserChangeIsLocked",
		{
			identifier: user.identifier,
		},
		{
			params: {
				identifier: user.identifier,
			},
		}
	);
	return response.status === 200;
}
