import dayjs from "dayjs";

import Table from "react-bootstrap/Table";

import { addressConcat } from "@/utils/adreessConcat";
import { HomeVisit } from "@/@types/esus/HomeVisit";
import { Paginated } from "@/@types/generics/paginated";

type Props = {
	homeVisits: Paginated<HomeVisit>;
};

export function verifyConclusionType(conclusionTypeDescription?: string) {
	return conclusionTypeDescription?.toUpperCase() === "VISITA REALIZADA"
		? "text-primary"
		: conclusionTypeDescription?.toUpperCase() === "AUSENTE"
		? "text-warning"
		: conclusionTypeDescription?.toUpperCase() === "VISITA RECUSADA"
		? "text-danger"
		: "text-dark";
}

export function TableForExportPdf({ homeVisits }: Props) {
	return (
		<Table>
			<thead className="fw-bolder bg-secondary">
				<tr>
					<th className="ps-2 text-center">Cidadão</th>
					<th className="text-center">Data</th>
					<th className="text-center">Endereço</th>
					<th className="text-center">Telefone</th>
					<th className="text-center">Whatsapp</th>
				</tr>
			</thead>
			<tbody>
				{homeVisits.data?.map((homeVisit, index) => (
					<tr
						key={`home-visit-${index}`}
						className={verifyConclusionType(
							homeVisit.homeVisitConclusionType?.description
						)}
					>
						<td className="ps-2">{homeVisit.citizen?.fullName || "NÃO INFORMADO"}</td>
						<td className="text-center">
							{dayjs(homeVisit.date).format("DD/MM/YYYY")}
						</td>
						<td className="text-center">
							{addressConcat(
								homeVisit.citizen?.citizenExtra?.street,
								homeVisit.citizen?.citizenExtra?.houseNumber,
								homeVisit.citizen?.citizenExtra?.neighborhood
							)}
						</td>
						<td className="text-center">{homeVisit.citizen?.citizenExtra?.phone}</td>
						<td>{homeVisit.citizen?.citizenExtra?.phone}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}
