import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { toast } from "react-toastify";
import ReactInputMask from "react-input-mask";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import CloseButton from "react-bootstrap/CloseButton";
import Button from "react-bootstrap/Button";

import { identifierReplaceAll } from "@/utils/formatIdentifier";
import { Account } from "@/@types/app/Account";
import { Option } from "@/@types/generics/Option";
import { License } from "@/@types/app/License";
import { getAllLicenses } from "@/services/app/licenseService";
import { requestCityLicense } from "@/services/app/cityLicenseService";

import { SelectComponent } from "@/components/SelectComponent";

type Props = ModalProps & {
	city: Account;
};

export function ModalLicense({ city, ...rest }: Props) {
	const [licenses, setLicenses] = useState<License[]>([]);
	const [identifier, setIdentifier] = useState("");
	const [selectedOption, setSelectedOption] = useState<Option<number | undefined>>();
	let options = [...licenses?.map((license) => ({ value: license?.id, label: license.name }))];

	async function handleAllLicenses() {
		setLicenses(await getAllLicenses());
	}

	async function registerAndActivateLicense() {
		if (
			!city.federalUnit?.abbreviation ||
			!city.ibgeCode ||
			!identifier ||
			!selectedOption?.value
		)
			return;
		const isRegistered = await requestCityLicense({
			uf: city.federalUnit?.abbreviation,
			ibgeCode: city.ibgeCode,
			locale: city.locale,
			identifier: identifier,
			licenseId: selectedOption?.value,
		});
		if (isRegistered) {
			toast.success(`Licença registra com sucesso.`, { autoClose: 3000 });
		} else {
			toast.error(`Erro ao tentar registrar a licença`, { autoClose: 3000 });
		}
	}

	useEffect(() => {
		handleAllLicenses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Modal size="lg" scrollable {...rest}>
			<Modal.Header className="d-block pb-3">
				<div className="col-xl-12 d-flex">
					<CloseButton onClick={rest.onHide} />
				</div>
				<div className="col-xl-12">
					<Modal.Title className="text-center">Nova Licença</Modal.Title>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Row>
						<Col className="fs-6">
							<Form.Group>
								<Form.Label>
									<strong> Cidade: </strong>
									{[city.locale, city.federalUnit?.abbreviation]
										.filter((value) => value && value.trim().length > 0)
										.join(" - ")}
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>
					<Row className="w-100">
						<Col lg={6}>
							<Form.Group>
								<Form.Label>
									<strong> Data de Inicio: </strong>
									{dayjs().format("DD/MM/YYYY")}
								</Form.Label>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group>
								<Form.Label>
									<strong className="me-2">Data de Expiração:</strong>
									{licenses.find(
										(license) => license.id === selectedOption?.value
									)?.period
										? dayjs()
												.add(
													licenses.find(
														(license) =>
															license.id === selectedOption?.value
													)?.period || 0,
													"day"
												)
												.format("DD/MM/YYYY")
										: "-"}
								</Form.Label>
							</Form.Group>
						</Col>
					</Row>
					<hr />

					<Row className="d-flex">
						<Col md={6} lg={6}>
							<Form.Group className="d-flex flex-column">
								<Form.Label>
									<strong>Licenças:</strong>
								</Form.Label>
								<SelectComponent
									className="w-100 fs-5"
									options={options}
									id={rest.id}
									placeholder="Selecione..."
									onChange={(event) => {
										setSelectedOption(event as Option<number>);
									}}
									value={selectedOption}
									noOptionsMessage={() => "Nenhuma Licença encontrada"}
									{...rest}
								/>
							</Form.Group>
						</Col>
						<Col md={6} lg={6}>
							<Form.Group className="d-flex flex-column">
								<Form.Label>
									<strong>CPF Solicitante:</strong>
								</Form.Label>
								<ReactInputMask
									mask="999.999.999-99"
									name="identifier"
									placeholder="000.000.000-00"
									className="form-control form-control-md fs-6 "
									value={identifier}
									onChange={(event) =>
										setIdentifier(identifierReplaceAll(event.target.value))
									}
								/>
							</Form.Group>
						</Col>
					</Row>

					<div className="d-flex justify-content-center mt-5">
						<Button
							title="Salvar Alterações"
							variant="success"
							className="px-10 fs-7"
							onClick={registerAndActivateLicense}
						>
							Salvar
						</Button>
					</div>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
