import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type Props = {
	series: ApexAxisChartSeries | ApexNonAxisChartSeries;
	categories: string[];
	annotations?: ApexAnnotations;
	height?: string | number;
};

export function PregnantDashboardChildBirthAreaChart({
	series,
	categories,
	annotations,
	height,
}: Props) {
	const tripleViralAreaChartOptions: ApexOptions = {
		series: series,
		dataLabels: {
			enabled: true,
			offsetY: -10,
		},
		chart: {
			redrawOnWindowResize: true,
			foreColor: "black",
		},
		stroke: {
			curve: "smooth",
		},
		xaxis: {
			categories: categories,
		},
		annotations: annotations,
		markers: {
			size: 8,
			colors: undefined,
			strokeColors: "#fff",
			strokeOpacity: 0.9,
			strokeDashArray: 0,
			fillOpacity: 1,
			discrete: [],
			shape: "circle",
			radius: 2,
			offsetX: 0,
			offsetY: 0,
			showNullDataPoints: true,
			hover: {
				size: undefined,
				sizeOffset: 3,
			},
		},
		grid: {
			show: true,
			position: "back",
			xaxis: {
				lines: {
					show: true,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
			padding: {
				top: 20,
			},
		},
	};

	return (
		<ReactApexChart
			series={tripleViralAreaChartOptions.series}
			options={tripleViralAreaChartOptions}
			type="area"
			height={height || "100%"}
		/>
	);
}
