import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { AttendanceDashboard, getEpidemicDashboard } from "@/services/esus/attendanceService";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";

import { LoadingScreen } from "@/components/LoadingScreen";
import { AreaChart } from "@/components/Charts/AreaChart";
import { BarChart } from "@/components/Charts/BarChart";
import {
	PopulationByGenderAndAge,
	PopulationByGenderAndAgeGroupBarChart,
} from "../ProfessionalAttendanceDashboard/PopulationByGenderBarChart";
import { EpidemicDashboardFilters } from "./EpidemicDashboardFilters";
import { DiseasesEnum } from "@/utils/enums/DiseasesEnum";

export function EpidemicDashboard() {
	const { uf, ibgeCode } = useCurrentAccount();
	const { setTitle } = useLayout();
	const [isLoading, setIsloading] = useState(false);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));

	const [epidemicType, setEpidemicType] = useState<string>(String(DiseasesEnum.DENGUE));

	const [attendanceDashboard, setAttendanceDashboard] = useState<AttendanceDashboard>(
		{} as AttendanceDashboard
	);

	const periodSeries =
		attendanceDashboard.attendancesAggregatedByPeriodAndProfessionalCategory?.map(
			(aggregatedAttendances) => aggregatedAttendances.total
		) || [];
	const periodCategories =
		attendanceDashboard.attendancesAggregatedByPeriodAndProfessionalCategory?.map((period) =>
			period.key.year && period.key.month
				? `${period.key.year}/${SHORT_MONTHS[period.key.month - 1]}`
				: ""
		) || [];

	const ageGroupCategories =
		attendanceDashboard.attendancesAggregatedByAgeAndGender
			?.map<PopulationByGenderAndAge>((dashboard) => {
				return {
					ageGroup: dashboard.key || "",
					men:
						dashboard.values?.find((ageGroup) => ageGroup.key === "Masculino")?.total ||
						0,
					women:
						dashboard.values?.find((ageGroup) => ageGroup.key === "Feminino")?.total ||
						0,
				};
			})
			.sort((a, b) => b.ageGroup.localeCompare(a.ageGroup)) || [];

	const healthUnitSeries =
		attendanceDashboard.attendancesAggregatedByHealthUnitAndProfessionalCategory?.map(
			(aggregatedAttendances) => aggregatedAttendances.total
		) || [];
	const healthUnitCategories =
		attendanceDashboard.attendancesAggregatedByHealthUnitAndProfessionalCategory
			?.map((aggregatedAttendances) => aggregatedAttendances.key)
			.map((dashboard) => (dashboard.cnes ? `${dashboard.cnes} - ${dashboard.name}` : "")) ||
		[];

	const healthTeamSeries =
		attendanceDashboard.attendancesAggregatedByHealthTeamAndProfessionalCategory?.map(
			(aggregatedAttendances) => aggregatedAttendances.total
		) || [];
	const healthTeamCategories =
		attendanceDashboard.attendancesAggregatedByHealthTeamAndProfessionalCategory
			?.map((aggregatedAttendances) => aggregatedAttendances.key)
			.map((dashboard) => (dashboard.ine ? `${dashboard.ine} - ${dashboard.name}` : "")) ||
		[];

	async function fetchEpidemicDashboard() {
		return await getEpidemicDashboard({
			uf: uf,
			ibgeCode: ibgeCode,
			startDate: startDate,
			endDate: endDate,
			epidemicType: epidemicType,
		});
	}

	async function fetchDashboard() {
		setIsloading(true);
		setAttendanceDashboard(await fetchEpidemicDashboard());
		setIsloading(false);
	}

	useEffect(() => {
		setTitle("Dados epidemiológicos");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<EpidemicDashboardFilters
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				handleFilter={fetchDashboard}
				setEpidemicType={setEpidemicType}
			/>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos mensais
						</Card.Header>
						<Card.Body>
							<AreaChart
								series={[{ data: periodSeries, name: "Total" }]}
								categories={periodCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body>
							<PopulationByGenderAndAgeGroupBarChart
								populationByGenderAndAges={ageGroupCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por unidade
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthUnitSeries, name: "Total" }]}
								categories={healthUnitCategories}
								categoryTextWidth={400}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por equipe
						</Card.Header>
						<Card.Body>
							<BarChart
								series={[{ data: healthTeamSeries, name: "Total" }]}
								categories={healthTeamCategories}
								categoryTextWidth={400}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
