import { Cid } from "@/@types/esus/Cid";
import { get } from "../api";

type CidsParams = {
	uf: string;
	ibgeCode: string;
	search: string;
};

export async function getCids({ uf, ibgeCode, ...cidsParams }: CidsParams) {
	const response = await get<Cid[]>(`/uf/${uf}/city/${ibgeCode}/Cid/Get`, cidsParams);
	return response.data || [];
}
