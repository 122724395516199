import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import { FaList, FaPen, FaPlusCircle } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

import { useLayout } from "@/../_metronic/layout/core";
import { TrackList } from "@/@types/app/TrackList";
import { getAllTrackLists } from "@/services/app/trackListService";

import { ModalTrackListEdit } from "./ModalTrackListEdit";

export function TrackListManagement() {
	const { setTitle } = useLayout();
	const navigate = useNavigate();
	const [selectedTrackList, setSelectedTrackList] = useState<TrackList>({} as TrackList);
	const [trackLists, setTrackLists] = useState<TrackList[]>([]);

	async function fetchTrackLists() {
		setTrackLists(await getAllTrackLists());
	}

	const [showModalTrackListEdit, setShowModalTrackListEdit] = useState(false);
	const handleCloseModalTrackListEdit = () => setShowModalTrackListEdit(false);
	const handleShowModalTrackListEdit = () => setShowModalTrackListEdit(true);

	useEffect(() => {
		fetchTrackLists();
		setTitle("GERENCIAMENTO DE PLAYLIST");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="bg-white p-4">
			<ModalTrackListEdit
				selectedTrackList={selectedTrackList}
				fetchTrackLists={fetchTrackLists}
				show={showModalTrackListEdit}
				onHide={handleCloseModalTrackListEdit}
			/>
			<div className="d-flex justify-content-between my-2">
				<h1 className="text-primary fs-4">PlayLists</h1>
				<Button onClick={() => navigate("/configuracoes/track-lists/novo")}>
					<FaPlusCircle className="me-2" />
					Novo
				</Button>
			</div>
			<Table
				striped
				responsive
				className="table align-middle fs-6 dataTable no-footer bg-white rounded"
			>
				<thead>
					<tr className="fw-bold text-center text-black">
						<th>Capa</th>
						<th>Titulo</th>
						<th>Descrição</th>
						<th>Ações</th>
					</tr>
				</thead>
				<tbody>
					{trackLists.map((trackList, index) => (
						<tr className="text-muted text-center p-2" key={`trackList-${index}`}>
							<td>
								<img
									height={100}
									loading="lazy"
									src={trackList.urlThumbnail}
									alt={trackList.title}
								/>
							</td>
							<td>{trackList.title}</td>
							<td>{trackList.description}</td>
							<td>
								<div className="d-flex justify-content-center gap-2">
									<Button
										onClick={() => {
											handleShowModalTrackListEdit();
											setSelectedTrackList(trackList);
										}}
										title="Editar"
										variant="warning"
									>
										<FaPen />
									</Button>
									<Button
										title="Gerenciar Videos"
										variant="secondary"
										onClick={() =>
											navigate(`/configuracoes/track-lists/${trackList.id}`)
										}
									>
										<FaList />
									</Button>
								</div>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</section>
	);
}
