import { HomeVisitConclusionType } from "@/@types/esus/HomeVisitConclusionType";
import { get } from "../api";

type GetHomeVisitConclusionTypesParams = {
	uf: string;
	ibgeCode: string;
};

export async function getHomeVisitConclusionTypes({
	uf,
	ibgeCode,
}: GetHomeVisitConclusionTypesParams) {
	const response = await get<HomeVisitConclusionType[]>(
		`/uf/${uf}/city/${ibgeCode}/homeVisitConclusionType/GetAll`
	);
	return response.data || [];
}
