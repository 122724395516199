import Table from "react-bootstrap/Table";

import { Procedure } from "@/@types/esus/Procedure";
import { ResumeGrouped } from "@/services/esus/attendanceService";

type Props = {
	aggregatedAttendanceProcedures: ResumeGrouped<Procedure, object>[];
};

export function AttendanceProcedureRateByProcedureTable({ aggregatedAttendanceProcedures }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Procedimento</th>
					<th className="text-start">Descrição</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{aggregatedAttendanceProcedures
					.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
					.map((aggregatedAttendanceProcedure, index) => (
						<tr key={`attendance-procedure-rate-${index}`}>
							<td>{aggregatedAttendanceProcedure.key.procedureCode}</td>
							<td className="text-start">{aggregatedAttendanceProcedure.key.name}</td>
							<td className="fw-bolder">{aggregatedAttendanceProcedure.total}</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
