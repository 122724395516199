import { HealthUnit } from "@/@types/esus/HealthUnit";
import { get, post } from "../api";
import { Paginated } from "@/@types/generics/paginated";

type GetUnitsParams = {
	uf: string;
	ibgeCode: string;
	cnes?: string;
};

type GetUnitsPaginatedParams = {
	uf: string;
	ibgeCode: string;
	cnes: string;
	pageNumber: number;
	name?: string;
};

export async function getHealthUnits(params: GetUnitsParams) {
	const { data } = await get<HealthUnit[]>(`/healthunit/gethealthunits`, params);
	return data || [];
}

export async function getUnitsPaginated(params: GetUnitsPaginatedParams) {
	const { data } = await get<Paginated<HealthUnit>>(
		`/healthunit/gethealthunitspaginated`,
		params
	);
	return data || ({} as Paginated<HealthUnit>);
}

export async function updateCityHealthUnits(uf: string, ibgeCode: string) {
	const response = await post<number>(`/HealthUnit/updateHealthUnits`, { uf, ibgeCode });
	if (response.status === 200) {
		const numberOfHealthUnitsAdded = response.data || 0;
		return numberOfHealthUnitsAdded;
	}
}
