import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";

import { fetchIndicators, IndicatorsData } from "@/services/esus/indicatorService";
import { Option } from "@/@types/generics/Option";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { Account } from "@/@types/app/Account";
import { useLayout } from "@/../_metronic/layout/core";

import { CityAdminReportContent } from "./CityAdminReportContent";
import { CityAdminReportFilters } from "./CityAdminReportFilters";
import { LoadingScreen } from "@/components/LoadingScreen";

export function CityAdminReport() {
	const { setTitle } = useLayout();
	const [federalUnit, setFederalUnit] = useState<FederalUnit>({} as FederalUnit);
	const [city, setCity] = useState<Account>({} as Account);
	const resourceRef = useRef<HTMLDivElement | null>(null);
	const [indicators, setIndicators] = useState<IndicatorsData>({} as IndicatorsData);
	const [isLoading, setIsLoading] = useState(false);

	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const selectedQuarter = {
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	} as Option<string>;

	const printPage = useReactToPrint({
		content: () => resourceRef.current,
	});

	async function handleIndicators() {
		setIsLoading(true);
		setIndicators(
			await fetchIndicators(federalUnit.abbreviation, city.ibgeCode, selectedQuarter.value)
		);
		setIsLoading(false);
	}

	useEffect(() => {
		setCity({} as Account);
	}, [federalUnit]);

	useEffect(() => {
		setTitle("Relatório Previne Brasil");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen loading={isLoading} />
			<Card className="mb-3">
				<Card.Body>
					<CityAdminReportFilters
						selectedFederalUnit={federalUnit}
						selectedCity={city}
						setSelectedFederalUnit={setFederalUnit}
						setSelectedCity={setCity}
						handleFilter={handleIndicators}
						printPage={printPage}
					/>
				</Card.Body>
			</Card>
			<CityAdminReportContent
				city={city}
				quarter={selectedQuarter.value}
				indicators={indicators}
				resourceRef={resourceRef}
			/>
		</div>
	);
}
