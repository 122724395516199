import { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core";
import { MasterInit } from "../_metronic/layout/MasterInit";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./styles/global.scss";

const App = () => {
	const [showSplash, setShowSplash] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => setShowSplash(false), 1800);

		return () => clearTimeout(timer);
	}, []);
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			{showSplash ? (
				<LayoutSplashScreen />
			) : (
				<LayoutProvider>
					<ToastContainer autoClose={5000}/>
					<Outlet />
					<MasterInit />
				</LayoutProvider>
			)}
		</Suspense>
	);
};

export { App };
