import { get, post } from "../api";
import { TrackOrder } from "@/@types/app/TrackOrder";

export async function registerTrackOrder(trackListId: number, trackFileId: number) {
	const response = await post("/trackorder/register", {
		trackListId,
		trackFileId,
	});
	return response.status === 200;
}

export async function updateOrderTrackOrder(
	trackListId: number,
	trackOrderId: number,
	newSequenceOrder: number
) {
	const response = await post("/trackorder/updateorder", {
		trackListId,
		trackOrderId,
		newSequenceOrder,
	});
	return response.status === 200;
}

export async function removeTrackOrder(trackOrderId: number) {
	const response = await post("/trackorder/delete", { trackOrderId });
	return response.status === 200;
}

export async function getAllTrackOrders() {
	const { data } = await get<TrackOrder[]>("/trackorder/All");
	return data || [];
}

export async function getTrackOrdersByTrackListId(trackListId: number) {
	const { data } = await get<TrackOrder[]>("/trackorder/GetByTrackListId", { trackListId });
	return data || [];
}

export async function getTrackOrdersNotFinished() {
	const { data } = await get<TrackOrder[]>(`/trackOrder/getTrackOrdersNotFinished`);
	return data || [];
}
