import { Account } from "@/@types/app/Account";
import { get } from "../api";
import { Period } from "@/@types/generics/Period";
import { Immunobiological } from "@/@types/esus/Immunobiological";
import { ImmunobiologicalDosage } from "@/@types/esus/ImmunobiologicalDosage";

type ChildAbandonmentTripleViralByPeriod = {
	period: Period;
	totalD1: number;
	totalD2: number;
	totalDU: number;
	totalChildren: number;
	totalWithoutVaccination: number;
	totalWithVaccinationPending: number;
	totalWithVaccinationFinished: number;
};

export type ChildAbandonmentTripleViralByCityAndPeriod = {
	city: Account;
	resumesByPeriod: ChildAbandonmentTripleViralByPeriod[];
	totalChildren: number;
	totalWithoutVaccination: number;
	totalWithVaccinationPending: number;
	totalWithVaccinationFinished: number;
};

export type TripleViralVaccinationsByCityAndPeriod = {
	city: Account;
	vaccinationsResumes: VaccinationsByPeriodAndVaccine[];
};

export type VaccinationsByPeriodAndVaccine = {
	period: Period;
	vaccinationResumes: VaccinationResume[];
};

export type ResumeAbandonmentTripleViral = {
	city: Account;
	totalChildren: number;
	totalWithoutVaccination: number;
	totalWithVaccinationPending: number;
	totalWithVaccinationFinished: number;
	totalD1: number;
	totalD2: number;
	totalDU: number;
};

type VaccinationResume = {
	immunobiological: Immunobiological;
	immunobiologicalDosage: ImmunobiologicalDosage;
	total: number;
};

type ChildAbandonmentTripleViralByPeriodParams = {
	startDate: string;
	endDate: string;
	uf: string;
};

type ChildTripleViralVaccinationsByCityAndPeriodResumeParams = {
	startDate: string;
	endDate: string;
	uf: string;
};

type ResumeAbandonmentTripleViralParams = {
	startDate: string;
	endDate: string;
	uf: string;
};

export async function handleChildAbandonmentTripleViralByPeriod({
	uf,
	...params
}: ChildAbandonmentTripleViralByPeriodParams) {
	const { data } = await get<ChildAbandonmentTripleViralByCityAndPeriod[]>(
		`/uf/${uf}/vaccination/ResumeAbandonmentTripleViralByCitiesAndPeriods`,
		params
	);
	return data || [];
}

export async function handleChildTripleViralVaccinationsByCityAndPeriodResume({
	uf,
	...params
}: ChildTripleViralVaccinationsByCityAndPeriodResumeParams) {
	const { data } = await get<TripleViralVaccinationsByCityAndPeriod[]>(
		`/uf/${uf}/vaccination/ChildTripleViralVaccinationsByPeriodAndVaccineResume`,
		params
	);
	return data || [];
}

export async function handleResumeAbandonmentTripleViral({
	uf,
	...params
}: ResumeAbandonmentTripleViralParams) {
	const { data } = await get<ResumeAbandonmentTripleViral[]>(
		`/uf/${uf}/vaccination/ResumeAbandonmentTripleViral`,
		params
	);
	return data || [];
}
