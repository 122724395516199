import { Props as SelectProps } from "react-select";

import { FederalUnit } from "@/@types/app/FederalUnit";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	federalUnits: FederalUnit[];
};

export function FederalUnitsSelect({ federalUnits, ...rest }: Props) {
	const options = [
		...federalUnits?.map((federalUnit) => ({
			value: federalUnit.abbreviation,
			label: federalUnit.abbreviation || "Todos os estados",
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label title="Lista de Estados Disponiveis" className="form-label">
				UF:
			</label>
			<SelectComponent
				options={options}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma UF encontrada"}
				{...rest}
			/>
		</div>
	);
}
