import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import {
	getPregnancyWithChildBirthResumeCity,
	PregnancyWithChildBirthResumeCity,
} from "@/services/federalUnit/attendanceService";
import { useLayout } from "@/../_metronic/layout/core";

import { PregnancyDashboardFilters } from "./PregnancyDashboardFilters";
import {
	PregnancyDashboardIndicators,
	PregnancyIndicatorsResumeCity,
} from "./PregnancyDashboardIndicators";
import { PregnancyDashboardPrenatal, PregnancyResumeCity } from "./PregnancyDashboardPrenatal";
import { ChildBirthResumeCity, PregnancyDashboardChildBirth } from "./PregnancyDashboardChildBirth";
import { LoadingScreen } from "@/components/LoadingScreen";

export function PregnancyDashboard() {
	const { setTitle } = useLayout();
	const { uf } = useCurrentAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [pregnancyWithChildBirthResumeCities, setPregnancyWithChildBirthResumeCities] = useState<
		PregnancyWithChildBirthResumeCity[]
	>([]);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
	const [selectedIbgeCode, setSelectedIbgeCode] = useState("");

	const pregnancyWithChildBirthResumeCitiesFiltered = pregnancyWithChildBirthResumeCities.filter(
		(resume) => resume.city.ibgeCode.includes(selectedIbgeCode)
	);

	async function getPregnancyResume() {
		return await getPregnancyWithChildBirthResumeCity({
			uf: uf,
			startDate: startDate,
			endDate: endDate,
		});
	}

	async function fetch() {
		setIsLoading(true);
		setSelectedIbgeCode("");
		setPregnancyWithChildBirthResumeCities([]);
		setPregnancyWithChildBirthResumeCities(await getPregnancyResume());
		setIsLoading(false);
	}

	useEffect(() => {
		fetch();
		setTitle("Dashboard de Gestantes");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				indicator1Resumes: pregnancyResumeCity.pregnancyResume?.indicator1Resumes,
				indicator2Resumes: pregnancyResumeCity.pregnancyResume?.indicator2Resumes,
				indicator3Resumes: pregnancyResumeCity.pregnancyResume?.indicator3Resumes,
			} as PregnancyIndicatorsResumeCity;
		});

	const pregnancyResumeCities: PregnancyResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				totalActivePregnants: pregnancyResumeCity.pregnancyResume?.totalActivePregnants,
				pregnancyByAgeResumes: pregnancyResumeCity.pregnancyResume?.pregnancyByAgeResumes,
				pregnancyByRacialColorResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByRacialColorResumes,
				pregnancyByPlannedPregnancyStatusResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByPlannedPregnancyStatusResumes,
				pregnancyByHighRiskStatusResumes:
					pregnancyResumeCity.pregnancyResume?.pregnancyByHighRiskStatusResumes,
			} as PregnancyResumeCity;
		});

	const childBirthResumeCities: ChildBirthResumeCity[] =
		pregnancyWithChildBirthResumeCitiesFiltered.map((pregnancyResumeCity) => {
			return {
				city: pregnancyResumeCity.city,
				childBirthByPeriodAndAverageGestationalAgeResumes:
					pregnancyResumeCity.pregnancyResume
						?.childBirthByPeriodAndAverageGestationalAgeResumes,
				childBirthByPeriodAndTypeResumes:
					pregnancyResumeCity.pregnancyResume?.childBirthByPeriodAndTypeResumes,
				childBirthTypeResumes: pregnancyResumeCity.pregnancyResume?.childBirthTypeResumes,
			} as ChildBirthResumeCity;
		});

	return (
		<div>
			<LoadingScreen loading={isLoading} />
			<Card className="my-2 mx-4">
				<Card.Body>
					<PregnancyDashboardFilters
						cities={pregnancyWithChildBirthResumeCities
							.map((resume) => resume.city)
							.sort((a, b) => a.locale.localeCompare(b.locale))}
						setSelectedIbgeCode={setSelectedIbgeCode}
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
						handleFilter={fetch}
					/>
				</Card.Body>
			</Card>
			<PregnancyDashboardIndicators
				pregnancyIndicatorsResumeCities={pregnancyIndicatorsResumeCities}
			/>
			<PregnancyDashboardPrenatal pregnancyResumeCities={pregnancyResumeCities} />
			<PregnancyDashboardChildBirth childBirthResumeCities={childBirthResumeCities} />
		</div>
	);
}
