import Table from "react-bootstrap/Table";

import { StateMeasure, getIndicatorColor } from "@/utils/indicators";
import { ResumeAbandonmentTripleViral } from "@/services/federalUnit/vaccinationService";

import { TableSkeletons } from "@/components/TableSkeletons";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";

type Props = {
	isLoading: boolean;
	resumeAbandonmentTripleViral?: ResumeAbandonmentTripleViral[];
};

export function calculateAbandonmentRate(resumeAbandonmentRate: ResumeAbandonmentTripleViral) {
	return (
		((resumeAbandonmentRate.totalWithVaccinationPending || 0) * 100) /
		(resumeAbandonmentRate.totalChildren - resumeAbandonmentRate.totalWithoutVaccination || 1)
	);
}

export function TableRankingTripleViral({ resumeAbandonmentTripleViral, isLoading }: Props) {
	const rankedResumeAbandonmentTripleViral = resumeAbandonmentTripleViral?.sort(
		(resumeA, resumeB) => calculateAbandonmentRate(resumeB) - calculateAbandonmentRate(resumeA)
	);

	const dropOutRateMeasures: StateMeasure[] = [
		{
			description: "< 5%",
			minValue: 0,
			maxValue: 5,
			classColor: "success",
		},
		{
			description: "≥ 5% e < 10%",
			minValue: 5,
			maxValue: 10,
			classColor: "warning",
		},
		{
			description: "≥ 10%",
			minValue: 10,
			maxValue: 100,
			classColor: "danger",
		},
	];

	return (
		<Table responsive className="bg-white" bordered hover>
			<thead className="fw-bolder text-muted">
				<tr>
					<th className="text-center">
						<label className="me-2">Ranking</label>
						<CardOverlayTrigger message="Ranking pela taxa de abandono" />
					</th>
					<th className="text-center">UF</th>
					<th className="text-center">Municipio</th>
					<th className="text-center">Identificados</th>
					<th className="text-center">D1</th>
					<th className="text-center">D2</th>
					<th className="text-center">DU</th>
					<th
						title="Crianças que tomaram D1 e não completaram o esquema vacinal"
						className="text-center"
					>
						Taxa de abandono{" "}
						<CardOverlayTrigger
							children={
								<LegendData
									ClassNamesColors={dropOutRateMeasures.map(
										(dropOutRate) => `text-${dropOutRate.classColor}`
									)}
									titles={dropOutRateMeasures.map(
										(dropOutRateMeasure) => dropOutRateMeasure.description
									)}
								/>
							}
						/>
					</th>
					<th className="text-center">Não vacinados</th>
				</tr>
			</thead>
			<tbody className="overflow-auto">
				{!isLoading ? (
					rankedResumeAbandonmentTripleViral?.map((item, index) => (
						<tr key={index}>
							<td
								title="posição"
								className=" border border-none border border-none text-center fw-bold"
							>{`${index + 1}º`}</td>
							<td title="UF" className="border border-none text-center fw-bold">
								{item.city.federalUnit?.abbreviation}
							</td>
							<td title="cidade" className="border border-none text-center fw-bold">
								{item.city?.locale}
							</td>
							<td
								title="identificados"
								className="border border-none text-center fw-bold"
							>
								{item.totalChildren?.toLocaleString()}
							</td>
							<td
								title="D1"
								className={`border border-none text-center text-nowrap fw-bold`}
							>
								{item.totalD1?.toLocaleString()}
							</td>
							<td
								title="D2"
								className={`border border-none text-center text-nowrap fw-bold`}
							>
								{item.totalD2?.toLocaleString()}
							</td>
							<td
								title="DU"
								className={`border border-none text-center text-nowrap fw-bold`}
							>
								{item.totalDU?.toLocaleString()}
							</td>
							<td
								title="Taxa de abandono"
								className={`border border-none text-center text-nowrap fw-bold text-${getIndicatorColor(
									dropOutRateMeasures,
									calculateAbandonmentRate(item)
								)}`}
							>
								{Math.round(calculateAbandonmentRate(item) * 100) / 100}%
							</td>
							<td
								title="não vacinados"
								className="border border-none text-center fw-bold"
							>
								{item.totalWithoutVaccination?.toLocaleString()}
							</td>
						</tr>
					))
				) : (
					<TableSkeletons numberOfCells={9} numberOfRows={10} />
				)}
			</tbody>
		</Table>
	);
}
