import Card from "react-bootstrap/Card";
import ProgressBar from "react-bootstrap/ProgressBar";

type Props = {
	schedulesInMonthTotal: number;
	scheduledTotal: number;
	percentageScheduled: number;
	scheduleAttendedTotal: number;
	percentageAttended: number;
	scheduleCanceledTotal: number;
	percentageCanceled: number;
	scheduleCitizenPresentTotal: number;
	percentageCitizenPresent: number;
	scheduleDidNoWaitTotal: number;
	percentageDidNoWait: number;
	scheduleDidNotShowUpTotal: number;
	percentageDidNotShowUpTotal: number;
	scheduleExcludedTotal: number;
	percentageExcluded: number;
};

export function SchedulingByProgressBarStatusComponent({
	percentageScheduled,
	scheduledTotal,
	schedulesInMonthTotal,
	percentageAttended,
	scheduleAttendedTotal,
	scheduleCanceledTotal,
	percentageCanceled,
	scheduleCitizenPresentTotal,
	percentageCitizenPresent,
	scheduleDidNoWaitTotal,
	percentageDidNoWait,
	scheduleDidNotShowUpTotal,
	percentageDidNotShowUpTotal,
	scheduleExcludedTotal,
	percentageExcluded,
}: Props) {
	return (
		<Card className="h-100">
			<Card.Header className="m-0 p-3">
				<Card.Title>
					Status
				</Card.Title>
				<Card.Title className="fs-8">{schedulesInMonthTotal.toLocaleString()}</Card.Title>
			</Card.Header>
			<Card.Body className="d-flex flex-column justify-content-between p-3 h-100">
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							{" "}
							Agendados
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-muted fs-8">
							{scheduledTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="muted"
						label={`${percentageScheduled}%`}
						now={percentageScheduled}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							{" "}
							Atendidos{" "}
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-primary fs-8">
							{scheduleAttendedTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="primary"
						label={`${percentageAttended}%`}
						now={percentageAttended}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							{" "}
							Cancelados{" "}
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-danger fs-8">
							{scheduleCanceledTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="danger"
						label={`${percentageCanceled}%`}
						now={percentageCanceled}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							Presentes{" "}
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-success fs-8">
							{scheduleCitizenPresentTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="success"
						label={`${percentageCitizenPresent}%`}
						now={percentageCitizenPresent}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							{" "}
							Não aguardaram{" "}
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-warning fs-8">
							{scheduleDidNoWaitTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="warning"
						label={`${percentageDidNoWait}%`}
						now={percentageDidNoWait}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex align-items-end m-0 fs-8">
							{" "}
							Não compareceram{" "}
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-black fs-8">
							{scheduleDidNotShowUpTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="black"
						label={`${percentageDidNotShowUpTotal}%`}
						now={percentageDidNotShowUpTotal}
					/>
				</Card>
				<Card>
					<Card.Header className="m-0 p-0 h-25" style={{ minHeight: "2rem" }}>
						<Card.Title className="d-flex fs-8 m-0 align-items-end">
							Excluidos
						</Card.Title>
						<Card.Title className="d-flex align-items-end text-info fs-8 m-0">
							{scheduleExcludedTotal.toLocaleString()}
						</Card.Title>
					</Card.Header>
					<ProgressBar
						className="fw-bolder"
						variant="info"
						label={`${percentageExcluded}%`}
						now={percentageExcluded}
					/>
				</Card>
			</Card.Body>
		</Card>
	);
}
