import { FaInfoCircle } from "react-icons/fa";
import { Card } from "react-bootstrap";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

import "./style.css";

type Props = {
	title: string;
	content: string;
	metaDataHeader?: string;
	metaDataDescription?: string;
	backgroundClassName?: string;
	titleClassName?: string;
	contentClassName?: string;
	backgroundStyle?: React.CSSProperties;
	backgroundImageUrl?: string;
};

export function ProfessionalAttendanceResumeCard({
	title,
	content,
	metaDataHeader,
	metaDataDescription,
	backgroundClassName,
	titleClassName,
	contentClassName,
	backgroundStyle,
	backgroundImageUrl,
}: Props) {
	const defaultBackgroundImageUrl = "/media/images/card-icons/notes.svg";

	return (
		<Card className="w-100 h-100 rounded" style={{ minHeight: 100, ...backgroundStyle }}>
			<Card.Body className={`w-100 h-100 rounded p-0 ${backgroundClassName || ""}`}>
				<div
					className={`d-flex flex-column justify-content-evenly h-100 rounded`}
					style={backgroundStyle}
				>
					<div className="d-flex justify-content-center align-items-end gap-4">
						<div></div>
						<label className={`fw-bolder mt-3 ${titleClassName}`}>{title}</label>
						<div>
							{metaDataHeader && metaDataDescription && (
								<CardOverlayTrigger
									header={metaDataHeader}
									message={metaDataDescription}
									icon={<FaInfoCircle className={titleClassName} />}
								/>
							)}
						</div>
					</div>

					<strong className="d-flex justify-content-center align-items-center">
						<div className={`fs-1 ${contentClassName}`} >
							{content}
						</div>
					</strong>
				</div>
				<div className="resume-card-icon rounded">
					<img
						src={backgroundImageUrl || defaultBackgroundImageUrl}
						className="text-muted fw-bolder resume-card-icon-image"
						alt="Ícone"
					/>
				</div>
			</Card.Body>
		</Card>
	);
}
