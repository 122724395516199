import { useEffect, useState } from "react";

import { FaChartBar, FaCloudDownloadAlt, FaPhoneAlt } from "react-icons/fa";
import { MdComputer } from "react-icons/md";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { BannerTypeEnum } from "@/utils/enums/BannerTypeEnum";
import { ReleaseNote } from "@/@types/app/ReleaseNote";
import { getPendingReleaseNote } from "@/services/app/releaseNoteService";

import { BannerDisplay } from "@/components/BannerDisplay";

import "./styles.scss";
import { ModalReleaseNoteView } from "../ReleaseNotesManagement/ModalReleaseNoteView";

export function Home() {
	const { setTitle } = useLayout();
	const { ibgeCode } = useCurrentAccount();

	const [releaseNote, setReleaseNote] = useState<ReleaseNote>({} as ReleaseNote);

	async function fetchPendingReleaseNote() {
		const releaseNote = await getPendingReleaseNote();
		setReleaseNote(releaseNote);
		if (releaseNote.id) {
			setShowModalReleaseNoteView(true);
		}
	}

	const [showModalReleaseNoteView, setShowModalReleaseNoteView] = useState(false);

	useEffect(() => {
		setTitle("");
		if (ibgeCode) {
			fetchPendingReleaseNote();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div>
			<ModalReleaseNoteView
				titlemodal="Notas de Atualização"
				show={showModalReleaseNoteView}
				onHide={() => setShowModalReleaseNoteView(false)}
				releaseNote={releaseNote}
			/>
			<section>
				<BannerDisplay bannerTypeEnum={BannerTypeEnum.HOME} />
			</section>
			<Row className="d-flex justify-content-around">
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<FaCloudDownloadAlt className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Centralizador em nuvem</Card.Title>
							<Card.Text>
								Backups automatizados de forma segura unificando informações de
								registros
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<MdComputer className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Prontuário Eletrônico</Card.Title>
							<Card.Text>
								Leitura dos registros de atendimento dos profissionais de saúde da
								atenção básica
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={4} lg={3} className="m-3">
					<Card className="h-100">
						<FaChartBar className="h-25 w-25 px-2 text-primary" />
						<Card.Body>
							<Card.Title>Dashboard</Card.Title>
							<Card.Text>
								Monitoramento dos indicadores para tomada de decisões mais precisas
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<a rel="noreferrer" href="https://wa.me/5585992487956" target="_blank">
				<div title="Contato suporte" className="btn-whatsapp pulsaDelay">
					<FaPhoneAlt />
				</div>
			</a>
		</div>
	);
}
