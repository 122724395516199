import { Props as SelectProps } from "react-select";

import { Account } from "@/@types/app/Account";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	accounts: Account[];
};

export function CitiesSelect({ accounts, ...rest }: Props) {
	const options = [
		...accounts?.map((account) => ({
			value: account.ibgeCode,
			label: account.locale || "Todas as cidades",
		})),
	];

	return (
		<div className="d-flex flex-column">
			<label title="Lista de cidades Disponiveis" className="form-label">
				Cidades:
			</label>
			<SelectComponent
				options={options}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma Cidade encontrada"}
				{...rest}
			/>
		</div>
	);
}
