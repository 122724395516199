import { useNavigate } from "react-router-dom";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { HealthTeam } from "@/@types/esus/HealthTeam";
import { Option } from "@/@types/generics/Option";

import { QuartersSelect } from "@/components/QuartersSelect";
import { UnitsSelect } from "@/components/UnitsSelect";
import { HealthTeamsSelect } from "@/components/HealthTeamsSelect";
import { IndicatorSelect } from "@/components/IndicatorsSelect";

type Props = {
	units: HealthUnit[];
	healthTeams: HealthTeam[];
	selectedIndicator?: Option<number>;
	setSelectedIndicator: (selectedIndicator?: Option<number>) => void;
	selectedQuarter: Option<string>;
	setSelectedQuarter: (selectedQuarter: Option<string>) => void;
	selectedUnit: Option<string>;
	setSelectedUnit: (selectedUnit: Option<string>) => void;
	selectedHealthTeam: Option<string>;
	setSelectedHealthTeam: (selectedHealthTeam: Option<string>) => void;
};

export function FiltersIndicatosDetails({
	selectedIndicator,
	setSelectedIndicator,
	selectedQuarter,
	setSelectedQuarter,
	units,
	selectedUnit,
	setSelectedUnit,
	healthTeams,
	selectedHealthTeam,
	setSelectedHealthTeam,
}: Props) {
	const navigate = useNavigate();
	const { cnes } = useCurrentAccount();
	const hasSelectedUnit = selectedUnit.value !== "0";
	const hasSelectedHealthTeam = selectedHealthTeam.value !== "0";

	return (
		<Row className="d-flex justify-content-between w-100">
			<Col xs={12} sm={12} md={4} lg={2} xl={2}>
				<IndicatorSelect
					id="indicator-select"
					value={selectedIndicator}
					onChange={(newValue) => {
						setSelectedIndicator(newValue as Option<number>);
						navigate(
							`/componente-qualidade/indicador/${
								(newValue as Option<number>).value
							}/detalhes`
						);
					}}
				/>
			</Col>
			<Col xs={12} sm={12} md={4} lg={2} xl={2}>
				<QuartersSelect
					id="quarters-select"
					value={selectedQuarter}
					onChange={(newValue) => setSelectedQuarter(newValue as Option<string>)}
				/>
			</Col>
			{!cnes && (
				<Col xs={12} sm={12} md={4} lg={4} xl={4}>
					<UnitsSelect
						id="units-select"
						units={units}
						value={selectedUnit}
						onChange={(newValue) => setSelectedUnit(newValue as Option<string>)}
						isDisabled={!hasSelectedUnit && hasSelectedHealthTeam}
					/>
				</Col>
			)}
			<Col xs={12} sm={12} md={4} lg={4} xl={4}>
				<HealthTeamsSelect
					id="health-teams-select"
					healthTeams={healthTeams}
					value={selectedHealthTeam}
					onChange={(newValue) => setSelectedHealthTeam(newValue as Option<string>)}
				/>
			</Col>
		</Row>
	);
}
