import dayjs from "dayjs";

import { FaInfoCircle } from "react-icons/fa";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Row from "react-bootstrap/Row";

import { IndicatorData, IndicatorsData } from "@/services/esus/indicatorService";
import { getChartColor } from "@/utils/getChartColor";
import { calculateIndicatorDataPercentage } from "@/utils/calculatePercentage";
import { indicatorsDetail } from "@/utils/indicators";
import { calculateIndicatorIsf, calculateISF } from "@/utils/calculateIsf";

import { ReportHeader } from "@/components/Generics/ReportHeader";
import { PopoverInformation } from "@/components/PopoverInformation";
import { BarChart } from "@/components/Charts/BarChart";
import { IndicatorCard } from "@/pages/esus/PrevineBrasilDashboard/IndicatorCard";
import { RadialChart } from "@/components/Charts/RadialChart";
import { CardWithBullet } from "@/components/CardWithBullet";
import { Option } from "@/@types/generics/Option";

type Props = {
	city: string;
	quarter: string;
	indicators: IndicatorsData;
	selectedUnit?: Option<string>;
	selectedHealthTeam?: Option<string>;
};

export function PrevineBrasilComponent({
	city,
	quarter,
	indicators,
	selectedHealthTeam,
	selectedUnit,
}: Props) {
	const {
		indicatorOne,
		indicatorTwo,
		indicatorThree,
		indicatorFour,
		indicatorFive,
		indicatorSix,
		indicatorSeven,
	} = indicators;

	const { indicator1, indicator2, indicator3, indicator4, indicator5, indicator6, indicator7 } =
		indicatorsDetail;

	function calculateIndicatorData(indicatorsData: IndicatorData[], key: keyof IndicatorData) {
		return indicatorsData
			?.map((indicator) => indicator[key] || 0)
			?.reduce((acc, current) => acc + current, 0);
	}

	const calculateValids = calculateIndicatorData(
		Object.values(indicators)?.flatMap((item) => item) as IndicatorData[],
		"Valid"
	);

	const calculateIdentified = calculateIndicatorData(
		Object.values(indicators)?.flatMap((item) => item) as IndicatorData[],
		"Identified"
	);

	const calculatePending = calculateIdentified - calculateValids;

	const indicatorOneIdentified = indicatorOne?.at(0)?.Identified || 0;
	const indicatorTwoIdentified = indicatorTwo?.at(0)?.Identified || 0;
	const indicatorThreeIdentified = indicatorThree?.at(0)?.Identified || 0;
	const indicatorFourIdentified = indicatorFour?.at(0)?.Identified || 0;
	const indicatorFiveIdentified = indicatorFive?.at(0)?.Identified || 0;
	const indicatorSixIdentified = indicatorSix?.at(0)?.Identified || 0;
	const indicatorSevenIdentified = indicatorSeven?.at(0)?.Identified || 0;

	const indicatorOneValid = indicatorOne?.at(0)?.Valid || 0;
	const indicatorTwoValid = indicatorTwo?.at(0)?.Valid || 0;
	const indicatorThreeValid = indicatorThree?.at(0)?.Valid || 0;
	const indicatorFourValid = indicatorFour?.at(0)?.Valid || 0;
	const indicatorFiveValid = indicatorFive?.at(0)?.Valid || 0;
	const indicatorSixValid = indicatorSix?.at(0)?.Valid || 0;
	const indicatorSevenValid = indicatorSeven?.at(0)?.Valid || 0;

	const indicatorOnePending = indicatorOneIdentified - indicatorOneValid;
	const indicatorTwoPending = indicatorTwoIdentified - indicatorTwoValid;
	const indicatorThreePending = indicatorThreeIdentified - indicatorThreeValid;
	const indicatorFourPending = indicatorFourIdentified - indicatorFourValid;
	const indicatorFivePending = indicatorFiveIdentified - indicatorFiveValid;
	const indicatorSixPending = indicatorSixIdentified - indicatorSixValid;
	const indicatorSevenPending = indicatorSevenIdentified - indicatorSevenValid;

	const indicatorOnePercentage = calculateIndicatorDataPercentage(indicatorOne);
	const indicatorTwoPercentage = calculateIndicatorDataPercentage(indicatorTwo);
	const indicatorThreePercentage = calculateIndicatorDataPercentage(indicatorThree);
	const indicatorFourPercentage = calculateIndicatorDataPercentage(indicatorFour);
	const indicatorFivePercentage = calculateIndicatorDataPercentage(indicatorFive);
	const indicatorSixPercentage = calculateIndicatorDataPercentage(indicatorSix);
	const indicatorSevenPercentage = calculateIndicatorDataPercentage(indicatorSeven);

	const indicatorOneChartColor = getChartColor(
		indicatorOnePercentage,
		indicatorsDetail,
		"indicator1"
	);

	const indicatorTwoChartColor = getChartColor(
		indicatorTwoPercentage,
		indicatorsDetail,
		"indicator2"
	);

	const indicatorThreeChartColor = getChartColor(
		indicatorThreePercentage,
		indicatorsDetail,
		"indicator3"
	);

	const indicatorFourChartColor = getChartColor(
		indicatorFourPercentage,
		indicatorsDetail,
		"indicator4"
	);

	const indicatorFiveChartColor = getChartColor(
		indicatorFivePercentage,
		indicatorsDetail,
		"indicator5"
	);

	const indicatorSixChartColor = getChartColor(
		indicatorSixPercentage,
		indicatorsDetail,
		"indicator6"
	);

	const indicatorSevenChartColor = getChartColor(
		indicatorSevenPercentage,
		indicatorsDetail,
		"indicator7"
	);

	return (
		<section>
			<Row>
				<div className="print-container" id="report-dashboard">
					<ReportHeader city={city} />
					<div className="my-2 mx-10 text-end">
						<p>Data: {dayjs().format("DD/MM/YYYY HH:mm")}</p>
					</div>
					<div className="mt-2 mb-2 text-center">
						<h3>Relatório Previne Brasil - {quarter}</h3>
					</div>
					<div>
						<p>
							{selectedUnit?.value && `${selectedUnit.value} - ${selectedUnit.label}`}
						</p>
						<p>
							{selectedHealthTeam?.value &&
								`${selectedHealthTeam.value} - ${selectedHealthTeam.label}`}
						</p>
					</div>
				</div>
			</Row>
			<Row className="px-2 text-center">
				<Col sm={6} md={3} lg={3} xl={3} className="px-2 mb-2 align-items-center">
					<CardWithBullet
						title="IDENTIFICADOS"
						value={calculateIdentified?.toLocaleString()}
						backgroundColor="bg-muted rounded"
						textColor="text-white"
					>
						<OverlayTrigger
							trigger={["hover", "click"]}
							placement="bottom"
							overlay={PopoverInformation(
								"CARD IDENTIFICADOS",
								"A quantidade de cidadãos na base local do PEC, constitui o numerador (Nº de cadastros identificados), os quais serão avaliados no quadrante filtrado, deste modo esse número reflete o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada."
							)}
							rootClose
						>
							<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
								<FaInfoCircle className="text-white" />
							</span>
						</OverlayTrigger>
					</CardWithBullet>
				</Col>
				<Col sm={6} md={3} lg={3} xl={3} className="mb-2 px-2">
					<CardWithBullet
						title="VÁLIDOS"
						value={calculateValids?.toLocaleString()}
						backgroundColor="bg-primary rounded"
						textColor="text-white"
					>
						<OverlayTrigger
							trigger={["hover", "click"]}
							placement="bottom"
							overlay={PopoverInformation(
								"CARD VÁLIDOS",
								"O Número de cidadãos que atenderam às condições exigidas pelos sete indicadores do Previne Brasil, também constitui o numerador ( Nº de pessoas cadastradas no perfil de cada indicador com a condição atendida), deste modo esse número reflete o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada."
							)}
							rootClose
						>
							<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
								<FaInfoCircle className="text-white" />
							</span>
						</OverlayTrigger>
					</CardWithBullet>
				</Col>
				<Col sm={6} md={3} lg={3} xl={3} className="mb-2 px-2">
					<CardWithBullet
						title="PENDENTES"
						value={calculatePending.toLocaleString()}
						backgroundColor="bg-warning rounded"
						textColor="text-white"
					>
						<OverlayTrigger
							trigger={["hover", "click"]}
							placement="bottom"
							overlay={PopoverInformation(
								"CARD PENDENTES",
								"O Número de cidadãos que apresentam pendências para atingir as condições exigidas pelos sete indicadores do programa Previne Brasil (Nº de pessoas cadastradas no perfil de cada indicador com a condição NÃO atendida), deste modo esse número reflete o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada."
							)}
							rootClose
						>
							<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
								<FaInfoCircle className="text-white" />
							</span>
						</OverlayTrigger>
					</CardWithBullet>
				</Col>

				<Col sm={6} md={3} lg={3} xl={3} className="mb-2 px-2">
					<CardWithBullet
						title="ISF"
						value={calculateISF(indicators)}
						backgroundColor="bg-info rounded"
						textColor="text-white"
					>
						<OverlayTrigger
							trigger={["hover", "click"]}
							placement="bottom"
							overlay={PopoverInformation(
								"CARD ISF",
								"O Índice Sintético Final (ISF)  corresponde ao cálculo do desempenho do conjunto dos sete indicadores, a nota final é resultado da pontuação e a ponderação de cada indicador com condição atendida, deste modo esse número reflete o momento atual, considerando que a análise é baseada nos dados da última avaliação da base local realizada."
							)}
							rootClose
						>
							<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
								<FaInfoCircle className="text-white" />
							</span>
						</OverlayTrigger>
					</CardWithBullet>
				</Col>
			</Row>

			<Row>
				<Col>
					<Card>
						<Card.Header>
							<Card.Title>
								<label className="me-2">ÍNDICE SINTÉTICO FINAL - ISF</label>
								<OverlayTrigger
									trigger={["hover", "click"]}
									placement="bottom"
									overlay={PopoverInformation(
										"Composição da nota do ISF",
										"indicadores 3, 5 e 6 tem o resultado multiplicado por 2 , por esse motivo podem chegar a um resultado maior na nota"
									)}
									rootClose
								>
									<span className="d-flex flex-center gap-1 svg-icon-light ms-n1 user-select-none">
										<FaInfoCircle />
									</span>
								</OverlayTrigger>
							</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<BarChart
								series={[
									{
										data: [
											calculateIndicatorIsf(
												1,
												indicatorOnePercentage,
												"indicatorOneTargetValue"
											),
											calculateIndicatorIsf(
												2,
												indicatorTwoPercentage,
												"indicatorTwoTargetValue"
											),
											calculateIndicatorIsf(
												3,
												indicatorThreePercentage,
												"indicatorThreeTargetValue"
											),
											calculateIndicatorIsf(
												4,
												indicatorFourPercentage,
												"indicatorFourTargetValue"
											),
											calculateIndicatorIsf(
												5,
												indicatorFivePercentage,
												"indicatorFiveTargetValue"
											),
											calculateIndicatorIsf(
												6,
												indicatorSixPercentage,
												"indicatorSixTargetValue"
											),
											calculateIndicatorIsf(
												7,
												indicatorSevenPercentage,
												"indicatorSevenTargetValue"
											),
										],
										name: "Nota",
									},
								]}
								categories={["I1", "I2", "I3", "I4", "I5", "I6", "I7"]}
								colors={["#7239ea"]}
								dataLabelsColors={["#ffffff"]}
								isVertical
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="page-break-before">
				<Col sm={12} md={12} lg={6} xl={6}>
					<Card className="m-2">
						<Card.Header>
							<Card.Title>Válidos</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<BarChart
								categories={["I1", "I2", "I3", "I4", "I5", "I6", "I7"]}
								series={[
									{
										data: [
											indicatorOneValid,
											indicatorTwoValid,
											indicatorThreeValid,
											indicatorFourValid,
											indicatorFiveValid,
											indicatorSixValid,
											indicatorSevenValid,
										],
										name: "Válidos",
									},
								]}
								colors={["#009ef7"]}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6}>
					<Card className="m-2">
						<Card.Header>
							<Card.Title>Pendentes</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<BarChart
								categories={["I1", "I2", "I3", "I4", "I5", "I6", "I7"]}
								series={[
									{
										data: [
											indicatorOnePending,
											indicatorTwoPending,
											indicatorThreePending,
											indicatorFourPending,
											indicatorFivePending,
											indicatorSixPending,
											indicatorSevenPending,
										],
										name: "pending",
									},
								]}
								colors={["#ffc700"]}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row className="page-break-before">
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator1?.title}
						total={indicatorOneIdentified}
						stateMeasures={indicator1?.stateMeasures}
						description={indicator1?.description}
						children={
							<RadialChart
								categories={[indicator1?.title]}
								series={[indicatorOnePercentage]}
								colors={[indicatorOneChartColor]}
								width={300}
							/>
						}
						indicatorNumber={1}
					/>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator2?.title}
						total={indicatorTwoIdentified}
						stateMeasures={indicator2?.stateMeasures}
						description={indicator2?.description}
						children={
							<RadialChart
								categories={[indicator2?.title]}
								series={[indicatorTwoPercentage]}
								colors={[indicatorTwoChartColor]}
								width={300}
							/>
						}
						indicatorNumber={2}
					/>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator3?.title}
						total={indicatorThreeIdentified}
						stateMeasures={indicator3?.stateMeasures}
						description={indicator3?.description}
						children={
							<RadialChart
								categories={[indicator3?.title]}
								series={[indicatorThreePercentage]}
								colors={[indicatorThreeChartColor]}
								width={300}
							/>
						}
						indicatorNumber={3}
					/>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator4?.title}
						total={indicatorFourIdentified}
						stateMeasures={indicator4?.stateMeasures}
						description={indicator4?.description}
						children={
							<RadialChart
								categories={[indicator4?.title]}
								series={[indicatorFourPercentage]}
								colors={[indicatorFourChartColor]}
								width={300}
							/>
						}
						indicatorNumber={4}
					/>
				</Col>
			</Row>
			<Row className="mt-1 page-break-before">
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator5?.title}
						total={indicatorFiveIdentified}
						stateMeasures={indicator5?.stateMeasures}
						description={indicator5?.description}
						children={
							<RadialChart
								categories={[indicator5?.title]}
								series={[indicatorFivePercentage]}
								colors={[indicatorFiveChartColor]}
								width={300}
							/>
						}
						indicatorNumber={5}
					/>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator6?.title}
						total={indicatorSixIdentified}
						stateMeasures={indicator6?.stateMeasures}
						description={indicator6?.description}
						children={
							<RadialChart
								categories={[indicator6?.title]}
								series={[indicatorSixPercentage]}
								colors={[indicatorSixChartColor]}
								width={300}
							/>
						}
						indicatorNumber={6}
					/>
				</Col>
				<Col sm={12} md={6} lg={3} xl={3} className="my-1">
					<IndicatorCard
						title={indicator7?.title}
						total={indicatorSevenIdentified}
						stateMeasures={indicator7?.stateMeasures}
						description={indicator7?.description}
						children={
							<RadialChart
								categories={[indicator7?.title]}
								series={[indicatorSevenPercentage]}
								colors={[indicatorSevenChartColor]}
								width={300}
							/>
						}
						indicatorNumber={7}
					/>
				</Col>
			</Row>
		</section>
	);
}
