import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";

import { Paginated } from "@/@types/generics/paginated";
import {
	getCitizensDuplicated,
	ManagedCitizen,
} from "@/services/esus/registrationManagementService";

import { PaginationLinks } from "@/components/PaginationLinks";
import { BaseModalProps } from "@/components/BaseModal";
import { TableSkeletons } from "@/components/TableSkeletons";

import "./styles.scss";

type Props = BaseModalProps & {
	selectedCitizen: ManagedCitizen;
	citizenDuplicated: ManagedCitizen;
	uf: string;
	ibgeCode: string;
	selectedUnit: string;
	selectedProfessional: string;
	isDuplicatedFilter: boolean;
	hasSelectedUnit: boolean;
	hasSelectedProfessional: boolean;
};

export function ModalCitizensDuplicates({
	citizenDuplicated,
	selectedCitizen,
	uf,
	ibgeCode,
	selectedUnit,
	selectedProfessional,
	isDuplicatedFilter,
	hasSelectedProfessional,
	hasSelectedUnit,
	...rest
}: Props) {
	const [citizensDuplicated, setCitizensDuplicated] = useState<Paginated<ManagedCitizen>>(
		{} as Paginated<ManagedCitizen>
	);
	const [isLoading, setIsLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(1);

	async function fetchCitizenDuplicated() {
		if (!isDuplicatedFilter) return;
		setIsLoading(true);
		setCitizensDuplicated(
			await getCitizensDuplicated({
				uf,
				ibgeCode,
				fullName: selectedCitizen.name,
				motherName: selectedCitizen.motherName,
				birthdate: selectedCitizen.birthDate,
				gender: selectedCitizen.gender,
				pageNumber: pageNumber,
				pageSize: 10,
				cnes: selectedUnit,
				ine: selectedProfessional,
			})
		);
		setIsLoading(false);
	}

	function handleChangePageNumber(newPage: number) {
		setPageNumber(newPage);
	}

	useEffect(() => {
		fetchCitizenDuplicated();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCitizen, pageNumber]);

	return (
		<Modal size="xl" scrollable {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>{citizenDuplicated?.name}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Table id="table-duplicates" striped hover>
					<thead className="fw-bold">
						<tr>
							<th>CIDADÃO</th>
							<th>CPF</th>
							<th>CNS</th>
							<th>NOME DO PAI</th>
							<th>NOME DA MÃE</th>
						</tr>
					</thead>
					<tbody>
						{!isLoading ? (
							citizensDuplicated.data?.map((citizen, index) => {
								return (
									<tr key={index}>
										<td
											data-content={`${citizen.name} - ${
												citizen.birthDate
													? dayjs(citizen.birthDate).format("DD/MM/YYYY")
													: "DATA DE NASCIMENTO NÃO INFORMADA"
											}`}
										>
											{citizen.name}
											{citizen.birthDate && (
												<span className="d-block fs-9">
													Nasceu em{" "}
													{dayjs(citizen.birthDate).format("DD/MM/YYYY")}
												</span>
											)}
										</td>
										<td>{citizen.cpf || "NÃO INFORMADO"}</td>
										<td>{citizen.cns || "NÃO INFORMADO"}</td>
										<td>{citizen.fatherName || "NÃO INFORMADO"}</td>
										<td>{citizen.motherName || "NÃO INFORMADO"}</td>
									</tr>
								);
							})
						) : (
							<TableSkeletons numberOfCells={5} />
						)}
					</tbody>
				</Table>
				{citizensDuplicated?.totalRecords > 0 && (
					<PaginationLinks
						itemsPerPage={citizensDuplicated.pageSize}
						totalPages={citizensDuplicated.totalRecords}
						changeSelectedPage={handleChangePageNumber}
						pageNumber={pageNumber || 0}
					/>
				)}
			</Modal.Body>
		</Modal>
	);
}
