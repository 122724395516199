import { RolePermission } from "@/@types/app/RolePermission";
import { get, post } from "../api";

export async function getProfilePermissionsByProfileId(roleId: number) {
	const { data } = await get<RolePermission[]>(`/profilepermission/GetByProfileId`, {
		roleId,
	});
	return data || [];
}

export async function registerProfilePermission(roleId: number, permissionId: number) {
	const response = await post(`/profilepermission/RegisterProfilePermission`, {
		roleId,
		permissionId,
	});
	return response.status === 200;
}

export async function deleteProfilePermission(roleId: number, permissionId: number) {
	const response = await post(`/profilepermission/DeleteProfilePermission`, {
		roleId,
		permissionId,
	});
	return response.status === 200;
}
