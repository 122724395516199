import { useEffect, useState } from "react";

import dayjs from "dayjs";

import { BsSearch } from "react-icons/bs";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import {
	getResumeByHealthTeams,
	getResumeByHealthUnits,
	getResumeByProfessionals,
	getResumeCitizensUpdatedByPeriod,
	getResumeNewCitizensByPeriod,
	getTerritorialCitizenResume,
	ResumeByPeriod,
	ResumeByProfessional,
	ResumeHealthTeam,
	ResumeHealthUnit,
	TerritorialCitizenResume,
} from "@/services/esus/territorialCitizenService";
import { fetchWithCache } from "@/utils/fetchWithCache";
import { getExpirationDateMidnight } from "@/utils/getExpirationDateMidnight";
import { useUnits } from "@/hooks/useUnits";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { Option } from "@/@types/generics/Option";

import { GlobalInformationVisits } from "./Globalnformation";
import { LoadingScreen } from "@/components/LoadingScreen";
import { AreaChart } from "@/components/Charts/AreaChart";
import { BarChart } from "@/components/Charts/BarChart";
import { UnitsSelect } from "@/components/UnitsSelect";

type Props = {
	uf: string;
	ibgeCode: string;
	cnes: string;
};

export function CitizenAssistance({ uf, ibgeCode, cnes }: Props) {
	const [isLoading, setIsLoading] = useState(false);
	const { units } = useUnits();
	const [selectedHealthUnit, setSelectedHealthUnit] = useState<Option<string>>(
		{} as Option<string>
	);
	const [keyMenuRegistration, setKeyMenuRegistration] = useState<string | null>("new");
	const [keyMenuCitizen, setKeyMenuCitizen] = useState<string | null>("healthTeams");
	const [territorialCitizenResume, setTerritorialCitizenResume] =
		useState<TerritorialCitizenResume>({} as TerritorialCitizenResume);
	const [resumeHealthUnits, setResumeHealthUnits] = useState<ResumeHealthUnit[]>([]);
	const [resumeHealthTeams, setResumeHealthTeams] = useState<ResumeHealthTeam[]>([]);
	const [resumeProfessionals, setResumeProfessionals] = useState<ResumeByProfessional[]>([]);
	const [resumeNewRegistrationsCitizens, setResumeNewRegistrationsCitizens] = useState<
		ResumeByPeriod[]
	>([]);
	const [resumeUpdatedRegistrationsCitizens, setResumeUpdatedRegistrationsCitizens] = useState<
		ResumeByPeriod[]
	>([]);
	const startDate = dayjs().subtract(12, "month").format("YYYY-MM-DD");
	const endDate = dayjs().format("YYYY-MM-DD");
	const cacheExpirationDate = getExpirationDateMidnight();

	const unitCurrentDefault = units?.find((unit) => unit.cnes === cnes);

	async function handleTerritorialCitizenResume() {
		const cacheKey = `[territorialCitizenResume][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;
		return await fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getTerritorialCitizenResume({
				uf,
				ibgeCode,
				cnes: selectedHealthUnit.value || cnes,
			})
		);
	}

	async function handleResumeByHealthTeams() {
		const cacheKey = `[territorialHealthTeamsResume][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;

		return await fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getResumeByHealthTeams({
				uf,
				ibgeCode,
				cnes: selectedHealthUnit.value || cnes,
			})
		);
	}

	async function handleResumeNewCitizensByPeriod() {
		const cacheKey = `[territorialNewCitizensResume][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;
		return await fetchWithCache(cacheKey, cacheExpirationDate, () =>
			getResumeNewCitizensByPeriod({
				uf,
				ibgeCode,
				cnes: selectedHealthUnit.value || cnes,
				startDate: startDate,
				endDate: endDate,
			})
		);
	}

	async function updateRegistersCitizens() {
		const cacheKey = `[reterritorialzationCitizenUpdatedRegisters][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}][${startDate}][${endDate}]`;
		setResumeUpdatedRegistrationsCitizens(
			await fetchWithCache(cacheKey, cacheExpirationDate, () =>
				getResumeCitizensUpdatedByPeriod({
					uf,
					ibgeCode,
					cnes: selectedHealthUnit.value || cnes,
					startDate: startDate,
					endDate: endDate,
				})
			)
		);
	}
	async function newRegistersCitizens() {
		const cacheKey = `[reterritorialzationCitizenNewRegisters][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}][${startDate}][${endDate}]`;
		setResumeNewRegistrationsCitizens(
			await fetchWithCache(cacheKey, cacheExpirationDate, () =>
				getResumeNewCitizensByPeriod({
					uf,
					ibgeCode,
					cnes: selectedHealthUnit.value || cnes,
					startDate: startDate,
					endDate: endDate,
				})
			)
		);
	}

	async function resumeByProfessionals() {
		const cacheKey = `[reterritorialzationByProfessionals][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;
		setResumeProfessionals(
			await fetchWithCache(cacheKey, cacheExpirationDate, () =>
				getResumeByProfessionals({
					uf,
					ibgeCode,
					cnes: selectedHealthUnit.value || cnes,
				})
			)
		);
	}

	async function resumeByHealthTeams() {
		const cacheKey = `[reterritorialzationByHealthTeams][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;
		setResumeHealthTeams(
			await fetchWithCache(cacheKey, cacheExpirationDate, () =>
				getResumeByHealthTeams({
					uf,
					ibgeCode,
					cnes: selectedHealthUnit.value || cnes,
				})
			)
		);
	}

	async function resumeByHealthUnits() {
		const cacheKey = `[reterritorialzationByHealthUnits][${uf}][${ibgeCode}][${
			selectedHealthUnit.value || cnes
		}]`;
		setResumeHealthUnits(
			await fetchWithCache(cacheKey, cacheExpirationDate, () =>
				getResumeByHealthUnits({
					uf,
					ibgeCode,
					cnes: selectedHealthUnit.value || cnes,
				})
			)
		);
	}

	async function fetch() {
		setIsLoading(true);
		const [
			resumeByTerritorialCitizenDb,
			resumeNewRegistersCitizensByPeriodDb,
			resumeHealthTeamsDb,
		] = await Promise.all([
			handleTerritorialCitizenResume(),
			handleResumeNewCitizensByPeriod(),
			handleResumeByHealthTeams(),
		]);

		setTerritorialCitizenResume(resumeByTerritorialCitizenDb);
		setResumeNewRegistrationsCitizens(resumeNewRegistersCitizensByPeriodDb);
		setResumeHealthTeams(resumeHealthTeamsDb);
		setIsLoading(false);
	}

	useEffect(() => {
		fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{!cnes ? (
				<Row className="d-flex justify-content-between mx-3 p-2 bg-white rounded">
					<Col sm={6} md={6} lg={6} xl={6}>
						<UnitsSelect
							units={units}
							onChange={(event) => setSelectedHealthUnit(event as Option<string>)}
						/>
					</Col>
					<Col
						sm={2}
						md={2}
						lg={2}
						xl={2}
						className="d-flex align-items-center justify-content-center my-2"
					>
						<Button
							variant="light"
							className="align-items-end w-100"
							onClick={() => {
								setKeyMenuRegistration("new");
								setKeyMenuCitizen("healthTeams");
								fetch();
							}}
						>
							<BsSearch className="fs-1 me-2" />
						</Button>
					</Col>
				</Row>
			) : (
				<h1 className="mx-3 p-2 fs-3 bg-white">{unitCurrentDefault?.name}</h1>
			)}
			<LoadingScreen loading={isLoading} />
			<section className="rounded">
				<GlobalInformationVisits
					totalCitizens={territorialCitizenResume.totalCitizens}
					totalAcs={territorialCitizenResume.totalAcs}
					totalFamily={territorialCitizenResume.totalFamilies}
					totalOtherBuildings={territorialCitizenResume.totalOtherBuildings}
					totalRegistraionFa={territorialCitizenResume.totalFA}
					totalRegistrationMc={territorialCitizenResume.totalMC}
					totalResidences={territorialCitizenResume.totalResidences}
				/>
			</section>
			<section className="mt-5">
				<Row className="m-2">
					<Card>
						<Card.Header>
							<Card.Title>
								Cadastros nos últimos 12 meses
							</Card.Title>
							<Nav
								fill
								variant="tabs"
								className="d-flex align-items-center"
								activeKey={keyMenuRegistration!}
								onSelect={(event) => setKeyMenuRegistration(event)}
							>
								<Nav.Item
									onClick={() => {
										newRegistersCitizens();
									}}
								>
									<Nav.Link
										eventKey="new"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Novos
									</Nav.Link>
								</Nav.Item>
								<Nav.Item
									onClick={() => {
										updateRegistersCitizens();
									}}
								>
									<Nav.Link
										eventKey="updated"
										className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
									>
										Atualizados
									</Nav.Link>
								</Nav.Item>
							</Nav>
						</Card.Header>
						<Card.Body>
							{keyMenuRegistration === "new" ? (
								<AreaChart
									series={[
										{
											name: "Novos cadastros",
											data: resumeNewRegistrationsCitizens?.map(
												(resumeNewRegistration) =>
													resumeNewRegistration?.total
											),
										},
									]}
									categories={resumeNewRegistrationsCitizens?.map(
										(resumeNewRegistration) =>
											`${resumeNewRegistration.period?.year}/${
												SHORT_MONTHS[resumeNewRegistration.period.month - 1]
											}`
									)}
								/>
							) : keyMenuRegistration === "updated" ? (
								<AreaChart
									series={[
										{
											name: "Atualização de cadastros",
											data: resumeUpdatedRegistrationsCitizens?.map(
												(resumeUpdatedRegistration) =>
													resumeUpdatedRegistration?.total
											),
										},
									]}
									categories={resumeUpdatedRegistrationsCitizens?.map(
										(resumeUpdatedRegistration) =>
											`${resumeUpdatedRegistration.period?.year}/${
												SHORT_MONTHS[
													resumeUpdatedRegistration.period?.month - 1
												]
											}`
									)}
								/>
							) : null}
						</Card.Body>
					</Card>
				</Row>
				<Row className="p-2">
					<Col sm={12} lg={12}>
						<Card>
							<Card.Header className="d-flex fs-2 fw-bolder justify-content-between align-items-center">
								<Card.Title>Quantitativo de cidadãos vinculados</Card.Title>
								<Nav
									fill
									variant="tabs"
									className="d-flex align-items-center"
									activeKey={keyMenuCitizen!}
									onSelect={(event) => setKeyMenuCitizen(event)}
								>
									<Nav.Item
										onClick={() => {
											resumeByHealthTeams();
										}}
									>
										<Nav.Link
											eventKey="healthTeams"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Equipes
										</Nav.Link>
									</Nav.Item>
									<Nav.Item
										onClick={() => {
											resumeByHealthUnits();
										}}
									>
										<Nav.Link
											eventKey="healthUnits"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Unidades
										</Nav.Link>
									</Nav.Item>
									<Nav.Item
										onClick={() => {
											resumeByProfessionals();
										}}
									>
										<Nav.Link
											eventKey="professionals"
											className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
										>
											Profissionais
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body style={{ maxHeight: "50rem", overflowY: "auto" }}>
								{keyMenuCitizen === "healthTeams" ? (
									<BarChart
										series={[
											{
												data:
													resumeHealthTeams
														?.map(
															(resumeHealthTeam) =>
																resumeHealthTeam?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeHealthTeams
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeHealthTeam) =>
														resumeHealthTeam?.healthTeam?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : keyMenuCitizen === "healthUnits" ? (
									<BarChart
										series={[
											{
												data:
													resumeHealthUnits
														?.map(
															(resumeHealthUnit) =>
																resumeHealthUnit?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeHealthUnits
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeHealthUnit) =>
														resumeHealthUnit?.healthUnit?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : keyMenuCitizen === "professionals" ? (
									<BarChart
										series={[
											{
												data:
													resumeProfessionals
														?.map(
															(resumeByProfessional) =>
																resumeByProfessional?.total
														)
														?.sort((a, b) => b - a) || [],
												name: "Total",
											},
										]}
										categories={
											resumeProfessionals
												?.sort((a, b) => b.total - a.total)
												?.map(
													(resumeByProfessional) =>
														resumeByProfessional?.professional?.name
												) || []
										}
										categoryTextWidth={160}
									/>
								) : null}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</section>
		</div>
	);
}
