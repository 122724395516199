import { get } from "../api";
import { Paginated } from "@/@types/generics/paginated";
import { Permission } from "@/@types/app/Permission";

export async function getAllPermissions(roleId: number, pageNumber: number, pageSize?: number) {
	const { data } = await get<Paginated<Permission>>(`/permission/GetAll`, {
		roleId: roleId,
		pageNumber: pageNumber,
		pageSize: pageSize || 10,
	});
	return data || ({} as Paginated<Permission>);
}
