import { useEffect, useState } from "react";
import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import { FaPlus } from "react-icons/fa";

import { ReleaseNote } from "@/@types/app/ReleaseNote";
import { getAllReleaseNotes } from "@/services/app/releaseNoteService";

import { ModalReleaseNoteEdit } from "./ModalReleaseNoteEdit";
import { Restrict } from "@/components/Restrict";
import { useLayout } from "@/../_metronic/layout/core";

export function ReleaseNotesManagement() {
	const { setTitle } = useLayout();
	const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[]>([]);

	const [showModalReleaseNoteEdit, setShowModalReleaseNoteEdit] = useState(false);
	const handleShowModalReleaseNoteEdit = () => {
		setShowModalReleaseNoteEdit(true);
	};
	const handleCloseModalReleaseNoteEdit = () => {
		setShowModalReleaseNoteEdit(false);
	};

	async function fetchReleaseNotes() {
		setReleaseNotes(await getAllReleaseNotes());
	}

	useEffect(() => {
		fetchReleaseNotes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTitle("HISTÓRICO DE VERSÕES");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<ModalReleaseNoteEdit
				titlemodal="Notas de Atualização"
				show={showModalReleaseNoteEdit}
				onHide={handleCloseModalReleaseNoteEdit}
			/>
			<Card className={"border border-3 secondary"}>
				<Card.Body className="">
					<Restrict>
						<div className="d-flex justify-content-end my-4">
							<Button variant={"primary"} onClick={handleShowModalReleaseNoteEdit}>
								<FaPlus className="me-2" />
								Novo
							</Button>
						</div>
					</Restrict>
					{releaseNotes?.length > 0 && (
						<Accordion>
							{releaseNotes.map((releaseNote, index) => (
								<Accordion.Item key={`release-note-${index}`} eventKey={`${index}`}>
									<Accordion.Header>
										<div className="w-100 pe-8 d-flex justify-content-between align-items-center">
											<span className="fs-4 fw-bold">{`Versão ${releaseNote.version}`}</span>
											<span className="ms-2 text-end">
												{dayjs(releaseNote.createdAt).format(
													"DD/MM/YYYY HH:mm"
												)}
											</span>
										</div>
									</Accordion.Header>
									<Accordion.Body>
										<div
											dangerouslySetInnerHTML={{
												__html: releaseNote.description,
											}}
										></div>
									</Accordion.Body>
								</Accordion.Item>
							))}
						</Accordion>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}
