import { Props as SelectProps } from "react-select";

import { HomeVisitConclusionType } from "@/@types/esus/HomeVisitConclusionType";
import { SelectComponent } from "../SelectComponent";

type Props = SelectProps & {
	conclusionTypes: HomeVisitConclusionType[];
};

export function SelectConclusionType({ conclusionTypes, ...rest }: Props) {
	const defaultOption = { label: "Todos" };
	const options = [
		...conclusionTypes?.map((conclusionType) => ({
			value: conclusionType.id,
			label: conclusionType.description,
		})),
	];
	return (
		<div className="d-flex flex-column">
			<label
				title="Lista de status de conclusão de visitas"
				htmlFor={rest.id}
				className="form-label"
			>
				Tipo de Conclusão:
			</label>
			<SelectComponent
				options={[defaultOption, ...options]}
				id={rest.id}
				placeholder="Selecione..."
				noOptionsMessage={() => "Nenhuma conclusão encontrada"}
				{...rest}
			/>
		</div>
	);
}
