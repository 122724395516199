import { useNavigate, useParams } from "react-router";

import dayjs from "dayjs";

import { TbPlayerPlayFilled } from "react-icons/tb";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";

import { TrackFile } from "@/@types/app/TrackFile";

import "../../styles.scss";

type Props = {
	videos: TrackFile[];
	isLoading: boolean;
};

export function Playlist({ isLoading, videos }: Props) {
	const navigate = useNavigate();
	const { trackListId } = useParams();

	return (
		<ListGroup
			className="list-group"
			style={{
				height: "90vh",
				overflowY: "auto",
			}}
		>
			{videos.map((video, index) => (
				<ListGroup.Item
					key={`item-video-${index}`}
					className="d-flex zoom py-1 px-0 ps-1 cursor-pointer "
					onClick={() =>
						navigate(
							`/ambiente-de-apoio/acesso-a-plataforma/tracks/${trackListId}/video/${
								index + 1
							}`
						)
					}
				>
					<Card>
						<Card.Body className="title-item p-0">
							<img
								width="100%"
								className="rounded"
								alt="indicador"
								src={video.urlThumbnail}
							/>
							<div className="d-flex justify-content-between w-100  ps-2 items">
								<button className="border boder-white rounded-circle p-2 d-flex justify-content-center bg-transparent">
									<TbPlayerPlayFilled className="fs-1 text-white" />
								</button>
								<p className="px-1 font-roboto fs-8 text-white">
									{dayjs("0").add(video.duration, "seconds").format("HH:mm:ss")}
								</p>
							</div>
						</Card.Body>
					</Card>
				</ListGroup.Item>
			))}
		</ListGroup>
	);
}
