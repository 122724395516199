import { useEffect, useState, useRef } from "react";

import statesAndCities from "@/utils/static/states-and-cities.json";
import { City, StatesAndCities } from "@/@types/statesAndCities";
import { Account } from "@/@types/app/Account";
import { FederalUnit } from "@/@types/app/FederalUnit";

export type Classification = {
	city: Account;
	color: string;
	cityDetails?: React.ReactNode;
};

type Props = {
	showTooltip: (evt: MouseEvent) => void;
	hideTooltip: (evt: MouseEvent) => void;
	setSelectedCity: (city?: City) => void;
	children?: React.ReactNode;
	federalUnit: FederalUnit;
	classifications?: Classification[];
	width: string;
	height: string;
};

export function StateGeographicalNetwork({
	hideTooltip,
	showTooltip,
	setSelectedCity,
	children,
	federalUnit,
	width,
	height,
	classifications,
}: Props) {
	const [svgData, setSvgData] = useState<string>();
	const svgContainerRef = useRef<HTMLDivElement>(null);
	const { cities } = statesAndCities as StatesAndCities;

	const citiesWithFederalUnit = cities.filter(
		(city) => city.microrregiao.mesorregiao.UF.sigla === federalUnit.abbreviation
	);

	useEffect(() => {
		const fetchSvg = async () => {
			try {
				const response = await fetch(
					`https://servicodados.ibge.gov.br/api/v3/malhas/estados/${federalUnit.abbreviation}?intrarregiao=municipio`
				);
				const svgText = await response.text();
				setSvgData(svgText);
			} catch (error) {
				console.error("Erro ao obter o SVG:", error);
			}
		};

		fetchSvg();
	}, [federalUnit]);

	useEffect(() => {
		if (svgContainerRef.current && svgData) {
			const svgElement = svgContainerRef.current.querySelector("svg");
			if (svgElement) {
				const paths = svgElement.querySelectorAll("path");
				const colorDefault = "#dbdfe9";
				paths.forEach((path) => {
					const aux =
						classifications?.find(
							(item) => path.getAttribute("id") === item.city.ibgeCode
						)?.color || colorDefault;
					path.setAttribute("stroke", "#838383"); //cor line
					path.setAttribute("stroke-width", "50"); //width line
					path.setAttribute("stroke-miterlimit", "10");
					path.setAttribute("stroke-opacity", "1");
					const originalColor = aux;
					path.setAttribute("fill", originalColor);
					// Add hover event listeners
					path.addEventListener("mouseenter", (event) => {
						const cityId = path.getAttribute("id");
						path.setAttribute("fill-opacity", "0.7"); // cor de preenchimento ao passar o mouse
						setSelectedCity(
							citiesWithFederalUnit?.find((city) => String(city.id) === cityId)
						);
						showTooltip(event);
					});
					path.addEventListener("mouseleave", (event) => {
						path.setAttribute("fill-opacity", originalColor); // cor original ao sair do mouse
						hideTooltip(event);
					});
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [svgData, classifications]);

	const applyFillColorTeste = (svgText: string) => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(svgText, "image/svg+xml");
		const svgElement = doc.documentElement;

		resizing(width, height, svgElement);

		return new XMLSerializer().serializeToString(svgElement);
	};

	const resizing = (width: string, height: string, svgElement: HTMLElement) => {
		svgElement.setAttribute("width", width);
		svgElement.setAttribute("height", height);
	};

	const coloredSvg = svgData ? applyFillColorTeste(svgData) : null;

	return (
		<div className="mt-2">
			<div
				className="cursor-pointer overflow-auto"
				ref={svgContainerRef}
				dangerouslySetInnerHTML={{ __html: coloredSvg || "" }}
			/>
			{children}
		</div>
	);
}
