import { createContext, ReactNode, useState, useEffect } from "react";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Professional } from "@/@types/esus/Professional";
import { getProfessionals } from "@/services/esus/professionalService";

type ProfessionalContextType = {
	professionals: Professional[];
};

export const ProfessionalsContext = createContext({} as ProfessionalContextType);

type ProfessionalsContextProviderProps = {
	children: ReactNode;
};

export function ProfessionalsContextProvider({ children }: ProfessionalsContextProviderProps) {
	const [professionals, setProfessionals] = useState<Professional[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function handleGetProfessionals() {
		if (!ibgeCode) return;
		setProfessionals(await getProfessionals({ uf, ibgeCode, cnes }));
	}

	useEffect(() => {
		handleGetProfessionals();
		return () => setProfessionals([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ProfessionalsContext.Provider value={{ professionals }}>
			{children}
		</ProfessionalsContext.Provider>
	);
}
