import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { BsSearch } from "react-icons/bs";

type Props = {
	startDate: string;
	setStartDate: (startDate: string) => void;
	endDate: string;
	setEndDate: (endDate: string) => void;
	handleFilter: () => void;
};

export function TripleViralReportFilters({
	startDate,
	setStartDate,
	endDate,
	setEndDate,
	handleFilter,
}: Props) {
	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				handleFilter();
			}}
		>
			<Row>
				<Col sm={12} md={6} className="mb-3">
					<Form.Group className=" gap-3">
						<Form.Label className="align-items-center fw-bolder">Período</Form.Label>
						<div className="d-flex gap-3">
							<Form.Control
								type="date"
								placeholder="Data inicial"
								value={startDate}
								min={dayjs().subtract(2, "years").format("YYYY-MM-DD")}
								max={dayjs().format("YYYY/MM/DD")}
								onChange={(event) => setStartDate(event.target.value)}
							/>
							<Form.Control
								type="date"
								placeholder="data final"
								value={endDate}
								min={startDate}
								max={dayjs().format("YYYY-MM-DD")}
								onChange={(event) => setEndDate(event.target.value)}
							/>
						</div>
					</Form.Group>
				</Col>
				<Col sm={12} md={6} className="mb-3">
					<div className="d-flex align-items-end justify-content-center justify-content-sm-center justify-content-md-end h-100">
						<Button
							className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-grow-1 flex-lg-grow-0"
							type="submit"
						>
							<BsSearch className="me-2" />
							Consultar
						</Button>
					</div>
				</Col>
			</Row>
		</Form>
	);
}
