import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { toast } from "react-toastify";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { useDebounce } from "@/hooks/useDebounce";
import { HealthUnit } from "@/@types/esus/HealthUnit";
import { RoleEnum, StateRoleEnum } from "@/utils/enums/RoleEnum";
import { User } from "@/@types/app/user";
import { Paginated } from "@/@types/generics/paginated";
import { SelectOptionsProps } from "@/@types/generics/genericals";
import { Account } from "@/@types/app/Account";
import { Option } from "@/@types/generics/Option";
import { Role } from "@/@types/app/Role";
import { StatesAndCities } from "@/@types/statesAndCities";
import { FederalUnit } from "@/@types/app/FederalUnit";
import {
	getUserAccounts,
	updateAccessUserAccount,
	UserAccountsUnitsAccess,
} from "@/services/app/userAccountService";
import { getPermittedCities, getPermittedFederalUnits } from "@/services/app/userService";
import { getAllProfiles } from "@/services/app/profileService";
import { getUnitsPaginated } from "@/services/app/healthUnitService";

import { SearchInput } from "@/components/SearchInput";
import { CitiesSelect } from "../CitiesSelect";
import { ProfileSelect } from "../ProfileSelect";
import { ProfessionalUnitAccessTable } from "./ProfessionalUnitAccessTable";
import { FederalUnitsSelect } from "../FederalUnitsSelect";

import statesAndCities from "@/utils/static/states-and-cities.json";

type Props = ModalProps & {
	selectedUser: User;
	selectedUf?: string | null;
	selectedIbgeCode?: string | null;
	selectedCnes?: string | null;
	setRefreshPage: Dispatch<SetStateAction<boolean>>;
};

export function ModalProfessionalUnitAccess({
	selectedUser,
	selectedUf,
	selectedIbgeCode,
	selectedCnes,
	setRefreshPage,
	...rest
}: Props) {
	const { user } = useCurrentAccount();
	const statesAndCitiesJson = statesAndCities as StatesAndCities;
	const [healthUnitsPaginated, setHealthUnitsPaginated] = useState<Paginated<HealthUnit>>(
		{} as Paginated<HealthUnit>
	);
	const [selectedCnesList, setSelectedCnesList] = useState<string[]>([]);
	const [roles, setRoles] = useState<Role[]>([]);
	const [selectedOptionRole, setSelectedOptionRole] = useState<SelectOptionsProps>();
	const [searchUnit, setSearchUnit] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const debouncedSearchunit = useDebounce(searchUnit, 1000);

	const [userAccountsUnitsAccess, setUserAccountsUnitsAccess] = useState<
		UserAccountsUnitsAccess[]
	>([]);

	const [federalUnits, setFederalUnits] = useState<FederalUnit[]>([]);
	const [cities, setCities] = useState<Account[]>([]);
	const [pageNumber, setPageNumber] = useState(1);

	const defaultFederalUnitOption = user.isAdmin
		? { label: selectedUf || "Todos os estados", value: selectedUf || "" }
		: { label: selectedUf || "", value: selectedUf || "" };
	const defaultCityOption = user.isAdmin
		? {
				label:
					statesAndCitiesJson.cities.find((s) => String(s.id) === selectedIbgeCode)
						?.nome || "Todas as cidades",
				value: "",
		  }
		: {
				label:
					statesAndCitiesJson.cities.find((s) => String(s.id) === selectedIbgeCode)
						?.nome || "",
				value: selectedIbgeCode || "",
		  };

	const [selectedFederalUnitOption, setSelectedFederalUnitOption] =
		useState<Option<string>>(defaultFederalUnitOption);
	const [selectedCityOption, setSelectedCityOption] = useState<Option<string>>(defaultCityOption);

	const isFederalUnitsSelectDisabled = !user.isAdmin;
	const isCitiesSelectDisabled = !user.isAdmin;

	const filteredFederalUnits = federalUnits.filter(
		(federalUnit) =>
			federalUnit.abbreviation === selectedFederalUnitOption.value || user.isAdmin
	);

	const filteredRoles = roles.filter((role) =>
		!selectedCityOption.value
			? Object.values(StateRoleEnum).find((stateRole) => String(role.id) === stateRole)
			: Object.values(RoleEnum).find((roleObject) => String(role.id) === roleObject)
	);

	async function fetchPermittedFederalUnits() {
		setFederalUnits(await getPermittedFederalUnits());
	}

	async function fetchPermittedCities() {
		if (selectedFederalUnitOption?.value) {
			setCities(await getPermittedCities(selectedFederalUnitOption?.value));
		} else {
			setCities([]);
		}
	}

	async function handleFetchProfiles() {
		setRoles(await getAllProfiles());
	}

	async function handleFetchUnitsPaginated() {
		if (!selectedCityOption.value) {
			return;
		}
		setIsLoading(true);
		setHealthUnitsPaginated(
			await getUnitsPaginated({
				uf: selectedFederalUnitOption.value,
				ibgeCode: selectedCityOption.value,
				cnes: selectedCnes || "",
				pageNumber: pageNumber,
				name: searchUnit,
			})
		);
		setIsLoading(false);
	}

	async function handleFetchUserAccounts() {
		if (user.isAdmin && !selectedCityOption.value) {
			setUserAccountsUnitsAccess([]);
			return;
		}
		setUserAccountsUnitsAccess(
			await getUserAccounts(
				selectedFederalUnitOption.value,
				selectedCityOption.value,
				selectedUser.identifier,
				selectedCnes || ""
			)
		);
	}

	async function handleUpdateProfessionalAccess(
		userId: number,
		uf: string,
		ibgeCode: string,
		cnesList: string[],
		roleId?: string
	) {
		const slug = `${selectedUser.fullName}:`;
		const isUpdated = await updateAccessUserAccount({ userId, uf, ibgeCode, cnesList, roleId });
		if (isUpdated) {
			setRefreshPage(true);
			toast.info(`${slug} Atualizado com Sucesso`, { autoClose: 1500 });
			handleFetchUserAccounts();
		} else {
			toast.error(`${slug} deve solicitar o acesso previamente`, {
				autoClose: 1500,
			});
		}
	}

	useEffect(() => {
		fetchPermittedCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFederalUnitOption.value]);

	useEffect(() => {
		handleFetchUnitsPaginated();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser, selectedCityOption.value, pageNumber, debouncedSearchunit]);

	useEffect(() => {
		if (pageNumber !== 1) {
			setPageNumber(1);

			return;
		}
		handleFetchUnitsPaginated();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchunit]);

	useEffect(() => {
		const cnesList: string[] = [];
		userAccountsUnitsAccess.forEach((userAccount) => {
			if (userAccount.isActive && userAccount.cnes) {
				cnesList.push(userAccount.cnes);
			}
		});
		setSelectedCnesList(cnesList);
		if (userAccountsUnitsAccess.length > 0) {
			setSelectedOptionRole({
				label: userAccountsUnitsAccess[0].role?.description,
				value: String(userAccountsUnitsAccess[0].role?.id),
			});
		} else {
			setSelectedOptionRole(undefined);
		}
	}, [userAccountsUnitsAccess]);

	useEffect(() => {
		if (
			String(selectedOptionRole?.value) === RoleEnum.SUPER_ADMINISTRADOR ||
			String(selectedOptionRole?.value) === RoleEnum.ADMIN ||
			String(selectedOptionRole?.value) === RoleEnum.GESTOR
		) {
			setSelectedCnesList([]);
		}
	}, [selectedOptionRole]);

	useEffect(() => {
		if (selectedUser && selectedCityOption.value) {
			handleFetchUserAccounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedUser, selectedCityOption.value]);

	useEffect(() => {
		handleFetchProfiles();
		fetchPermittedFederalUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Modal {...rest} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>
						Acesso do Profissional{" "}
						<label className="d-flex text-primary fs-6 ">
							{selectedUser?.fullName}
						</label>{" "}
					</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Row className="d-flex mb-5">
						<Col
							sm={12}
							md={6}
							lg={6}
							xxl={6}
							className="d-flex flex-column justify-content-start"
							style={{ minWidth: "30vh" }}
						>
							<FederalUnitsSelect
								value={selectedFederalUnitOption}
								federalUnits={filteredFederalUnits}
								isDisabled={isFederalUnitsSelectDisabled}
								onChange={(event) => {
									setSelectedFederalUnitOption(event as Option<string>);
									setSelectedCityOption({
										label: "Todas as cidades",
										value: "",
									} as Option<string>);
								}}
							/>
						</Col>
						<Col
							sm={12}
							md={6}
							lg={6}
							xxl={6}
							className="d-flex flex-column justify-content-start"
							style={{ minWidth: "30vh" }}
						>
							<CitiesSelect
								value={selectedCityOption}
								accounts={cities}
								isDisabled={isCitiesSelectDisabled}
								onChange={(event) => {
									setSelectedCityOption(event as Option<string>);
								}}
							/>
						</Col>
						<Col
							sm={12}
							md={6}
							lg={6}
							xxl={6}
							className="d-flex flex-column justify-content-start"
							style={{ minWidth: "30vh" }}
						>
							<ProfileSelect
								roles={filteredRoles}
								value={selectedOptionRole}
								onChange={(event) => {
									setSelectedOptionRole(event as SelectOptionsProps);
								}}
							/>
						</Col>

						<Col
							sm={12}
							md={6}
							lg={6}
							xxl={6}
							className="d-flex align-items-end justify-content-start"
						>
							<Button
								className="mt-2"
								variant="success"
								onClick={() =>
									handleUpdateProfessionalAccess(
										selectedUser.id,
										selectedFederalUnitOption.value,
										selectedCityOption.value,
										selectedCnesList,
										selectedOptionRole?.value || ""
									)
								}
								disabled={
									String(selectedOptionRole?.value) !==
										RoleEnum.SUPER_ADMINISTRADOR &&
									String(selectedOptionRole?.value) !== RoleEnum.ADMIN &&
									String(selectedOptionRole?.value) !== RoleEnum.GESTOR
										? !Boolean(selectedOptionRole?.value)
										: !Boolean(selectedOptionRole?.value)
								}
							>
								Salvar
							</Button>
						</Col>
					</Row>

					{!(
						String(selectedOptionRole?.value) === RoleEnum.SUPER_ADMINISTRADOR ||
						String(selectedOptionRole?.value) === RoleEnum.ADMIN ||
						String(selectedOptionRole?.value) === RoleEnum.GESTOR ||
						!selectedOptionRole?.value ||
						!selectedCityOption.value
					) && (
						<>
							<Row className="d-flex align-items-start mb-5">
								<Col
									sm={6}
									className="d-flex flex-column justify-content-start"
									style={{ minWidth: "30vh" }}
								>
									<label className="form-label">Pesquisar Unidade</label>
									<SearchInput
										placeholder="Pesquisar Unidade"
										value={searchUnit}
										setValue={setSearchUnit}
									/>
								</Col>
							</Row>
							<ProfessionalUnitAccessTable
								pageNumber={pageNumber}
								setPageNumber={setPageNumber}
								selectedCnesList={selectedCnesList}
								setSelectedCnesList={setSelectedCnesList}
								healthUnitsPaginated={healthUnitsPaginated}
								isLoading={isLoading}
							/>
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
