import { createContext, ReactNode, useState, useEffect } from "react";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { getHealthUnits } from "@/services/app/healthUnitService";
import { HealthUnit } from "@/@types/esus/HealthUnit";

type UnitsContextType = {
	units: HealthUnit[];
};

export const UnitsContext = createContext({} as UnitsContextType);

type UnitsContextProviderProps = {
	children: ReactNode;
};

export function UnitsContextProvider({ children }: UnitsContextProviderProps) {
	const [units, setUnits] = useState<HealthUnit[]>([]);
	const { uf, ibgeCode, cnes } = useCurrentAccount();

	async function handleGetHealthUnits() {
		if (!ibgeCode) return;
		setUnits(await getHealthUnits({ uf, ibgeCode, cnes }));
	}

	useEffect(() => {
		handleGetHealthUnits();
		return () => setUnits([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ibgeCode, cnes]);

	return <UnitsContext.Provider value={{ units }}>{children}</UnitsContext.Provider>;
}
