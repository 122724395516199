import Table from "react-bootstrap/Table";
import { Cid } from "@/@types/esus/Cid";
import { ResumeGrouped } from "@/services/esus/attendanceService";

type Props = {
	aggregatedAttendanceCids: ResumeGrouped<Cid, object>[];
};

export function AttendanceRateByCidTable({ aggregatedAttendanceCids }: Props) {
	return (
		<Table
			bordered
			responsive
			striped
			hover
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th>Cid</th>
					<th className="text-start">Descrição</th>
					<th>Total</th>
				</tr>
			</thead>
			<tbody className="table-tbody text-center">
				{aggregatedAttendanceCids
					.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total)
					.map((aggregatedAttendanceCid, index) => (
						<tr key={`attendance-rate-by-cid-${index}`}>
							<td>{aggregatedAttendanceCid.key.cidCode}</td>
							<td className="text-start">
								{aggregatedAttendanceCid.key.description}
							</td>
							<td className="fw-bolder">{aggregatedAttendanceCid.total}</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
}
