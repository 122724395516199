import { useEffect, useState } from "react";

import { useLayout } from "@/../_metronic/layout/core";
import { useFormik } from "formik";
import Yup from "@/utils/mixins/yup";

import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Account } from "@/@types/app/Account";
import {
	DatabaseConnection,
	getAdminCity,
	updateDatabaseConnectionString,
} from "@/services/app/cityService";

export function DatabaseManagement() {
	const { setTitle } = useLayout();
	const { currentAccount } = useCurrentAccount();
	const [accountAdmin, setAccountAdmin] = useState<Account>({} as Account);

	const initialValues = {
		server: "",
		port: "",
		database: "",
		userid: "",
		password: "",
	} as DatabaseConnection;

	const validationDatabaseSchema = Yup.object().shape({
		server: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 50 caracteres!")
			.required("Host é Obrigatório!")
			.trim(),
		database: Yup.string()
			.min(3, "Mínimo de 3 caracteres!")
			.max(100, "Máximo de 30 caracteres!")
			.required("Nome é Obrigatório!")
			.trim(),
		port: Yup.string().max(10).required("Porta é Obrigatória!").trim(),
		userid: Yup.string().required("Usuario é Obrigatório!").trim(),
		password: Yup.string().required("Senha é Obrigatório").trim(),
	});

	const formik = useFormik<DatabaseConnection>({
		initialValues,
		validationSchema: validationDatabaseSchema,
		onSubmit: (values) => {
			handleUpdateDatabase(accountAdmin.ibgeCode, values);
		},
	});

	async function handleFetchDatabase() {
		if (!currentAccount.ibgeCode) {
			return;
		}
		const data = await getAdminCity(currentAccount.ibgeCode);
		setAccountAdmin(data);
		if (data.databaseConnectionString) {
			formik.setValues(destructureDatabaseConfiguration(data.databaseConnectionString));
		}
	}

	async function handleUpdateDatabase(
		ibgeCode: string,
		databaseConfiguration: DatabaseConnection
	) {
		const isUpdated = await updateDatabaseConnectionString(ibgeCode, databaseConfiguration);
		if (isUpdated) {
			toast.success("Alterado com sucesso!!");
			handleFetchDatabase();
		} else {
			toast.error("Não foi possível realizar a alteração.");
		}
	}

	function destructureDatabaseConfiguration(databaseConnectionString: string) {
		const configBancoDadosObjeto = {} as any;

		databaseConnectionString?.split(";").forEach((item) => {
			if (item.length === 0) return;
			const [key, value] = item?.split("=");
			configBancoDadosObjeto[key?.trim().split(" ").join("").toLocaleLowerCase()] =
				value?.trim();
		});
		return configBancoDadosObjeto as DatabaseConnection;
	}

	useEffect(() => {
		setTitle("");
		handleFetchDatabase();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<h1 className="text-primary py-3">Configurações de Conexão ao Banco de dados</h1>
			<section className="bg-white mb-5 rounded">
				<h2 className="ms-2 mb-5 py-2 px-2 fs-4">Informações da Cidade </h2>
				<Row className="ms-2">
					<Col lg={4} className="px-2 py-2 ">
						<label className="fs-6 text-break">
							<strong>IBGE Code: </strong>
							{currentAccount.ibgeCode}
						</label>
					</Col>
					<Col lg={4}>
						<label className="fs-6  py-2 text-break">
							<strong>Cidade: </strong>
							{currentAccount.locale}
						</label>
					</Col>
					<Col lg={4}>
						<label className="fs-6  py-2 text-break">
							<strong>Estado: </strong>
							{currentAccount.federalUnit?.abbreviation}
						</label>
					</Col>
				</Row>
			</section>
			<section className="bg-white rounded">
				<Form onSubmit={formik.handleSubmit} className="ms-2 py-2 px-2">
					<Row>
						<Col lg={8} className="my-2">
							<label className="fs-6">
								<strong>Host:</strong>
							</label>
							<input
								name="server"
								value={formik.values.server}
								onChange={formik.handleChange}
								className="form-control form-control-lg px-5 fs-6"
							/>
						</Col>
						<Col lg={4} className="my-2">
							<label className="fs-6">
								<strong>Porta:</strong>
							</label>
							<input
								name="port"
								value={formik.values.port}
								onChange={formik.handleChange}
								className="form-control form-control-lg px-5 fs-6"
							/>
						</Col>
					</Row>
					<Row>
						<Col className="my-2">
							<label className="fs-6">
								<strong>Nome do Banco:</strong>
							</label>
							<input
								name="database"
								value={formik.values.database}
								onChange={formik.handleChange}
								className="form-control form-control-lg px-5 fs-6"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={6} className="my-2">
							<label className="fs-6">
								<strong>Usuario:</strong>
							</label>
							<input
								name="userid"
								value={formik.values.userid}
								onChange={formik.handleChange}
								className="form-control form-control-lg px-5 fs-6"
							/>
						</Col>
						<Col xl={6} className="my-2">
							<label className="fs-6">
								<strong>Senha:</strong>
							</label>
							<input
								name="password"
								value={formik.values.password}
								onChange={formik.handleChange}
								className="form-control form-control-lg px-5 fs-6"
							/>
						</Col>
					</Row>
					<Row className="my-2">
						<Row>
							<div className="form-check ms-2 my-5 rounded">
								<input
									className="form-check-input rounded"
									type="checkbox"
									id="check-authorization"
								/>
								<label>
									Aceito os{" "}
									<a href="/configuracoes/banco-de-dados">
										<strong>Termos de condição</strong>
									</a>
								</label>
							</div>
						</Row>
						<Row className="d-flex my-2">
							<Col className="d-flex justify-content-center">
								<Button className="px-12" variant="success" type="submit">
									Salvar
								</Button>
							</Col>
						</Row>
					</Row>
				</Form>
			</section>
		</div>
	);
}
