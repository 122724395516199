import { useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { displaySingularOrPlural } from "@/utils/genericals";
import { identifierOrNationalHealthCard } from "@/utils/identifierOrNationalHealthCard";
import { displayFirstDumDate } from "@/utils/indicators";
import { Paginated } from "@/@types/generics/paginated";
import { PregnantPatient } from "@/services/esus/indicatorService";

import { CitizenSyncLabel } from "@/components/CitizenSyncLabel";
import { TableSkeletons } from "@/components/TableSkeletons";
import { PremilinaryReportStatusIcon } from "@/components/PremilinaryReportStatusIcon";
import { ViewCitizenButton } from "@/components/ViewCitizenButton";
import { ModalIndicator3 } from "../ModalIndicator3";

import "./styles.scss";

type Props = {
	pregnantPatient: Paginated<PregnantPatient>;
	isLoading: boolean;
};

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	sinc: Column;
	name: Column;
	birthdate: Column;
	identifier: Column;
	healthTeam: Column;
	mc: Column;
	dum: Column;
	firstAttendance: Column;
	gestationalAge1St: Column;
	dpp: Column;
};

export function TableIndicator3({ isLoading, pregnantPatient }: Props) {
	const [patientSelected, setPatientSelected] = useState<PregnantPatient>({} as PregnantPatient);
	const [showModalIndicator3, setShowModalIndicator3] = useState(false);
	const handleShowModalIndicator3 = () => {
		setShowModalIndicator3(true);
	};
	const handleCloseModalIndicator3 = () => {
		setShowModalIndicator3(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		sinc: { title: "Sinc", visible: false },
		name: { title: "Nome", visible: true },
		birthdate: { title: "DN", visible: false },
		identifier: { title: "CPF/CNS", visible: false },
		healthTeam: { title: "Equipe", visible: false },
		mc: { title: "MC", visible: false },
		dum: { title: "DUM", visible: true },
		firstAttendance: { title: "1º Atendimento", visible: true },
		gestationalAge1St: { title: "IG 1º Atendimento", visible: true },
		dpp: { title: "DPP", visible: true },
	});

	const handleColumnToggle = (column: keyof ColumnsTable) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[column]: {
				...prevColumns[column],
				visible: !prevColumns[column].visible,
			},
		}));
	};

	return (
		<div>
			<ModalIndicator3
				show={showModalIndicator3}
				onHide={handleCloseModalIndicator3}
				patientSelected={patientSelected}
			/>
			<Table responsive className="table-striped-custom table-rounded border gs-3">
				<thead>
					<tr className="fw-bold fs-6 text-center text-gray-800 text-uppercase border-bottom border-gray-200">
						{columns.sinc.visible && (
							<th>
								<CitizenSyncLabel />
							</th>
						)}
						{columns.name.visible && <th>CIDADÃO</th>}
						{columns.birthdate.visible && <th className="white-space-nowrap">DN</th>}
						{columns.identifier.visible && <th>CPF/CNS</th>}
						{columns.healthTeam.visible && <th>EQUIPE</th>}
						{columns.mc.visible && <th>MC</th>}
						{columns.dum.visible && <th>DUM</th>}
						{columns.firstAttendance.visible && (
							<th className="white-space-nowrap">1° ATENDIMENTO</th>
						)}
						{columns.gestationalAge1St.visible && (
							<th className="white-space-nowrap">IG 1° CONSULTA</th>
						)}
						{columns.dpp.visible && <th>DPP</th>}
						<th className="d-flex align-items-baseline justify-content-between text-nowrap pb-0 no-print no-excel">
							<label>AÇÕES</label>
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody>
					{!isLoading && pregnantPatient?.data ? (
						pregnantPatient?.data?.map((patient, index) => (
							<tr key={`patient-pregnant-${index}`}>
								{columns.sinc.visible && (
									<td title="Sincronização">
										<PremilinaryReportStatusIcon
											status={patient.preliminaryReportStatus}
										/>
									</td>
								)}
								{columns.name.visible && (
									<td title="Nome completo" className="text-nowrap">
										{patient.fullName || "N/A"}
									</td>
								)}
								{columns.birthdate.visible && (
									<td className="text-nowrap text-center">
										{patient.birthdate
											? dayjs(patient.birthdate).format("DD/MM/YYYY")
											: "N/A"}
									</td>
								)}
								{columns.identifier.visible && (
									<td title="CPF/CNS" className="text-nowrap text-center">
										{identifierOrNationalHealthCard(patient) || "N/A"}
									</td>
								)}
								{columns.healthTeam.visible && (
									<td title="Equipe" className="text-nowrap text-center">
										{patient.healthTeam?.name || "N/A"}
									</td>
								)}
								{columns.mc.visible && (
									<td
										title="Micro Área"
										className="text-nowrap text-center text-center"
									>
										{patient.area || "N/A"}
									</td>
								)}
								{columns.dum.visible && (
									<td
										title="Data da última menstruação (DUM)"
										className="text-nowrap text-center"
									>
										{displayFirstDumDate(patient.firstDumDate) || "N/A"}
									</td>
								)}
								{columns.firstAttendance.visible && (
									<td
										title="Data do primeiro atendimento odontológico"
										className="text-nowrap text-center"
									>
										{patient.dentalAttendances &&
										patient.dentalAttendances[0]?.date
											? dayjs(patient.dentalAttendances[0]?.date).format(
													"DD/MM/YYYY"
											  )
											: "N/A"}
									</td>
								)}
								{columns.gestationalAge1St.visible && (
									<td
										title="Idade gestacional na primeira consulta (IG)"
										className="text-nowrap text-center"
									>
										{patient.gestationalAge1St
											? displaySingularOrPlural(
													patient.gestationalAge1St,
													"semana"
											  )
											: "N/A"}
									</td>
								)}
								{columns.dpp.visible && (
									<td
										title="Data provável do parto (DPP)"
										className="text-nowrap text-center"
									>
										{dayjs(patient.dppDate).format("DD/MM/YYYY") || "N/A"}
									</td>
								)}
								<td className="text-center no-print">
									<ViewCitizenButton
										onClick={() => {
											handleShowModalIndicator3();
											setPatientSelected(patient);
										}}
									/>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons
							numberOfCells={
								Object.entries(columns).filter((a) => a[1]?.visible === true)
									.length + 1
							}
							height={20}
							numberOfRows={10}
						/>
					)}
				</tbody>
			</Table>
		</div>
	);
}
