import React, { useState } from "react";

import { useFormik } from "formik";
import { toast } from "react-toastify";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { registerTrackList, TrackListFormData } from "@/services/app/trackListService";

export function FormTrackList() {
	const initialValues = {} as TrackListFormData;
	const [isLoading, setIsLoading] = useState(false);
	const formik = useFormik<TrackListFormData>({
		initialValues,
		onSubmit: handleRegisterTrackList,
	});

	async function handleRegisterTrackList(trackListFormData: TrackListFormData) {
		if (!trackListFormData.thumbnailFile) return;
		setIsLoading(true);
		const isRegistered = await registerTrackList(trackListFormData);
		if (isRegistered) {
			toast.success("Playlist registrada com sucesso");
		} else {
			toast.error("Erro ao registrar playlist");
		}
		setIsLoading(false);
	}

	async function handleFileChange(event: React.ChangeEvent<any>, formikKey: string) {
		if (event.target.files) {
			formik.setFieldValue(formikKey, event.target.files[0]);
		}
	}

	return (
		<section className="bg-white p-4">
			<h1 className="text-primary fs-4 mb-5">Cadastro de playlist</h1>
			<Form onSubmit={formik.handleSubmit} encType="multipart/form-data">
				<Form.Group>
					<Form.Label>Título</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={formik.values.title}
						onChange={formik.handleChange}
						required
					/>
				</Form.Group>
				<Form.Group className="m-2">
					<Form.Label>Descrição</Form.Label>
					<Form.Control
						type="text"
						name="description"
						value={formik.values.description}
						onChange={formik.handleChange}
						required
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Arquivo da Thumbnail</Form.Label>
					<Form.Control
						type="file"
						name="thumbnailFile"
						accept={"image/*"}
						onChange={(event) => handleFileChange(event, "thumbnailFile")}
						required
					/>
				</Form.Group>
				<Button className="m-2" type="submit" disabled={isLoading}>
					Registrar
				</Button>
			</Form>
		</section>
	);
}
