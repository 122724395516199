import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";

export function Logout() {
	const { logout } = useCurrentAccount();
	useEffect(() => {
		return () => {
			logout();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Navigate to={"/auth"} />;
}
