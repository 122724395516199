import { useState } from "react";

import dayjs from "dayjs";

import { FaEllipsisV, FaEye } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { DiseaseClasssificationEnum } from "@/utils/enums/DiseaseClassificationEnum";
import { Paginated } from "@/@types/generics/paginated";
import { DiseaseData } from "..";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { LegendData } from "@/components/LegendData";
import { TableSkeletons } from "@/components/TableSkeletons";
import { ModalCitizensWithDiseases } from "./ModalCitizensWithDiseases";

type Props = {
	diseaseData: Paginated<DiseaseData>;
	isLoading: boolean;
};

type Column = {
	title: string;
	visible: boolean;
};

type ColumnsTable = {
	closure: Column;
	name: Column;
	birthdate: Column;
	reason: Column;
	firstDateFci: Column;
	lastDateFci: Column;
	totalAttendances: Column;
	firstAttendanceDate: Column;
	lastAttendanceDate: Column;
};

export function treatmentIsOver(item: DiseaseData) {
	return !item.currentStatusDisease &&
		item.lastIndividualRecordWithDiseaseNoDate &&
		(!item.lastProblemDate ||
			dayjs(item.lastIndividualRecordWithDiseaseNoDate).isSameOrAfter(
				dayjs(item.lastProblemDate)
			))
		? dayjs(item.lastIndividualRecordWithDiseaseNoDate).format("DD/MM/YYYY")
		: "-";
}

export function reasonForIdentification(item: DiseaseData) {
	return item.firstProblemDate && item.firstIndividualRecordDate
		? dayjs(item.firstProblemDate).isAfter(dayjs(item.firstIndividualRecordDate))
			? "FAI"
			: "FCI"
		: item.firstProblemDate
		? "FAI"
		: "FCI";
}

export function TableCitizensWithDiseases({ diseaseData, isLoading }: Props) {
	const [selectedCitizenWithDisease, setSelectedCitizenWithDisease] = useState<DiseaseData>(
		{} as DiseaseData
	);
	const [showModalCitizensWithDiseases, setShowModalCitizensWithDiseases] = useState(false);
	const handleShowModalCitizensWithDiseases = () => {
		setShowModalCitizensWithDiseases(true);
	};
	const handleCloseModalCitizensWithDiseases = () => {
		setShowModalCitizensWithDiseases(false);
	};

	const [columns, setColumns] = useState<ColumnsTable>({
		closure: { title: "Encerramento", visible: true },
		name: { title: "Nome", visible: true },
		birthdate: { title: "DN", visible: false },
		reason: { title: "Motivo", visible: true },
		firstDateFci: { title: "Primeira fci", visible: false },
		lastDateFci: { title: "Última fci", visible: true },
		totalAttendances: { title: "Nº de atendimentos", visible: false },
		firstAttendanceDate: { title: "Primeiro atendimento", visible: false },
		lastAttendanceDate: { title: "Último atendimento", visible: true },
	});

	function treatmentClassificationClassColor(status: number) {
		switch (status) {
			case DiseaseClasssificationEnum.RECOVERD:
				return "text-primary";

			case DiseaseClasssificationEnum.UNDERTREATMENT:
				return "text-warning";

			case DiseaseClasssificationEnum.UNTREATEDMENT:
				return "text-danger";
			default:
				return "";
		}
	}

	const handleColumnToggle = (column: keyof ColumnsTable) => {
		setColumns((prevColumns) => ({
			...prevColumns,
			[column]: {
				...prevColumns[column],
				visible: !prevColumns[column].visible,
			},
		}));
	};

	return (
		<div>
			<ModalCitizensWithDiseases
				selectedCitizenDiseaseData={selectedCitizenWithDisease}
				show={showModalCitizensWithDiseases}
				onHide={handleCloseModalCitizensWithDiseases}
			/>
			<LegendData
				titles={["Resolvido", "Em acompanhamento", "Não acompanhado"]}
				ClassNamesColors={["text-primary", "text-warning", "text-danger"]}
			/>
			<Table responsive className="bg-white" bordered hover>
				<thead className="fw-bolder text-muted">
					<tr>
						{columns.closure.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">ENCERRAMENTO</label>
								<CardOverlayTrigger message="Encerrado quando a última FCI sem o problema é igual ou posterior à última FCI com o problema e o status da doença é inativo" />
							</th>
						)}
						{columns.name.visible && <th className="text-center">NOME</th>}
						{columns.birthdate.visible && <th className="text-center">DN</th>}
						{columns.reason.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">MOTIVO</label>
								<CardOverlayTrigger message="Motivo da primeira identificação se foi FCI ou FAI." />
							</th>
						)}
						{columns.firstDateFci.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">1ª FCI</label>
								<CardOverlayTrigger message="Primeira vez que o cidadão foi identificado por FCI." />
							</th>
						)}
						{columns.lastDateFci.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">ÚlTIMA FCI</label>
								<CardOverlayTrigger message="Última vez que o cidadão foi identificado por FCI." />
							</th>
						)}
						{columns.totalAttendances.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">Nº DE ATENDIMENTOS</label>
								<CardOverlayTrigger message="Nº de atendimentos, independente do período filtrado." />
							</th>
						)}
						{columns.firstAttendanceDate.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">1º ATENDIMENTO</label>
								<CardOverlayTrigger message="Primeiro atendimento do cidadão com o problema, independente do período filtrado." />
							</th>
						)}
						{columns.lastAttendanceDate.visible && (
							<th className="text-center text-nowrap">
								<label className="me-2">ÚLTIMO ATENDIMENTO</label>
								<CardOverlayTrigger message="Último atendimento do cidadão com o problema, independente do período filtrado." />
							</th>
						)}
						<th className="d-flex gap-2 align-items-baseline text-center justify-content-between text-nowrap no-print no-excel">
							AÇÕES
							<OverlayTrigger
								trigger="click"
								placement="bottom"
								overlay={
									<Popover>
										<Popover.Body>
											<Form className="d-flex flex-column gap-2">
												{Object.keys(columns).map((key, index) => (
													<Form.Check
														key={`column-${index}`}
														type="checkbox"
														label={
															columns[key as keyof ColumnsTable].title
														}
														checked={
															columns[key as keyof ColumnsTable]
																.visible
														}
														onChange={() =>
															handleColumnToggle(
																key as keyof ColumnsTable
															)
														}
													/>
												))}
											</Form>
										</Popover.Body>
									</Popover>
								}
								rootClose
							>
								<Button
									title="Exibição de colunas da tabela"
									variant="white"
									className="px-2"
									disabled={isLoading}
								>
									<FaEllipsisV />
								</Button>
							</OverlayTrigger>
						</th>
					</tr>
				</thead>
				<tbody>
					{diseaseData.data && !isLoading ? (
						diseaseData.data?.map((item, index) => (
							<tr
								key={index}
								className={`${
									item?.status && treatmentClassificationClassColor(item?.status)
								}`}
							>
								{columns.closure.visible && (
									<td title="Encerramento" className="text-center">
										{treatmentIsOver(item)}
									</td>
								)}
								{columns.name.visible && (
									<td title="Nome" className="text-nowrap ps-2">
										{item.citizen.fullName}
									</td>
								)}
								{columns.birthdate.visible && (
									<td className="text-center ps-2" title={item.citizen.age}>
										{dayjs(item.citizen.birthdate).format("DD/MM/YYYY")}
									</td>
								)}
								{columns.reason.visible && (
									<td
										className="text-center ps-2"
										title="Motivo da identificação"
									>
										{reasonForIdentification(item)}
									</td>
								)}
								{columns.firstDateFci.visible && (
									<td className="text-center">
										{item.firstIndividualRecordDate
											? dayjs(item.firstIndividualRecordDate).format(
													"DD/MM/YYYY"
											  )
											: "-"}
									</td>
								)}
								{columns.lastDateFci.visible && (
									<td className="text-center">
										{item.lastIndividualRecordWithDiseaseYesDate
											? dayjs(
													item.lastIndividualRecordWithDiseaseYesDate
											  ).format("DD/MM/YYYY")
											: "-"}
									</td>
								)}
								{columns.totalAttendances.visible && (
									<td className="text-center">{item.problems?.length}</td>
								)}
								{columns.firstAttendanceDate.visible && (
									<td className="text-center">
										{item.firstProblemDate
											? dayjs(item.firstProblemDate).format("DD/MM/YYYY")
											: "-"}
									</td>
								)}
								{columns.lastAttendanceDate.visible && (
									<td className="text-center">
										{item.lastProblemDate
											? dayjs(item.lastProblemDate).format("DD/MM/YYYY")
											: "-"}
									</td>
								)}
								<td className="text-center">
									<Button
										className="bg-primary py-1 px-3"
										onClick={() => {
											handleShowModalCitizensWithDiseases();
											setSelectedCitizenWithDisease(item);
										}}
									>
										<FaEye className="fs-4" />
									</Button>
								</td>
							</tr>
						))
					) : (
						<TableSkeletons
							numberOfCells={
								Object.entries(columns).filter((a) => a[1]?.visible === true)
									.length + 1
							}
							height={20}
							numberOfRows={10}
						/>
					)}
				</tbody>
			</Table>
		</div>
	);
}
