import dayjs from "dayjs";
import "dayjs/locale/pt";

import Card from "react-bootstrap/Card";
import FormControl from "react-bootstrap/FormControl";

import { generateCalendar } from "@/utils/generateCalendar";

dayjs.locale("pt");

export type CalendarContent = {
	content?: React.ReactNode;
	date: string;
};

type Props = {
	title: string;
	calendarContents: CalendarContent[];
	currentMonth: dayjs.Dayjs;
	setCurrentMonth?: (month: dayjs.Dayjs) => void;
	overlayTriggerChildren?: React.ReactNode;
	calendarDayClickEvent?: (date: dayjs.Dayjs) => void;
};

export function Calendar({
	calendarContents,
	title,
	currentMonth,
	setCurrentMonth,
	overlayTriggerChildren,
	calendarDayClickEvent,
}: Props) {
	const daysInMonth = generateCalendar(currentMonth);

	const getEventsForDay = (day: dayjs.Dayjs): CalendarContent[] => {
		return calendarContents.filter((event) => event.date === day.format("YYYY-MM-DD"));
	};

	const weekDays = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
	return (
		<Card className="h-100" style={{ minWidth: "30rem" }}>
			<Card.Header className="d-flex align-items-center p-2">
				<Card.Title>
					{title}
				</Card.Title>
				<Card.Title>
					<FormControl
						type="month"
						className="border border-none"
						onChange={(event) => setCurrentMonth?.(dayjs(event.target.value))}
						value={dayjs(currentMonth).format("YYYY-MM")}
					/>
				</Card.Title>

				<Card.Title>{overlayTriggerChildren}</Card.Title>
			</Card.Header>
			<Card.Body className="pb-8 h-100">
				<div
					className="text-white"
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(7, 1fr)",
						gap: "10px",
						backgroundColor: "#0c6fd1",
					}}
				>
					{weekDays.map((day, index) => (
						<div className="text-center fs-5 fw-bolder" key={index}>
							{day}
						</div>
					))}
				</div>
				<div
					className="h-100"
					style={{ display: "grid", gridTemplateColumns: "repeat(7, 1fr)" }}
				>
					{daysInMonth.map((day) => {
						const dayEvents = getEventsForDay(day);
						return (
							<div
								key={day.format("YYYY-MM-DD")}
								className={`border border-secondary p-2 text-center ${
									dayEvents.filter((item) => item.content).length > 0 &&
									"cursor-pointer "
								}`}
								onClick={() => {
									if (dayEvents.filter((item) => item.content).length > 0) {
										calendarDayClickEvent?.(day);
									}
								}}
								style={{
									backgroundColor: day.isSame(dayjs(), "day")
										? "lightblue"
										: day.month() !== currentMonth.month()
										? "ghostwhite"
										: "white",
									textAlign: "center",
								}}
							>
								<div className="d-flex justify-content-start text-nowrap w-100">
									<div
										className="d-flex justify-content-center align-items-center rounded-circle bg-info text-white fw-bolder"
										style={{ height: "2rem", width: "2rem" }}
									>
										{day.date()}
									</div>
								</div>
								<div className="mt-3 fs-8 font-black">
									{dayEvents.map((event, index) => {
										return (
											<div
												key={index}
												className="d-flex align-items-center mb-2"
											>
												<span className="fw-bolder w-100 h-100">
													{event.content}
												</span>
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			</Card.Body>
		</Card>
	);
}
