import { useState } from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaChevronRight } from "react-icons/fa";

import { PregnancyDashboardIndicatorsMap } from "../PregnancyDashboardIndicatorsMap";
import { PregnancyDashboardIndicatorsTableFilters } from "../PregnancyDashboardIndicatorsTableFilters";
import { PregnancyDashboardIndicatorsTable } from "../PregnancyDashboardIndicatorsTable";
import { PregnancyIndicatorsResumeCity } from "..";

type Props = {
	pregnancyIndicatorsResumeCities: PregnancyIndicatorsResumeCity[];
};

export function PregnancyDashboardIndicatorsDetails({ pregnancyIndicatorsResumeCities }: Props) {
	const [cityName, setCityName] = useState("");
	const [indicatorType, setIndicatorType] = useState<1 | 2 | 3>();
	const pregnancyIndicatorsResumeCitiesFiltered = pregnancyIndicatorsResumeCities.filter(
		(cityResume) => cityResume.city.locale.toUpperCase().includes(cityName.toUpperCase())
	);

	function handleChangeIndicator(newIndicatorType: 1 | 2 | 3) {
		if (indicatorType === newIndicatorType) {
			setIndicatorType(undefined);
		} else {
			setIndicatorType(newIndicatorType);
		}
	}

	return (
		<>
			<Row className="px-2">
				<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<h3 className="mx-2 mb-2 rounded">Filtre por indicador:</h3>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<p className="mx-2 mb-4">
								Clique em um dos indicadores para filtrar no mapa
							</p>
							<Row className="m-2">
								<Card
									className={`${
										indicatorType === 1 ? "bg-primary" : "bg-secondary"
									} cursor-pointer`}
									onClick={() => handleChangeIndicator(1)}
								>
									<Card.Body className="m-0 p-4">
										<Row
											className={`d-flex justify-content-between ${
												indicatorType === 1 ? "text-white" : ""
											}`}
										>
											<Col>
												<Row>
													<h3
														className={`${
															indicatorType === 1 ? "text-white" : ""
														}`}
													>
														Indicador 1
													</h3>
												</Row>
												<Row>
													<p>
														Quantidade de gestantes com pelo menos 6
														consultas de pré-natal realizadas, sendo a
														primeira até a 12ª semana de gestação
													</p>
												</Row>
											</Col>
											<Col
												sm={2}
												md={2}
												lg={2}
												xl={2}
												className="d-flex align-items-center justify-content-center"
											>
												<FaChevronRight />
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Row>
							<Row className="m-2">
								<Card
									className={`${
										indicatorType === 2 ? "bg-primary" : "bg-secondary"
									} cursor-pointer`}
									onClick={() => handleChangeIndicator(2)}
								>
									<Card.Body className="m-0 p-4">
										<Row
											className={`d-flex justify-content-between ${
												indicatorType === 2 ? "text-white" : ""
											}`}
										>
											<Col>
												<Row>
													<h3
														className={`${
															indicatorType === 2 ? "text-white" : ""
														}`}
													>
														Indicador 2
													</h3>
												</Row>
												<Row>
													<p>
														Quantidade de gestantes com a realização de
														exame para sífilis e HIV
													</p>
												</Row>
											</Col>
											<Col
												sm={2}
												md={2}
												lg={2}
												xl={2}
												className="d-flex align-items-center justify-content-center"
											>
												<FaChevronRight />
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Row>
							<Row className="m-2">
								<Card
									className={`${
										indicatorType === 3 ? "bg-primary" : "bg-secondary"
									} cursor-pointer`}
									onClick={() => handleChangeIndicator(3)}
								>
									<Card.Body className="m-0 p-4">
										<Row
											className={`d-flex justify-content-between ${
												indicatorType === 3 ? "text-white" : ""
											}`}
										>
											<Col>
												<Row>
													<h3
														className={`${
															indicatorType === 3 ? "text-white" : ""
														}`}
													>
														Indicador 3
													</h3>
												</Row>
												<Row>
													<p>
														Quantidade de gestantes com consultas
														odontológicas
													</p>
												</Row>
											</Col>
											<Col
												sm={2}
												md={2}
												lg={2}
												xl={2}
												className="d-flex align-items-center justify-content-center"
											>
												<FaChevronRight />
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Row>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={6} xl={6} className="mb-2">
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								<h3 className="mx-2 mb-2 rounded ">Indicador por cidade:</h3>
							</Card.Title>
						</Card.Header>
						<Card.Body>
							<PregnancyDashboardIndicatorsMap
								pregnancyIndicatorsResumeCities={pregnancyIndicatorsResumeCities}
								indicatorType={indicatorType}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row>
				<Col>
					<Card className="m-2 p-4">
						<Card.Body>
							<div className="mb-4">
								<PregnancyDashboardIndicatorsTableFilters
									cityName={cityName}
									setCityName={setCityName}
								/>
							</div>
							<div>
								<PregnancyDashboardIndicatorsTable
									pregnancyIndicatorsResumeCities={
										pregnancyIndicatorsResumeCitiesFiltered
									}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	);
}
