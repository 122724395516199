import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BsGraphUpArrow } from "react-icons/bs";
import { FaBookMedical, FaInfoCircle, FaUsers } from "react-icons/fa";
import { TbNotesOff } from "react-icons/tb";
import { AiOutlineWarning } from "react-icons/ai";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { CardInformative } from "@/pages/esus/ReterritorializationAcsDashboard/CardInformative";

type Props = {
	isLoadingResume: boolean;
	totalPregnants: number;
	totalRegisteredChildBirth: number;
	totalUnregisteredChildBirth: number;
	totalRiskPregnants: number;
	averagePregnantAge: number;
	averageGestationalAge: number;
};

export function PregnancyResume({
	isLoadingResume,
	totalPregnants,
	totalRegisteredChildBirth,
	totalUnregisteredChildBirth,
	totalRiskPregnants,
	averageGestationalAge,
}: Props) {
	return (
		<Row className="d-flex justify-content-evenly m-0 gap-1">
			<Col sm={6} md={4} lg={2} xxl={1} className="bg-muted rounded p-2">
				<CardInformative
					title="Gestantes"
					value={!isLoadingResume ? totalPregnants : 0}
					classNameTitle="text-white"
					classNameValue="text-white"
					icon={<FaUsers className="fs-2 text-white" />}
					metaData={
						<CardOverlayTrigger
							header="Gestantes"
							message="Quantidade de gestantes identificadas no período"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={1} className="bg-primary rounded p-2">
				<CardInformative
					title="Gestantes ativas"
					classNameTitle="text-white"
					classNameValue="text-white"
					value={
						!isLoadingResume
							? totalPregnants -
							  totalRegisteredChildBirth -
							  totalUnregisteredChildBirth
							: 0
					}
					icon={<FaUsers className="text-white fs-2 fw-bolder" />}
					metaData={
						<CardOverlayTrigger
							header="Gestantes ativas"
							message="Quantidade de gestantes que não tiveram parto registrado"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={1} className="bg-success rounded p-2">
				<CardInformative
					title="Partos registrados"
					classNameTitle="text-white"
					classNameValue="text-white"
					value={!isLoadingResume ? totalRegisteredChildBirth : 0}
					icon={<FaBookMedical className="fs-2 fw-bolder text-white" />}
					metaData={
						<CardOverlayTrigger
							header="Partos registrados"
							message="Quantidade de gestantes que possuem procedimento de parto registrado no município"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={1} className="bg-info rounded p-2">
				<CardInformative
					title="Partos não registrados"
					classNameTitle="text-white"
					classNameValue="text-white"
					value={!isLoadingResume ? totalUnregisteredChildBirth : 0}
					icon={<TbNotesOff className="text-white fs-2 fw-bolder" />}
					metaData={
						<CardOverlayTrigger
							header="Partos não registrados"
							message="Quantidade de gestantes que não possuem procedimento de parto registrado no município"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>
			<Col sm={6} md={4} lg={2} xxl={1} className="bg-warning rounded p-2">
				<CardInformative
					title="Gestantes de risco"
					classNameTitle="text-white"
					classNameValue="text-white"
					value={!isLoadingResume ? totalRiskPregnants : 0}
					icon={<AiOutlineWarning className="text-white fs-2 fw-bolder" />}
					metaData={
						<CardOverlayTrigger
							header="Gestantes de risco"
							message="Quantidade de gestantes que possuem condições de risco na gestação"
							icon={<FaInfoCircle className="text-white" />}
						/>
					}
				/>
			</Col>

			<Col sm={6} md={4} lg={2} xxl={1} className="border border-muted bg-white rounded p-2">
				<CardInformative
					title="Idade média gestacional"
					classNameTitle="text-muted"
					classNameValue="text-muted"
					value={!isLoadingResume ? averageGestationalAge : 0}
					icon={<BsGraphUpArrow className="text-muted fs-2 fw-bolder" />}
					metaData={
						<CardOverlayTrigger
							header="Idade média gestacional"
							message="Idade média gestacional em semanas considerando como valor base a idade gestacional na data da finalização da gestação"
							icon={<FaInfoCircle className="text-muted" />}
						/>
					}
				/>
			</Col>
		</Row>
	);
}
