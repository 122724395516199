import { useEffect, useState } from "react";

import dayjs from "dayjs";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLayout } from "@/../../src/_metronic/layout/core";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { removeDuplicates } from "@/utils/genericals";
import {
	CityChronicCommunicableDiseaseDashboard,
	getChronicCommunicableDiseaseDashboard,
} from "@/services/federalUnit/attendanceService";
import { DiseasesEnum } from "@/utils/enums/DiseasesEnum";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";

import { PieChart } from "@/components/Charts/PieChart";
import { LoadingScreen } from "@/components/LoadingScreen";
import { BarChart } from "@/components/Charts/BarChart";
import { AreaChart } from "@/components/Charts/AreaChart";
import { ChronicCommunicableDiseasesDashboardFilters } from "./ChronicCommunicableDiseasesDashboardFilters";
import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";

export function ChronicCommunicableDiseasesDashboard() {
	const { setTitle } = useLayout();
	const { uf } = useCurrentAccount();
	const [isLoading, setIsloading] = useState(false);
	const [startDate, setStartDate] = useState<string>(
		dayjs().subtract(4, "months").format("YYYY-MM-DD")
	);
	const [endDate, setEndDate] = useState<string>(dayjs().format("YYYY-MM-DD"));
	const [communicableDiseaseType, setCommunicableDiseaseType] = useState<string>(
		String(DiseasesEnum.HANSEN)
	);
	const [diseaseDashboards, setDiseaseDashboards] = useState<
		CityChronicCommunicableDiseaseDashboard[]
	>([]);
	const aggregatedDashboards = diseaseDashboards.map((dashboard) => dashboard.resume);

	async function fetchChronicCommunicableDiseasesDashboard() {
		return await getChronicCommunicableDiseaseDashboard({
			uf: uf,
			startDate: startDate,
			endDate: endDate,
			epidemicType: communicableDiseaseType,
		});
	}

	const attendancesCategoriesByPeriod = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(aggregatedDashboard.attendancesAggregatedByPeriodAndProfessionalCategory || []).map(
				(periodDashboard) =>
					`${SHORT_MONTHS[periodDashboard.key.month - 1]}/${periodDashboard.key.year}`
			)
		)
	);
	const attendancesSeriesByPeriod = attendancesCategoriesByPeriod.map((period) =>
		aggregatedDashboards
			.flatMap((aggregatedDashboard) =>
				(aggregatedDashboard.attendancesAggregatedByPeriodAndProfessionalCategory || [])
					.filter(
						(periodDashboard) =>
							`${SHORT_MONTHS[periodDashboard.key.month - 1]}/${
								periodDashboard.key.year
							}` === period
					)
					.map((periodDashboard) => periodDashboard.total)
			)
			.reduce((acc, curr) => acc + curr, 0)
	);

	const ageRangesCategories = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(aggregatedDashboard.attendancesAggregatedByAgeAndGender || []).map(
				(ageDashboard) => ageDashboard.key
			)
		)
	);
	const ageRangesSeries = ageRangesCategories.map((age) =>
		aggregatedDashboards
			.flatMap((aggregatedDashboard) =>
				(aggregatedDashboard.attendancesAggregatedByAgeAndGender || [])
					.filter((ageDashboard) => ageDashboard.key === age)
					.map((ageDashboard) => ageDashboard.total)
			)
			.reduce((acc, curr) => acc + curr, 0)
	);

	const genderCategories = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(aggregatedDashboard.attendancesAggregatedByAgeAndGender || [])
				.flatMap((ageDashboard) => ageDashboard.values || [])
				.map((genderDashboard) => genderDashboard.key)
		)
	);
	const genderSeries = genderCategories.map((gender) =>
		aggregatedDashboards
			.flatMap((aggregatedDashboard) =>
				(aggregatedDashboard.attendancesAggregatedByAgeAndGender || [])
					.flatMap((ageDashboard) => ageDashboard.values || [])
					.filter((genderDashboard) => genderDashboard.key === gender)
					.map((genderDashboard) => genderDashboard.total)
			)
			.reduce((acc, curr) => acc + curr, 0)
	);

	const attendancesCategoriesByProfessionalCategory = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(
				aggregatedDashboard.attendancesAggregatedByProfessionalCategoryAndProfessional || []
			).map((professionalCategoryDashboard) =>
				String(ProfessionalCategoryEnum.MEDIC) === professionalCategoryDashboard.key
					? "Médico"
					: String(ProfessionalCategoryEnum.NURSE) === professionalCategoryDashboard.key
					? "Enfermeiro"
					: String(ProfessionalCategoryEnum.NURSING_TECHNICIAN) ===
					  professionalCategoryDashboard.key
					? "Técnico de enfermagem"
					: "Outros"
			)
		)
	);
	const attendancesSeriesByProfessionalCategory = attendancesCategoriesByProfessionalCategory.map(
		(professionalCategory) =>
			aggregatedDashboards
				.flatMap((aggregatedDashboard) =>
					(
						aggregatedDashboard.attendancesAggregatedByProfessionalCategoryAndProfessional ||
						[]
					)
						.filter((professionalCategoryDashboard) =>
							String(ProfessionalCategoryEnum.MEDIC) ===
							professionalCategoryDashboard.key
								? "Médico"
								: String(ProfessionalCategoryEnum.NURSE) ===
								  professionalCategoryDashboard.key
								? "Enfermeiro"
								: String(ProfessionalCategoryEnum.NURSING_TECHNICIAN) ===
								  professionalCategoryDashboard.key
								? "Técnico de enfermagem"
								: "Outros" === professionalCategory
						)
						.map((professionalCategoryDashboard) => professionalCategoryDashboard.total)
				)
				.reduce((acc, curr) => acc + curr, 0)
	);
	const attendancesAggregatedByProfessionalCategory = Array.from({
		length: attendancesCategoriesByProfessionalCategory.length || 0,
	})
		.map((_, index) => {
			return {
				category: attendancesCategoriesByProfessionalCategory[index],
				total: attendancesSeriesByProfessionalCategory[index],
			};
		})
		.filter((serie) => serie.total > 0)
		.sort((serieA, serieB) => serieB.total - serieA.total)
		.slice(0, 10);

	const attendancesCategoriesByCity = diseaseDashboards.map((aggregatedDashboard) =>
		[aggregatedDashboard.city.locale, aggregatedDashboard.city.federalUnit?.abbreviation]
			.filter((description) => description && description.length > 0)
			.join(" - ")
	);
	const attendancesSeriesByCity = attendancesCategoriesByCity.map((city) =>
		(
			diseaseDashboards.filter(
				(aggregatedDashboard) =>
					[
						aggregatedDashboard.city.locale,
						aggregatedDashboard.city.federalUnit?.abbreviation,
					]
						.filter((description) => description && description.length > 0)
						.join(" - ") === city
			) || []
		)
			.map((dashboard) =>
				(dashboard.resume.attendancesAggregatedByPeriodAndProfessionalCategory || [])
					.map((periodDashboard) => periodDashboard.total)
					.reduce((totalPeriodA, totalPeriodB) => totalPeriodA + totalPeriodB, 0)
			)
			.reduce((totalA, totalB) => totalA + totalB, 0)
	);
	const attendancesAggregatedByCity = Array.from({
		length: attendancesCategoriesByCity.length || 0,
	})
		.map((_, index) => {
			return {
				category: attendancesCategoriesByCity[index],
				total: attendancesSeriesByCity[index],
			};
		})
		.filter((serie) => serie.total > 0)
		.sort((serieA, serieB) => serieB.total - serieA.total)
		.slice(0, 10);

	const attendancesCategoriesByHealthUnit = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(
				aggregatedDashboard.attendancesAggregatedByHealthUnitAndProfessionalCategory || []
			).map(
				(healthUnitDashboard) =>
					`${healthUnitDashboard.key.cnes} - ${healthUnitDashboard.key.name}`
			)
		)
	);
	const attendancesSeriesByHealthUnit = attendancesCategoriesByHealthUnit.map((healthUnitName) =>
		aggregatedDashboards
			.flatMap((aggregatedDashboard) =>
				(aggregatedDashboard.attendancesAggregatedByHealthUnitAndProfessionalCategory || [])
					.filter(
						(healthUnitDashboard) =>
							`${healthUnitDashboard.key.cnes} - ${healthUnitDashboard.key.name}` ===
							healthUnitName
					)
					.map((healthUnitDashboard) => healthUnitDashboard.total)
			)
			.reduce((acc, curr) => acc + curr, 0)
	);
	const attendancesAggregatedByHealthUnit = Array.from({
		length: attendancesCategoriesByHealthUnit.length || 0,
	})
		.map((_, index) => {
			return {
				category: attendancesCategoriesByHealthUnit[index],
				total: attendancesSeriesByHealthUnit[index],
			};
		})
		.filter((serie) => serie.total > 0)
		.sort((serieA, serieB) => serieB.total - serieA.total)
		.slice(0, 10);

	const attendancesCategoriesByHealthTeam = removeDuplicates<string[]>(
		aggregatedDashboards.flatMap((aggregatedDashboard) =>
			(
				aggregatedDashboard.attendancesAggregatedByHealthTeamAndProfessionalCategory || []
			).map(
				(healthTeamDashboard) =>
					`${healthTeamDashboard.key.ine} - ${healthTeamDashboard.key.name}`
			)
		)
	);
	const attendancesSeriesByHealthTeam = attendancesCategoriesByHealthTeam.map((healthTeamName) =>
		aggregatedDashboards
			.flatMap((aggregatedDashboard) =>
				(aggregatedDashboard.attendancesAggregatedByHealthTeamAndProfessionalCategory || [])
					.filter(
						(healthTeamDashboard) =>
							`${healthTeamDashboard.key.ine} - ${healthTeamDashboard.key.name}` ===
							healthTeamName
					)
					.map((healthTeamDashboard) => healthTeamDashboard.total)
			)
			.reduce((acc, curr) => acc + curr, 0)
	);
	const attendancesAggregatedByHealthTeam = Array.from({
		length: attendancesCategoriesByHealthTeam.length || 0,
	})
		.map((_, index) => {
			return {
				category: attendancesCategoriesByHealthTeam[index],
				total: attendancesSeriesByHealthTeam[index],
			};
		})
		.filter((serie) => serie.total > 0)
		.sort((serieA, serieB) => serieB.total - serieA.total)
		.slice(0, 10);

	async function fetchDashboard() {
		setIsloading(true);
		setDiseaseDashboards(await fetchChronicCommunicableDiseasesDashboard());
		setIsloading(false);
	}

	useEffect(() => {
		setTitle("DASHBOARD DE DOENÇAS CRÔNICAS");
		fetchDashboard();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="p-2">
			<LoadingScreen
				loading={isLoading}
				messages={[
					"Já estamos processando a consulta do periodo.",
					"aguarde alguns instantes.",
					"Estamos quase lá!",
				]}
			/>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Body>
							<ChronicCommunicableDiseasesDashboardFilters
								startDate={startDate}
								setStartDate={setStartDate}
								endDate={endDate}
								setEndDate={setEndDate}
								handleFilter={fetchDashboard}
								setCommunicableDiseaseType={setCommunicableDiseaseType}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Atendimentos por período
						</Card.Header>
						<Card.Body>
							<AreaChart
								categories={attendancesCategoriesByPeriod}
								series={[
									{
										data: attendancesSeriesByPeriod,
										name: "Qtde. Atendimentos",
									},
								]}
								heigth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Faixa etária
						</Card.Header>
						<Card.Body className="p-0">
							<PieChart
								categories={ageRangesCategories}
								series={ageRangesSeries}
								height={300}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title>Gêneros</Card.Title>
						</Card.Header>
						<Card.Body className="p-0">
							<PieChart
								categories={genderCategories}
								series={genderSeries}
								height={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Atendimentos por Categoria profissional</Card.Title>
						</Card.Header>
						<Card.Body>
							<BarChart
								categories={attendancesAggregatedByProfessionalCategory.map(
									(serie) => serie.category
								)}
								series={[
									{
										data: attendancesAggregatedByProfessionalCategory.map(
											(serie) => serie.total
										),
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={12}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Cidades com maior incidência
						</Card.Header>
						<Card.Body>
							<BarChart
								categories={attendancesAggregatedByCity.map(
									(serie) => serie.category
								)}
								series={[
									{
										data: attendancesAggregatedByCity.map(
											(serie) => serie.total
										),
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} md={12} lg={12} xl={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Unidades com maior incidência
						</Card.Header>
						<Card.Body>
							<BarChart
								categories={attendancesAggregatedByHealthUnit.map(
									(serie) => serie.category
								)}
								series={[
									{
										data: attendancesAggregatedByHealthUnit.map(
											(serie) => serie.total
										),
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} md={12} lg={12} xl={6}>
					<Card>
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							Equipes com maior incidência
						</Card.Header>
						<Card.Body>
							<BarChart
								categories={attendancesAggregatedByHealthTeam.map(
									(serie) => serie.category
								)}
								series={[
									{
										data: attendancesAggregatedByHealthTeam.map(
											(serie) => serie.total
										),
										name: "Total",
									},
								]}
								categoryTextWidth={300}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
