import { useEffect } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { getUpdateBanner } from "@/services/app/storageService";
import { Banner } from "@/@types/app/Banner";

type Props = ModalProps & {
	bannerType?: number;
	selectedBanner: Banner;
	fetchBanners: () => Promise<void>;
};

export function BannerEditModal({
	fetchBanners,
	selectedBanner,
	bannerType,
	show,
	...rest
}: Props) {
	const formik = useFormik<Banner>({
		initialValues: {} as Banner,
		onSubmit: handleRegisterBanner,
	});

	async function handleRegisterBanner(banner: Banner) {
		const bannerUpdate = {
			id: banner.id,
			storageFileId: banner.storageFileId,
			bannerTypeId: banner.bannerTypeId,
			startDate: banner.startDate,
			endDate: banner.endDate,
		} as Banner;
		const response = await getUpdateBanner(bannerUpdate);
		if (response.status !== 200) {
			toast.error("Erro ao Atualizar período");
		} else {
			toast.success("Período atualizado com sucesso.");
			fetchBanners();
			rest.onHide && rest.onHide();
		}
	}

	useEffect(() => {
		formik.setValues({
			id: selectedBanner.id,
			bannerTypeId: selectedBanner.bannerTypeId,
			startDate: dayjs(selectedBanner.startDate).format("YYYY-MM-DD"),
			endDate: selectedBanner.endDate
				? dayjs(selectedBanner.endDate).format("YYYY-MM-DD")
				: null,
			storageFileId: selectedBanner.storageFileId,
		} as Banner);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedBanner]);

	return (
		<Modal size="sm" scrollable show={show} {...rest}>
			<Modal.Header closeButton>
				<Modal.Title>Editar Período</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={formik.handleSubmit}>
					<Row className="px-10">
						<Col sm={12} md={12} lg={12} xl={12}>
							<Form.Label>Data Inicial</Form.Label>
							<Form.Control
								type="date"
								name="startDate"
								defaultValue={dayjs(selectedBanner.startDate).format("YYYY-MM-DD")}
								onChange={formik.handleChange}
							/>
						</Col>
						<Col sm={12} md={12} lg={12} xl={12} className="my-2">
							<Form.Label>Data Final</Form.Label>
							<Form.Control
								type="date"
								name="endDate"
								defaultValue={
									selectedBanner.endDate
										? dayjs(selectedBanner.endDate).format("YYYY-MM-DD")
										: ""
								}
								onChange={formik.handleChange}
							/>
						</Col>
						<Col className="d-flex align-items-end justify-content-center">
							<Button type="submit" className="mt-3">
								Confirmar
							</Button>
						</Col>
					</Row>
				</Form>
			</Modal.Body>
		</Modal>
	);
}
