import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Account } from "@/@types/app/Account";
import { FederalUnit } from "@/@types/app/FederalUnit";
import { SelectOptionsProps } from "@/@types/generics/genericals";
import {
	getPermittedCities,
	getPermittedFederalUnits,
	getPermittedHealthUnits,
} from "@/services/app/userService";

import { FederalUnitsSelect } from "@/components/FederalUnitsSelect";
import { CitiesSelect } from "@/components/CitiesSelect";
import { UnitsSelect } from "@/components/UnitsSelect";

import "./styles.scss";

export function FormUnitSelection() {
	const navigate = useNavigate();
	const { currentFederalUnit, currentAccount, getCurrentAccount } = useCurrentAccount();
	const [selectedOptionFederalUnit, setSelectedOptionFederalUnit] = useState<SelectOptionsProps>({
		label: currentFederalUnit.abbreviation || "Selecione...",
		value: currentFederalUnit.abbreviation,
	});
	const [selectedOptionCities, setSelectedOptionCities] = useState<SelectOptionsProps>({
		label: currentAccount.locale || "Selecione...",
		value: currentAccount.ibgeCode,
	});
	const [selectedOptionUnit, setSelectedOptionUnit] = useState<SelectOptionsProps>();
	const [inputSelectUnitDisable, setInputSelectUnitDisable] = useState<boolean>(
		currentAccount.ibgeCode ? false : true
	);
	const [buttonDisabled, setButtonDisabled] = useState(true);

	const [federalUnits, setFederalUnits] = useState<FederalUnit[]>([
		{ abbreviation: "Todos os estados" } as FederalUnit,
	]);
	const [cities, setCities] = useState<Account[]>([
		{ ibgeCode: "", locale: "Selecione..." } as Account,
	]);
	const [healthUnits, setHealthUnits] = useState<HealthUnit[]>([
		{ cnes: "", name: "Selecione..." } as HealthUnit,
	]);

	async function fetchPermittedFederalUnits() {
		setFederalUnits(await getPermittedFederalUnits());
	}
	async function fetchPermittedCities() {
		setCities(await getPermittedCities(selectedOptionFederalUnit?.value));
	}

	async function fetchPermittedHealthUnits() {
		setHealthUnits(
			await getPermittedHealthUnits(
				selectedOptionFederalUnit?.value,
				selectedOptionCities?.value
			)
		);
	}

	useEffect(() => {
		fetchPermittedFederalUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchPermittedCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptionFederalUnit]);

	useEffect(() => {
		fetchPermittedHealthUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOptionCities]);

	async function handleLogin(uf?: string, ibgeCode?: string, cnes?: string) {
		await getCurrentAccount(uf, ibgeCode, cnes);
		navigate("/");
	}

	return (
		<div
			id="body-layout"
			className="d-flex flex-column justify-content-between bg-white"
			style={{ height: "25rem" }}
		>
			<Row className="ps-10 pe-10">
				<Col sm={12} md={12} lg={3} xl={3}>
					<section className="mb-5 ms-3 me-3">
						<FederalUnitsSelect
							id="federal-unit-select"
							defaultValue={selectedOptionFederalUnit}
							value={selectedOptionFederalUnit}
							federalUnits={federalUnits}
							onChange={(newValue) => {
								setSelectedOptionFederalUnit(newValue as SelectOptionsProps);
								setSelectedOptionCities({
									label: "Selecione...",
								} as SelectOptionsProps);
								setSelectedOptionUnit({} as SelectOptionsProps);
							}}
						/>
					</section>
				</Col>
				<Col sm={12} md={12} lg={9} xl={9}>
					<section className="mb-5 ms-3 me-3">
						<CitiesSelect
							id="cities-select"
							defaultValue={selectedOptionCities}
							value={selectedOptionCities}
							accounts={cities}
							onChange={(newValue) => {
								setSelectedOptionCities(newValue as SelectOptionsProps);
								setSelectedOptionUnit({
									label: "Selecione...",
								} as SelectOptionsProps);
								setInputSelectUnitDisable(false);
							}}
						/>
					</section>
				</Col>
			</Row>
			<section className="mb-5 ms-3 me-3 pe-10 ps-10">
				<UnitsSelect
					id="units-select"
					noDefaultOption={true}
					units={healthUnits}
					value={selectedOptionUnit}
					isDisabled={inputSelectUnitDisable}
					onChange={(newValue) => {
						setSelectedOptionUnit(newValue as SelectOptionsProps);
						setButtonDisabled(false);
					}}
				/>
			</section>
			<div className="position-relative text-center mt-5 w-auto p-2 mb-2">
				<Button
					disabled={buttonDisabled}
					className="button-entrar mb-2 "
					style={{ width: "20rem" }}
					onClick={() =>
						handleLogin(
							selectedOptionFederalUnit?.value,
							selectedOptionCities?.value,
							selectedOptionUnit?.value
						)
					}
				>
					Entrar
				</Button>
			</div>
		</div>
	);
}
