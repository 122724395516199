import { useState } from "react";
import { FaBan, FaCheckCircle, FaEye, FaKey, FaUnlock, FaUser } from "react-icons/fa";

import Button from "react-bootstrap/Button";

import { Paginated } from "@/@types/generics/paginated";
import { PermissionEnum } from "@/utils/enums/PermissionEnum";
import { formatIdentifier } from "@/utils/formatIdentifier";
import { UserRequest } from "..";

import { TableSkeletons } from "@/components/TableSkeletons";
import { PaginationLinks } from "@/components/PaginationLinks";
import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { Restrict } from "@/components/Restrict";
import { ModalProfessionalInformation } from "@/components/ModalProfessionalInformation";
import { ModalProfessionalUnitAccess } from "@/components/ModalProfessionalUnitAccess";
import { User } from "@/@types/app/user";
import { Account } from "@/@types/app/Account";

type Props = {
	isLoading: boolean;
	paginatedUsers: Paginated<UserRequest>;
	pageNumber: number;
	cities: Account[];
	selectedUf?: string;
	selectedIbgeCode?: string;
	selectedCnes?: string;
	handleChangePageNumber: (pageNumber: number) => void;
	handleChangeAccess: (user: User) => void;
	handleConfirmRevoke: (user: User, uf: string, ibgeCode?: string) => void;
	refreshTable: () => void;
};

export function UsersTable({
	isLoading,
	paginatedUsers,
	pageNumber,
	selectedUf,
	selectedIbgeCode,
	selectedCnes,
	handleChangePageNumber,
	handleChangeAccess,
	handleConfirmRevoke,
	refreshTable,
}: Props) {
	const [selectedUser, setSelectedUser] = useState<User>({} as User);
	const [showModalProfessionalInformation, setShowModalProfessionalInformation] = useState(false);
	const [showModalProfessionalUnitAccess, setShowModalProfessionalUnitAccess] = useState(false);

	const handleShowModalProfessionalInformation = () => {
		setShowModalProfessionalInformation(true);
	};
	const handleCloseModalProfessionalInformation = () => {
		setShowModalProfessionalInformation(false);
		refreshTable();
	};

	const handleShowModalProfessionalUnitAccess = () => {
		setShowModalProfessionalUnitAccess(true);
	};
	const handleCloseModalProfessionalUnitAccess = () => {
		setShowModalProfessionalUnitAccess(false);
		refreshTable();
	};

	return (
		<>
			<table
				id="table-professionals"
				className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
			>
				<thead>
					<tr className="text-center text-muted">
						<th className="min-w-125px sorting">STATUS</th>
						<th className="min-w-125px sorting">NOME</th>
						<th className="min-w-125px sorting">PERFIL</th>
						<th className="min-w-125px sorting">CPF</th>
						<th className="min-w-125px sorting">AÇÕES</th>
					</tr>
				</thead>
				<tbody className="table-tbody">
					{paginatedUsers.data ? (
						<>
							{paginatedUsers.data?.map((userRow: UserRequest) => (
								<tr
									key={userRow.id}
									className="text-center border-bottom-1 border-dark"
								>
									<td>
										{Boolean(userRow.isLocked) ? (
											<label className="alert alert-block">Bloqueado</label>
										) : Boolean(userRow.hasProfileActive) ? (
											<label className="alert alert-active my-2 ps-7 pe-7">
												Ativado
											</label>
										) : (
											<label className="alert alert-pending my-2 ps-7 pe-7">
												Pendente
											</label>
										)}
									</td>
									<td>{userRow.fullName} </td>
									<td>{userRow.profile}</td>
									<td>{formatIdentifier(userRow.identifier)}</td>
									<td>
										<div className="d-flex justify-content-center gap-2 w-100">
											<Button
												title="visualizar professional"
												variant="primary"
												className="fs-8 px-5 py-2 ml-auto"
												onClick={() => {
													setSelectedUser(userRow);
													handleShowModalProfessionalInformation();
												}}
											>
												<FaEye />
											</Button>
											{!userRow.isLocked && (
												<Button
													title="Configuração de acesso de Unidades"
													variant="warning"
													className="fs-8 px-5 py-2 ml-auto"
													onClick={() => {
														setSelectedUser(userRow);
														handleShowModalProfessionalUnitAccess();
													}}
												>
													<FaKey />
												</Button>
											)}
											<Restrict
												permissions={[
													PermissionEnum.STATE_PROFESSIONAL_MANAGEMENT,
													PermissionEnum.VIEW_PROFESSIONAL_MANAGEMENT,
												]}
											>
												{selectedUf && (
													<Button
														title="Remover Acessos"
														variant="danger"
														className="fs-8 px-5 py-2 ml-auto"
														onClick={() => {
															setSelectedUser(userRow);
															handleConfirmRevoke(
																userRow,
																selectedUf || "",
																selectedIbgeCode
															);
														}}
													>
														<FaUnlock />
													</Button>
												)}
											</Restrict>

											<Restrict>
												{userRow.isLocked ? (
													<Button
														title="Desbloqueio"
														variant="success"
														className="fs-8 px-5 py-2 ml-auto"
														onClick={() => {
															setSelectedUser(userRow);
															handleChangeAccess(userRow);
														}}
													>
														<FaCheckCircle />
													</Button>
												) : (
													<Button
														title="Bloqueio"
														variant="secondary"
														className="fs-8 px-5 py-2 ml-auto"
														onClick={() => {
															setSelectedUser(userRow);
															handleChangeAccess(userRow);
														}}
													>
														<FaBan />
													</Button>
												)}
											</Restrict>
										</div>
									</td>
								</tr>
							))}
						</>
					) : (
						isLoading && <TableSkeletons numberOfCells={5} />
					)}
				</tbody>
			</table>
			{paginatedUsers.totalRecords ? (
				<PaginationLinks
					itemsPerPage={paginatedUsers.pageSize}
					totalPages={paginatedUsers.totalRecords}
					changeSelectedPage={handleChangePageNumber}
					pageNumber={pageNumber || 0}
				/>
			) : (
				!isLoading && (
					<NoRecordsFeedback
						message={"Nenhum Profissional encontrado."}
						icon={<FaUser />}
					/>
				)
			)}

			<ModalProfessionalInformation
				show={showModalProfessionalInformation}
				onHide={handleCloseModalProfessionalInformation}
				selectedUser={selectedUser}
			/>

			<ModalProfessionalUnitAccess
				show={showModalProfessionalUnitAccess}
				onHide={handleCloseModalProfessionalUnitAccess}
				selectedUser={selectedUser}
				selectedUf={selectedUf}
				selectedIbgeCode={selectedIbgeCode}
				selectedCnes={selectedCnes}
				setRefreshPage={() => refreshTable()}
			/>
		</>
	);
}
