import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import dayjs from "dayjs";

import { useUnits } from "@/hooks/useUnits";
import { Indicators } from "@/utils/enums/indicators";
import { useHealthTeams } from "@/hooks/useHealthTeam";
import { SelectOptionsProps } from "@/@types/generics/genericals";
import { Option } from "@/@types/generics/Option";

import { FiltersIndicatosDetails } from "./FiltersIndicatorsDetails";
import { defaultUnit } from "../UnitsSelect";
import { defaultHealthTeam } from "../HealthTeamsSelect";
import { Indicator1 } from "./Indicator1";
import { Indicator2 } from "./Indicator2";
import { Indicator3 } from "./Indicator3";
import { Indicator4 } from "./Indicator4";
import { Indicator5 } from "./Indicator5";
import { Indicator6 } from "./Indicator6";
import { Indicator7 } from "./Indicator7";

export function IndicatorsPanel() {
	const { indicator } = useParams();
	const navigate = useNavigate();
	const { units } = useUnits();
	const { healthTeams } = useHealthTeams();
	const [selectedUnit, setSelectedUnit] = useState<SelectOptionsProps>(defaultUnit);
	const [selectedHealthTeam, setSelectedHealthTeam] =
		useState<SelectOptionsProps>(defaultHealthTeam);
	const [selectedIndicator, setSelectedIndicator] = useState<Option<number> | undefined>({
		value: indicator ? Number(indicator) : Indicators.PregnantWomenWithAtLeastSixConsultations,
		label: `Indicador ${
			indicator ? Number(indicator) : Indicators.PregnantWomenWithAtLeastSixConsultations
		}`,
	});
	const quarter = Math.ceil((dayjs().month() + 1) / 4);
	const currentYear = dayjs().year();
	const [selectedQuarter, setSelectedQuarter] = useState<SelectOptionsProps>({
		value: `Q${quarter}/${currentYear}`,
		label: `Q${quarter} - ${currentYear}`,
	});

	function getIndicator() {
		switch (Number(indicator) || selectedIndicator?.value) {
			case Indicators.PregnantWomenWithAtLeastSixConsultations:
				return (
					<Indicator1
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.PregnantWomenTestedForSyphilisAndHIV:
				return (
					<Indicator2
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.PregnantWomenWithDentalCarePerformed:
				return (
					<Indicator3
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.CytopathologicalExamsCoverage:
				return (
					<Indicator4
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.PoliomyelitisAndPentavalentVaccinationCoverage:
				return (
					<Indicator5
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.HypertensivePeopleWithBloodPressureMeasuredEverySixMonths:
				return (
					<Indicator6
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			case Indicators.DiabeticsWithHemoglobinA1cRequested:
				return (
					<Indicator7
						selectedHealthTeam={selectedHealthTeam}
						selectedQuarter={selectedQuarter}
						selectedUnit={selectedUnit}
					/>
				);
			default:
				navigate(`/error/404`);
		}
	}

	return (
		<div className="p-2">
			<FiltersIndicatosDetails
				selectedQuarter={selectedQuarter}
				selectedUnit={selectedUnit}
				selectedHealthTeam={selectedHealthTeam}
				selectedIndicator={selectedIndicator}
				units={units}
				healthTeams={healthTeams}
				setSelectedQuarter={setSelectedQuarter}
				setSelectedUnit={setSelectedUnit}
				setSelectedHealthTeam={setSelectedHealthTeam}
				setSelectedIndicator={setSelectedIndicator}
			/>

			{getIndicator()}
		</div>
	);
}
