import dayjs from "dayjs";

import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FaDotCircle, FaEye, FaSyringe } from "react-icons/fa";

import { Citizen } from "@/@types/esus/Citizen";
import { Vaccination } from "@/@types/esus/Vaccination";

import { TableSkeletons } from "@/components/TableSkeletons";
import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";

export type CitizenVaccinations = {
	citizen: Citizen & {
		area?: string;
		phone?: string;
	};
	statusD1: boolean;
	statusD2: boolean;
	statusDU: boolean;
	vaccinationStatus: "finished" | "pending" | "delayed" | "abandoned" | "lost";
	vaccinations: Vaccination[];
};

type CaptionType = {
	title: string;
	color: string;
};

type Props = {
	citizensVaccinations: CitizenVaccinations[];
	isLoading?: boolean;
	handleViewCitizenVaccinations?: (citizenVaccinations: CitizenVaccinations) => void;
};

export function TripleViralCitizensTable({
	citizensVaccinations,
	isLoading,
	handleViewCitizenVaccinations,
}: Props) {
	const captions: CaptionType[] = [
		{ title: "Concluído - crianças que completaram o esquema vacinal", color: "#008ffb" },
		{
			title: "Pendente - crianças que não completaram a vacinação e estão em dias",
			color: "#ffef00",
		},
		{
			title: "Em atraso - crianças que estão com alguma vacina em atraso (considerando 15 dias de tolerância do período da D1 e D2)",
			color: "#feb019",
		},
		{
			title: "Perdido - crianças que completaram 2 anos e não tomaram nenhuma vacina",
			color: "#ff4560",
		},
		{
			title: "Abandono - crianças que completaram 2 anos e não concluiram o esquema vacinal",
			color: "#775dd0",
		},
	];

	return (
		<Table
			bordered
			responsive
			hover
			id="table-vaccinated-citizens"
			className="table align-middle table-row-dashed fs-7 fw-bold dataTable no-footer"
		>
			<thead>
				<tr className="text-center text-muted">
					<th style={{ width: "130px" }}>
						STATUS{" "}
						<CardOverlayTrigger>
							<section>
								<label className="fw-bolder">Legenda:</label>
								{captions?.map((caption, index) => (
									<div
										key={`triple-viral-caption-${index}`}
										className="d-flex gap-3 m-2"
									>
										<div className="d-flex align-items-center">
											{
												<FaDotCircle
													className="me-2"
													style={{ color: caption.color }}
												/>
											}
											<label className="text-nowrap">{caption.title}</label>
										</div>
									</div>
								))}
							</section>
						</CardOverlayTrigger>
					</th>
					<th>NOME</th>
					<th>DATA DE NASCIMENTO</th>
					<th>IDADE</th>
					<th>MC</th>
					<th>D1</th>
					<th>D2</th>
					<th>DU</th>
					<th>AÇÕES</th>
				</tr>
			</thead>
			<tbody className="table-tbody" id="triple-viral-citizens-table">
				{!isLoading ? (
					<>
						{citizensVaccinations?.map(
							(citizenVaccinations: CitizenVaccinations, index) => (
								<tr
									key={`citizen-vaccinations-${index}`}
									className="text-center border-bottom-1 border-dark"
								>
									<td style={{ width: "150px" }}>
										{citizenVaccinations.vaccinationStatus === "abandoned" ? (
											<label
												className="alert alert-block text-black m-0 w-75"
												style={{ backgroundColor: "#775dd0" }}
											>
												Abandono
											</label>
										) : citizenVaccinations.vaccinationStatus === "pending" ? (
											<label
												className="alert alert-pending text-black m-0 w-75"
												style={{ backgroundColor: "#ffef00" }}
											>
												Pendente
											</label>
										) : citizenVaccinations.vaccinationStatus === "delayed" ? (
											<label
												className="alert alert-pending text-black m-0 w-75"
												style={{ backgroundColor: "#feb019" }}
											>
												Em atraso
											</label>
										) : citizenVaccinations.vaccinationStatus === "finished" ? (
											<label
												className="alert alert-success text-black m-0 w-75"
												style={{ backgroundColor: "#008ffb" }}
											>
												Concluído
											</label>
										) : (
											<label
												className="alert alert-block text-black m-0 w-75"
												style={{ backgroundColor: "#ff4560" }}
											>
												Perdido
											</label>
										)}
									</td>
									<td className="text-start">
										{citizenVaccinations.citizen.fullName}{" "}
									</td>
									<td>
										{citizenVaccinations.citizen.birthdate
											? dayjs(citizenVaccinations.citizen.birthdate).format(
													"DD/MM/YYYY"
											  )
											: ""}
									</td>
									<td>{citizenVaccinations.citizen.age}</td>
									<td>{citizenVaccinations.citizen.area}</td>
									<td>
										{citizenVaccinations.statusD1 ? (
											<FaSyringe className="text-primary fs-2" />
										) : (
											<FaSyringe className="text-secondary fs-2" />
										)}
									</td>
									<td>
										{citizenVaccinations.statusD2 ? (
											<FaSyringe className="text-primary fs-2" />
										) : (
											<FaSyringe className="text-secondary fs-2" />
										)}
									</td>
									<td>
										{citizenVaccinations.statusDU ? (
											<FaSyringe className="text-primary fs-2" />
										) : (
											<FaSyringe className="text-secondary fs-2" />
										)}
									</td>
									<td>
										<div className="d-flex justify-content-center gap-2 w-100">
											<Button
												title="Visualizar histórico de vacinação"
												variant="primary"
												className="fs-8 px-5 py-2 ml-auto"
												onClick={() => {
													handleViewCitizenVaccinations &&
														handleViewCitizenVaccinations(
															citizenVaccinations
														);
												}}
											>
												<FaEye />
											</Button>
										</div>
									</td>
								</tr>
							)
						)}
					</>
				) : (
					<TableSkeletons numberOfCells={9} numberOfRows={10} />
				)}
			</tbody>
		</Table>
	);
}
