import { useState } from "react";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import { FaInfoCircle } from "react-icons/fa";

import { HealthTeam } from "@/@types/esus/HealthTeam";
import { HealthUnit } from "@/@types/esus/HealthUnit";
import { Period } from "@/@types/generics/Period";
import { Professional } from "@/@types/esus/Professional";
import { TScheduleStatus } from "@/@types/esus/TScheduleStatus";
import { SHORT_MONTHS } from "@/utils/enums/DateEnum";
import { ProfessionalCategoryEnum } from "@/utils/enums/ProfessionalCategoryEnum";
import { TScheduleStatusEnum } from "@/utils/enums/TScheduleStatusEnum";
import { ResumeGrouped } from "@/services/esus/attendanceService";

import { CardOverlayTrigger } from "@/components/CardOverlayTrigger";
import { BarChart } from "@/components/Charts/BarChart";
import { DonutChart } from "@/components/Charts/DonutChart";

type AttendanceResumeType = "healthUnit" | "healthTeam";
type ProfessionalAttendanceResumeType = "medic" | "nurse" | "dentist" | "others";

type Props = {
	attendancesAggregatedByProfessionalCategoryAndProfessional: ResumeGrouped<
		string,
		ResumeGrouped<Professional, object>
	>[];
	attendancesAggregatedByPeriodAndProfessionalCategory: ResumeGrouped<
		Period,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByHealthUnitAndProfessionalCategory: ResumeGrouped<
		HealthUnit,
		ResumeGrouped<string, object>
	>[];
	attendancesAggregatedByHealthTeamAndProfessionalCategory: ResumeGrouped<
		HealthTeam,
		ResumeGrouped<string, object>
	>[];
	schedulesAggregatedByPeriodAndScheduleStatus: ResumeGrouped<
		Period,
		ResumeGrouped<TScheduleStatus, object>
	>[];
};

export function ProfessionalAttendanceDetails({
	attendancesAggregatedByProfessionalCategoryAndProfessional,
	attendancesAggregatedByPeriodAndProfessionalCategory,
	attendancesAggregatedByHealthUnitAndProfessionalCategory,
	attendancesAggregatedByHealthTeamAndProfessionalCategory,
	schedulesAggregatedByPeriodAndScheduleStatus,
}: Props) {
	const [attendanceResumeOptionMenu, setAttendanceResumeOptionMenun] =
		useState<AttendanceResumeType>("healthUnit");
	const [professionalAttendanceResumeOptionMenu, setProfessionalAttendanceResumeOptionMenu] =
		useState<ProfessionalAttendanceResumeType>("medic");
	const selectedProfessionalAttendanceResumeOptionMenuCode =
		professionalAttendanceResumeOptionMenu === "medic"
			? ProfessionalCategoryEnum.MEDIC
			: professionalAttendanceResumeOptionMenu === "nurse"
			? ProfessionalCategoryEnum.NURSE
			: professionalAttendanceResumeOptionMenu === "dentist"
			? ProfessionalCategoryEnum.DENTIST
			: undefined;

	const periodsSeries = attendancesAggregatedByPeriodAndProfessionalCategory.map(
		(aggregatedAttendances) => aggregatedAttendances.total
	);
	const periodsCategories = attendancesAggregatedByPeriodAndProfessionalCategory.map((period) =>
		period.key.year && period.key.month
			? `${period.key.year}/${SHORT_MONTHS[period.key.month - 1]}`
			: ""
	);

	const healthUnitsCategories = attendancesAggregatedByHealthUnitAndProfessionalCategory
		?.map((aggregatedAttendances) => aggregatedAttendances.key)
		.map((dashboard) => (dashboard.cnes ? `${dashboard.cnes} - ${dashboard.name}` : ""));
	const healthTeamsCategories = attendancesAggregatedByHealthTeamAndProfessionalCategory
		?.map((aggregatedAttendances) => aggregatedAttendances.key)
		.map((dashboard) => (dashboard.ine ? `${dashboard.ine} - ${dashboard.name}` : ""));

	const selectedProfessionalCategoryAttendanceCategories =
		attendanceResumeOptionMenu === "healthUnit" ? healthUnitsCategories : healthTeamsCategories;
	const selectedProfessionalCategoryDashboard =
		attendanceResumeOptionMenu === "healthUnit"
			? attendancesAggregatedByHealthUnitAndProfessionalCategory.map(
					(dashboard) => dashboard.values || []
			  )
			: attendancesAggregatedByHealthTeamAndProfessionalCategory.map(
					(dashboard) => dashboard.values || []
			  );

	const professionalAttendances = attendancesAggregatedByProfessionalCategoryAndProfessional
		.filter(
			(aggregatedAttendances) =>
				(!selectedProfessionalAttendanceResumeOptionMenuCode &&
					![
						ProfessionalCategoryEnum.DENTIST.valueOf(),
						ProfessionalCategoryEnum.MEDIC.valueOf(),
						ProfessionalCategoryEnum.NURSE.valueOf(),
					].includes(aggregatedAttendances.key)) ||
				(selectedProfessionalAttendanceResumeOptionMenuCode &&
					aggregatedAttendances.key ===
						selectedProfessionalAttendanceResumeOptionMenuCode.valueOf())
		)
		.flatMap((aggregatedAttendances) => aggregatedAttendances.values || [])
		.sort((aggregatedA, aggregatedB) => aggregatedB.total - aggregatedA.total);
	const selectedProfessionalSeries = [
		{
			data: professionalAttendances.map((professional) => professional.total),
			name: "Profissional",
		},
	];
	const selectedProfessionalCategories = professionalAttendances.map(
		(professional) => `${professional.key.cns} - ${professional.key.name}`
	);

	const selectedProfessionalCategoryAttendanceSeries = [
		{
			data: selectedProfessionalCategoryDashboard.map(
				(professionalCategories) =>
					professionalCategories
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.MEDIC.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Médico",
		},
		{
			data: selectedProfessionalCategoryDashboard.map(
				(professionalCategories) =>
					professionalCategories
						.filter(
							(professionalCategory) =>
								professionalCategory.key ===
								ProfessionalCategoryEnum.NURSE.valueOf()
						)
						?.map((professionalCategory) => professionalCategory.total)
						.reduce((acc, curr) => acc + curr, 0) || 0
			),
			name: "Enfermeiro",
		},
	];
	const otherProfessionalCategoryAttendanceSerie = [
		{
			data: attendancesAggregatedByProfessionalCategoryAndProfessional
				.filter(
					(professionalCategory) =>
						![
							ProfessionalCategoryEnum.MEDIC.valueOf(),
							ProfessionalCategoryEnum.NURSE.valueOf(),
							ProfessionalCategoryEnum.DENTIST.valueOf(),
						].includes(professionalCategory.key)
				)
				.map((professionalCategory) => professionalCategory.total),
			name: "Outros",
		},
	];
	const scheduleStatusAggregated = schedulesAggregatedByPeriodAndScheduleStatus.flatMap(
		(aggregatedSchedule) => aggregatedSchedule.values || []
	);

	const scheduleResume = {
		totalCanceled: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.CANCELED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalExcluded: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.EXCLUDED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalNotShowUp: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier ===
						TScheduleStatusEnum.DID_NOT_SHOW_UP.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalNotWait: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.DID_NOT_WAIT.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
		totalAttended: scheduleStatusAggregated
			.filter(
				(scheduleAggregated) =>
					scheduleAggregated.key.identifier &&
					scheduleAggregated.key.identifier === TScheduleStatusEnum.ATTENDED.valueOf()
			)
			.map((scheduleAggregated) => scheduleAggregated.total || 0)
			.reduce((acc, curr) => acc + curr, 0),
	};

	const attendedSchedulePeriodsSeries = schedulesAggregatedByPeriodAndScheduleStatus.map(
		(aggregatedSchedule) =>
			aggregatedSchedule.values?.find(
				(schedule) => schedule.key.identifier === TScheduleStatusEnum.ATTENDED
			)?.total || 0
	);

	const scheduleSeries = [
		scheduleResume.totalAttended,
		scheduleResume.totalCanceled,
		scheduleResume.totalExcluded,
		scheduleResume.totalNotShowUp,
		scheduleResume.totalNotWait,
	];
	const scheduleCategories = [
		"Atendidos",
		"Cancelados",
		"Excluídos",
		"Não compareceu",
		"Não aguardou",
	];

	return (
		<>
			<Row className="m-2">
				<Col sm={12} md={12} lg={12} xl={12} className="px-0">
					<Card className="h-100">
						<Card.Header>
							<Card.Title className="d-flex gap-2">
								Evolução histórica dos atendimentos
								<CardOverlayTrigger
									header={"Evolução histórica dos atendimentos"}
									message={
										"Acompanhamento mensal dos atendimentos realizados com ou sem agendamento"
									}
									icon={<FaInfoCircle />}
								/>
							</Card.Title>
						</Card.Header>
						<Card.Body className="h-100">
							<BarChart
								series={[
									{ data: periodsSeries, name: "Não agendados" },
									{ data: attendedSchedulePeriodsSeries, name: "Agendados" },
								]}
								categories={periodsCategories}
								minHeight={400}
								isVertical
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="p-2">
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header>
							<Card.Title>
								Percentual de atendimentos por categoria profissional
							</Card.Title>
						</Card.Header>
						<Card.Body
							className="d-flex justify-content-center"
							style={{ height: 400 }}
						>
							<DonutChart
								height={"100%"}
								series={selectedProfessionalCategoryAttendanceSeries
									.concat(otherProfessionalCategoryAttendanceSerie)
									.map((professionalCategory) =>
										professionalCategory.data.reduce(
											(acc, curr) => acc + curr,
											0
										)
									)}
								labels={selectedProfessionalCategoryAttendanceSeries
									.concat(otherProfessionalCategoryAttendanceSerie)
									.map((professionalCategory) => professionalCategory.name)}
							/>
						</Card.Body>
					</Card>
				</Col>
				<Col sm={12} lg={6}>
					<Card className="h-100">
						<Card.Header className="d-flex fs-2 fw-bolder justify-content-center align-items-center">
							<Card.Title>Percentual de agendamentos por status</Card.Title>
						</Card.Header>
						<Card.Body
							className="d-flex justify-content-center"
							style={{ height: 400 }}
						>
							<DonutChart
								height={"100%"}
								series={scheduleSeries}
								labels={scheduleCategories}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<Row className="m-2">
				<Card>
					<Card.Header>
						<Card.Title className="d-flex gap-2">
							Atendimentos por categoria profissional
							<CardOverlayTrigger
								header={"Atendimentos por categoria profissional"}
								message={
									"Total de atendimentos realizados no período por categoria profissional e unidade/equipe"
								}
								icon={<FaInfoCircle />}
							/>
						</Card.Title>
						<Nav
							fill
							variant="tabs"
							className="d-flex align-items-center"
							activeKey={attendanceResumeOptionMenu!}
							onSelect={(event) =>
								setAttendanceResumeOptionMenun(
									(event || "healthUnit") as AttendanceResumeType
								)
							}
						>
							<Nav.Item>
								<Nav.Link
									eventKey="healthUnit"
									className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
								>
									Unidade
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="healthTeam"
									className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
								>
									Equipe
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</Card.Header>
					<Card.Body>
						<div className="overflow-y-scroll" style={{ maxHeight: 600 }}>
							<BarChart
								series={selectedProfessionalCategoryAttendanceSeries}
								categories={selectedProfessionalCategoryAttendanceCategories}
								categoryTextWidth={380}
								isStacked
							/>
						</div>
					</Card.Body>
				</Card>
			</Row>
			<Row className="m-2">
				<Card>
					<Card.Header>
						<Card.Title className="d-flex gap-2">
							Atendimentos por profissional
							<CardOverlayTrigger
								header={"Atendimentos por profissional"}
								message={
									"Total de atendimentos realizados no período por profissional"
								}
								icon={<FaInfoCircle />}
							/>
						</Card.Title>
						<Nav
							fill
							variant="tabs"
							className="d-flex align-items-center"
							activeKey={professionalAttendanceResumeOptionMenu!}
							onSelect={(event) =>
								setProfessionalAttendanceResumeOptionMenu(
									(event || "medic") as ProfessionalAttendanceResumeType
								)
							}
						>
							<Nav.Item>
								<Nav.Link
									eventKey="medic"
									className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
								>
									Médico
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="nurse"
									className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
								>
									Enfermeiro
								</Nav.Link>
							</Nav.Item>
							<Nav.Item>
								<Nav.Link
									eventKey="others"
									className="btn btn-sm btn-color-muted btn-active btn-active-secondary fw-bold fs-6 px-4 me-1 cursor-pointer"
								>
									Outros
								</Nav.Link>
							</Nav.Item>
						</Nav>
					</Card.Header>
					<Card.Body>
						<div className="overflow-y-scroll" style={{ maxHeight: 600 }}>
							<BarChart
								series={selectedProfessionalSeries}
								categories={selectedProfessionalCategories}
								categoryTextWidth={380}
							/>
						</div>
					</Card.Body>
				</Card>
			</Row>
		</>
	);
}
