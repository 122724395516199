import Table from "react-bootstrap/Table";

import { removeDuplicates } from "@/utils/genericals";
import { Vaccination } from "@/@types/esus/Vaccination";

import { IndicatorModalCard } from "@/components/IndicatorModalCard";

export type VaccinationCardData = Vaccination & {
	status?: "vaccinated" | "onTime" | "pending" | "delayed";
};

type Props = {
	vaccinations: VaccinationCardData[];
};

export function VaccinationCard({ vaccinations }: Props) {
	const immunobiologicalDosageNames = removeDuplicates<string[]>(
		vaccinations
			.map((vaccination) => vaccination.immunobiologicalDosage.acronym)
			.sort((a, b) => a.localeCompare(b))
	);
	const immunobiologicalIdentifiers = removeDuplicates<string[]>(
		vaccinations.map((vaccination) => vaccination.immunobiological.identifier?.toString() || "")
	);

	return (
		<section className="overflow-auto">
			<Table responsive>
				<thead>
					<tr>
						<th
							colSpan={1 + (vaccinations?.length || 0)}
							className="text-center text-white bg-primary fs-6"
						>
							CARTÃO DE VACINAÇÃO
						</th>
					</tr>
				</thead>
				<tbody>
					{immunobiologicalIdentifiers?.map((identifier, index) => (
						<tr key={`immunobiological-${index}`} className="border border-2">
							<td className="align-middle text-center fs-9 fw-bolder p-2">
								{vaccinations
									.find(
										(vaccine) =>
											vaccine.immunobiological.identifier ===
											String(identifier)
									)
									?.immunobiological.name.toUpperCase()}
							</td>
							{immunobiologicalDosageNames?.map((immunobiologicalDosage, index) => (
								<td key={`dosage-name-${index}`}>
									<div className="d-flex justify-content-center flex-column gap-2 h-100">
										{vaccinations
											?.filter(
												(vaccination) =>
													vaccination.immunobiological.identifier ===
														identifier &&
													vaccination.immunobiologicalDosage.acronym ===
														immunobiologicalDosage
											)
											.map((item, index) => (
												<IndicatorModalCard
													key={`card-${index}`}
													theme={
														item.status === "vaccinated"
															? "success"
															: item.status === "onTime"
															? "primary"
															: item.status === "pending"
															? "warning"
															: item.status === "delayed"
															? "danger"
															: "success"
													}
													title={item.immunobiologicalDosage?.name}
													subtitle={item.dateofVaccine ?? ""}
													subtitleHeading=""
													containerClassName="h-100"
												>
													<span className="d-flex flex-column text-gray-900 fw-bolder">
														{item.professional.name && (
															<label className="font-roboto fs-9 fw-light ms-2 text-gray-900">
																<label className="fw-bolder fs-9">
																	Professional:
																</label>
																{item.professional?.name}
															</label>
														)}
													</span>
													<span className="d-flex align-items-center text-gray-900 fw-bolder">
														{item.healthUnit?.name && (
															<label className="font-roboto fs-9 fw-light ms-2 text-gray-900 ">
																<label className="fw-bolder fs-9">
																	Unidade:
																</label>
																{item.healthUnit?.name}
															</label>
														)}
													</span>
												</IndicatorModalCard>
											))}
									</div>
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Table>
		</section>
	);
}
