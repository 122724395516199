import dayjs from "dayjs";

import Modal, { ModalProps } from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Row from "react-bootstrap/Row";

import { displayRegisterOrigin } from "@/utils/indicators";
import { PregnantPatient } from "@/services/esus/indicatorService";

import { TimeLine, TimeLineContent } from "@/components/TimeLine";

type Props = ModalProps & {
	patientSelected: PregnantPatient;
};

export function ModalIndicator1({ patientSelected, ...rest }: Props) {
	return (
		<Modal {...rest} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title>
					<h3 className="text-uppercase text-dark fw-bold fs-7">ATENDIMENTOS</h3>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<section className="my-3">
					<Row>
						<Col sm={12} md={5} lg={5} xl={5}>
							<span className="fw-bold fs-8 text-primary">Paciente: </span>
							<span>
								{patientSelected?.fullName ? patientSelected?.fullName : "N/A"}
							</span>
						</Col>
						<Col sm={4} md={3} lg={3} xl={3}>
							<span className="fw-bold fs-8 text-primary">Data de nascimento: </span>
							<span>
								{patientSelected?.birthdate
									? dayjs(patientSelected?.birthdate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">DUM: </span>
							<span>
								{patientSelected?.firstAttendanceDate
									? dayjs(patientSelected?.firstDumDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
						<Col sm={4} md={2} lg={2} xl={2}>
							<span className="fw-bold fs-8 text-primary">DPP: </span>
							<span>
								{patientSelected?.dppDate
									? dayjs(patientSelected?.dppDate).format("DD/MM/YYYY")
									: "N/A"}
							</span>
						</Col>
					</Row>
				</section>

				<TimeLine
					timeLineTitle="Atendimentos"
					timeLineContents={patientSelected?.attendances?.map<TimeLineContent>(
						(attendance, index) => {
							return {
								content: (
									<Row key={index}>
										<Col sm={6} md={6} lg={9} xl={9}>
											<p>
												<span className="fw-bold text-primary">
													Equipe:
												</span>
												{attendance.healthTeam?.ine
													? ` ${attendance?.healthTeam?.ine}-
													${attendance?.healthTeam?.name}`
													: "N/A"}
											</p>
											<p>
												<span className="fw-bold text-primary">IG: </span>
												{attendance?.gestationalAge
													? `${attendance?.gestationalAge} SEMANAS`
													: "N/A"}
											</p>
											<p>
												<p className="text-primary">
													Profissional:
												</p>
												<p>
													<span className="text-primary">Nome: </span>
													{attendance?.professional1?.name?.toUpperCase() ||
														"N/A"}
												</p>
												<p>
													<span className="text-primary">Categoria: </span>
													{attendance.cbo1?.description?.toUpperCase() ||
														"N/A"}
												</p>
											</p>
										</Col>
										<Col sm={3} md={6} lg={3} xl={3}>
											<Col className="align-items-center mt-3 gap-2">
												<span className="fw-bold">Origem: </span>
												<Badge bg="primary" className="fs-5 text-end">
													{displayRegisterOrigin(
														attendance?.registerType?.name
													)}
												</Badge>
											</Col>
										</Col>
									</Row>
								),
								eventDate: attendance?.date
									? dayjs(attendance?.date).format("DD/MM/YYYY")
									: "N/A",
							};
						}
					)}
				/>
			</Modal.Body>
		</Modal>
	);
}
