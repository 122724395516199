import Table from "react-bootstrap/Table";
import { FaHome } from "react-icons/fa";

import { HealthUnit } from "@/@types/esus/HealthUnit";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { Paginated } from "@/@types/generics/paginated";

import { NoRecordsFeedback } from "@/components/NoRecordsFeedback";
import { Skeleton } from "@/components/Skeleton";
import { PaginationLinks } from "@/components/PaginationLinks";

type Props = {
	isLoading?: boolean;
	healthUnitsPaginated: Paginated<HealthUnit>;
	selectedCnesList: string[];
	setSelectedCnesList: (cnesList: string[]) => void;
	pageNumber: number;
	setPageNumber: (pageNumber: number) => void;
};

export function ProfessionalUnitAccessTable({
	isLoading,
	healthUnitsPaginated,
	selectedCnesList,
	setSelectedCnesList,
	pageNumber,
	setPageNumber,
}: Props) {
	const { uf, user } = useCurrentAccount();

	function handleChangeSelectedCnesList(e: React.ChangeEvent<HTMLInputElement>, cnes: string) {
		const newSelectedCnesList = selectedCnesList;
		const newSelectedCnesListIndex = newSelectedCnesList.findIndex(
			(newSelectedCnes) => newSelectedCnes === cnes
		);
		if (newSelectedCnesListIndex >= 0) {
			newSelectedCnesList.splice(newSelectedCnesListIndex, 1);
		} else {
			newSelectedCnesList.push(cnes);
		}
		setSelectedCnesList(newSelectedCnesList);
	}

	return (
		<>
			<Table className="table align-middle fs-6 dataTable no-footer " responsive>
				<thead>
					<tr className="fw-bold ">
						<th style={{ width: "10%" }}>STATUS</th>
						<th style={{ width: "90%" }}>UNIDADES</th>
					</tr>
				</thead>
				<tbody>
					{isLoading ? (
						Array.from({ length: 10 }, (_, index) => (
							<tr key={index}>
								<td>
									<Skeleton className="ms-2" circle height={18} width={18} />
								</td>
								<td>
									<Skeleton />
								</td>
							</tr>
						))
					) : healthUnitsPaginated?.data ? (
						healthUnitsPaginated?.data?.map((healthUnit) => (
							<tr className="fs-7 justify-content-center" key={healthUnit.id}>
								<td className="text-center fs-1">
									<div className="form-check ms-2">
										<input
											type="checkbox"
											name="healthUnitCnes"
											className="form-check-input"
											value={healthUnit.cnes}
											defaultChecked={
												selectedCnesList.find(
													(cnes) => cnes === healthUnit.cnes
												)
													? true
													: false
											}
											onChange={(e) =>
												handleChangeSelectedCnesList(e, healthUnit.cnes)
											}
										/>
									</div>
								</td>
								<td>{healthUnit?.name}</td>
							</tr>
						))
					) : user.isAdmin && !uf ? (
						<tr>
							<td colSpan={2}>
								<div
									className="d-flex alert alert-warning text-center justify-content-center"
									role="alert"
								>
									Atenção Administrador, você deve selecionar uma cidade, para que
									seja exibida as unidades respectivas.
								</div>
							</td>
						</tr>
					) : (
						<tr>
							<td colSpan={2}>
								<NoRecordsFeedback
									message={"UNIDADE NÃO ENCONTRADA!!"}
									icon={<FaHome />}
								/>
							</td>
						</tr>
					)}
				</tbody>
			</Table>
			{healthUnitsPaginated?.totalRecords > 0 && (
				<PaginationLinks
					itemsPerPage={healthUnitsPaginated?.pageSize}
					totalPages={healthUnitsPaginated?.totalRecords}
					changeSelectedPage={setPageNumber}
					pageNumber={pageNumber || 0}
				/>
			)}
		</>
	);
}
