/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { App } from "../App";
import { useCurrentAccount } from "@/hooks/useCurrentAccount";
import { AuthPage, Logout } from "@/modules/auth";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
	const { user } = useCurrentAccount();
	const isAuthorized = Boolean(user?.id);
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<App />}>
					<Route path="error/*" element={<ErrorsPage />} />
					<Route path="logout" element={<Logout />} />
					{isAuthorized ? (
						<>
							<Route path="/*" element={<PrivateRoutes />} />
							<Route index element={<Navigate to="/home" />} />
						</>
					) : (
						<>
							<Route path="auth/*" element={<AuthPage />} />
							<Route path="*" element={<Navigate to="/auth" />} />
						</>
					)}
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export { AppRoutes };
